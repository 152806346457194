import { Component, OnInit } from '@angular/core';

@Component({
  selector:    'app-statistics',
  templateUrl: './statistics.component.html',
  styles:      [],
})
export class StatisticsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
