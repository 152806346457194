<div class="text-right">
  <div class="dropleft">
    <button style="min-width: 120px" [hidden]="requestingDocuments" mat-raised-button color="primary" class="btn"
            [matMenuTriggerFor]="saveDocMenu">
      <mat-icon style="font-size: 17px;margin-top: 5px;">keyboard_arrow_left</mat-icon>
      {{"SHARED.send" | translate}}
    </button>
    <mat-menu #saveDocMenu="matMenu" class="saveDocMenu">
      <a mat-menu-item (click)="requestDocuments($event,'email')" href="#">Email</a>
      <a mat-menu-item (click)="requestDocuments($event,'sms')" href="#">SMS</a>
      <a mat-menu-item (click)="requestDocuments($event, 'all')" href="#">Email & SMS</a>
      <a mat-menu-item (click)="requestDocuments($event, 'none')"
         href="#">{{"CASES.single.none" | translate}}</a>
    </mat-menu>
  </div>
  <!--  <button mat-raised-button color="primary" style="min-width: 120px" *ngIf="!requestingDocuments"-->
  <!--          (click)="requestDocuments()">{{"SHARED.save" | translate}}</button>-->
  <div class="spinner-container p-2">
    <mat-spinner *ngIf="requestingDocuments" diameter="30"></mat-spinner>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <!--    Basic Docs -->
    <div class="d-flex justify-content-between">
      <div>
        <h4 class="m-0 text-primary"><b>Documentación Obligatorios</b></h4>
      </div>
      <div style="padding-right: 16px">
        <mat-checkbox (change)="toggleAllDocTypes($event, 'basic')"></mat-checkbox>
      </div>
    </div>
    <mat-selection-list>
      <mat-list-option class="doc-type-holder" *ngFor="let type of this.basicDocuments"
                       (click)="addOrRemoveDocument(type)"
                       [selected]="shouldBeChecked(type)">
        {{type.label || type.name}}
      </mat-list-option>
    </mat-selection-list>
    <!--    Circumstantial Docs -->
    <div class="d-flex justify-content-between">
      <div>
        <h4 class="m-0 text-primary"><b>Según Circunstancias Del Cliente</b></h4>
      </div>
      <div style="padding-right: 16px">
        <mat-checkbox (change)="toggleAllDocTypes($event, 'circumstantial')"></mat-checkbox>
      </div>
    </div>
    <mat-selection-list>
      <mat-list-option class="doc-type-holder" *ngFor="let type of this.circumstanceDocuments"
                       (click)="addOrRemoveDocument(type)"
                       [selected]="shouldBeChecked(type)">
        {{type.label || type.name}}
      </mat-list-option>
    </mat-selection-list>
    <!--    Case Creditor Docs -->
    <div class="d-flex justify-content-between">
      <div>
        <h4 class="m-0 text-primary"><b>Justificante de deudas</b></h4>
      </div>
      <div style="padding-right: 16px">
        <mat-checkbox (change)="toggleAllDocTypes($event, 'case_creditor')"></mat-checkbox>
      </div>
    </div>
    <mat-selection-list>
      <mat-list-option *ngFor="let caseCreditor of this.caseCreditorDocs" (click)="addOrRemoveDocument(caseCreditor)"
                       [selected]="shouldBeChecked(caseCreditor)">
        {{caseCreditor.name}}
      </mat-list-option>
    </mat-selection-list>
    <mat-selection-list>
      <mat-list-option *ngFor="let publicDebtDoc of this.publicDebtDocs" (click)="addOrRemoveDocument(publicDebtDoc)"
                       [selected]="shouldBeChecked(publicDebtDoc)">
        {{publicDebtDoc.label}}
      </mat-list-option>
    </mat-selection-list>
    <!--    Bank Account Docs -->
    <div class="d-flex justify-content-between">
      <div>
        <h4 class="m-0 text-primary"><b>Certificado de titularidad y saldo de las cuentas bancarias</b></h4>
      </div>
      <div style="padding-right: 16px">
        <mat-checkbox (change)="toggleAllDocTypes($event, 'bank_account')"></mat-checkbox>
      </div>
    </div>
    <mat-selection-list>
      <mat-list-option *ngFor="let bankAccount of this.bankAccounts" (click)="addOrRemoveDocument(bankAccount)"
                       [selected]="shouldBeChecked(bankAccount)">
        {{bankAccount.name}}
      </mat-list-option>
    </mat-selection-list>
    <!--    Custom Docs -->
    <div class="pt-4">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="m-0 text-primary"><b>CUSTOM DOCUMENTS</b></h4>
        <div class="d-flex">
          <input type="text" class="form-control" [formControl]="customDocName">
          <button style="width: 100%" mat-button (click)="addCustomDocument($event)">+ Add Document</button>
        </div>
      </div>
      <mat-selection-list>
        <div class="d-flex align-items-center" *ngFor="let type of expiryDocuments.custom">
          <mat-list-option (click)="addOrRemoveDocument(type)" [selected]="shouldBeChecked(type)">
            {{type.name}}
          </mat-list-option>
          <button mat-icon-button color="warn" *ngIf="type.type === 'new-custom'">
            <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">remove_circle
            </mat-icon>
          </button>
        </div>
      </mat-selection-list>
    </div>
  </div>
</div>
