import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { Proposal } from '../../../../../../../../_base-shared/models/CaseDocument/Proposal';
import { FinancialOverview } from '../../../../../../../../_base-shared/models/Payment/FinancialOverview';
import { environment } from '../../../../../../environments/environment';
import { FinancialOverviewService } from '../../../../payment/financial-overview.service';
import { CaseService } from '../../../case.service';
import { MiscConfigService } from '../../../../config/misc-config/misc-config.service';
import { Creditor } from 'projects/_base-shared/models/Entity/Creditor';
import { CaseCreditor } from 'projects/_base-shared/models/Case/CaseCreditor';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-proposal-modal',
  templateUrl: './proposal-modal.component.html',
  styleUrls:   ['./proposal-modal.component.scss'],
  animations:  [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.3s ease-out',
              style({height: '*', opacity: 1})),
          ],
        ),
        transition(
          ':leave',
          [
            animate('0.3s ease-in',
              style({height: 0, opacity: 0})),
          ],
        ),
      ],
    ),
  ],
})
export class ProposalModalComponent implements OnInit {
  public case: Case;
  public form: UntypedFormGroup;
  public formActive = false;
  public isLoading  = 0;
  public storageUrl = environment.STORAGE_URL + '/';

  public financialOverview: FinancialOverview;
  public disposableIncome = 0;
  public unsecuredDebt    = 0;
  public showStepPayment  = false;

  public fixedDistributionFee: number = 0;
  public percentageDistributionFee: number = 0;
  public distributionFee: number = 0;
  public actualDisposable: number = 0;
  public creditors: Array<Creditor> = [];
  public creditorHasOffer: boolean = false;
  public unallocated: number = 0;
  public allocated: number = 0;
  public isLocked: boolean = true;
  public authUser: User;
  todayDate:Date = new Date();

  public disposableOptions: Array<{ value: number, label: string }> = [];
  private subscriptions: Array<Subscription> = [];

  constructor(private fb: UntypedFormBuilder,
              private caseService: CaseService,
              private dialogRef: MatDialogRef<ProposalModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private translateService: TranslateService,
              private toast: ToastrService,
              private financialOverviewService: FinancialOverviewService,
              private configService: MiscConfigService,
              public globalEventsService: MainGlobalEventService) {
  }

  ngOnInit(): void {
    this.getDistributionFees();
    this.case               = this.data.case;
    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => this.authUser = user));
    //if (this.authUser) {
    //  this.isManager = environment.MANAGER_USER_IDS.includes(this.authUser.id);
    //}
    
    const requiredRelations = [
      'product',
      'terms',
      'proposal',
      'expense',
    ];
    const loadRelations     = [];
    requiredRelations.forEach(relation => {
      if (!this.case[relation]) {
        loadRelations.push(relation);
      }
    });
    loadRelations.push('creditors');

    this.isLoading++;
    this.financialOverviewService.fetchLatest(this.case.id).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.financialOverview = result.data;
      this.isLoading++;
      this.caseService.get(this.case.id, loadRelations).pipe(finalize(() => this.isLoading--)).subscribe(res => {
        loadRelations.forEach(relation => {
          this.case[relation] = res.data[relation];
        });
        this.case.creditors = this.case.creditors.filter(creditor => creditor.pivot.type === 'unsecured');
        this.case.creditors = this.case.creditors.filter(creditor => creditor.pivot.product_id !== 18);
        this.case.creditors = this.case.creditors.filter(creditor => creditor.pivot.case_creditor_status_id !== 11);

        this.disposableOptions = [
          {value: +this.case.target_monthly_payment, label: Number(this.case.target_monthly_payment).toFixed(2) +'€ - '+ this.translateService.instant('CASES.single.target_monthly_payment')},
          {value: this.financialOverview.income - this.financialOverview.expenses, label: (+this.financialOverview.income - +this.financialOverview.expenses).toFixed(2) + '€ - ' + this.translateService.instant('CASES.financial_overview.table.disposable_income')},
        ];
        this.case.creditors = this.case.creditors.filter(creditor => creditor.pivot.case_creditor_status_id !== 14 && creditor.pivot.case_creditor_status_id !== 15 && creditor.pivot.case_creditor_status_id !== 17 );

        this.disposableIncome = +this.case.target_monthly_payment ?? this.financialOverview.income - this.financialOverview.expenses;
        // sum the unsecured debt from the creditors
        this.case.creditors.forEach(creditor => {
          this.unsecuredDebt += +creditor.pivot.debt_amount;
        });

        // this.unsecuredDebt    = this.financialOverview.unsecured_debt;
        this.buildForm();

        if (this.form && this.form.get('actual_disposable').value) {
          this.calculateDistributionFee();
          this.calculateDisposableIncome();
          this.calculateDividend();
          this.calculateUnallocated();
        }

        if (this.case.proposal?.created_at){ //&& this.case.status_id === 414
          this.isLocked=true;
        }else{
          this.isLocked=false;
        }

        this.form.get('disposable_income').valueChanges.subscribe(() => {
          this.calculateDistributionFee();
          this.calculateDisposableIncome()
        });
        this.form.get('actual_disposable').valueChanges.subscribe(() => {
          this.creditorHasOffer = false;
          this.calculateDividend()
          // this.calculateFinalPayment(this.form.get('actual_disposable').value);
        });
        this.form.get('term').valueChanges.subscribe(() => this.calculateDividend());
        this.form.get('debt_level').valueChanges.subscribe(() => this.calculateDividend());
        this.form.get('step_offer').valueChanges.subscribe(() => this.calculateDividend());
        this.form.get('step_period').valueChanges.subscribe(() => this.calculateDividend());
        this.form.get('distribution_fee').valueChanges.subscribe(value => this.calculateDisposableIncome());
        if (this.case.product.slug === 'cajaplus') {
          this.form.get('legal_fees')
            .valueChanges
            .subscribe((value: number) => this.calculateOfferToCreditorsV2(value));
        } else {
          // this.form.get('dividend').valueChanges.subscribe(value => this.calculateOfferToCreditors(value));
        }
        if(this.form.get('offer_to_creditors')) {
          this.form.get('offer_to_creditors').valueChanges.subscribe(value => this.calculateDividend());
        }
      });
    });
  }

  public closeModal(proposal: Proposal | null, dismissed: boolean): void {
    this.dialogRef.close({dismissed, data: proposal});
  }

  private buildForm(): void {
    const proposal = this.case.proposal;

    // let legalFees: number;
    // let offerToCreditors: number;
    // if (this.case.product.slug === 'cajaplus') {
    //   legalFees        = proposal?.legal_fees ?
    //     proposal?.legal_fees :
    //     Math.max(this.disposableIncome * 0.2 * 1.21, 50);
    //   offerToCreditors = +((Math.max(this.disposableIncome, 0)).toFixed(2));
    // } else {
    //   legalFees        = this.case.expense?.legal_fees || 0;
    //   offerToCreditors = proposal?.offer_to_creditors ? proposal.offer_to_creditors : null;
    // }

    const totalInstallments = this.case.product.slug === 'cajaplus' ?
      Math.ceil(this.unsecuredDebt / this.disposableIncome) :
      (proposal?.term ? proposal.term : 60);

    const disposableIncomeDisabled  = this.case.product.slug === 'cajaplus';
    const offerToCreditorsDisabled  = this.case.product.slug === 'cajaplus';
    const totalInstallmentsDisabled = this.case.product.slug === 'cajaplus';

    // calculate distribution fee
    const distributionFeePercentageCalc = (this.disposableIncome * this.percentageDistributionFee) / 100;
    this.distributionFee = distributionFeePercentageCalc;
    if (distributionFeePercentageCalc < this.fixedDistributionFee) {
      this.distributionFee = this.fixedDistributionFee;
    }
    this.distributionFee = this.distributionFee * 1.21;
    const offerToCreditorsCalc = this.disposableIncome - this.distributionFee;
    // check if the case has distribution fee set in the expense table
    if (this.case.expense?.distribution_fee) {
      this.distributionFee = +this.case.expense.distribution_fee;
    }

    this.form = this.fb.group({
      total_income:             [this.financialOverview.income.toFixed(2), [Validators.required]],
      debt_level:               [this.unsecuredDebt.toFixed(2), [Validators.required]],
      total_expenses:           [this.financialOverview.expenses.toFixed(2), [Validators.required]],
      total_assets:             [this.financialOverview.assets.toFixed(2), [Validators.required]],
      disposable_income:        [
        {value: this.disposableOptions[0].value, disabled: disposableIncomeDisabled},
        [Validators.required],
      ],
      actual_disposable:        [this.case.expense?.offer_to_creditors_total ?? (this.disposableIncome - this.distributionFee).toFixed(2)],
      distribution_fee:               [
        {value: this.case.expense?.distribution_fee ? Number(this.case.expense?.distribution_fee).toFixed(2) : Number(this.distributionFee).toFixed(2), disabled: false},
        [Validators.required],
      ],
      step_period:              [proposal?.step_period],
      step_offer:               [proposal?.step_offer],
      // offer_to_creditors:       [],
      term:                     [
        {value: totalInstallments, disabled: totalInstallmentsDisabled},
        [Validators.required]],
      dividend:                 [
        proposal?.dividend ? proposal.dividend : null,
        [Validators.required],
      ],
      payment_date:             [
        proposal?.payment_date ? new Date(proposal.payment_date) : this.setDefaultPaymentDate(),
        [Validators.required],
      ],
      unallocated: [Number(this.unallocated).toFixed(2)],
      creditor_payment_account: [proposal?.creditor_payment_account],
      payment_reference:        [proposal?.payment_reference],
      creditors: this.fb.array([])
    });
    this.addCreditorsToForm(this.case.creditors);
    this.formActive      = true;
    this.showStepPayment = !!proposal?.step_period;
  }

  private calculateDistributionFee(): void {
    const disposableIncome = this.form.get('disposable_income').value;
    const fixedDistributionFee = this.fixedDistributionFee;
    const percentageDistributionFee = this.percentageDistributionFee;
    const distributionFee = (disposableIncome * percentageDistributionFee) / 100;
    if (distributionFee < fixedDistributionFee) {
      this.form.get('distribution_fee').patchValue(+(fixedDistributionFee * 1.21).toFixed(2), {emitEvent: false, onlySelf: true});
    } else {
      this.form.get('distribution_fee').patchValue(+(distributionFee * 1.21).toFixed(2), {emitEvent: false, onlySelf: true});
    }
  }

  private addCreditorsToForm(caseCreditors: Array<CaseCreditor>): void {
    if (caseCreditors?.length > 0) {
      caseCreditors.forEach(caseCreditor => {
        this.addCreditor(caseCreditor);
      });
    }
    this.calculateUnallocated();
    // subscribe to the offer_to_creditors changes
    const creditors = this.form.get('creditors') as UntypedFormArray;
    creditors.controls.forEach(creditor => {
      creditor.get('offer_to_creditors').valueChanges.subscribe(() => {
        this.calculateUnallocated();
        this.calculateFinalPayment(creditor)
      });
    });
  }

  // add creditors with offer_to_creditors to the form array
  private addCreditor(caseCreditor: CaseCreditor = null): void {
    const creditors = this.form.get('creditors') as UntypedFormArray;
    // check if the creditor has an offer to creditors
    if (caseCreditor.pivot.offer_to_creditor) {
      this.creditorHasOffer = true;
    }
    creditors.push(this.fb.group({
      case_creditor_id: [caseCreditor.pivot.id],
      creditor_id: [caseCreditor.id],
      creditor_name: [caseCreditor.name],
      current_balance: [Number(caseCreditor.pivot.debt_amount).toFixed(2) ?? 0],
      offer_to_creditors: [Number(caseCreditor.pivot.offer_to_creditor).toFixed(2) ?? 0],
      final_payment: [Number(caseCreditor.pivot.final_payment).toFixed(2) ?? 0],
      creditor_term: [caseCreditor.pivot.terms],
      has_offer: [caseCreditor.pivot.offer_to_creditor ? true : false],
    }));
  }

  public getFormArray(): UntypedFormArray {
    return this.form.get('creditors') as UntypedFormArray;
  }

  private calculateDisposableIncome(): void {
    const disposable = this.form.get('disposable_income').value;
    const deudafixFee = this.form.get('distribution_fee').value;

    const disposableIncome = +(disposable - deudafixFee).toFixed(2);
    this.actualDisposable = disposableIncome;
    // Prevent infinite loop by disabling value change event
    this.form.get('actual_disposable').patchValue(disposableIncome.toFixed(2), {emitEvent: false, onlySelf: true});
  }

  private calculateOfferToCreditors(dividend: number): void {
    let offerToCreditors: number;
    const debtDevel  = this.form.get('debt_level').value;
    const term       = this.form.get('term').value;
    const stepOffer  = this.form.get('step_offer').value;
    const stepPeriod = this.form.get('step_period').value;

    if (this.showStepPayment) {
      offerToCreditors = +((
        ((+dividend / 100) * +debtDevel - (+stepOffer * +stepPeriod)) / (term - stepPeriod)
      ).toFixed(2));
    } else {
      offerToCreditors = +((((+dividend / 100) * +debtDevel) / +term).toFixed(2));
    }

    // Prevent infinite loop by disabling value change event
    this.form.get('actual_disposable').patchValue(offerToCreditors, {emitEvent: false, onlySelf: true});
  }

  private calculateOfferToCreditorsV2(legalFees: number): void {
    this.form.get('actual_disposable').patchValue(+((Math.max(this.disposableIncome - legalFees, 0)).toFixed(2)));
  }

  private calculateDividend(): void {
    let dividend: number;
    const offerToCreditors = this.form.get('actual_disposable').value;
    // const term             = this.form.get('term').value;
    const debtLevel        = this.form.get('debt_level').value;
    // const stepOffer        = this.form.get('step_offer').value;
    // const stepPeriod       = this.form.get('step_period').value;

    // if (this.showStepPayment) {
    //   const value = ((+offerToCreditors * (+term - stepPeriod) + (stepOffer * stepPeriod)) / +debtLevel).toFixed(4);
    //   dividend    = +(+value * 100).toFixed(2);
    // } else {
    //   const value = ((+offerToCreditors * +term) / +debtLevel).toFixed(4);
    //   dividend    = +(+value * 100).toFixed(2);
    // }
    dividend = 100;
    // calculate term based on dividing debt level by offer to creditors
    const term = Math.ceil(+debtLevel / +offerToCreditors);
    this.form.get('term').patchValue(term, {emitEvent: false, onlySelf: true});

    // Prevent infinite loop by disabling value change event
    this.form.get('dividend').patchValue(dividend, {emitEvent: false, onlySelf: true});

    // update each offer to creditors in the form array based on the new dividend
    // if(!this.creditorHasOffer) {
      const creditors = this.form.get('creditors') as UntypedFormArray;
      creditors.controls.forEach(creditor => {
        const currentBalance = creditor.get('current_balance').value;
        creditor.get('offer_to_creditors').patchValue(this.percentageOfDisposable(+debtLevel, +currentBalance), {emitEvent: false, onlySelf: true});
        // calculate the final payment to creditors
        this.calculateFinalPayment(creditor);
      });
    // }
  }

  public submitForm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const requestData = {...this.form.getRawValue(), payment_date: this.form.get('payment_date').value.toLocaleDateString('en-CA')};

    this.isLoading++;
    this.caseService.generateProposal(this.case.id, requestData).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.case.proposal = result.data;
        this.toast.success(this.translateService.instant('CASES.details.generate-proposal-success'));
        this.closeModal(this.case.proposal, false);
      },
      error => {
        console.error(error);
        this.toast.error(this.translateService.instant('CASES.details.generate-proposal-error'));
      });
  }

  public stepPayment(): void {
    this.showStepPayment = !this.showStepPayment;
    if (!this.showStepPayment) { // remove value in form
      this.form.get('step_period').patchValue(null);
      this.form.get('step_offer').patchValue(null);
    }
    //  Update form values to recalculate `dividend` field
    this.form.updateValueAndValidity();
  }

  private getDistributionFees(): void {
    this.isLoading++;
    this.configService.getConfigData().pipe(finalize(() => this.isLoading--)).subscribe(result => {
      const fixedDistributionFee      = result.data.find(obj => obj.key === 'fixed_distribution_fee').value;
      const percentageDistributionFee = result.data.find(obj => obj.key === 'percentage_distribution_fee').value;

      this.fixedDistributionFee      = fixedDistributionFee;
      this.percentageDistributionFee = percentageDistributionFee;
    });
  }

  public formatCurrency(value): string {
    // if value is null or undefined return 0
    if (!value) {
      return '0.00';
    }
    return value.toFixed(2);
  }

  public percentageOfDisposable(amount, debt_level): string {
    const percentage = (debt_level / amount) * 100;
    const actualDisposable = this.form.get('actual_disposable').value;
    const offerToCreditors = (actualDisposable * percentage) / 100;
    return offerToCreditors.toFixed(2);
  }

  public calculateFinalPayment(creditor): void {
    // calculate the final payment based on debt level and offer to creditors and the terms
    const offerToCreditors = creditor.get('offer_to_creditors').value;
    const currentBalance = creditor.get('current_balance').value;
    const temp = Math.floor(Number(currentBalance) / Number(offerToCreditors));
    const terms = Math.ceil(Number(currentBalance) / Number(offerToCreditors));
    let total = (temp * Number(offerToCreditors));
    let finalPayment = Number(currentBalance) - total;
    // update terms
    creditor.get('creditor_term').patchValue(terms, {emitEvent: false, onlySelf: true});
    creditor.get('final_payment').patchValue(finalPayment.toFixed(2), {emitEvent: false, onlySelf: true});
  }

  // check if the sum of the offer to creditors is equal or greater to the actual disposable
  public checkOfferToCreditors(): boolean {
    const creditors = this.form.get('creditors') as UntypedFormArray;
    let sum = 0;
    creditors.controls.forEach(creditor => {
      sum += +creditor.get('offer_to_creditors').value;
    });
    console.log(sum >= this.actualDisposable)
    return sum >= this.actualDisposable;
  }
  
  // calculate unallocated funds when creditor offer changes
  public calculateUnallocated(): void {
    const creditors = this.form.get('creditors') as UntypedFormArray;
    let sum = 0;
    creditors.controls.forEach(creditor => {
      sum += +creditor.get('offer_to_creditors').value;
    });
    sum = +sum.toFixed(2);
    const actualDisposable = this.form.get('actual_disposable').value;
    this.unallocated = actualDisposable - sum;
    this.form.get('unallocated').patchValue(this.unallocated.toFixed(2), {emitEvent: false, onlySelf: true});
  }

  public isAllowedToGenerateProposal(): boolean {
    if (this.authUser) {
      return this.authUser.department_assignments.some(department => department.department_id === 5 || department.department_id === 13) || this.authUser.role_id === 5;
    }
    return false;
  }

  public setDefaultPaymentDate(): Date {
    const lastPhaseOneTerm = this.case.terms.filter(term => term.type === 'phase_one').pop();
    const date = lastPhaseOneTerm ? lastPhaseOneTerm.term_date : new Date();
    const formattedDate = new Date(date);
    formattedDate.setMonth(formattedDate.getMonth() + 1);
    return formattedDate;
  }
}
