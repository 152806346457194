<div class="row" *ngIf="formReady">
  <div class="offset-md-3 col-md-6">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="card">
        <div class="card-header">
          <h1>{{"CLIENT.notary-download.notary-download" | translate}}</h1>
        </div>
        <div class="card-body">

          <div class="form-group">
            <label for="code">{{"CLIENT.notary-download.enter-code" | translate}}</label>
            <input type="text" class="form-control" id="code" formControlName="code">
            <mat-error
              *ngIf="form.get('code').touched && form.get('code').hasError('required')">
              {{'SHARED.field-required' | translate}}</mat-error>
          </div>
          <div style="padding-top: 25px" class="d-flex justify-content-center align-items-center">
            <app-spinner-btn [loading]="isLoading" [name]="'SHARED.submit' | translate"></app-spinner-btn>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
