import { NgModule } from '@angular/core';
import { CalendarCommonModule, CalendarDayModule, CalendarMonthModule, CalendarWeekModule } from 'angular-calendar';
import { ChartsModule } from 'ng2-charts';
import { SharedModule } from '../../_shared/shared.module';
import { DashboardComponent } from './dashboard.component';
import { SalesLeaderboardPageRoutingModule } from './sales-leaderboard-page/sales-leaderboard-page-routing.module';
import { SalesLeaderboardPageComponent } from './sales-leaderboard-page/sales-leaderboard-page.component';
import { SalesLeaderboardComponent } from './sales-leaderboard/sales-leaderboard.component';

import { NegotiatorLeaderboardModule } from './negotiator-leaderboard/negotiator-leaderboard.module';

@NgModule({
  declarations: [
    DashboardComponent,
    SalesLeaderboardComponent,
    SalesLeaderboardPageComponent
  ],
  imports:      [
    SharedModule,
    ChartsModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
    CalendarCommonModule,
    SalesLeaderboardPageRoutingModule,
    NegotiatorLeaderboardModule
  ],
  exports:      [
    DashboardComponent,
  ],
})
export class DashboardModule {
}
