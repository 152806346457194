import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { MainBaseApiService } from '../../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root'
})
export class NegotiatorsService extends MainBaseApiService {
    // get negotiators
    index(): Observable<LaravelResourceResponse> {
        return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/negotiators`)
            .pipe(catchError(response => this.handleError(response)));
    }
}