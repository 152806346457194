import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, first, take } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import Swal from 'sweetalert2';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor, CaseCreditorPivot } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../../../_base-shared/models/Case/CasePublicDebt';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { TownHall } from '../../../../../../../_base-shared/models/Entity/TownHall';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { CreditorService } from '../../../creditor/creditor.service';
import { TownHallService } from '../../../legal-entity/town-hall.service';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { UserService } from '../../../user/user.service';
import { CaseCreditorService } from '../../case-creditor.service';
import { CaseService } from '../../case.service';

@Component({
  selector:    'app-case-creditor-editor',
  templateUrl: './case-creditor-editor.component.html',
  styleUrls:   ['./case-creditor-editor.component.scss']
})
export class CaseCreditorEditorComponent implements OnInit {
  public case: Case;
  public isLoading                                      = 0;
  public isSubmitting: boolean;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public townHalls: Array<TownHall>                     = [];
  public singleOwnershipOptions: Array<AppSelectOption> = [];
  public jointOwnershipOptions: Array<AppSelectOption>  = [];
  public publicOrganizations: Array<AppSelectOption>    = [];
  public publicDebtTypes: Array<AppSelectOption>        = [];
  public securedDebtTypes: Array<AppSelectOption>       = [];
  public unsecuredDebtTypes: Array<AppSelectOption>     = [];
  public typesOfGuarantee: Array<AppSelectOption>       = [];

  public creditors: Array<Creditor>         = [];
  public recoveryCreditors: Array<Creditor> = [];

  public totalDebt           = 0;
  public unsecuredDebt       = 0;
  public totalMonthlyPayment = 0;
  public isDmProductGroup: boolean;
  public isLsoProductGroup: boolean;
  public hasDmFields: boolean;
  public hasLsoFields: boolean;
  public dgsLegalAdvisor: boolean;
  public authUser: User;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventService: MainGlobalEventService,
    private userService: UserService,
    private caseService: CaseService,
    private creditorService: CreditorService,
    private caseCreditorService: CaseCreditorService,
    private financialOverviewService: FinancialOverviewService,
    private townHallService: TownHallService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.buildSelectOptions();
    this.fetchTownHalls(null);
    this.translate.onLangChange.subscribe(() => this.buildSelectOptions());
    this.globalEventService.authUser$.subscribe(user => {
      this.authUser = user;
      this.getAuthUser(user.id);
    });
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId, ['product', 'creditors', 'public_debts'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
          this.case              = result.data;
          const productGroup     = this.case.product.group_slug;
          this.isDmProductGroup  = this.case.product_assigned_at &&
            (productGroup === 'dmp' || productGroup === 'cajaplus' || productGroup === 'dgs');
          this.isLsoProductGroup = this.case.product_assigned_at && productGroup === 'lso';

          this.hasDmFields              = ! this.case.product_assigned_at || this.isDmProductGroup;
          this.hasLsoFields             = ! this.case.product_assigned_at || this.isLsoProductGroup;
          this.case.unsecured_creditors = this.case.creditors.filter(creditor => creditor.pivot.type === 'unsecured');
          this.case.secured_creditors   = this.case.creditors.filter(creditor => creditor.pivot.type === 'secured');
          this.case.claim_creditors     = this.case.creditors.filter(creditor => creditor.pivot.type === 'claim');
          this.buildForm(this.case);
        });
    });
  }

  public submitForm(form: UntypedFormGroup): void {
    this.checkIfTotalRepaidIsRequired('secured_creditors');
    this.checkIfTotalRepaidIsRequired('unsecured_creditors');
    this.checkIfTotalRepaidIsRequired('claim_creditors');
    if (form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.caseCreditorService.upsert(this.case.id, form.getRawValue())
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        () => {
          this.form.markAsPristine();
          this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
        },
        error => {
          this.serverResponse = error.error;
          this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
        }
      );
  }

  public isComponentDirty(): boolean {
    return this.form.dirty;
  }

  public isComponentValid(): boolean {
    return this.form.valid;
  }

  public submitComponent() {
    return this.submitForm(this.form);
  }

  private updateTotalDebt(updateFinancialOverview = true) {
    this.totalDebt           = 0;
    this.unsecuredDebt       = 0;
    this.totalMonthlyPayment = 0;

    this.getFormArray('public_debts').controls.forEach(control => {
      this.totalDebt += +control.value.current_balance;
      this.totalMonthlyPayment += +control.value.monthly_payments;
    });

    this.getFormArray('secured_creditors').controls.forEach(control => {
      this.totalDebt += +control.value.current_balance;
      this.totalMonthlyPayment += +control.value.monthly_payments;
    });

    this.getFormArray('unsecured_creditors').controls.forEach(control => {
      this.totalDebt += +control.value.current_balance;
      this.unsecuredDebt += +control.value.current_balance;
      this.totalMonthlyPayment += +control.value.monthly_payments;
    });

    this.getFormArray('claim_creditors').controls.forEach(control => {
      this.totalDebt += +control.value.current_balance;
      this.totalMonthlyPayment += +control.value.monthly_payments;
    });
    if (updateFinancialOverview) {
      this.financialOverviewService.financialOverview$.pipe(take(1)).subscribe(overview => {
        if (overview) {
          overview.total_debt      = this.totalDebt;
          overview.monthly_payment = this.totalMonthlyPayment;
          overview.unsecured_debt  = this.unsecuredDebt;
          this.financialOverviewService.updateFinancialOverviewLocally(overview);
        }
      });
    }
  }

  private getAuthUser(userId: number) {
    this.isLoading++;
    this.userService.get(userId, {}, ['packager']).pipe(finalize(() => this.isLoading--)).subscribe(user => {
      this.authUser = user.data;
      this.authUser.department_assignments?.forEach(assignment => {
        if (assignment.department_id === 2) {
          this.dgsLegalAdvisor = !! assignment.dgs_group;
        }
      });
    });
  }

  private buildForm(clientCase: Case) {
    this.form = this.fb.group({
      public_debts:        this.fb.array([]),
      unsecured_creditors: this.fb.array([]),
      secured_creditors:   this.fb.array([]),
      claim_creditors:     this.fb.array([])
    });
    this.addPublicDebts();
    this.addCreditorsToForm(clientCase.unsecured_creditors, 'unsecured_creditors');
    this.addCreditorsToForm(clientCase.secured_creditors, 'secured_creditors');
    this.addCreditorsToForm(clientCase.claim_creditors, 'claim_creditors');

    this.fetchCreditors();
    this.updateTotalDebt();

    this.form.get('public_debts').valueChanges.subscribe(() => this.updateTotalDebt());
    this.form.get('unsecured_creditors').valueChanges.subscribe(() => this.updateTotalDebt());
    this.form.get('secured_creditors').valueChanges.subscribe(() => this.updateTotalDebt());
    this.form.get('claim_creditors').valueChanges.subscribe(() => this.updateTotalDebt());
  }

  private addPublicDebts(): void {
    if (this.case.public_debts?.length > 0) {
      this.case.public_debts.forEach(publicDebt => {
        this.addPublicDebt(publicDebt);
      });
    }
  }

  private addCreditorsToForm(caseCreditors: Array<CaseCreditor>,
                             type: 'unsecured_creditors' | 'secured_creditors' | 'claim_creditors') {
    if (caseCreditors?.length > 0) {
      caseCreditors.forEach(caseCreditor => {
        this.addCreditor(type, caseCreditor);
      });
    }
    this.form.get(type).valueChanges.subscribe((resp) => {
      resp.forEach(res => {
        if (res.lender_type === 'high_interest' && this.case.product.group_slug === 'cajaplus') {
          const creditorForm = this.form.get(type) as FormArray;
          creditorForm.at(resp.length - 1).get('total_repaid').addValidators([Validators.required]);
          this.form.updateValueAndValidity();
        }
      });
    });
  }

  public checkCreditors(creditorId: number, type: 'secured_creditors' | 'unsecured_creditors' | 'claim_creditors', index: number) {
    const selectedCreditor    = this.creditors.find(creditor => creditor.id === creditorId);
    const approvedFormControl = this.getFormArray(type).at(index).get('approved');
    if (approvedFormControl.value !== selectedCreditor.approved) {
      approvedFormControl.patchValue(selectedCreditor.approved);
      approvedFormControl.updateValueAndValidity();
    }
    const lenderTypeFormControl = this.getFormArray(type).at(index).get('lender_type');
    if (lenderTypeFormControl.value !== selectedCreditor.lender_type) {
      lenderTypeFormControl.patchValue(selectedCreditor.lender_type);
      lenderTypeFormControl.updateValueAndValidity();
    }
    const creditorForm = this.form.get(type) as FormArray;
    creditorForm.at(index).get('creditor_id')?.valueChanges.subscribe((res) => {
      if (creditorForm.at(index).value.lender_type === 'high_interest' && this.case.product.group_slug === 'cajaplus') {
        creditorForm.at(index).get('total_repaid').addValidators([Validators.required]);
      }
    });
  }

  public getFormArray(creditorType: 'public_debts' | 'unsecured_creditors' | 'secured_creditors' | 'claim_creditors') {
    return this.form.get(creditorType) as UntypedFormArray;
  }

  public addPublicDebt(publicDebt: CasePublicDebt = null) {
    publicDebt = publicDebt ? publicDebt : this.getDefaultPublicDebt();

    const formArray = this.getFormArray('public_debts');

    const formGroup = this.fb.group({
      id:                     [publicDebt.id],
      town_hall_id:           [
        publicDebt.town_hall_id,
        publicDebt.public_organisation === 'town-hall' ? [Validators.required] : []
      ],
      ownership:              [
        { value: publicDebt.ownership, disabled: ! this.case.joint_application }, [Validators.required]
      ],
      interested_third_party: [publicDebt.interested_third_party],
      public_organisation:    [publicDebt.public_organisation],
      debt_type:              [publicDebt.debt_type],
      initial_balance:        [publicDebt.initial_balance],
      current_balance:        [publicDebt.current_balance],
      monthly_payments:       [publicDebt.monthly_payments],
      making_payments:        [publicDebt.making_payments],
      reference_number:       [publicDebt.reference_number],
      judicial_claim:         [publicDebt.judicial_claim],
      response_received:      [publicDebt.response_received],
      verified:               [publicDebt.verified],
      city:                   [
        { value: publicDebt.city, disabled: publicDebt.public_organisation !== 'town-hall' }
      ],
      notes:                  [publicDebt.notes]
    });

    formArray.push(formGroup);
  }

  public addCreditor(formArrayName: 'secured_creditors' | 'unsecured_creditors' | 'claim_creditors',
                     caseCreditor: CaseCreditor = null) {
    caseCreditor    = caseCreditor ? caseCreditor : this.getDefaultCaseCreditor(formArrayName === 'secured_creditors');
    const formArray = this.getFormArray(formArrayName);
    const formGroup = this.fb.group({
      approved:             [{ value: caseCreditor.approved, disabled: true }],
      lender_type:          [caseCreditor.lender_type],
      id:                   [caseCreditor.pivot.id],
      case_id:              [caseCreditor.pivot.case_id],
      creditor_id:          [caseCreditor.pivot.creditor_id, [Validators.required]],
      // current_creditor_id:  [caseCreditor.pivot.current_creditor_id],
      recovery_creditor_id: [
        caseCreditor.pivot.recovery_creditor_id,
        !! caseCreditor.pivot.recovery_creditor_id ? [Validators.required] : []],
      ownership:            [
        { value: caseCreditor.pivot.ownership, disabled: ! this.case.joint_application },
        [Validators.required]
      ],
      additional_partner:   [caseCreditor.pivot.additional_partner, null],
      debt_type:            [caseCreditor.pivot.debt_type, null],
      type_of_guarantee:    [caseCreditor.pivot.type_of_guarantee, null],
      initial_balance:      [caseCreditor.pivot.initial_balance, null],
      current_balance:      [caseCreditor.pivot.current_balance, null],
      monthly_payments:     [caseCreditor.pivot.monthly_payments, null],
      making_payments:      [caseCreditor.pivot.making_payments, null],
      tae:                  [caseCreditor.pivot.tae, null],
      restructured:         [caseCreditor.pivot.restructured, null],
      reference_number:     [caseCreditor.pivot.reference_number, null],
      judicial_claim:       [caseCreditor.pivot.judicial_claim, null],
      verified:             [caseCreditor.pivot.verified, null],
      notes:                [caseCreditor.pivot.notes, null],
      total_repaid:         [
        caseCreditor.pivot.total_repaid,
        caseCreditor.lender_type === 'high_interest' && this.case.product.group_slug === 'cajaplus' ?
        [Validators.required] :
        []],
      has_recovery:         [ !! caseCreditor.pivot.recovery_creditor_id, null],
      last_paid:            [
        caseCreditor.pivot.last_paid ? new Date(caseCreditor.pivot.last_paid) : null,
      ],
    });
    formArray.push(formGroup);
  }

  public removeCreditor(creditorType: 'public_debts' | 'unsecured_creditors' | 'secured_creditors' | 'claim_creditors',
                        index: number) {
    const formArray = this.getFormArray(creditorType);
    formArray.removeAt(index);
  }

  private fetchCreditors() {
    this.isLoading++;
    this.creditorService.index({ all: 1, active: 1 }).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
          this.creditors         = result.data;
          this.recoveryCreditors = result.data.filter(creditor => creditor.can_be_recovery);
          // this.checkCreditors();
        },
        err => console.error(err)
      );
  }

  public publicOrganizationChanged(newSelection: string, index: number) {
    if (newSelection === 'town-hall') {
      this.getFormArray('public_debts').at(index).get('city').enable();
      this.getFormArray('public_debts').at(index).get('town_hall_id').enable();
      this.getFormArray('public_debts').at(index).get('town_hall_id').setValidators([Validators.required]);
    } else {
      this.getFormArray('public_debts').at(index).get('city').patchValue(null);
      this.getFormArray('public_debts').at(index).get('city').disable();
      this.getFormArray('public_debts').at(index).get('town_hall_id').patchValue(null);
      this.getFormArray('public_debts').at(index).get('town_hall_id').disable();
      this.getFormArray('public_debts').at(index).get('town_hall_id').setValidators([]);
    }
    this.getFormArray('public_debts').at(index).get('city').updateValueAndValidity();
    this.getFormArray('public_debts').at(index).get('town_hall_id').updateValueAndValidity();
  }

  public hasRecoveryChanged(arrayName: 'secured_creditors' | 'unsecured_creditors' | 'claim_creditors', newValue: any,
                            index: number) {
    if (newValue) {
      this.getFormArray(arrayName).at(index).get('recovery_creditor_id').enable();
      this.getFormArray(arrayName).at(index).get('recovery_creditor_id').setValidators([Validators.required]);
    } else {
      this.getFormArray(arrayName).at(index).get('recovery_creditor_id').patchValue(null);
      this.getFormArray(arrayName).at(index).get('recovery_creditor_id').disable();
      this.getFormArray(arrayName).at(index).get('recovery_creditor_id').setValidators([]);
    }
    this.getFormArray(arrayName).at(index).get('recovery_creditor_id').updateValueAndValidity();
  }

  private buildSelectOptions() {
    this.singleOwnershipOptions = [
      { value: 'applicant', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.applicant') }
    ];

    this.jointOwnershipOptions = [
      {
        value: 'applicant',
        label: this.translate.instant('CASE_CREDITOR.model.ownership.options.applicant_joint')
      },
      { value: 'partner', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.partner') },
      { value: 'joint', label: this.translate.instant('CASE_CREDITOR.model.ownership.options.joint') }
    ];

    this.publicOrganizations = [
      {
        value: 'social-security',
        label: this.translate.instant('CASE_CREDITOR.model.public_organization.options.social-security')
      },
      { value: 'estate', label: this.translate.instant('CASE_CREDITOR.model.public_organization.options.estate') },
      {
        value: 'town-hall',
        label: this.translate.instant('CASE_CREDITOR.model.public_organization.options.town-hall')
      }
    ];

    this.publicDebtTypes = [
      { value: 'tax', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.tax') },
      { value: 'capital-gain', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.capital-gain') },
      { value: 'penalty-fee', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.penalty-fee') }
    ];

    this.securedDebtTypes = [
      { value: 'mortgage', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.mortgage') },
      { value: 'personal-loan', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.personal-loan') },
      { value: 'car-finance', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.car-finance') },
      {
        value: 'domain-reservation',
        label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.domain-reservation')
      },
      { value: 'endorsement', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.endorsement') },
      { value: 'guarantor', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.guarantor') },
      { value: 'other', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.other') }
    ];

    this.unsecuredDebtTypes = [
      { value: 'loan', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.loan') },
      { value: 'card', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.card') },
      { value: 'tax', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.tax') },
      { value: 'overdraft', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.overdraft') }
    ];

    this.unsecuredDebtTypes.push(
      { value: 'other', label: this.translate.instant('CASE_CREDITOR.model.debt_type.options.other') }
    );

    this.typesOfGuarantee = [
      {
        value: 'mortgage',
        label: this.translate.instant('CASE_CREDITOR.model.type_of_guarantee.options.mortgage')
      },
      {
        value: 'domain_reservation',
        label: this.translate.instant('CASE_CREDITOR.model.type_of_guarantee.options.domain_reservation')
      },
      {
        value: 'warranty',
        label: this.translate.instant('CASE_CREDITOR.model.type_of_guarantee.options.warranty')
      }
    ];
  }

  public fetchTownHalls(search: string) {
    this.isLoading++;
    this.townHallService.index({ select_all: 1, search }).pipe(finalize(() => this.isLoading--)).subscribe(
      result => this.townHalls = result.data,
      error => console.error(error)
    );
  }

  private getDefaultPublicDebt(): CasePublicDebt {
    const newPublicDebt     = new CasePublicDebt();
    newPublicDebt.ownership = 'applicant';

    return newPublicDebt;
  }

  private getDefaultCaseCreditor(isSecured: boolean): CaseCreditor {
    const caseCreditor           = new CaseCreditor();
    caseCreditor.approved        = 0;
    caseCreditor.pivot           = new CaseCreditorPivot();
    caseCreditor.pivot.ownership = 'applicant';
    caseCreditor.pivot.type      = isSecured ? 'secured' : 'unsecured';

    return caseCreditor;
  }

  public toggleType(currentArrayName: 'secured_creditors' | 'unsecured_creditors' | 'claim_creditors',
                    index: number,
                    creditorType: 'unsecured' | 'claim' | 'secured') {
    const caseCreditor = this.getFormArray(currentArrayName).at(index).getRawValue();
    const newArrayName = creditorType === 'secured' ?
                         'secured_creditors' :
                         (creditorType === 'unsecured' ? 'unsecured_creditors' : 'claim_creditors')

    this.caseService.toggleIsClaimable(this.case.id, caseCreditor.id, { type: creditorType })
      .pipe(first())
      .subscribe(
        result => {
          this.toastr.success(this.translate.instant('CASES.editor.creditors.result.success'));
          this.addCreditor(newArrayName, caseCreditor);
          this.removeCreditor(currentArrayName, index);
        },
        error => {
          this.serverResponse = error.error;
          this.toastr.error(this.translate.instant('CASES.editor.creditors.result.error'), error.error.message);
        }
      );

    return;
  }

  public getOutcome(formGroup) {
    const initialBalance = formGroup.get('initial_balance').value;
    const totalRepaid    = formGroup.get('total_repaid').value;
    if (initialBalance === null || totalRepaid === null) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.undetermined');
    }
    if (initialBalance === totalRepaid) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.cancel_debt');
    }
    if (initialBalance > totalRepaid) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.pay_difference');
    }
    if (initialBalance < totalRepaid) {
      return this.translate.instant('CASE_CREDITOR.model.outcomes.payback');
    }
  }

  public getAmountToCondone(amount: number, currentBalance: number) {
    return amount > 0 ? currentBalance - amount : currentBalance;
  }

  private checkIfTotalRepaidIsRequired(formType: 'secured_creditors' | 'unsecured_creditors' | 'claim_creditors'): any {
    const numberOfCreditors = this.form.get(formType).value.length;
    for (let i = 0; i < numberOfCreditors - 1; i++) {
      const creditorsArray = this.form.get(formType) as FormArray;
      if (creditorsArray.at(i).get('total_repaid').hasError('required')) {
        /* this.toastr.error(this.translate.instant('CASE_CREDITOR.errors.error_total_repaid'));*/
        return Swal.fire({
          title:              this.translate.instant('CASE_CREDITOR.errors.error_total_repaid') + '!',
          icon:               'error',
          customClass:        {
            title: 'error-txt'
          },
          showConfirmButton:  false,
          showCancelButton:   true,
          cancelButtonText:   this.translate.instant('SHARED.close'),
          confirmButtonColor: '#4267b2'
        });
      }
    }
  }

  public sendCase(clientCase: Case, projectId: string): void {
    Swal.fire({
      title: this.translate.instant('CASE_CREDITOR.refer_case.heading'),
      text: this.translate.instant('CASE_CREDITOR.refer_case.warning-text') + ' ' + projectId.toUpperCase(),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.caseService.sendCase(clientCase.id, { destination_project_id: projectId })
          .pipe(finalize(() => this.isLoading--))
          .subscribe(result => {
              if (projectId === 'dvl') {
                this.case.dvl_ref_number   = result.data.case.dvl_ref_number;
              }

              if (projectId === 'epi') {
                this.case.epi_ref_number   = result.data.case.epi_ref_number;
              }

              this.toastr.success('Sent case successfully');
            },
            error => {
              let errorMessage = 'Failed to send case';
              if (error.error.status === 422) {
                const parsedError = JSON.parse(error.error.message);
                errorMessage = parsedError[Object.keys(parsedError)[0]][0];
              }
              errorMessage = errorMessage.replace('.', ' ');
              this.toastr.error(errorMessage);
            }
          );
      }});
  }
}
