import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../case.service';

@Component({
  selector:    'app-case-payment',
  templateUrl: './case-payment.component.html',
  styles:      [],
})
export class CasePaymentComponent implements OnInit, OnDestroy {
  public componentType: 'distribution' | 'admin' = 'admin';
  public case: Case;
  public isLoading                               = 0;
  public serverResponse: LaravelResourceResponse;

  public openDialog$: EventEmitter<any>       = new EventEmitter<any>();
  public fetchInstalments$: EventEmitter<any> = new EventEmitter<any>();

  private subscriptions: Array<Subscription> = [];

  constructor(private fb: UntypedFormBuilder,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private translate: TranslateService,
              private caseService: CaseService,
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.isLoading++;
      this.subscriptions.push(
        this.caseService.get(caseId, ['client', 'debt_payment_plan', 'terms', 'distribution_case_terms', 'creditors', 'public_debts', 'debt_payment_plan', 'secured_creditors', 'unsecured_creditors', 'expense'])
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            result => this.case = result.data,
            error => this.serverResponse = error.error,
          ),
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public paymentRecorded() {
    this.caseService.get(this.case.id).subscribe(res => {
      // this.case = res.data;
    });
    this.fetchInstalments$.emit();
  }
}
