import { CaseDistribution } from './CaseDistribution';
import { DistributionBatchStatusLog } from './DistributionBatchStatusLog';
import { DistributionProvider } from './DistributionProvider';
import { DistributionCaseTerms } from '../Payment/DistributionCaseTerms';

export type DistributionBatchStatus = 'not_in_batch' | 'in_batch' | 'pending' | 'submitted' | 'funded' | 'rejected';

export class DistributionBatchTotalAmounts {
  total_monthly_fee_amount: number;
  total_batch_contract_amount: number;
  total_funded_amount: number;
  total_cash_hurdle_amount: number;
  total_distributed_amount: number;
  total_fees_obtainable: number;
  total_fees_retained: number;
  total_provider_fees_obtainable: number;
  total_provider_fees_retained: number;
}

export class DistributionBatch {
  id?: number;
  distribution_provider_id?: number;
  name?: string;
  status?: DistributionBatchStatus;
  slug?: string;
  status_updated_at?: Date | string;
  submitted_at?: Date | string;
  case_creditor_id?: number;

  created_at?: Date | string;
  updated_at?: Date | string;

  // Relations
  distribution_provider?: DistributionProvider;
  case_distributions?: Array<CaseDistribution>;
  distribution_case_terms? : Array<DistributionCaseTerms>

  // Helpers
  total_monthly_fee_amount?: number;
  total_contract_amount?: number;
  total_amount_paid?: number;
  total_distributed_amount?: number;
  total_current_funded_amount?: number;
  total_funded_amount?: number;
  total_cash_hurdle_amount?: number;
  total_batch_contract_amount: number;

  status_logs?: Array<DistributionBatchStatusLog>;
  latest_status_log?: DistributionBatchStatusLog;
  latest_submitted_log?: DistributionBatchStatusLog;
}
