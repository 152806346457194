<div class="pt-5">
  <h2>Document Statistics:</h2>
</div>
<div class="row text-center pb-5">
  <div class="col-md-3">
    <div class="card">
      <div class="card-body">
        <h3>Total documents requested:</h3>
        <p>0</p>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="card-body">
        <h3>Total uploaded:</h3>
        <p>0</p>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="card-body">
        <h3>Total pending:</h3>
        <p>0</p>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card">
      <div class="card-body">
        <h3>Total accepted:</h3>
        <p>0</p>
      </div>
    </div>
  </div>
</div>
