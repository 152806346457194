import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppJob } from '../../../../../../_base-shared/models/App/AppJob';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { ReportService } from '../report.service';
import { AutomatedCreditorReportComponent } from './automated-creditor-report/automated-creditor-report.component';
import { NegotiationReportComponent } from './negotiation-report/negotiation-report.component';
import { PackagerSalesReportComponent } from './packager-sales-report/packager-sales-report.component';
import { PaymentReceivedModalComponent } from './payment-received-modal/payment-received-modal.component';
import { SalesReportModalComponent } from './sales-report-modal/sales-report-modal.component';
import { BulkDmModalComponent } from './bulk-dm-report/bulkdm-report-modal.component';
import { CreditorNegotiationModalComponent } from './creditor-negotiator-report/creditor-negotiation-modal.component';
import { CreditorDebtModalComponent } from './creditor-debt-report/creditor-debt-modal.component';

@Component({
  selector:    'app-financial-report-list',
  templateUrl: './financial-report-list.component.html',
  styles:      [],
})
export class FinancialReportListComponent implements OnInit {
  public authUser: User;
  public appJobs: Array<AppJob> = [];
  public isLoadingJobs          = 0;
  public generatingBreakageReport: boolean;


  public displayedColumns: string[] = [
    'name',
    'description',
    'actions',
  ];

  public reportOptions: Array<any> = [
    {
      name:        'Payment Received',
      description: 'Generate Payment Received Report',
      action:      this.paymentReceivedModal.bind(this),
      disabled:    false,
    },
    {
      name:        'Sales Report',
      description: 'Generate Sales Report',
      action:      this.salesReportModal.bind(this),
      disabled:    false,
    },
    {
      name:        'Packager Sales Report',
      description: 'Generate Packager Sales Report',
      action:      this.packagerSalesReportModal.bind(this),
      disabled:    false,
    },
    {
      name:        'Automated Creditor Report',
      description: 'Generate Automated Creditor Report',
      action:      this.automatedCreditorReport.bind(this),
      disabled:    false,
    },
    {
      name:        'Negotiation Report',
      description: 'Generate Negotiation Report',
      action:      this.negotiationReportModal.bind(this),
      disabled:    false,
    },
    {
      name:        'Bulk DM Report',
      description: 'Generate Bulk DM Report for a specific creditor',
      action:      this.bulkDMReport.bind(this),
      disabled:    false,
    },
    {
      name:        'Creditor Negotiation Report',
      description: 'Generate Creditor Negotiation Report',
      action:      this.creditorNegotiationReportModal.bind(this),
      disabled:    false,
    },
    {
      name:        'Creditor Debt Report',
      description: 'Generate Creditor Debt Report',
      action:      this.creditorDebtReportModal.bind(this),
      disabled:    false,
    }
  ];

  constructor(private toastr: ToastrService,
              private globalEventsService: MainGlobalEventService,
              private reportService: ReportService,
              public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    this.fetchQueuedJobs();
  }

  public fetchQueuedJobs(): void {
    this.isLoadingJobs++;
    this.reportService.indexQueuedExports({queue: 'export', 'with[]': 'user'}).pipe(finalize(() => this.isLoadingJobs--))
        .subscribe(
            result => {
              this.toastr.success(result.message ?? 'Queued jobs fetched successfully');
              this.appJobs = result.data;
            },
        );
  }

  public paymentReceivedModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(PaymentReceivedModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public salesReportModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(SalesReportModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public packagerSalesReportModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(PackagerSalesReportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public automatedCreditorReport($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(AutomatedCreditorReportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public negotiationReportModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(NegotiationReportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public bulkDMReport($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(BulkDmModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public creditorNegotiationReportModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(CreditorNegotiationModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public creditorDebtReportModal($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(CreditorDebtModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }
}
