import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { DistributionBatch, DistributionBatchTotalAmounts } from '../../../../../_base-shared/models/Distribution/DistributionBatch';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DistributionBatchService extends MainBaseApiService {

  public index(requestData = {}, relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest(
        {...requestData, with: relations, with_count: relationsCount}, 'get',
    );

    return this.http.get<LaravelResourceResponse<Array<DistributionBatch>>>(
        this.apiUrl + '/distribution-provider/distribution-batches', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public show(modelId, relations: Array<string> = [], relationsCount: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations, with_count: relationsCount}, 'get');

    return this.http.get<LaravelResourceResponse<DistributionBatch>>(
        this.apiUrl + '/distribution-batches/' + modelId, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public store(model: DistributionBatch) {
    return this.http.post<LaravelResourceResponse<DistributionBatch>>(this.apiUrl + '/distribution-batches', model)
        .pipe(catchError(response => this.handleError(response)));
  }

  public update(batchId: number, updatedModel: DistributionBatch, relations: Array<string> = []) {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');

    return this.http.put<LaravelResourceResponse<DistributionBatch>>(
        this.apiUrl + '/distribution-batches/' + batchId, updatedModel, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getTotalAmounts(batchId: number) {
    return this.http.get<LaravelResourceResponse<DistributionBatchTotalAmounts>>(
        this.apiUrl + '/distribution-provider/distribution-batches/' + batchId + '/total-amounts',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getTotalCollectedAmount(requestData = {}) {
    
    const params = MainBaseApiService.convertFiltersForRequest(
      {...requestData }, 'get',
    );
    
    return this.http.get<LaravelResourceResponse<any>>(
        this.apiUrl + '/distribution-batches/get-total-collected-amt', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getTotalDistributedAmount(requestData = {}) {

    const params = MainBaseApiService.convertFiltersForRequest(
      {...requestData }, 'get',
    );
    
    return this.http.get<LaravelResourceResponse<any>>(
        this.apiUrl + '/distribution-batches/get-total-distributed-amt', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getTotalOnHold(requestData = {}) {

    const params = MainBaseApiService.convertFiltersForRequest(
      {...requestData }, 'get',
    );
    
    return this.http.get<LaravelResourceResponse<any>>(
        this.apiUrl + '/distribution-batches/get-total-on-hold', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getDistributionTerms(requestData = {}) {

    const params = MainBaseApiService.convertFiltersForRequest(
      {...requestData }, 'get',
    );
    
    //create model in angular DistributionTerm or whatever
    return this.http.get<LaravelResourceResponse<any>>(
        this.apiUrl + '/distribution-batches/get-distribution-terms', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getConciliationTerms(requestData = {}) {

    const params = MainBaseApiService.convertFiltersForRequest(
      {...requestData }, 'get',
    );
    
    //create model in angular DistributionTerm or whatever
    return this.http.get<LaravelResourceResponse<any>>(
        this.apiUrl + '/distribution-batches/get-conciliation-terms', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getMissingWallets(requestData = {}): Observable<DistributionBatch[]> {

    const params = MainBaseApiService.convertFiltersForRequest(
      {...requestData }, 'get',
    );
    
    return this.http.get<DistributionBatch[]>(
        this.apiUrl + '/distribution-batches/get-missing-batch', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }
}
