import { Product } from '../Product';
import { User } from '../User/User';
import { DistributionBatch } from './DistributionBatch';

export class DistributionProvider {
  id?: number;
  payment_processor_id?: number;
  company_name?: string;
  min_contract_value?: number;
  min_monthly_payment?: number;
  max_installments?: number;
  funding_amount_percentage?: number;
  cash_hurdle_percentage?: number;
  retention_percentage?: number;
  client_account_iban?: string;
  payment_iban?: string;
  address?: string;
  contact_person?: string;
  email?: string;
  contact_email?: string;
  phone?: string;

  created_at?: Date | string;
  updated_at?: Date | string;

  // Relations
  products?: Array<Product>;
  distribution_batches?: Array<DistributionBatch>;
  users?: Array<User>;
}
