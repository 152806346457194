<mat-dialog-content class="dialog-container">
  <mat-card class="mat-elevation-z3">
    <h2 style="margin: 0px; padding: 0px;" mat-dialog-title>{{ title }}</h2>
    <mat-card-content>

      <div class="filter-section">
        <mat-form-field appearance="standard" class="filter-field">
          <mat-label>{{ 'DISTRIBUTION.dialog-model-info.start_date' | translate }}</mat-label>
          <input matInput [matDatepicker]="startPicker" [(ngModel)]="startDate">
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        
        <mat-form-field appearance="standard" class="filter-field">
          <mat-label>{{ 'DISTRIBUTION.dialog-model-info.end_date' | translate }}</mat-label>
          <input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate">
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
        
        <mat-form-field appearance="standard" class="filter-field">
          <mat-label>{{ 'DISTRIBUTION.dialog-model-info.filter_by' | translate }}</mat-label>
          <mat-select [(ngModel)]="filterField">
            <mat-option *ngFor="let field of displayedFilterColumns" [value]="field">{{field}}</mat-option>
          </mat-select>
        </mat-form-field>
        
        <app-input class="filter-field" type="select" [formControl]="casesFilter" appearance="standard" multiple fullWidth="false"
                   [searchable]="true"
                   [label]="'Cases'"
                   [selectOptions]="cases" 
                   [selectLabel]="'ref_number'" 
                   [selectValue]="'id'">
        </app-input>
        
        <div class="button-group">
          <button mat-raised-button color="primary" (click)="applyFilter()">
            {{ 'DISTRIBUTION.dialog-model-info.filter' | translate }}
          </button>
          <button mat-raised-button color="primary" class="blue-button" (click)="clearFilters()">
            {{ 'DISTRIBUTION.dialog-model-info.clear_filters' | translate }}
          </button>
        </div>
      </div>

      <div class="search-and-pagination-container">
        <app-input type="text" [formControl]="searchFilter" [appearance]="'standard'"
                   [label]="'SHARED.search' | translate" [extraLabel]="false" prefixType="icon" prefix="search">
        </app-input>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>

      <div class="table-container">
        <mat-table [dataSource]="dataSource" matSort>
          <!-- Define table columns as needed -->
          <ng-container matColumnDef="ref_number">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DISTRIBUTION.dialog-model-info.ref_num' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> 
              <!--<a [routerLink]="['/cases', element.case_id, 'general']">
                {{element.case.ref_number}}
              </a>-->
              <a [href]="['/cases', element.case_id, 'general'].join('/')" target="_blank">
                {{element.case.ref_number}}
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DISTRIBUTION.dialog-model-info.amount' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.amount}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="amount_paid">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DISTRIBUTION.dialog-model-info.amount_paid' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.amount_paid}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="term_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DISTRIBUTION.dialog-model-info.term_date' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.term_date | date}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DISTRIBUTION.dialog-model-info.type' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DISTRIBUTION.dialog-model-info.name' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="date_paid">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DISTRIBUTION.dialog-model-info.date_paid' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.date_paid | date}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="amount_to_distribute">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DISTRIBUTION.dialog-model-info.amount_to_distribute' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.amount_to_distribute}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="amount_distributed">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DISTRIBUTION.dialog-model-info.amount_distributed' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.amount_distributed}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="distributed_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DISTRIBUTION.dialog-model-info.distributed_at' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.distributed_at | date}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="distribution_status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DISTRIBUTION.dialog-model-info.distribution_status' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.distribution_status}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div *ngIf="dataSource.data.length === 0" class="no-rows-message">
          No rows available
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
