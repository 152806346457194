import { Creditor } from '../Entity/Creditor';
import { CaseCreditorStatusLog } from './CaseCreditorStatusLog';

export class CaseCreditorPivot {
  id?: number;
  case_id?: number;
  creditor_id?: number;
  recovery_creditor_id?: number;
  type: 'secured' | 'unsecured' | 'claim';
  lender_type: 'collections' | 'standard' | 'high_interest';
  debt_type?: string;
  type_of_guarantee?: string;
  initial_balance?: number;
  current_balance?: number;
  monthly_payments?: number;
  tae?: number;
  restructured?: number;
  making_payments?: number;
  ownership?: string;
  additional_partner: any;
  reference_number?: string;
  judicial_claim?: string;
  response_received?: string;
  verified?: number;
  notes?: string;
  proposal_location: string;
  proposal_location_accord: string;
  proposal_location_accord_partner: string;
  proposal_location_accord_both: string;
  drafting_status: string;
  total_repaid?: number;
  signature?: string;
  signature_partner?: string;
  viewed_by_client?: string;
  viewed_by_partner?: string;
  signed_by_client?: string;
  signed_by_partner?: string;
  contract_sent: boolean;
  client_ip?: string;
  partner_ip?: string;
  aep_sent: boolean | 0 | 1;
  amount_repaid?: number;
  client_documents_visible: boolean | 0 | 1;
  excluded: boolean | 0 | 1;
  debt_amount?: number;
  negotiated_amount?: number;
  total_reduction?: number;
  percent_reduction?: number;
  created_at?: Date | string;
  updated_at?: Date | string;
  case_creditor_status_id?: number;

  // Relations
  recovery_creditor?: Creditor;
  creditor?: Creditor;
  case_creditor_status_logs?: CaseCreditorStatusLog;
  product_id?: number;
  offer_to_creditor?: number;
  final_payment?: number;
  terms?: number;
  current_creditor_id?: number;
  last_paid?: Date | string;
}

export class CaseCreditor extends Creditor {
  pivot?: CaseCreditorPivot;
}
