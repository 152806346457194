import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../_base-shared/models/Case/Case';
import { PaymentInfoResource } from '../../../../_base-shared/models/Payment/PaymentInfoResource';
import { PaymentCard } from '../../../../_base-shared/models/Payment/PaymentCard';
import { PaymentRequest } from '../../../../_base-shared/models/Payment/PaymentRequest';
import { OppwaOrder } from '../../../../_base-shared/models/Payment/Transaction/OppwaOrder';
import { User } from '../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../_shared/services/main-base-api.service';

interface NewCardRequest {
  payment_request_uuid?: string;
  amount: number;
  card_number: string;
  cvv: string;
  holder: string;
  expiry_month: string;
  expiry_year: string;
  brand: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClientService extends MainBaseApiService {
  private navSubjectSource = new BehaviorSubject<boolean>(false);
  public navSubject$       = this.navSubjectSource.asObservable();

  setNavSubject(isTrue: boolean): void {
    this.navSubjectSource.next(isTrue);
  }

  public getPaymentRequest(paymentRequestUuid: string, signature: string, relations: Array<string> = [])
    : Observable<LaravelResourceResponse<PaymentRequest>> {
    const params = MainBaseApiService.convertFiltersForRequest(
      {signature, with: relations}, 'get',
    );
    return this.http.get<LaravelResourceResponse<PaymentRequest>>(
      this.apiUrl + '/client/payment-requests/' + paymentRequestUuid, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public authorizePaymentRequest(paymentRequestUuid: string, data: { id_card: string, signature: string })
    : Observable<LaravelResourceResponse<Array<PaymentCard>>> {
    return this.http.post<LaravelResourceResponse<Array<PaymentCard>>>(
      this.apiUrl + '/client/payment-requests/' + paymentRequestUuid + '/authorize', data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getCaseByIdCard(id_card: any, relations: Array<string> = []): Observable<LaravelResourceResponse<Case>> {
    const params = MainBaseApiService.convertFiltersForRequest({...id_card, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Case>>(
      this.apiUrl + '/public/users' + '/get-case-by-dni', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexPaymentCards(userUuid: string, caseUuid: string): Observable<LaravelResourceResponse<Array<PaymentCard>>> {
    return this.http.get<LaravelResourceResponse<Array<PaymentCard>>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payment-cards',
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getPaymentCards(caseUuid: string, relations: Array<string> = []): Observable<LaravelResourceResponse<Array<PaymentCard>>> {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');

    return this.http.get<LaravelResourceResponse<Array<PaymentCard>>>(
      this.apiUrl + '/public/cases/' + caseUuid + '/payment-cards', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }

  public chargeExistingCard(userUuid: string, caseUuid: string, data: { payment_request_uuid?: string, card_id: number, amount: number })
    : Observable<LaravelResourceResponse<OppwaOrder>> {
    return this.http.post<LaravelResourceResponse<OppwaOrder>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payment-cards/payments', data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public chargeNewCard(userUuid: string, caseUuid: string, data: NewCardRequest): Observable<LaravelResourceResponse<OppwaOrder>> {
    return this.http.post<LaravelResourceResponse<OppwaOrder>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payments', data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public paymentAmount(userUuid: string, caseUuid: string): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid + '/payment-amount',
    ).pipe(catchError(response => this.handleError(response)));
  }

  checkPaymentSatus(data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ data.uuid }/payment`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  saveSepaInfo(uuid, data): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse>(`${ this.apiUrl }/cases/${ uuid }/update-iban`, data)
      .pipe(catchError(response => this.handleError(response)));
  }

  getMymoidOrder(data: { payment_request: string, signature: string }): Observable<LaravelResourceResponse> {
    return this.http.post<LaravelResourceResponse<{ type: string, base_url: string, short_code: string }>>(
      `${ this.apiUrl }/client/payment`, data,
    ).pipe(catchError(response => this.handleError(response)));
  }

  getPaymentInfo(transactionableUuid: string): Observable<LaravelResourceResponse<PaymentInfoResource>> {
    return this.http.get<LaravelResourceResponse<PaymentInfoResource>>(
      this.apiUrl + '/client/payment-info/' + transactionableUuid,
    ).pipe(catchError(response => this.handleError(response)));
  }

  optOut(data): Observable<LaravelResourceResponse<any>> {
    return this.http.get<LaravelResourceResponse>(`${ this.apiUrl }/client/unsubscribe`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }

  optOutPublic(data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/public/unsubscribe', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  // TODO: move to public service
  notaryDownload(data): Observable<HttpResponse<Blob>> {
    return this.http.get(`${ this.apiUrl }/public/notary-download/get-files`, {
      params: data, observe: 'response', responseType: 'blob',
    }).pipe(catchError(response => this.handleError(response)));
  }

  updateUser(userUuid: string, userData): Observable<LaravelResourceResponse<User>> {
    return this.http.put<LaravelResourceResponse<User>>(this.apiUrl + '/client/users/' + userUuid, userData)
      .pipe(catchError(response => this.handleError(response)));
  }

  showUserCase(userUuid: string, caseUuid: string, relations: Array<string> = []): Observable<LaravelResourceResponse<Case>> {
    const params = MainBaseApiService.convertFiltersForRequest({with: relations}, 'get');
    return this.http.get<LaravelResourceResponse<Case>>(
      this.apiUrl + '/client/users/' + userUuid + '/cases/' + caseUuid, {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  showCase(caseUuid: string): Observable<LaravelResourceResponse<Case>> {
    return this.http.get<LaravelResourceResponse<Case>>(this.apiUrl + '/client/cases/' + caseUuid)
      .pipe(catchError(response => this.handleError(response)));
  }


  public requestBizumPayment(caseUuid: string, data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/request-bizum', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public makeRedSysCardPayment(caseUuid: string, data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/client/cases/' + caseUuid + '/init-redsys-card-payment', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public submitRedSysPayment(data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>('https://sis-t.redsys.es:25443/sis/realizarPago', data)
      .pipe(catchError(response => this.handleError(response)));
  }

  public chargeRedSysCard(caseUuid: string, data): Observable<LaravelResourceResponse<any>> {
    return this.http.post<LaravelResourceResponse>(this.apiUrl + '/client/cases/' + caseUuid + '/charge-redsys-card', data)
      .pipe(catchError(response => this.handleError(response)));
  }
}
