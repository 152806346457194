<!--<div class="col-6" *ngIf="filterType !== 'legal'">-->
<!--    <button mat-stroked-button color="primary" class="ml-2" (click)="clearFilters()">-->
<!--        {{"SHARED.reset-filters" | translate}}-->
<!--    </button>-->
<!--</div>-->

<div class="d-flex justify-content-end">
  <button *ngIf="authUser?.role_id === 5 && componentType === 'admin'" mat-raised-button class="ml-3"
          (click)="changeComponentType('affiliate')">
    Show affiliate view
  </button>
  <button *ngIf="authUser?.role_id === 5 && componentType === 'affiliate'" mat-raised-button class="ml-3"
          (click)="changeComponentType('admin')">
    {{ 'DISTRIBUTION.batch.detail.actions.show_admin_view' | translate }}
  </button>
</div>

<app-affiliate-case-list-filters *ngIf="initFilters" (filtersReady)="handleFiltersState($event)" [affiliateIds]="affiliateIds"
                                 (submitFilters)="handleFilters($event)">
</app-affiliate-case-list-filters>
<button mat-raised-button (click)="exportRecords(affiliateCaseListFilters)" color="primary" class="my-2" type="button">
  {{'CASES.list.export.button-name' | translate}}
</button>
<div class="mat-elevation-z8">
  <table mat-table matSort [dataSource]="dataSource" class="w-100">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!-- Ref Number -->
    <ng-container matColumnDef="df_ref">
      <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.cases-reference" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <a *ngIf="componentType === 'admin'" [routerLink]="['/cases', element.id]">
          {{ element.ref_number }}
        </a>
        <span *ngIf="componentType === 'affiliate'">{{ element.ref_number }}</span>
      </td>
    </ng-container>
    <!-- First Name -->
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef>{{ 'USERS.model.first_name' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.first_name }} </td>
    </ng-container>
    <!-- Last Name -->
    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef>{{ "USERS.model.last_name" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.last_name }}</td>
    </ng-container>
    <!-- Email -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>{{ "USERS.model.email" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.client.email }}</td>
    </ng-container>
    <!-- Product -->
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef>{{ "CASES.product" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.product?.group_name_es | uppercase }}</td>
    </ng-container>
    <!-- Conversion status-->
    <ng-container matColumnDef="conversion_status">
      <th mat-header-cell *matHeaderCellDef>{{"AFFILIATES.conversion-status" | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ getConversionStatus(element)}}</td>
    </ng-container>
    <!-- Status and Payment Status -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ "CASES.case-status" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.status?.name === 'Cancelado - 14 dias' ? 'Inactive' : 'Active'}}</td>
    </ng-container>
    <!-- Created At -->
    <ng-container matColumnDef="created_date">
      <th mat-header-cell *matHeaderCellDef>{{ "CASES.sign-up" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <p class="m-0">{{ element.created_at | toDateObject | date:'dd/MM/yy' }}</p>
        <p class="m-0">{{ element.created_at | toDateObject | date:'HH:mm' }}</p>
      </td>
    </ng-container>
    <!-- Affiliate name -->
    <ng-container matColumnDef="affiliate_name">
      <th mat-header-cell *matHeaderCellDef>{{ "AFFILIATES.affiliate_name" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{element.affiliate[0].name}}</td>
    </ng-container>

    <ng-container matColumnDef="conversion_date">
      <th mat-header-cell *matHeaderCellDef>{{ "AFFILIATES.conversion-date" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <p class="m-0">{{ element.lead.conversion_date | toDateObject | date:'dd/MM/yy' }}</p>
        <p class="m-0">{{ element.lead.conversion_date | toDateObject | date:'HH:mm' }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="earnings">
      <th mat-header-cell *matHeaderCellDef>{{ "AFFILIATES.editor.cost" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <p class="m-0">{{element.lead.cost}}</p>
      </td>
    </ng-container>
  </table>
  <div *ngIf="isLoadingCases > 0" class="text-center" style="padding: 50px">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div *ngIf="isLoadingCases < 1 && dataSource && dataSource.data.length < 1" style="padding: 50px">
  <h3 class="text-center">{{ "CASES.no-cases" | translate }}</h3>
</div>

<mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
               [pageSizeOptions]="[5, 10, 20, 50]" (page)="paginatorChange($event)">
</mat-paginator>
