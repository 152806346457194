import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ClientService } from '../../client.service';

@Component({
  selector: 'app-mymoid-payment-confirmation',
  templateUrl: './mymoid-payment-confirmation.component.html',
  styleUrls: ['./mymoid-payment-confirmation.component.scss'],
})
export class MymoidPaymentConfirmationComponent implements OnInit {
  @ViewChild('iframe') iframe: ElementRef;

  public generalSiteUrl    = environment.GENERAL_SITE_URL;
  public paymentRequestUuid: string;
  public signature: string;
  public iFrameSrcSecured: SafeResourceUrl;
  public appUrl: string;
  public isLoadingOrder    = 0;
  public companyNameLegal  = environment.COMPANY_NAME_LEGAL;
  public companyPhoneLegal = environment.COMPANY_PHONE_LEGAL;
  public companyAddress    = environment.COMPANY_ADDRESS;

  constructor(private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.paymentRequestUuid = this.route.snapshot.queryParamMap?.get('payment_request');
    this.signature          = decodeURI(this.route.snapshot.queryParamMap?.get('signature'));

    this.getMymoidOrder();
  }

  private getMymoidOrder() {
    const data = {
      payment_request: this.paymentRequestUuid,
      signature: this.signature,
    };

    this.isLoadingOrder++;
    this.clientService.getMymoidOrder(data).pipe(finalize(() => this.isLoadingOrder--)).subscribe(next => {
      if (next.data.type === 'mymoid') {
        const baseUrl             = next.data.base_url;
        const transactionableUuid = next.data.transactionable_uuid;
        const shortCode           = next.data.short_code;
        const urlOk               = encodeURI(
          this.appUrl + '/client/authorization-callbacks/mymoid/iframe/success?transactionableUuid=' +
          transactionableUuid,
        );
        const urlKo               = encodeURI(
          this.appUrl + '/client/authorization-callbacks/mymoid/iframe/error?transactionableUuid=' +
          transactionableUuid,
        );
        const iFrameSrc           = baseUrl + '/?urlok=' + urlOk + '&urlko=' + urlKo + '&p=' + shortCode;
        console.log(iFrameSrc);
        this.iFrameSrcSecured = this.sanitizer.bypassSecurityTrustResourceUrl(iFrameSrc);
      }
    });
  }

}
