<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h1>{{ 'DISTRIBUTION.batch.list.heading' | translate }}</h1>
    </mat-card-title>
    <div class="header-actions">
      <a *ngIf="componentType === 'admin'" mat-raised-button color="primary"
         [routerLink]="['/distribution', 'batches', 'create']">
        + {{ 'SHARED.create' | translate }}
      </a>
      <button *ngIf="authUser?.role_id === 5 && componentType === 'admin'" mat-raised-button class="ml-3"
              (click)="changeComponentType('distribution')">
        {{ 'DISTRIBUTION.batch.detail.actions.show_provider_view' | translate }}
      </button>
      <button *ngIf="authUser?.role_id === 5 && componentType === 'distribution'" mat-raised-button class="ml-3"
              (click)="changeComponentType('admin')">
        {{ 'DISTRIBUTION.batch.detail.actions.show_admin_view' | translate }}
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <!-- Filters -->
    <app-distribution-batch-list-filters (filtersReady)="handleFiltersState($event)" (submitFilters)="handleSubmitFilters($event)">
    </app-distribution-batch-list-filters>

    <div class="text-right">
      <button mat-raised-button color="primary" (click)="applyFilters()">
        {{ 'SHARED.apply_filters' | translate }}
      </button>
    </div>

    <!--<mat-expansion-panel class="mb-3">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "PAYMENTS.advanced-filters" | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row" [formGroup]="form">-->
        <!-- Batch Status -->
        <!--<div class="col-4">
          <app-input type="select" formControlName="statuses" appearance="standard" fullWidth="false"
                     [label]="'Wallet status'" multiple
                     [selectOptions]="batchStatusOptions" [selectLabel]="'label'" [selectValue]="'value'">-->
                     <!-- 'DISTRIBUTION.cases.list.filters.batch_status' | translate-->
          <!--</app-input>
        </div>-->
        <!-- Providers -->
        <!--<div class="col-4">
          <app-input type="select" formControlName="distribution_provider_ids" appearance="standard" fullWidth="false"
                     [label]="'DISTRIBUTION.batch.editor.form.distribution_provider_id' | translate" multiple
                     [selectOptions]="distributionProviders" [selectLabel]="'company_name'" [selectValue]="'id'">
          </app-input>
        </div>-->
      <!--</div>
    </mat-expansion-panel>-->
    <mat-card class="mat-elevation-z3" style="margin-top: 20px;">
      <mat-card-content>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
        <!-- Table List -->
        <mat-table [dataSource]="batches" matSort (matSortChange)="sortData($event)">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Selection -->
          <ng-container matColumnDef="select">
            <!-- Select All On Page -->
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox color="primary" (change)="$event ? togglePageSelect($event) : null"
                            [checked]="selection.hasValue() && allRowsSelected()"
                            [indeterminate]="selection.hasValue() && !allRowsSelected()">
              </mat-checkbox>
            </th>
            <!-- Select Row -->
            <td mat-cell *matCellDef="let element">
              <mat-checkbox color="primary" [checked]="selection.isSelected(element)"
                            (change)="toggleRow($event, element)">
              </mat-checkbox>
            </td>
          </ng-container>
          

          <!-- Name -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              <!--{{ 'DISTRIBUTION.batch.model.name' | translate }}-->
              {{ 'DISTRIBUTION.batch-list.wallet_id' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.name }} </td>
          </ng-container>
          <!-- Cases Count -->
          <ng-container matColumnDef="cases_count">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.batch.list.table.cases_count' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.case_distributions.length }}</td>
          </ng-container>
          <!-- Status Updated At -->
          <ng-container matColumnDef="status_updated_at">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.batch.model.status_updated_at' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.status_updated_at | date }}</td>
          </ng-container>
          <!-- Total Contract Amount -->
          <ng-container matColumnDef="total_batch_contract_amount">
            <th mat-header-cell *matHeaderCellDef>{{ 'DISTRIBUTION.batch-list.original_balance' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ (element.total_batch_contract_amount || 0)  | currency:'EUR' }}
            </td>
          </ng-container>
          <!-- Total Phase 2 Monthly Fee -->
          <ng-container matColumnDef="total_monthly_fee_amount">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.cases.list.table.phase_2_installment_amount' | translate }}
            </th>
            <!-- <td mat-cell *matCellDef="let element">{{ element.total_monthly_fee_amount  | currency: 'EUR'}}</td>-->
            <!-- <td mat-cell *matCellDef="let element">{{ element.c_monthly_installment  | currency: 'EUR'}}</td> -->
            <td mat-cell *matCellDef="let element">{{ (element.total_monthly_fee_amount  || 0) | currency: 'EUR'}}</td>
          </ng-container>
          <!-- Total amount collected -->
          <ng-container matColumnDef="amount_collected">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'DISTRIBUTION.batch-list.amount_collected' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              <!-- {{ element.total_current_funded_amount | currency:'EUR' }} -->
              {{ (element.c_amount_collected || 0) | currency:'EUR' }}
              
            </td>
          </ng-container>
          <!-- Total Funding Amount -->
          <ng-container matColumnDef="amount_distributed">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'DISTRIBUTION.batch-list.amount_distributed' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              <!-- {{ (element.total_funded_amount ? element.total_funded_amount : element.total_current_funded_amount) | currency:'EUR' }} -->
              <!-- {{ element.c_amount_collected < 0 ? 0 : element.c_amount_collected | currency:'EUR' }}-->
              {{ (element.c_distributed || 0) | currency:'EUR' }}
            </td>
          </ng-container>
           <!-- Total Amount Distributed -->
           <ng-container matColumnDef="balance_outstanding">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'DISTRIBUTION.batch-list.balance_outstanding' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              <!-- Original balance - amoutn collected-->
              {{ ((element.total_batch_contract_amount - element.c_amount_collected) || 0) | currency:'EUR' }}
            </td>
          </ng-container>
          <!-- Status -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              {{ 'DISTRIBUTION.batch-list.pending_distribution' | translate }}
            </th>
            <!--<td mat-cell *matCellDef="let element" class="text-left">{{ element.status }}</td>-->
            <!-- amount collected - amount distributed-->
            <td mat-cell *matCellDef="let element" class="text-left">{{ (element.c_amount_collected - element.c_distributed - element.c_on_hold) < 0 ? 0 : element.c_amount_collected - element.c_distributed - element.c_on_hold | currency:'EUR' }}</td>
          </ng-container>
          <!-- On account -->
          <ng-container matColumnDef="on-account">
            <th mat-header-cell *matHeaderCellDef class="pl-2">
              <!--{{ 'DISTRIBUTION.batch-list.pending_distribution' | translate }}-->
              On-account
            </th>
            <!--<td mat-cell *matCellDef="let element" class="text-left">{{ element.status }}</td>-->
            <!-- amount collected - amount distributed-->
            <td mat-cell *matCellDef="let element" class="text-left">{{ (element.c_on_hold || 0) | currency:'EUR' }}</td>
          </ng-container>
          <!-- Total Cash Hurdle -->
          <ng-container matColumnDef="default_rate">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'DISTRIBUTION.batch-list.default_rate' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              {{ element.c_default_rate | percent }}
            </td>
          </ng-container>
          <!-- Total Fees Retained -->
          <ng-container matColumnDef="total_fees_retained">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DISTRIBUTION.batch.detail.cases_table.fees_retained' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              <!--<span *ngIf="componentType ==='admin'">{{ (element.total_fees_retained || 0)  | currency:'EUR' }}</span>-->
              <span> <!-- *ngIf="componentType ==='distribution'" -->
                {{ (element.total_provider_fees_retained || 0) | currency:'EUR' }}
              </span>
            </td>
          </ng-container>
          <!-- Average term -->
          <ng-container matColumnDef="average_term">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'DISTRIBUTION.batch-list.average_term' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              <!-- Total months of plan in wallet divided by amount of plans-->
              {{ (
                  element.case_distributions.length > 0 && 
                  element.terms > 0 ? element.terms : 0.00
                 ) | number: (element.terms % 1 !== 0 || element.terms === 0 ? '1.2-2' : '1.0-0') 
              }}
            </td>
          </ng-container>
          <!-- Progress -->
          <ng-container matColumnDef="progress_bar">
            <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.amount" | translate }}</th>
            <td mat-cell *matCellDef="let element" style="padding-right: 10px;">
              <!--<small class="font-weight-lighter">{{element.amount_paid | number: '1.2-2'}}€
                / {{element.amount | number: '1.2-2'}}€ </small>-->
              <!--<span class="font-weight-bold">- {{element.amount - element.amount_paid | number: '1.2-2'}}€</span>-->
              <div class="progress" style="max-width: 120px; margin-right: 10px;">
                <div class="progress-bar progress-bar-striped" role="progressbar"
                     [style]="{width: getPercent(element.total_current_funded_amount, element.total_batch_contract_amount)  + '%'}"
                     [attr.aria-valuenow]="getPercent(element.total_current_funded_amount, element.total_batch_contract_amount)">
                     <!--[attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
                     {{ getPercent(element.total_current_funded_amount, element.total_batch_contract_amount) }}%-->
                </div>
              </div>
            </td>
          </ng-container>

          <!--<ng-container matColumnDef="progress_bar">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'DISTRIBUTION.batch.detail.cases_table.progress' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="pr-2">
              <div class="d-flex justify-content-center mt-3">
                <mat-progress-bar class="cash-hurdle-progress" mode="determinate"
                                  [matTooltip]="(element.total_distributed_amount | currency:'EUR') + ' / ' +
                                   (element.total_cash_hurdle_amount | currency:'EUR')"
                                  [value]="element.total_distributed_amount / (element.total_cash_hurdle_amount / 100)">
                </mat-progress-bar>
                <mat-progress-bar *ngIf="componentType === 'admin'" class="fees-retained-progress" mode="determinate"
                                  color="accent" [matTooltip]="(element.total_fees_retained | currency:'EUR') + ' / ' +
                                   (element.total_fees_obtainable | currency:'EUR')"
                                  [value]="element.total_fees_retained / (element.total_fees_obtainable / 100)">
                </mat-progress-bar>
                <mat-progress-bar *ngIf="componentType === 'distribution'" class="fees-retained-progress"
                                  mode="determinate" color="accent"
                                  [matTooltip]="(element.total_provider_fees_retained | currency:'EUR') + ' / ' +
                                   (element.total_provider_fees_obtainable | currency:'EUR')"
                                  [value]="element.total_provider_fees_retained / (element.total_provider_fees_obtainable / 100)">
                </mat-progress-bar>
              </div>
            </td>
          </ng-container>-->
          
          <!-- Actions -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
            <td mat-cell *matCellDef="let element">
              <div class="pt-2 pb-2" style="display: flex">
                <a mat-stroked-button [routerLink]="[element.id, 'cases']">
                  {{ 'SHARED.view' | translate }}
                </a>
                <a *ngIf="componentType === 'admin'" class="ml-3" mat-stroked-button
                   [routerLink]="[element.id, 'edit']">
                  {{ 'SHARED.edit' | translate }}
                </a>
              </div>
            </td>
          </ng-container>
        </mat-table>
        <!-- No Data -->
        <div *ngIf="isLoading < 1 && batches && batches.data.length < 1" style="padding: 50px">
          <h3
            class="text-center">{{ 'SHARED.no_data' | translate:{ models: 'DISTRIBUTION.batch.model_name.plural' | translate } }}</h3>
        </div>
        <div *ngIf="isLoading" class="text-center" style="padding: 50px">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <!--<button mat-raised-button color="primary" class="ml-3" (click)="confirmDistributeWallet()"
                  [disabled]="selection.selected.length === 0">
            Distribute
        </button>-->
        <div class="footer-actions ml-3"></div>
        <mat-paginator [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize" showFirstLastButtons
                       [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageIndex]="paginatorConfig.pageIndex"
                       (page)="paginatorChange($event)">
        </mat-paginator>
      </mat-card-footer>
    </mat-card>
  </mat-card-content>
</mat-card>
