<nav mat-tab-nav-bar>
  <a *ngIf="componentType === 'admin'" mat-tab-link [routerLink]="['/distribution', 'cases']" routerLinkActive
     #rla="routerLinkActive" [active]="rla.isActive">
    <!--{{ 'DISTRIBUTION.lists_wrapper.tabs.distribution_cases' | translate }}-->
    Cases
  </a>
  <a mat-tab-link [routerLink]="['/distribution', 'batches']" routerLinkActive
     #rla2="routerLinkActive" [active]="rla2.isActive">
    <!--{{ 'DISTRIBUTION.lists_wrapper.tabs.distribution_batches' | translate }}-->
    Wallets
  </a>
  <a mat-tab-link [routerLink]="['/distribution', 'model']" routerLinkActive
     #rla3="routerLinkActive" [active]="rla3.isActive">
    Models
  </a>
  <a mat-tab-link [routerLink]="['/distribution', 'reconciliation-model']" routerLinkActive
    #rla4="routerLinkActive" [active]="rla4.isActive">
    Reconciliation
  </a>
  <!--<a mat-tab-link [routerLink]="['/distribution', 'model-v2']" routerLinkActive
     #rla2="routerLinkActive" [active]="rla2.isActive">
    Distribution Model-v2
  </a>-->
  <!--<a *ngIf="componentType === 'admin'" mat-tab-link [routerLink]="['/distribution', 'providers']" routerLinkActive
     #rla3="routerLinkActive" [active]="rla3.isActive">
    {{ 'DISTRIBUTION.lists_wrapper.tabs.distribution_providers' | translate }}
  </a>-->
  <a *ngIf="componentType === 'admin'" mat-tab-link [routerLink]="['/distribution', 'reports']" routerLinkActive
     #rla5="routerLinkActive" [active]="rla5.isActive">
    <!--{{ 'DISTRIBUTION.lists_wrapper.tabs.distribution_reports' | translate }}-->
    Reports
  </a>
</nav>
<div class="mt-2">
  <router-outlet></router-outlet>
</div>
