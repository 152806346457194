<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h1>{{ 'CONFIG.dialer.trigger.header' | translate }}</h1>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div [hidden]="!isLoading" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
      <div *ngIf="serverResponse" class="col-12">
        <app-server-response [response]="serverResponse"></app-server-response>
      </div>
    </div>
    <form *ngIf="form" [formGroup]="form" style="width: 100%" (ngSubmit)="submitForm(form)">
      <div class="row">
        <div class="col-12">
          <mat-card>
            <mat-card-header>
              <mat-card-title><h2>{{ 'CONFIG.dialer.status_triggers' | translate }}</h2></mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <table class="table">
                <thead>
                <tr>
                  <th>{{ 'CONFIG.dialer.status' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.product_type' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.dialer_list_id' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.priority' | translate }}</th>
                  <th>{{'CONFIG.dialer.force_dial' | translate}}</th>
                  <th  style="padding-left: 25px;">{{ 'CONFIG.dialer.delay' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.delay' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.active' | translate }}</th>
                  <!--<th>{{ 'CONFIG.dialer.auto_queue' | translate }}</th>-->
                </tr>
                </thead>
                <tbody>
                <ng-container [formArrayName]="'dialer_triggers'"
                              *ngFor="let formGroup of getFormArray().controls let i = index">
                  <tr [formGroupName]="i" *ngIf="formGroup.get('statusable_type').value === 'status'">
                    <!--STATUS -->
                    <td>
                      <app-input type="select" *ngIf="statusCategories?.length" formControlName="statusable_id"
                                 appearance="outline"
                                 [searchable]="true" [label]="'CONFIG.dialer.status' | translate"
                                 class="full-width" [showClear]="false" [showLabel]="true"
                                 [selectOptions]="statusCategories" [optGroupProperty]="'statuses'"
                                 [optGroupLabel]="'name'"
                                 [selectLabel]="'name'" [selectValue]="'id'">
                      </app-input>
                    </td>
                    <!--Product type-->
                    <td>
                      <app-input type="select" appearance="outline" [label]="'CONFIG.dialer.product_type' | translate"
                                 formControlName="product_ids" [showClear]="false" [showLabel]="true"
                                 [selectOptions]="products" [multiple]="true"
                                 [selectLabel]="'name'" [selectValue]="'id'">
                      </app-input>
                    </td>
                    <!-- Dialer List Id-->
                    <td>
                      <app-input type="select" appearance="outline" [label]="'CONFIG.dialer.dialer_list_id' | translate"
                                 formControlName="dialer_list_id" class="full-width" [showClear]="false"
                                 [selectOptions]="dialerLists" [selectLabel]="'name'" [selectValue]="'id'">
                      </app-input>
                    </td>
                    <!--Priority-->
                    <td>
                      <app-input type="number" formControlName="priority"
                                 [label]="'CONFIG.dialer.priority' | translate">
                      </app-input>
                    </td>
                    <!--Force dial-->
                    <td>
                      <app-input type="switch"  formControlName="force_dial" appearance="standard"
                                  style="padding-left: 27px;display: block;">
                      </app-input>
                    </td>
                    <!--Delay-->
                    <td>
                      <app-input type="number" formControlName="delay" appearance="standard" [fullWidth]="true"
                                 style="width: 70%;display: block;margin-left: 25px;"
                                 [label]="'CONFIG.drip-campaign.editor.drip-notification.delay.label' | translate">
                      </app-input>
                    </td>
                    <!--Delay Unit-->
                    <td>
                      <app-input type="select" formControlName="delay_unit" appearance="standard"
                                 [fullWidth]="false" style="width: 70%;display: block"
                                 [label]="'TASK-TEMPLATE.editor.delay_unit.label' | translate"
                                 [selectOptions]="delayOptions" [selectLabel]="'label'" [selectValue]="'value'">
                      </app-input>
                    </td>
                    <!-- Active-->
                    <td>
                      <app-input type="switch" formControlName="active"
                                 [label]="'CONFIG.dialer.active' | translate">
                      </app-input>
                    </td>
                    <!--Auto Queue-->
                    <!--<td>
                      <app-input type="switch" formControlName="auto_queue"
                               [label]="'CONFIG.dialer.auto_queue' | translate">
                      </app-input>
                    </td>-->
                    <td>
                      <button type="button" mat-icon-button color="warn" (click)="removeStatus(i)">
                        <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                      </button>
                    </td>
                  </tr>
                  <tr [formGroupName]="i" *ngIf="formGroup.get('statusable_type').value === 'status'">
                    <td>
                      <p><b>{{'PACKAGER.model_name.singular' | translate}}</b></p>
                    <app-input type="select" formControlName="packager_id" appearance="outline" class="mt-2"
                               [label]="'PACKAGER.model_name.singular' | translate" showLabel="true" fullWidth="true" searchable="true"
                               [selectOptions]="packagers" [selectLabel]="['name_en']" [selectValue]="'id'">
                    </app-input>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </mat-card-content>
            <mat-card-footer>
              <div class="d-flex justify-content-end">
                <button type="button" class="mr-3 mb-3" mat-raised-button color="primary"
                        (click)="addNewStatusTrigger('status')">
                  + {{ 'CONFIG.dialer.trigger.actions.add' | translate }}
                </button>
              </div>
            </mat-card-footer>
          </mat-card>
        </div>
        <!-- Payment status trigger -->
        <div class="col-12 mt-3">
          <mat-card>
            <mat-card-header>
              <mat-card-title><h2>{{ 'CONFIG.dialer.payment_status_triggers' | translate }}</h2></mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <table class="table">
                <thead>
                <tr>
                  <th>{{ 'CONFIG.dialer.payment_status' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.product_type' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.dialer_list_id' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.priority' | translate }}</th>
                  <th>{{'CONFIG.dialer.force_dial' | translate}}</th>
                  <th style="padding-left: 25px;">{{ 'CONFIG.dialer.delay' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.delay' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.active' | translate }}</th>
                  <!-- <th>{{ 'CONFIG.dialer.auto_queue' | translate }}</th>-->
                </tr>
                </thead>
                <tbody>
                <ng-container [formArrayName]="'dialer_triggers'"
                              *ngFor="let formGroup of getFormArray().controls let i = index">
                  <tr [formGroupName]="i" *ngIf="formGroup.get('statusable_type').value === 'payment_status'">
                    <!--STATUS-->
                    <td>
                      <app-input type="select" *ngIf="statusCategories?.length" formControlName="statusable_id"
                                 appearance="outline"
                                 [searchable]="true" [label]="'CONFIG.dialer.status' | translate"
                                 class="full-width" [selectOptions]="paymentStatuses" [selectLabel]="['name']"
                                 [selectValue]="'id'" [showClear]="false" [showLabel]="true">
                      </app-input>
                    </td>
                    <!--Product type-->
                    <td>
                      <app-input type="select" appearance="outline" [label]="'CONFIG.dialer.product_type' | translate"
                                 formControlName="product_ids" [showClear]="false" [showLabel]="true"
                                 [selectOptions]="products" [multiple]="true"
                                 [selectLabel]="'name'" [selectValue]="'id'">
                      </app-input>
                    </td>
                    <!--Dialer List Id-->
                    <td>
                      <app-input type="select" appearance="outline" [label]="'CONFIG.dialer.dialer_list_id' | translate"
                                 formControlName="dialer_list_id" class="full-width" [showClear]="false"
                                 [selectOptions]="dialerLists" [selectLabel]="'name'" [selectValue]="'id'">
                      </app-input>
                    </td>
                    <!--Priority-->
                    <td>
                      <app-input type="number" formControlName="priority"
                                 [label]="'CONFIG.dialer.priority' | translate">
                      </app-input>
                    </td>
                    <!--Force dial-->
                    <td>
                      <app-input type="switch"  formControlName="force_dial" appearance="standard"
                                 style="padding-left: 27px;display: block;">
                      </app-input>
                    </td>
                    <!--Delay-->
                    <td>
                      <app-input type="number" formControlName="delay" appearance="standard"
                                 [fullWidth]="false" style="width: 70%;display: block;margin-left: 25px;"
                                 [label]="'CONFIG.drip-campaign.editor.drip-notification.delay.label' | translate">
                      </app-input>
                    </td>
                    <!--Delay Unit-->
                    <td>
                      <app-input type="select" formControlName="delay_unit" appearance="standard"
                                 [label]="'TASK-TEMPLATE.editor.delay_unit.label' | translate"
                                 [selectOptions]="delayOptions" [selectLabel]="'label'" [selectValue]="'value'">
                      </app-input>
                    </td>
                    <!--Active-->
                    <td>
                      <app-input type="switch" formControlName="active"
                                 [label]="'CONFIG.dialer.active' | translate">
                      </app-input>
                    </td>
                    <!--Auto Queue-->
                    <!--  <td>
                        <app-input type="switch" formControlName="auto_queue"
                          [label]="'CONFIG.dialer.auto_queue' | translate">
                        </app-input>
                      </td>-->
                    <td>
                      <button type="button" mat-icon-button color="warn"
                              (click)="removeStatus(i)">
                        <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                      </button>
                    </td>
                  </tr>
                  <tr [formGroupName]="i" *ngIf="formGroup.get('statusable_type').value === 'payment_status'">
                    <td>
                      <p><b>{{'PACKAGER.model_name.singular' | translate}}</b></p>
                      <app-input type="select" formControlName="packager_id" appearance="outline" class="mt-2"
                                 [label]="'PACKAGER.model_name.singular' | translate" showLabel="true" fullWidth="true" searchable="true"
                                 [selectOptions]="packagers" [selectLabel]="['name_en']" [selectValue]="'id'">
                      </app-input>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </mat-card-content>
            <mat-card-footer>
              <div class="d-flex justify-content-end">
                <button type="button" class="mr-3 mb-3" mat-raised-button color="primary"
                        (click)="addNewStatusTrigger('payment_status')">
                  + {{ 'CONFIG.dialer.trigger.actions.add' | translate }}
                </button>
              </div>
            </mat-card-footer>
          </mat-card>
        </div>

        <!-- Call status trigger -->
        <div class="col-12 mt-3">
          <mat-card>
            <mat-card-header>
              <mat-card-title><h2>{{ 'CONFIG.dialer.call_status_triggers' | translate }}</h2></mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <table class="table">
                <thead>
                <tr>
                  <th>{{ 'CONFIG.dialer.call_status' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.product_type' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.dialer_list_id' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.priority' | translate }}</th>
                  <th>{{'CONFIG.dialer.force_dial' | translate}}</th>
                  <th style="padding-left: 25px;">{{ 'CONFIG.dialer.delay' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.delay' | translate }}</th>
                  <th>{{ 'CONFIG.dialer.active' | translate }}</th>
                  <!-- <th>{{ 'CONFIG.dialer.auto_queue' | translate }}</th>-->
                </tr>
                </thead>
                <tbody>
                <ng-container [formArrayName]="'dialer_triggers'"
                              *ngFor="let formGroup of getFormArray().controls let i = index">
                  <tr [formGroupName]="i" *ngIf="formGroup.get('statusable_type').value === 'call_status'">
                    <!--STATUS-->
                    <td>
                      <app-input  *ngIf="statusCategories?.length" type="select" formControlName="statusable_id"
                                 appearance="outline"
                                 [searchable]="true" [label]="'CONFIG.dialer.call_status' | translate"
                                 class="full-width" [selectOptions]="callStatuses" [selectLabel]="['name']"
                                 [selectValue]="'id'" [showClear]="false" [showLabel]="true">
                      </app-input>
                    </td>
                    <!--Product type-->
                    <td>
                      <app-input type="select" appearance="outline" [label]="'CONFIG.dialer.product_type' | translate"
                                 formControlName="product_ids" [showClear]="false" [showLabel]="true"
                                 [selectOptions]="products" [multiple]="true"
                                 [selectLabel]="'name'" [selectValue]="'id'">
                      </app-input>
                    </td>
                    <!--Dialer List Id-->
                    <td>
                      <app-input type="select" appearance="outline" [label]="'CONFIG.dialer.dialer_list_id' | translate"
                                 formControlName="dialer_list_id" class="full-width" [showClear]="false"
                                 [selectOptions]="dialerLists" [selectLabel]="'name'" [selectValue]="'id'">
                      </app-input>
                    </td>
                    <!--Priority-->
                    <td>
                      <app-input type="number" formControlName="priority"
                                 [label]="'CONFIG.dialer.priority' | translate">
                      </app-input>
                    </td>
                    <!--Force dial-->
                    <td>
                      <app-input type="switch"  formControlName="force_dial" appearance="standard"
                                 style="padding-left: 27px;display: block;">
                      </app-input>
                    </td>
                    <!--Delay-->
                    <td>
                      <app-input type="number" formControlName="delay" appearance="standard"
                                 [fullWidth]="false" style="width: 70%;display: block;margin-left: 25px;"
                                 [label]="'CONFIG.drip-campaign.editor.drip-notification.delay.label' | translate">
                      </app-input>
                    </td>
                    <!--Delay Unit-->
                    <td>
                      <app-input type="select" formControlName="delay_unit" appearance="standard"
                                 [label]="'TASK-TEMPLATE.editor.delay_unit.label' | translate"
                                 [selectOptions]="delayOptions" [selectLabel]="'label'" [selectValue]="'value'">
                      </app-input>
                    </td>
                    <!--Active-->
                    <td>
                      <app-input type="switch" formControlName="active"
                                 [label]="'CONFIG.dialer.active' | translate">
                      </app-input>
                    </td>
                    <!--Auto Queue-->
                    <!--  <td>
                        <app-input type="switch" formControlName="auto_queue"
                          [label]="'CONFIG.dialer.auto_queue' | translate">
                        </app-input>
                      </td>-->
                    <td>
                      <button type="button" mat-icon-button color="warn"
                              (click)="removeStatus(i)">
                        <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                      </button>
                    </td>
                  </tr>
                  <tr [formGroupName]="i" *ngIf="formGroup.get('statusable_type').value === 'call_status'">
                    <td>
                      <p><b>{{'PACKAGER.model_name.singular' | translate}}</b></p>
                      <app-input type="select" formControlName="packager_id" appearance="outline" class="mt-2"
                                 [label]="'PACKAGER.model_name.singular' | translate" showLabel="true" fullWidth="true" searchable="true"
                                 [selectOptions]="packagers" [selectLabel]="['name_en']" [selectValue]="'id'">
                      </app-input>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </mat-card-content>
            <mat-card-footer>
              <div class="d-flex justify-content-end">
                <button type="button" class="mr-3 mb-3" mat-raised-button color="primary"
                        (click)="addNewStatusTrigger('call_status')">
                  + {{ 'CONFIG.dialer.trigger.actions.add' | translate }}
                </button>
              </div>
            </mat-card-footer>
          </mat-card>
        </div>

        <div class="col-12">
          <hr>
          <div class="d-flex justify-content-end">
            <app-spinner-btn class="ml-2 mt-1" type="submit" [loading]="isSubmitting"
                             [name]="'SHARED.save' | translate">
            </app-spinner-btn>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
