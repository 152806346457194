import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NegotiatorsComponent } from './negotiators.component';

const routes: Routes = [
  {
    path: 'negotiators',
    children: [
      {path: '', component: NegotiatorsComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class NegotiatorsRoutingModule {
}
