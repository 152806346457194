import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { CaseService } from '../../../admin/case/case.service';

@Component({
  selector:    'app-verify-signature',
  templateUrl: './verify-signature.component.html',
  styleUrls:   ['./verify-signature.component.scss'],
})
export class VerifySignatureComponent implements OnInit {
  public storageUrl = environment.STORAGE_URL + '/';
  public clientDni  = [];
  public clientSignature;
  public partnerDni = [];
  public partnerSignature;
  public jointApplication: boolean;
  public signatureVerified: boolean;

  constructor(private caseService: CaseService,
              public toast: ToastrService,
              public translate: TranslateService,
              public dialogRef: MatDialogRef<VerifySignatureComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.clientDni         = this.data.case.client_files_by_type.dni || [];
    this.partnerDni        = this.data.case.partner_files_by_type.dni || [];
    this.clientSignature   = this.data.case.contracts[0].signature;
    this.partnerSignature  = this.data.case.contracts[0].signature_1;
    this.jointApplication  = this.data.case.joint_application;
    this.signatureVerified = !!this.data.case.client.signature_verified_at;
  }

  closeDialog(value = null) {
    this.dialogRef.close(value);
  }

  public verifySignature() {
    this.caseService.verifySignature(this.data.case.id, {signature_verified: true}).subscribe(
        value => {
          this.toast.success(this.translate.instant('CASES.details.signature-verified'));
          this.closeDialog(value);
        },
        error => {
          this.toast.error(this.translate.instant('CASES.details.signature-verified-error'));
        });
  }
}
