import { NgModule } from '@angular/core';
import { SharedClientModule } from '../_shared/shared-client.module';
import { BizumPaymentComponent } from './bizum-payment/bizum-payment.component';
import { ClientPaymentRedirectComponent } from './client-payment-redirect/client-payment-redirect.component';
import { ClientPaymentComponent } from './client-payment/client-payment.component';
import { PaymentOutcomeComponent } from './client-payment/payment-outcome/payment-outcome.component';
import {
  MymoidPaymentConfirmationComponent
} from './mymoid-payment-confirmation/mymoid-payment-confirmation.component';
import { PaymentRoutingModule } from './payment-routing.module';
import { SepaPaymentComponent } from './sepa-payment/sepa-payment.component';

@NgModule({
  imports: [
    SharedClientModule,
    PaymentRoutingModule,
  ],
  declarations: [
    MymoidPaymentConfirmationComponent,
    ClientPaymentComponent,
    ClientPaymentRedirectComponent,
    PaymentOutcomeComponent,
    SepaPaymentComponent,
    BizumPaymentComponent,
  ],
})
export class PaymentModule {
}
