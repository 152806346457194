<div *ngIf="isLoading" class="text-center" style="padding: 50px">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-md-8">
      <h2>{{ "DASHBOARD.sales-leaderboard" | translate }}</h2>
      <!-- <div class="d-inline-block pb-2"> -->
      <!--   <mat-radio-group aria-label="Select an option" (change)="onRadioChange($event)"> -->
      <!--     <mat-radio-button *ngFor="let product of availableProducts" [checked]="selectedProduct === product.group_slug" -->
      <!--                       [value]="product.group_slug"> -->
      <!--       {{ product.name }} -->
      <!--     </mat-radio-button> -->
      <!--   </mat-radio-group> -->
      <!-- </div> -->
      <!-- DM -->
      <div class="mat-elevation-z8">
        <table style="width: 100%" mat-table matSort  [dataSource]="dataSourceAllProducts" matSortActive="this_month"
               matSortDirection="desc" #sort="matSort">
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
          <!-- Name -->
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
            <td mat-cell *matCellDef="let element;">
              {{ element.name }}
            </td>
            <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
          </ng-container>
          <!-- Today -->
          <ng-container matColumnDef="today">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.today" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.today }}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{ totalAgentSales[selectedProduct].today }}</b></td>
          </ng-container>
          <!-- This Week -->
          <ng-container matColumnDef="this_week">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-week" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.this_week }}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{ totalAgentSales[selectedProduct].this_week }}</b></td>
          </ng-container>
          <!-- This Month -->
          <ng-container matColumnDef="this_month">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.this-month" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.this_month }}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{ totalAgentSales[selectedProduct].this_month }}</b></td>
          </ng-container>
          <!-- Total Fees -->
          <ng-container matColumnDef="total_fees">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.total-fees" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.total_fees | currency }}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{ totalAgentSales[selectedProduct].total_fees | currency }} </b>
            </td>
          </ng-container>
          <!-- Quota -->
          <ng-container matColumnDef="quota">
            <th mat-header-cell *matHeaderCellDef>{{"DASHBOARD.quota" | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <small class="font-weight-lighter">{{ element.this_month }} / {{ element.quota }}</small>
              <div class="progress" style="max-width: 120px">
                <div class="progress-bar progress-bar-striped" role="progressbar"
                     [style]="{width: getPercent(element)  + '%'}"
                     [attr.aria-valuenow]="getPercent(element)"
                     [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercent(element) }}%
                </div>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ totalAgentSales[selectedProduct].this_month }} / {{ totalAgentSales[selectedProduct].quota }}
            </td>
          </ng-container>
        </table>
      </div>
    </div>
    <div class="col-md-4">
      <!-- 500 Sales-->
      <h2>{{"DASHBOARD.500-sales" | translate}}</h2>
      <div class="mat-elevation-z8 mb-5" style="margin-top: 49px">
        <table style="width: 100%" mat-table matSort [dataSource]="dataSourceSales" #salesSort="matSort">
          <ng-container matColumnDef="agent_name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.agent-name" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.agent_name }}</td>
            <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
          </ng-container>

          <ng-container matColumnDef="number_of_sales">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"DASHBOARD.number-of-cases" | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.number_of_sales }}</td>
            <td mat-footer-cell *matFooterCellDef><b>{{getTotalNumberOfSales('500')}}</b></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsSales"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsSales;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumnsSales"></tr>
        </table>
      </div>
      <!--CHARTS-->
      <ng-container *ngIf="chartsReady">
        <div *ngFor="let product of availableProducts" style="display: block; position: relative">
          <h2 class="text-center m-0">Progress</h2>
          <canvas baseChart
                  [data]="doughnutCharts[product.group_slug].data"
                  [options]="doughnutCharts[product.group_slug].options"
                  [labels]="doughnutCharts[product.group_slug].labels"
                  [colors]="doughnutCharts[product.group_slug].colors"
                  [plugins]="doughnutCharts[product.group_slug].plugins"
                  [chartType]="doughnutCharts[product.group_slug].type">
          </canvas>
          <div class="report">
            <b>{{ saleData[product.group_slug].number_of_sales}}/{{saleData[product.group_slug].monthly_quota }}</b>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
