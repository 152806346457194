import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {  NegotiatorLeaderboardComponent } from './negotiator-leaderboard.component';

const routes: Routes = [
  {path: 'negotiator-leaderboard', component: NegotiatorLeaderboardComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})

export class NegotiatorLeaderboardRoutingModule {
}