<div class="container">
    <div class="row">
        <div class="col-12">

            <div class="card shadow">
                <div class="card-body pt-5">
                <div class="container">
                    <div class="row pb-5">
                    <div class="col-12">
                        <h4>Add new Negotiator</h4>
                    </div>
                    <div class="col-12">
                        <form *ngIf="formReady" class="row" [formGroup]="form" (ngSubmit)="submit()">
                            <!-- name -->
                            <div class="col-4">
                                <div class="col-md-4">
                                  <div class="input-group bg-white shadow-inset-2" [class.has-length]="searchFocus">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-transparent border-right-0">
                                          <i class="fal fa-search"></i>
                                      </span>
                                    </div>
                                    <input
                                            [formControl]="search"
                                            type="text"
                                            class="form-control
                                      border-left-0 bg-transparent pl-0"
                                            (focus)="onFocus()"
                                            (blur)="onBlur()"
                                            [placeholder]="'SHARED.search' | translate">
                                  </div>
                                </div>
                                <!-- <app-input type="text" formControlName="name" [fullWidth]="true"
                                        label="Name" [extraLabel]="true">
                                </app-input> -->
                            </div>
                            <!-- upload dni -->
                            <div class="col-4">
                            </div>
                            <!-- upload signature -->
                            <div class="col-4">
                            </div>
                            <!-- submit form -->
                            <div class="col-12">
                                <!-- mat-button -->
                                <button mat-raised-button color="primary" type="button">Save new Negotiator</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h4>Current Negotiators</h4>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>DNI</th>
                                        <th>Signature</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let negotiator of negotiators">
                                        <td>{{negotiator.user.id}}</td>
                                        <td>{{negotiator.user.first_name + " " + negotiator.user.last_name}}</td>
                                        <td><mat-icon>{{checkmarkOrX(negotiator.dni_location)}}</mat-icon></td>
                                        <td><mat-icon>{{checkmarkOrX(negotiator.signature_location)}}</mat-icon></td>
                                        <td>
                                            <!-- mat-icon edit -->
                                            <mat-icon>edit</mat-icon>
                                            <!-- mat-icon delete -->
                                            <mat-icon>delete</mat-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>