import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CreditorService} from '../../creditor.service';
import {finalize} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Creditor} from '../../../../../../../_base-shared/models/Entity/Creditor';

@Component({
  selector: 'app-creditor-reassign',
  templateUrl: './creditor-reassign.component.html',
  styleUrls: ['./creditor-reassign.component.scss']
})
export class CreditorReassignComponent implements OnInit {
  public creditors    = [];
  public allCreditors = [];
  public isLoading = 0;
  public selectedCreditor: Creditor;
  public form: UntypedFormGroup;
  public commonDropdownSettings = {
    enableSearchFilter: true,
    addNewItemOnFilter: false,
    singleSelection   : true,
    text              : 'Select creditor',
    maxHeight: 200,
  };
  public creditor_case_count: number;

  constructor(
    private fb: UntypedFormBuilder,
    private creditorService: CreditorService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<CreditorReassignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.fetchCreditors();
  }

  buildForm() {
    this.form = this.fb.group({
      creditor: [null, Validators.required],
      notes: [null, null]
    });
  }

  creditorChange($event): void {
    console.log($event);
    this.selectedCreditor = this.creditors.find(creditor => creditor.id === $event);
    if ($event === null){
      this.form.get('creditor').setValue(null);
    }
  }

  fetchCreditors() {
    this.isLoading++;
    this.creditorService.index({all: 1, active: 1})
    .pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
          this.allCreditors = res.data;
          this.creditors    = res.data.map(i => ({id: i.id, name: i.name, itemName: i.name}));
          this.creditors = this.creditors.filter(creditor => creditor.id !== this.data.creditor.id);
          this.getCaseCount(this.data.creditor.id)
        },
        err => console.error(err)
      );
  }

  public submitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const creditorId = this.form.value.creditor;
    const notes = this.form.value.notes;

    if (creditorId !== this.selectedCreditor?.id) {
      return;
    }

    this.isLoading++;
    this.creditorService.deleteAndReassign(this.data.creditor.id, creditorId, notes)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
          this.toastr.success(res.message);
          this.dialogRef.close(true);
        },
        err => console.error(err)
      );
  }

  getCaseCount(creditorId: number) {
    this.isLoading++;
    this.creditorService.getCreditorCaseCount(creditorId)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
          this.allCreditors = this.allCreditors.map(creditor => {
            if (creditor.id === creditorId) {
              this.creditor_case_count = res.data;
            }
            return creditor;
          });
        },
        err => console.error(err)
      );
  }
}
