<h2 style="margin: 0px; padding: 0px;" mat-dialog-title>{{ 'CASE_CREDITOR.refinanced.mark' | translate }} {{data.creditor.name}} {{ 'CASE_CREDITOR.refinanced.as_refinanced' | translate }}</h2>
<div class="alert alert-danger" role="alert" *ngIf="!data.creditor.approved">{{"CASE_CREDITOR.info.unapproved_creditor" | translate}}</div>
<mat-dialog-content>
  <!-- print the values of data in a table. -->
  <table class="mt-3">
    <tbody>
      <tr>
        <td><b>{{ 'CASE_CREDITOR.refinanced.debt_level' | translate }}:</b></td>
        <td style="padding-left:2rem;">{{ creditorFee | currency  }}</td>
      </tr>
      <tr>
        <td><b>{{ 'CASE_CREDITOR.refinanced.monthly_payment' | translate }}:</b></td>
        <td style="padding-left:2rem;">
          <input type="number" (keyup)="calculateTerms()" [(ngModel)]="monthlyPayment" style="width: 50px;" min="1" max="10000" step="1" /> {{data.creditor.currency}}
        </td>
      </tr>
      <tr>
        <td><b>{{ 'CASE_CREDITOR.refinanced.term_length' | translate }}:</b></td>
        <td style="padding-left:2rem;">
          {{ termLength }} {{ 'CASE_CREDITOR.refinanced.months' | translate }}
        </td>
      </tr>
      <tr>
        <td colspan="2"><i>{{ 'CASE_CREDITOR.refinanced.note' | translate }}</i></td>
      </tr>
    </tbody>
  </table>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="primary">{{ 'SHARED.cancel' | translate }}</button>
  <button mat-button (click)="save()" color="primary" [disabled]="!monthlyPayment || !termLength">{{ 'SHARED.save' | translate }}</button>
</mat-dialog-actions>