<h1 mat-dialog-title>{{'CASES.single.rebuild-plan' | translate}}</h1>
<div mat-dialog-content>
  <form [formGroup]="optionsForm">
    <div class="form-group" style="margin-bottom: 0">
      <mat-form-field appearance="fill">
        <mat-label>Choose an option</mat-label>
        <mat-select formControlName="options">
          <mat-option value="from_beginning">{{'CASES.single.rebuild-payment-option-1' | translate}}</mat-option>
          <mat-option value="only_unpaid">{{'CASES.single.rebuild-payment-option-2' | translate}}</mat-option>
          <mat-option value="manual">{{'CASES.single.rebuild-payment-option-3' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="optionsForm.get('options').touched && optionsForm.get('options').errors?.required">
        {{"SHARED.field-required" | translate}}
      </mat-error>
<!--      <mat-error *ngIf="email.touched && email.errors?.required" class="pl-2">Please enter your email.</mat-error>-->
<!--      <mat-error *ngIf="email.touched && email.errors?.email" class="pl-2">Not a valid email address.</mat-error>-->
    </div>
    <p *ngIf="optionsForm.get('options').value === 'from_beginning'">{{'CASES.single.rebuild-payment-option-1-note' | translate}}</p>
    <p *ngIf="optionsForm.get('options').value === 'only_unpaid'">{{'CASES.single.rebuild-payment-option-2-note' | translate}}</p>


    <div *ngIf="optionsForm.get('options').value === 'manual'" [@inOutAnimation]>
      <div class="row">
        <div class="col-6">
          <h3><b>{{'CASES.single.phase-one' | translate}}</b></h3>
          <p>
            {{'CASES.single.rebuild-payment-option-3-note-phase-1' | translate}}
            <b>{{this.phaseOneAmountLeft}}€</b>
          </p>
          <div *ngFor="let formGroup of getFormArray('phase_1').controls let i = index" formArrayName="phase_1" [@inOutAnimation]>
            <div class="d-flex align-items-center pb-3">
              <span class="pr-2">{{i + 1}}.</span>
              <div class="" [formGroupName]="i">
                <input type="number" class="form-control" formControlName="amount">
              </div>
              <button mat-icon-button color="warn" class="ml-2" (click)="removeInstallment('phase_1', i, $event)">
                <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">remove_circle
                </mat-icon>
              </button>
            </div>
          </div>
          <mat-error *ngIf="phaseOneAmountLeft < 0">
            {{'CASES.single.rebuild-payment-option-3-minus-error' | translate}}
          </mat-error>
          <button *ngIf="phaseOneAmountLeft > 0" mat-raised-button color="primary" (click)="addInstallment('1', $event)">
            + {{ 'CASES.single.rebuild-payment-add-installment-1' | translate }}
          </button>
        </div>
        <div class="col-6">
          <h3><b>{{'CASES.single.phase-two' | translate}}</b></h3>
          <p>
            {{'CASES.single.rebuild-payment-option-3-note-phase-2' | translate}}
            <b>{{this.phaseTwoAmountLeft}}€</b>
          </p>
          <div *ngFor="let formGroup of getFormArray('phase_2').controls let i = index" formArrayName="phase_2" [@inOutAnimation]>
            <div class="d-flex align-items-center pb-3">
              <span class="pr-2">{{i + 1}}.</span>
              <div class="" [formGroupName]="i">
                <input type="number" class="form-control" formControlName="amount">
              </div>
              <button mat-icon-button color="warn" class="ml-2" (click)="removeInstallment('phase_2', i, $event)">
                <mat-icon class="text-danger mat-icon" aria-hidden="false" aria-label="Example home icon">remove_circle
                </mat-icon>
              </button>
            </div>
          </div>
          <mat-error *ngIf="phaseTwoAmountLeft < 0">
            {{'CASES.single.rebuild-payment-option-3-minus-error' | translate}}
          </mat-error>
          <button *ngIf="phaseTwoAmountLeft > 0" mat-raised-button color="primary" (click)="addInstallment('2', $event)">
            + {{ 'CASES.single.rebuild-payment-add-installment-2' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>

  <mat-error class="text-center pt-4" *ngIf="validationNotAllocated">{{validationNotAllocated}}</mat-error>

</div>
<div mat-dialog-actions class="py-4 justify-content-end">
<!--  <div *ngIf="serverError" class="p-2 mr-5 rounded border d-flex justify-content-center align-items-center border-danger">-->
<!--    <h3 class="text-danger">{{this.serverError}}</h3>-->
<!--  </div>-->
<!--  <h3 *ngIf="error" class="text-danger pr-5">{{this.error}}</h3>-->
  <ng-container>
    <button mat-button color="primary" class="mr-3" (click)="onNoClick()">{{"SHARED.cancel" | translate}}</button>
    <button mat-raised-button color="primary" (click)="submit()" style="min-width: 120px">{{"SHARED.save" | translate}}</button>
  </ng-container>
<!--  <ng-container *ngIf="isRecordingPayment">-->
<!--    <mat-spinner class="mr-5" color="primary" [diameter]="40" ></mat-spinner>-->
<!--  </ng-container>-->
</div>
