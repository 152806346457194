import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../_base-shared/models/Case/CasePublicDebt';
import { AppDocument } from '../../../../../_base-shared/models/Document/AppDocument';
import { Creditor } from '../../../../../_base-shared/models/Entity/Creditor';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';
import {Observable} from 'rxjs';
import {CaseCreditorStatus} from '../../../../../_base-shared/models/Case/CaseCreditorStatusLog';

type SupportedEmails = 'SettlementDemand' | 'ExtrajudicialNotice' | 'SendInvoiceToCreditorNotification' | 'NewContract';
type SupportedDocTypes = 'settlement-demand' | 'extrajudicial-notice' | 'creditor-invoice';

@Injectable({
  providedIn: 'root',
})
export class CaseCreditorService extends MainBaseApiService {
  public indexCaseCreditors(caseId: number, params) {
    return this.http.get<LaravelResourceResponse<{ secured: Array<Creditor>, unsecured: Array<CaseCreditor>,
      claim: Array<Creditor>, excluded: Array<Creditor> }>>(
      this.apiUrl + '/cases/' + caseId + '/creditors', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public upsert(caseId: number, requestData) {
    return this.http.put<LaravelResourceResponse<Case>>(
      this.apiUrl + '/cases/' + caseId + '/creditors', requestData,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public indexCasePublicDebts(caseId: number, params) {
    return this.http.get<LaravelResourceResponse<Array<CasePublicDebt>>>(
      this.apiUrl + '/cases/' + caseId + '/public-debts', {params},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public previewEmail(caseId: number, creditorId: number, documentType: SupportedEmails) {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/preview-email',
      {email_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public sendEmail(caseId: number, creditorId: number, documentType: SupportedEmails) {
    return this.http.post<LaravelResourceResponse<AppDocument>>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/send-email',
      {email_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public previewDocument(caseId: number, creditorId: number, documentType: SupportedDocTypes) {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/preview-document',
      {document_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public saveDocument(caseId: number, creditorId: number, documentType: 'settlement-demand' | 'extrajudicial-notice') {
    return this.http.post<LaravelResourceResponse>(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/save-document',
      {document_type: documentType},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public saveAndDownloadDocument(caseId: number, creditorId: number, documentType: SupportedDocTypes) {
    return this.http.post(
      this.apiUrl + '/cases/' + caseId + '/creditors/' + creditorId + '/save-download-document',
      {document_type: documentType}, {observe: 'response', responseType: 'blob'},
    ).pipe(catchError(response => this.handleError(response)));
  }

  public getCaseCreditorStatuses(data: any = {}, relations: Array<string> = [], appends: Array<string> = [])
    : Observable<LaravelResourceResponse> {
    data.with    = relations;
    data.append  = appends;
    const params = MainBaseApiService.convertFiltersForRequest(data, 'get');
    return this.http.get<LaravelResourceResponse<Array<CaseCreditorStatus>>>(
      this.apiUrl + '/case-creditor-statuses', {params}
    ).pipe(catchError(response => this.handleError(response)));
  }
}
