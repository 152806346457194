import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ReportService } from '../../report.service';

@Component({
  selector: 'app-negotiation-report',
  templateUrl: './negotiation-report.component.html',
  styles: []
})
export class NegotiationReportComponent implements OnInit, OnDestroy{
  public form: UntypedFormGroup;
  public isLoading = 0;

  constructor(
    private toast: ToastrService,
    private reportService: ReportService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NegotiationReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.form = this.fb.group({
      start_date: [null, Validators.required],
      end_date:   [null, Validators.required],
    });
  }

  sendReportData(): void {
    if (!this.form.valid) {
      return;
    }

    const requestData = {
      start_date: this.form.value.start_date.toISOString(),
      end_date:   this.form.value.end_date.toISOString(),
    };
    this.isLoading++;

    this.reportService.generateNegotiationReport(requestData).pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
          this.dialogRef.close(true);
          this.toast.success(res.message);

        },
        err => console.error(err),
      );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
  }
}
