import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { CaseDistribution } from '../../../../../../../_base-shared/models/Distribution/CaseDistribution';
import {
  DistributionBatch,
  DistributionBatchTotalAmounts,
} from '../../../../../../../_base-shared/models/Distribution/DistributionBatch';
import { PaymentStatus } from '../../../../../../../_base-shared/models/Status/PaymentStatus';
import { StatusCategory } from '../../../../../../../_base-shared/models/Status/StatusCategory';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../environments/environment';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { DistributionService } from '../../../../distribution/distribution.service';
import { StatusService } from '../../../status/status.service';
import { DistributionAmountCalculatorService } from '../../distribution-amount-calculator.service';
import { DistributionBatchService } from '../../distribution-batch.service';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../../case/case.service';

@Component({
  selector:    'app-distribution-batch-detail',
  templateUrl: './distribution-batch-detail.component.html',
  styleUrls:   ['./distribution-batch-detail-component.scss']
  /*styles:      [
    `
      mat-table {
        display: table;
        width: 100%;
      }

      mat-progress-bar {
        height: 16px;
      }
    `,
  ],*/
})
export class DistributionBatchDetailComponent extends DistributionAmountCalculatorService implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public authUser: User;
  public componentType: 'distribution' | 'admin';
  public filterList: any;
  public isLoading                                               = 0;
  public serverResponse: LaravelResourceResponse;
  public paymentStatuses: Array<PaymentStatus>                   = [];
  public distributionBatch: DistributionBatch;
  public batchTotalAmounts: DistributionBatchTotalAmounts;
  public caseDistributions: MatTableDataSource<CaseDistribution> = new MatTableDataSource<CaseDistribution>([]);
  public displayedColumns: Array<string>;
  public selection                                               = new SelectionModel(true, []);
  public defaultPaginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public paginatorConfig: { pageIndex: number, pageSize: number, length: number };
  public defaultSort: { direction: 'asc' | 'desc', active: string };
  public totalResults: number;
  public totalPages: number;
  public pageSelected: boolean;
  public storageUrl                                              = environment.STORAGE_URL + '/';
  public statusCategories: Array<StatusCategory>                 = [];
  public cases: Array<Case> = [];
  
  // Filters
  public paymentStatusFilter: UntypedFormControl = new UntypedFormControl(null);
  public caseStatusFilter: UntypedFormControl    = new UntypedFormControl(null);
  public searchFilter: UntypedFormControl           = new UntypedFormControl(null);
  public casesFilter: UntypedFormControl             = new UntypedFormControl(null);

  private subscriptions: Array<Subscription> = [];

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private toastr: ToastrService,
    private globalEventService: MainGlobalEventService,
    private distributionService: DistributionService,
    private distributionBatchService: DistributionBatchService,
    private statusService: StatusService,
    private caseService: CaseService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.defaultPaginatorConfig = {pageIndex: 0, pageSize: 20, length: 1};
    this.defaultSort            = {direction: 'desc', active: 'entered_batch_at'};
    this.paginatorConfig        = this.defaultPaginatorConfig;
    this.filterList             = {...this.paginatorConfig};
    this.resetSort();
    this.fetchPaymentStatuses();
    this.subscribeToFilterChanges();
    this.fetchStatuses();
    this.fetchCases();

    this.globalEventService.authUser$.subscribe(user => {
      this.authUser         = user;
      this.componentType    = this.authUser.role.slug === 'distribution-provider' ? 'distribution' : 'admin';
      this.displayedColumns = this.getTableColumns(this.componentType);
      this.route.paramMap.subscribe(params => {
        const batchId = +params.get('id');
        this.fetchDistributionBatch(batchId);
      });
    });
  }

  ngAfterViewInit(): void {
    this.caseDistributions.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchCases() {
    this.caseService.index({has_distribution: 1}).pipe()
      .subscribe(next => {
        this.cases = next.data;
      });
  }

  public paginatorChange($event: PageEvent): void {
    this.paginatorConfig.pageIndex = $event.pageIndex;
    this.paginatorConfig.pageSize  = $event.pageSize;
    this.paginatorConfig.length    = $event.length;

    this.filterList.page     = this.paginatorConfig.pageIndex + 1;
    this.filterList.per_page = this.paginatorConfig.pageSize;
    this.fetchBatchCaseDistributions(this.distributionBatch);
  }

  public sortData(sort): void {
    this.filterList.sort_by = sort.active ? sort.active : this.defaultSort.active;
    this.filterList.order   = sort.direction ? sort.direction : this.defaultSort.direction;

    this.fetchBatchCaseDistributions(this.distributionBatch);
  }

  private subscribeToFilterChanges(): void {
    this.subscriptions.push(
      this.paymentStatusFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe(paymentStatusIds => {
        this.paymentStatusChanged(paymentStatusIds);
      }),
    );
    this.subscriptions.push(
      this.caseStatusFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe(caseStatusIds => {
        this.caseStatusChanged(caseStatusIds);
      }),
    );
    this.subscriptions.push(
      this.searchFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe((caseStatusIds) => {
        this.caseSearchChanged(caseStatusIds);
      }),
    );
    this.subscriptions.push(
      this.casesFilter.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ).subscribe((casesIds: Array<number>) => {
        this.selectedFormCasesChanged(casesIds);
      }),
    );
  }

  private resetPagination(): void {
    this.paginatorConfig     = this.defaultPaginatorConfig;
    this.filterList.per_page = this.paginatorConfig.pageSize;
    this.filterList.page     = this.paginatorConfig.pageIndex;
  }

  private resetSort(): void {
    this.filterList.sort_by = this.defaultSort.active;
    this.filterList.order   = this.defaultSort.direction;
  }

  private clearSelection() {
    this.selection.clear();
    this.pageSelected = false;  //  Hide global select
    // this.patchSelectAllFilter(0);
    //delete this.filterList.case_ids;
  }

  private fetchPaymentStatuses() {
    this.isLoading++;
    this.distributionService.indexPaymentStatuses().pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.paymentStatuses = result.data;
    });
  }

  private fetchDistributionBatch(batchId: number) {
    this.isLoading++;
    this.distributionService.showBatch(batchId).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.distributionBatch = result.data;
        this.fetchBatchTotalAmounts(this.distributionBatch);
        this.fetchBatchCaseDistributions(this.distributionBatch);
      },
      () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }

  private fetchBatchTotalAmounts(distributionBatch: DistributionBatch) {
    this.isLoading++;
    this.distributionBatchService.getTotalAmounts(distributionBatch.id).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.batchTotalAmounts                          = result.data;
        this.batchTotalAmounts.total_distributed_amount = +(this.batchTotalAmounts.total_distributed_amount) +
          +(this.batchTotalAmounts.total_fees_retained);
      });
  }

  public addMonths(date: Date, months: number): Date {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  }

  // function working before refactor
  /*private fetchBatchCaseDistributions(distributionBatch: DistributionBatch) {
    this.clearSelection();

    const withRelations = [
      'batch.distribution_provider',
      'case.status',
      'case.latest_case_status_log',
      'case.payment_status',
      'case.latest_contract',
      'case.debt_payment_plan',
      'case.unsecured_creditors',
      'case.creditors',
      'case.expense', 
      'case.terms',
      'case.distribution_case_terms',
    ];

    const phaseTwoStatusIds = [414, 415];

    this.caseDistributions = new MatTableDataSource<CaseDistribution>([]);

    this.isLoading++;
    this.distributionService.indexBatchDistributions(distributionBatch.id, this.filterList, withRelations)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          
          console.log("I think it is here, fetchBatchCaseDistributions");   //debt_payment_plan
          console.log(result.data);
                    
          const resultData = result.data.map(cDistribution => {
            const totalDistributed = +(cDistribution.distributed_amount) + +(cDistribution.fees_retained);            

            let terms = 0;
            let c_debt_amount = 0;
            let monthly_installment = 0;
            let c_collected_amt = 0;
            let number_of_payments = 0;
            let number_of_ditributions = 0;
            let payment_end_date = cDistribution.case.terms[cDistribution.case.terms.length - 1].term_date;
            let proposal_location = "";
            //let case_offer_to_creditor = 0;
            let case_offer_to_creditors = cDistribution.case.expense.offer_to_creditors_total;
            let case_total_installment = cDistribution.case.expense.offer_to_creditors_total + cDistribution.case.expense.distribution_fee;
            let c_distributed = 0;
            
            //const unsecuredCreditor = cDistribution.case.unsecured_creditors.find(item => item.name === cDistribution.batch.name);
            ////const unsecuredCreditor = cDistribution.case.unsecured_creditors.find(item => {
            ////  return item.pivot.id === cDistribution.case_creditor_id;
            ////});

            //const unsecuredCreditor = cDistribution.case.unsecured_creditors.find(item => item.name === cDistribution.batch.name && item.pivot.id === cDistribution.case_creditor_id);
            
            let case_creditors;
            if (cDistribution.batch.name == 'UNIFYE'){
              case_creditors = cDistribution.case.creditors.find(item => item.name === cDistribution.batch.name && item.pivot.id === cDistribution.case_creditor_id);
            }else{
              case_creditors = cDistribution.case.unsecured_creditors.find(item => item.name === cDistribution.batch.name && item.pivot.id === cDistribution.case_creditor_id);
            }

            if (case_creditors) {
              terms = terms + case_creditors.pivot.terms;
              c_debt_amount = case_creditors.pivot.debt_amount; 
              monthly_installment = case_creditors.pivot.offer_to_creditor;
              proposal_location = case_creditors.pivot.proposal_location;
              //case_offer_to_creditor += unsecuredCreditor.pivot.offer_to_creditor; 
            }

            //const index = cDistribution.case.unsecured_creditors.findIndex(item => item.name === cDistribution.batch.name);
            //if (index !== -1) {
            //    const unsecuredCreditor = cDistribution.case.unsecured_creditors[index];
            //    terms += unsecuredCreditor.pivot.terms;
            //    c_debt_amount = unsecuredCreditor.pivot.debt_amount; 
            //    monthly_installment = unsecuredCreditor.pivot.offer_to_creditor;
                // case_offer_to_creditor += unsecuredCreditor.pivot.offer_to_creditor;

                // Remove the used unsecured creditor from the array
            //    cDistribution.case.unsecured_creditors.splice(index, 1);
            //}
            
            //problay best to extract the data from distribution, clean code, similar to next loop
            //cDistribution.case.terms.forEach(term => {
            cDistribution.case.distribution_case_terms.forEach(term => {
              if (
                  term.type == "phase_two" && 
                  (term.amount_paid > 0) &&
                  (case_offer_to_creditors <= term.amount_paid) && //offer_to_creditors + unifye fee? = case_total_installment
                  (term.distribution_batch_id == cDistribution.distribution_batch_id)
                ){
                number_of_payments += 1;
                //total_amount_paid += term.amount_paid;
                //c_amount_collected += case_offer_to_creditor
              }
            });

            //console.log(cDistribution.distribution_batch_id);

            cDistribution.case.distribution_case_terms.forEach(dterm => {
              if (
                  dterm.type == "phase_two" &&
                  (dterm.amount_paid > 0) && 
                  (dterm.amount_paid == dterm.amount) &&
                  (dterm.distribution_batch_id == cDistribution.distribution_batch_id) &&
                  (dterm.case_id = cDistribution.case.id) &&
                  (dterm.amount_distributed > 0) &&
                  (dterm.amount_distributed == dterm.amount_to_distribute) &&
                  (case_offer_to_creditors <= dterm.amount_paid)
                ){
                  console.log("yeah");
                  console.log(dterm.case_id);
                  number_of_ditributions += 1;
                  //console.log(dterm.amount_distributed);
                  //c_distributed += dterm.amount_distributed;
                }
            });
            
            console.log("rerechecking");
            console.log(cDistribution.case.id);
            console.log(monthly_installment);
            console.log(number_of_ditributions);
            
            c_collected_amt = monthly_installment * number_of_payments;
            c_distributed = monthly_installment * number_of_ditributions;
            if (cDistribution.batch.name == 'UNIFYE'){
              terms = cDistribution.case.debt_payment_plan.phase_one_duration + cDistribution.case.debt_payment_plan.phase_two_duration;
              c_debt_amount = cDistribution.case?.sum_total_debt_amount; 
              monthly_installment = cDistribution.case.expense.distribution_fee;
              c_collected_amt = monthly_installment * number_of_payments;
            }
            
            
            //cDistribution.case.case_distributions.forEach(caseDistribution => {
            //  const unsecuredCreditor = caseDistribution.case.unsecured_creditors.find(item => item.name === cDistribution.batch.name);
            //  if (unsecuredCreditor) {
            //    terms = terms + unsecuredCreditor.pivot.terms;
            //    c_debt_amount = unsecuredCreditor.pivot.debt_amount; 
            //  }
            //});


            //let monthly_installment = cDistribution.case.debt_payment_plan.phase_one_monthly_fee;
            //if(phaseTwoStatusIds.includes(cDistribution.case.status_id)){
            //  monthly_installment = cDistribution.case.debt_payment_plan.phase_two_monthly_fee;
            //}    

            return {...cDistribution, 
              distributed_amount: totalDistributed,
              id_card: cDistribution.case.client.id_card,
              ref_number: cDistribution.case.ref_number,
              term: terms,
              installment_amt: monthly_installment,
              collected_amt: c_collected_amt,
              current_balance: 0,
              distributed_amt: c_distributed,
              pending_dist: 0, //collected amount - distributed amount
              orig_balance: c_debt_amount,
              c_debt_plan_total: cDistribution.case?.sum_total_debt_amount || 0,
              c_payment_end_date: payment_end_date,
              c_proposal_location: proposal_location,
            };
          });

          this.caseDistributions      = new MatTableDataSource<CaseDistribution>(resultData);
          this.caseDistributions.sort = this.sort;
          this.paginatorConfig.length = result.meta.total;
          this.totalResults           = result.meta.total;
          this.totalPages             = result.meta.last_page;
        },
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
      );
  }*/

  //refactor

  private fetchBatchCaseDistributions(distributionBatch: DistributionBatch) {
    this.clearSelection();

    const withRelations = [
        'batch.distribution_provider',
        'case.status',
        'case.latest_case_status_log',
        'case.payment_status',
        'case.latest_contract',
        'case.debt_payment_plan',
        'case.unsecured_creditors',
        'case.creditors',
        'case.expense', 
        'case.terms',
        'case.distribution_case_terms',
    ];

    const phaseTwoStatusIds = [414, 415];

    this.caseDistributions = new MatTableDataSource<CaseDistribution>([]);
    this.isLoading++;

    this.distributionService.indexBatchDistributions(distributionBatch.id, this.filterList, withRelations)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            result => this.handleDistributionResult(result),
            () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
  }

  private handleDistributionResult(result) {
      console.log("Processing fetchBatchCaseDistributions result");
      console.log(result.data);

      const resultData = result.data.map(this.processDistributionData.bind(this));

      this.caseDistributions = new MatTableDataSource<CaseDistribution>(resultData);
      this.caseDistributions.sort = this.sort;
      this.paginatorConfig.length = result.meta.total;
      this.totalResults = result.meta.total;
      this.totalPages = result.meta.last_page;
  }

  private processDistributionData(cDistribution) {
      const totalDistributed = +(cDistribution.distributed_amount) + +(cDistribution.fees_retained);
      const caseDetails = this.extractCaseDetails(cDistribution);
      const { terms, c_debt_amount, monthly_installment, proposal_location } = caseDetails;
      const case_offer_to_creditors = cDistribution.case.expense.offer_to_creditors_total;
      const case_total_installment = cDistribution.case.expense.offer_to_creditors_total + cDistribution.case.expense.distribution_fee;
      
      const paymentDetails = this.calculatePaymentDetails(cDistribution, case_offer_to_creditors);
      const distributionDetails = this.calculateDistributionDetails(cDistribution, case_offer_to_creditors);

      const c_collected_amt = monthly_installment * paymentDetails.number_of_payments;
      const c_distributed = monthly_installment * distributionDetails.number_of_ditributions;
      const payment_end_date = cDistribution.case.terms[cDistribution.case.terms.length - 1].term_date;

      if (cDistribution.batch.name === 'UNIFYE') {
          return this.processUnifyeCase(cDistribution, paymentDetails, distributionDetails);
      }

      return {
          ...cDistribution, 
          distributed_amount: totalDistributed,
          id_card: cDistribution.case.client.id_card,
          ref_number: cDistribution.case.ref_number,
          term: terms,
          installment_amt: monthly_installment,
          collected_amt: c_collected_amt,
          current_balance: 0,
          distributed_amt: c_distributed,
          pending_dist: 0, // collected amount - distributed amount
          orig_balance: c_debt_amount,
          c_debt_plan_total: cDistribution.case?.sum_total_debt_amount || 0,
          c_payment_end_date: payment_end_date,
          c_proposal_location: proposal_location,
          c_on_hold: distributionDetails.on_hold_amt,
      };
  }

  private extractCaseDetails(cDistribution) {
      const creditorName = cDistribution.batch.name;
      const case_creditor = creditorName === 'UNIFYE'
          ? cDistribution.case.creditors.find(item => item.name === creditorName && item.pivot.id === cDistribution.case_creditor_id)
          : cDistribution.case.unsecured_creditors.find(item => item.name === creditorName && item.pivot.id === cDistribution.case_creditor_id);

      if (!case_creditor) return { terms: 0, c_debt_amount: 0, monthly_installment: 0, proposal_location: '' };

      return {
          terms: case_creditor.pivot.terms,
          c_debt_amount: case_creditor.pivot.debt_amount,
          monthly_installment: case_creditor.pivot.offer_to_creditor,
          proposal_location: case_creditor.pivot.proposal_location
      };
  }

  private calculatePaymentDetails(cDistribution, case_offer_to_creditors) {
      let number_of_payments = 0;

      cDistribution.case.distribution_case_terms.forEach(term => {
          if (this.isValidPhaseTwoTerm(term, case_offer_to_creditors, cDistribution.distribution_batch_id)) {
              number_of_payments += 1;
          }
      });

      return { number_of_payments };
  }

  private calculateDistributionDetails(cDistribution, case_offer_to_creditors) {
      let number_of_ditributions = 0;
      let on_hold_amt = 0;

      cDistribution.case.distribution_case_terms.forEach(dterm => {
          if (this.isValidDistributionTerm(dterm, case_offer_to_creditors, cDistribution)) {
              number_of_ditributions += 1;
          }
          if (this.isValidOnHoldTerm(dterm, case_offer_to_creditors, cDistribution)) {
            on_hold_amt += dterm.amount_to_distribute;
          }
      });

      return { number_of_ditributions, on_hold_amt };
  }

  private isValidPhaseTwoTerm(term, case_offer_to_creditors, distribution_batch_id) {
      return term.type === "phase_two" && term.amount_paid > 0 && 
            case_offer_to_creditors <= term.amount_paid && term.distribution_batch_id === distribution_batch_id
            && term.amount_paid === term.amount;
  }

  private isValidOnHoldTerm(dterm, case_offer_to_creditors, cDistribution) {
    return dterm.type === "phase_two" && dterm.amount_paid > 0 && dterm.amount_paid === dterm.amount &&
            dterm.distribution_batch_id === cDistribution.distribution_batch_id &&
            dterm.case_id === cDistribution.case.id && dterm.hold_distribution === 1;
  }

  private isValidDistributionTerm(dterm, case_offer_to_creditors, cDistribution) {
      return dterm.type === "phase_two" && dterm.amount_paid > 0 && dterm.amount_paid === dterm.amount &&
            dterm.distribution_batch_id === cDistribution.distribution_batch_id &&
            dterm.case_id === cDistribution.case.id && dterm.amount_distributed > 0 &&
            dterm.amount_distributed === dterm.amount_to_distribute && 
            case_offer_to_creditors <= dterm.amount_paid && dterm.hold_distribution === 0;
  }

  private processUnifyeCase(cDistribution, paymentDetails, distributionDetails) {
      const terms = cDistribution.case.debt_payment_plan.phase_one_duration + cDistribution.case.debt_payment_plan.phase_two_duration;
      const c_debt_amount = cDistribution.case?.sum_total_debt_amount;
      const monthly_installment = cDistribution.case.expense.distribution_fee;
      const c_collected_amt = monthly_installment * paymentDetails.number_of_payments;
      const c_distributed = monthly_installment * distributionDetails.number_of_ditributions;
      const payment_end_date = cDistribution.case.terms[cDistribution.case.terms.length - 1].term_date;

      return {
          ...cDistribution, 
          distributed_amount: cDistribution.distributed_amount + cDistribution.fees_retained,
          id_card: cDistribution.case.client.id_card,
          ref_number: cDistribution.case.ref_number,
          term: terms,
          installment_amt: monthly_installment,
          collected_amt: c_collected_amt,
          current_balance: 0,
          distributed_amt: c_distributed,
          pending_dist: 0, // collected amount - distributed amount
          orig_balance: c_debt_amount,
          c_debt_plan_total: cDistribution.case?.sum_total_debt_amount || 0,
          c_payment_end_date: payment_end_date,
          c_proposal_location: '',
          c_on_hold: distributionDetails.on_hold_amt,
      };
  }

  //end-refactor

  private getTableColumns(type: 'distribution' | 'admin'): Array<string> {
    const columns = [
      //'batch_index',
      'ref_number',
      'id_card',
      //'case_ref_number',
      'orig_balance',
      'term',
      'case_payment_status',
      'monthly_fee_amount',
      'plan_start_date',
      'plan_end_date',  
      //'case_status', 
      //'case_status_updated_at',
      //'batch_contract_amount',
      'debt_plan_total',
      'collected_amt',
      'current_balance',
      'distributed_amt',
      'pending_dist',
      'on-account',
      // 'funded_amount',
      // 'cash_hurdle_amount',
      // 'distributed_amount',
      // 'fees_retained',
      // 'progress_bar'
    ];

    if (type === 'admin' || type === 'distribution') {
      columns.push('contract_download');
    }

    return columns;
  }

  public downloadContracts(distributionBatch: DistributionBatch): void {
    console.log('TODO: download contracts');
  }

  public changeComponentType(type: 'distribution' | 'admin'): void {
    this.componentType    = type;
    this.displayedColumns = this.getTableColumns(type);
  }

  private paymentStatusChanged(paymentStatusIds: Array<number>): void {
    this.filterList.payment_statuses = paymentStatusIds ? paymentStatusIds : [];
    this.applyFilters();
  }

  private caseStatusChanged(caseStatusIds: Array<number>): void {
    this.filterList.case_statuses = caseStatusIds ? caseStatusIds : [];
    this.applyFilters();
  }

  private caseSearchChanged(search: String): void {
    this.filterList.search = search ? search : '';
    this.applyFilters();
  }

  public selectedFormCasesChanged(casesIds: Array<number>): void {
    console.log(casesIds);
    this.filterList.cases = casesIds;
    this.applyFilters();
  }

  private applyFilters(): void {
    this.resetPagination();
    this.resetSort();
    this.fetchBatchCaseDistributions(this.distributionBatch);
  }

  private fetchStatuses(): void {
    this.isLoading++;
    this.subscriptions.push(
      this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
        .subscribe(result => this.statusCategories = result.data),
    );
  }
}
