import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { ReportService } from '../../report.service';
import { StatusService } from '../../../status/status.service';
import { Status } from 'projects/_base-shared/models/Status/Status';
import { StatusCategory } from 'projects/_base-shared/models/Status/StatusCategory';

@Component({
  selector: 'app-negotiation-report',
  templateUrl: './creditor-negotiation-modal.component.html',
  styles: []
})
export class CreditorNegotiationModalComponent implements OnInit, OnDestroy{
  public form: UntypedFormGroup;
  public isLoading = 0;
  public statusCategories: Array<StatusCategory>         = [];
  public filteredStatusCategories: Array<StatusCategory> = [];
  public allStatuses: Array<Status>                      = [];

  constructor(
    private toast: ToastrService,
    private reportService: ReportService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreditorNegotiationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private statusService: StatusService,
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetchStatuses();
  }

  buildForm(): void {
    this.form = this.fb.group({
      start_date: [null, Validators.required],
      status:     [null, Validators.required],
    });
  }

  sendReportData(): void {
    if (!this.form.valid) {
      return;
    }

    const requestData = {
      start_date: this.form.value.start_date.toISOString(),
      status:     this.form.value.status,
    };
    this.isLoading++;

    this.reportService.generateCreditorNegotiationReport(requestData).pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
          this.dialogRef.close(true);
          this.toast.success(res.message);

        },
        err => console.error(err),
      );
  }

  private fetchStatuses() {
    this.isLoading++;
    this.statusService.indexCategoriesWithStatuses().pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
          this.statusCategories         = result.data;
          this.statusCategories         = this.statusCategories.filter(category => category.id !== 405);
          this.filteredStatusCategories = result.data;
          this.statusCategories.forEach(category => {
            this.allStatuses.push(category);
            category.statuses.forEach(status => this.allStatuses.push(status));
          });
        },
      ),
      err => console.error(err);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
  }
}
