import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../../_base-shared/contracts/common.interface';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CasePaymentPlan } from '../../../../../../../../_base-shared/models/Payment/CasePaymentPlan';
import { CasePaymentPlanPhase } from '../../../../../../../../_base-shared/models/Product/PaymentPlanPhase';
import { PaymentPlanType } from '../../../../../../../../_base-shared/models/Product/PaymentPlanType';
import { PaymentPlanTypeService } from '../../../../payment/payment-plan-type.service';
import { CasePaymentPlanService } from '../../../case-payment-plan.service';
import { CaseCreditor } from 'projects/_base-shared/models/Case/CaseCreditor';
import { interval } from 'rxjs';
import { MiscConfigService } from '../../../../config/misc-config/misc-config.service';

@Component({
  selector:    'app-case-payment-plan-generator',
  templateUrl: './case-payment-plan-generator.component.html',
  styles:      [],
})
export class CasePaymentPlanGeneratorComponent implements OnInit {
  @Input() case: Case;
  // @Input() creditor: Creditor;
  public creditor: CaseCreditor;
  public generatedPaymentPlan: CasePaymentPlan;
  public isLoading                                   = 0;
  public isSubmitting: boolean;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;
  public paymentPlanTypes: Array<PaymentPlanType>;
  public intervalUnitOptions: Array<AppSelectOption> = [];
  public startFromTypes: Array<AppSelectOption>      = [];
  public splitAmountTypes: Array<AppSelectOption>    = [];
  public defaultNegotiationPercent: number;
  public defaultTaxRate: number;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { case: Case, creditor: CaseCreditor },
    @Optional() public dialogRef: MatDialogRef<CasePaymentPlanGeneratorComponent>,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
    private paymentPlanTypeService: PaymentPlanTypeService,
    private casePaymentPlanService: CasePaymentPlanService,
    private configService: MiscConfigService,
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.case) {
      this.case = this.data.case;
    }
    this.creditor = this.data.creditor ?? null;
    this.fetchPaymentPlanTypes();
    this.buildIntervalUnitOptions();
    this.buildStartFromTypes();
    this.buildSplitAmountType();
    this.getConfigData();
  }

  get phasesArray() {
    return this.form.get('phases') as UntypedFormArray;
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    this.casePaymentPlanService.storePaymentPlan(this.case.id, form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        result => {
          this.generatedPaymentPlan = result.data;
          this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
            {model: 'Additional payment plan'}));
          this.dialogRef.close({dismissed: false, data: this.generatedPaymentPlan});
        },
        error => {
          this.serverResponse = error.error;
          this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
            {model: 'Additional payment plan'}));
        },
      );
  }

  public amountUpdated(newAmount: number, arrayIndex: number) {
    if (this.phasesArray.at(arrayIndex).get('split_amount_type').value === 'with_remainder') {
      this.phasesArray.at(arrayIndex).get('installments_count').patchValue(
        Math.ceil(newAmount / +this.phasesArray.at(arrayIndex).get('installment_amount').value),
        {onlySelf: true, emitEvent: false},
      );
      this.phasesArray.at(arrayIndex).get('installments_count').updateValueAndValidity();
      this.patchIntervalValidators(this.phasesArray.at(arrayIndex).get('installments_count').value > 1, arrayIndex);
    }
  }

  public installmentAmountChanged(installmentAmount: number, arrayIndex: number) {
    if (this.phasesArray.at(arrayIndex).get('split_amount_type').value === 'equally') {
      this.phasesArray.at(arrayIndex).get('amount').patchValue(
        installmentAmount * (+this.phasesArray.at(arrayIndex).get('installments_count').value),
        {onlySelf: true, emitEvent: false},
      );
    } else {
      this.phasesArray.at(arrayIndex).get('installments_count').patchValue(
        Math.ceil(+this.phasesArray.at(arrayIndex).get('amount').value / installmentAmount),
        {onlySelf: true, emitEvent: false},
      );
    }

    this.patchIntervalValidators(this.phasesArray.at(arrayIndex).get('installments_count').value > 1, arrayIndex);
  }

  public installmentCountChanged(installmentsCount: number, arrayIndex: number) {
    if (this.phasesArray.at(arrayIndex).get('split_amount_type').value === 'equally') {
      this.phasesArray.at(arrayIndex).get('amount').patchValue(
        (+this.phasesArray.at(arrayIndex).get('installment_amount').value) * installmentsCount,
        {onlySelf: true, emitEvent: false},
      );
    }

    this.patchIntervalValidators(installmentsCount > 1, arrayIndex);
  }

  private patchIntervalValidators(multipleInstallments: boolean, arrayIndex: number) {
    if (multipleInstallments) {
      this.phasesArray.at(arrayIndex).get('interval').setValidators([Validators.required, Validators.min(1)]);
      this.phasesArray.at(arrayIndex).get('interval_unit').setValidators([Validators.required]);
    } else {
      this.phasesArray.at(arrayIndex).get('interval').setValidators([]);
      this.phasesArray.at(arrayIndex).get('interval_unit').setValidators([]);
    }
    this.phasesArray.at(arrayIndex).get('interval').updateValueAndValidity();
    this.phasesArray.at(arrayIndex).get('interval_unit').updateValueAndValidity();
  }

  public addPaymentPhase() {
    const phasesArray = this.form.get('phases') as UntypedFormArray;
    phasesArray.push(this.initPaymentPhaseGroup(null));
  }

  private buildForm() {
    this.form = this.fb.group({
      payment_plan_type_id: [null, [Validators.required]],
      phases:               this.fb.array([
        this.initPaymentPhaseGroup(this.initDefaultPaymentPhase()),
      ]),
    });
    if(this.creditor) {
      this.buildCreditorPaymentPlan();
    }
  }

  private buildIntervalUnitOptions() {
    this.intervalUnitOptions = [
      {
        value: 'day',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.day'),
      },
      {
        value: 'week',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.week'),
      },
      {
        value: 'month',
        label: this.translate.instant('TASK-TEMPLATE.editor.task-template-notification.delay.options.month'),
      },
    ];
  }

  private buildSplitAmountType() {
    this.splitAmountTypes = [
      {
        value: 'with_remainder',
        label: this.translate.instant(
          'CASES.single.payments.case_payment_plan_generator.form.split_amount_type.options.with_remainder',
        ),
      },
      {
        value: 'equally',
        label: this.translate.instant(
          'CASES.single.payments.case_payment_plan_generator.form.split_amount_type.options.equally',
        ),
      },
    ];
  }

  private buildStartFromTypes() {
    this.startFromTypes = [
      {
        value: 'date',
        label: this.translate.instant(
          'CASES.single.payments.case_payment_plan_generator.form.start_from_type.options.date',
        ),
      },
      {
        value: 'end_of_all_installments',
        label: this.translate.instant(
          'CASES.single.payments.case_payment_plan_generator.form.start_from_type.options.end_of_all_installments',
        ),
      },
    ];
  }

  private fetchPaymentPlanTypes() {
    this.isLoading++;
    this.paymentPlanTypeService.index().pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        let data = result.data.filter(planType => !['claim_fee', 'asnef_fee', 'annual_review_fee'].includes(planType.slug));
        if(this.creditor) {
          data = data.filter(planType => !['management_fee', 'debt_plan'].includes(planType.slug));
        }
        this.paymentPlanTypes = data
      });
  }

  private initDefaultPaymentPhase() {
    const paymentPhase              = new CasePaymentPlanPhase();
    paymentPhase.start_from         = new Date();
    paymentPhase.split_amount_type  = 'equally';
    paymentPhase.amount             = 1;
    paymentPhase.installment_amount = 1;
    paymentPhase.installments_count = 1;
    paymentPhase.interval           = 1;
    paymentPhase.interval_unit      = 'month';

    return paymentPhase;
  }

  private initPaymentPhaseGroup(paymentPhase: CasePaymentPlanPhase) {
    return this.fb.group({
      start_from_type:    ['date'],
      start_from:         [paymentPhase.start_from, [Validators.required]],
      amount:             [
        {value: paymentPhase.amount, disabled: paymentPhase.split_amount_type === 'equally'},
        [Validators.required, Validators.min(1)],
      ],
      split_amount_type:  [paymentPhase.split_amount_type, [Validators.required]],
      installment_amount: [paymentPhase.installment_amount, [Validators.required, Validators.min(1)]],
      installments_count: [
        {
          value:    paymentPhase.installments_count,
          disabled: paymentPhase.split_amount_type === 'with_remainder',
        }, [Validators.required, Validators.min(1)],
      ],
      interval:           [paymentPhase.interval],
      interval_unit:      [paymentPhase.interval_unit],
    });
  }

  public splitTypeChanged(splitAmountType: 'equally' | 'with_remainder', index: number) {
    if (splitAmountType === 'equally') {
      this.phasesArray.at(index).get('installments_count').enable();
      this.phasesArray.at(index).get('amount').disable();

      this.phasesArray.at(index).get('installments_count').setValidators([Validators.required, Validators.min(1)]);
      this.phasesArray.at(index).get('amount').clearValidators();
    } else {
      this.phasesArray.at(index).get('amount').enable();
      this.phasesArray.at(index).get('installments_count').disable();

      this.phasesArray.at(index).get('amount').setValidators([Validators.required, Validators.min(1)]);
      this.phasesArray.at(index).get('installments_count').clearValidators();
    }

    this.phasesArray.at(index).get('amount').updateValueAndValidity();
    this.phasesArray.at(index).get('installments_count').updateValueAndValidity();
  }

  public buildCreditorPaymentPlan() {
    let amount          = (this.creditor.pivot.debt_amount - this.creditor.pivot.negotiated_amount) * this.defaultNegotiationPercent / 100;
    let creditor_amount = this.creditor.pivot.negotiated_amount;
    amount              = amount + (amount * this.defaultTaxRate / 100);

    if(this.form && this.creditor) {
      this.form.get('payment_plan_type_id').valueChanges.subscribe((id) => {
        this.form.get('phases').get('0').get('amount').patchValue(null);
        this.form.get('phases').get('0').get('installments_count').patchValue(1);
        if(id === 7) {
          this.form.get('phases').get('0').get('amount').patchValue(amount.toFixed(2));
          this.form.get('phases').get('0').get('installment_amount').patchValue(amount.toFixed(2));
          this.form.get('phases').get('0').get('installments_count').valueChanges.subscribe((count) => {
            if(count > 1) {
              this.form.get('phases').get('0').get('amount').patchValue(amount.toFixed(2));
              this.form.get('phases').get('0').get('installment_amount').patchValue((amount / count).toFixed(2));
            } else {
              this.form.get('phases').get('0').get('amount').patchValue(amount.toFixed(2));
              this.form.get('phases').get('0').get('installment_amount').patchValue(amount.toFixed(2));
            }
          })
        } else if(id === 2) {
          this.form.get('phases').get('0').get('amount').patchValue(creditor_amount);
          this.form.get('phases').get('0').get('installment_amount').patchValue(creditor_amount);
          this.form.get('phases').get('0').get('installments_count').valueChanges.subscribe((count) => {
            if(count > 1) {
              this.form.get('phases').get('0').get('amount').patchValue(creditor_amount);
              this.form.get('phases').get('0').get('installment_amount').patchValue((creditor_amount / count).toFixed(2));
            } else {
              this.form.get('phases').get('0').get('amount').patchValue(creditor_amount);
              this.form.get('phases').get('0').get('installment_amount').patchValue(creditor_amount);
            }
          })
        }
      })
    }
  }

  private getConfigData(): void {
    this.configService.getConfigData().subscribe(next => {
      this.defaultNegotiationPercent = next.data.find(obj => obj.key === 'negotiation_fee_percentage').value;
      this.defaultTaxRate            = next.data.find(obj => obj.key === 'default_iva_rate').value;
      this.buildForm();
    });
  }
}
