import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { CreditorService } from '../../../../../admin/creditor/creditor.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-case-creditor-info',
    templateUrl: './creditor-refinanced.component.html'
})

export class CreditorRefinanced implements OnInit {
    defaultNegotiationPercent: number;
    defaultTaxRate: number;

    public creditorFee: number;
    public termLength: number = 0;
    public monthlyPayment: number;
    public isLoading  = 0;

    constructor(
        public dialogRef: MatDialogRef<CreditorRefinanced>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toast: ToastrService,
        private creditorService: CreditorService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.creditorFee = this.data.creditor.pivot.negotiated_amount ?? 0;
    }

    calculateTerms(): void {
        let termLength = Math.ceil(this.data.creditor.pivot.debt_amount / this.monthlyPayment);
        this.termLength = termLength
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }
    
    closeDialog(value = null) {
        this.dialogRef.close(value);
    }

    public save() {
        this.isLoading++;
        const data = {};
        // @ts-ignore
        data.response_received = 'refinanced';
        // @ts-ignore
        data.product_id        = 18;
        // @ts-ignore
        data.term_length       = this.termLength;
        // @ts-ignore
        data.monthly_payment   = this.monthlyPayment;

        this.creditorService.updateCaseCreditor(this.data.creditor.pivot.id, data)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.toast.success(this.translate.instant('CASES.details.change-status-success'));
              this.closeDialog();
            },
            error => {
              if (error.error?.data?.message) {
                this.toast.error(error.error.data.message);
              } else {
                this.toast.error(this.translate.instant('CASES.details.change-status-error'));
              }
            });
    }

}
