<div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="container full-width">
            <div class="row">
                <!-- Name EN -->
                <app-input type="text" formControlName="name_en" appearance="standard" [fullWidth]="true" extraLabel="true" class="col-12"
                            [label]="'CASE_CREDITOR.case_creditor_status.status_name_en' | translate">
                </app-input>
            </div>
            <div class="row">
                <!-- Name ES -->
                <app-input type="text" formControlName="name_es" appearance="standard" [fullWidth]="true" extraLabel="true" class="col-12"
                            [label]="'CASE_CREDITOR.case_creditor_status.status_name_es' | translate">
                </app-input>
            </div>
            <div class="row">
                <!-- Is for Bulk DM -->
                <app-input type="checkbox" formControlName="is_for_bulk_dm" appearance="standard" [fullWidth]="true" extraLabel="true" class="col-12"
                            [label]="'CASE_CREDITOR.case_creditor_status.is_for_bulk_dm' | translate">
                </app-input>
            </div>
            <div class="row">
                <!-- Is System Status -->
                <app-input type="checkbox" formControlName="is_system_status" appearance="standard" [fullWidth]="true" extraLabel="true" class="col-12"
                            [label]="'CASE_CREDITOR.case_creditor_status.is_system_status' | translate">
                </app-input>
            </div>
            <div class="row">
                <!-- Slug -->
                <app-input type="text" formControlName="slug" appearance="standard" [fullWidth]="true" extraLabel="true" class="col-12"
                            label="Slug">
                </app-input>
            </div>
            <div class="row pt-5">
                <div class="col-md-4">
                <button mat-raised-button color="primary" class="ml-3" type="submit">
                    {{ 'SHARED.save' | translate }}
                </button>
                </div>
            </div>
        </div>
    </form>
</div>