import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CaseService } from '../../../../case.service';

@Component({
  selector:    'app-case-creditor-status-log',
  templateUrl: './case-creditor-status-log.component.html',
  styles:      []
})
export class CaseCreditorStatusLogComponent implements OnInit, OnDestroy{
  @Input() case: Case;
  public isLoading                           = 0;
  public caseCreditorStatusLogs: Array<CaseCreditor>;
  private subscriptions: Array<Subscription> = [];

  constructor(private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.fetchLogs(this.case.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchLogs(caseId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.getCaseCreditorStatusLogs(caseId, {select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => {
          this.caseCreditorStatusLogs = result.data;
        },
      ),
    );
  }
}
