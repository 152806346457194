import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import {Status} from '../../../../../_base-shared/models/Status/Status';
import {StatusCategory} from '../../../../../_base-shared/models/Status/StatusCategory';
import {MainBaseApiService} from '../../_shared/services/main-base-api.service';
import {CaseInvoiceStatus} from 'projects/_base-shared/models/Status/CaseInvoiceStatus';

@Injectable({
  providedIn: 'root',
})
export class StatusService extends MainBaseApiService {

  index(data = {}, relations: Array<string> = []): Observable<LaravelResourceResponse> {
    const params = MainBaseApiService.convertFiltersForRequest({...data, with: relations}, 'get');
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/statuses`, {params})
      .pipe(catchError(response => this.handleError(response)));
  }

  get(statusId: number): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse>(`${this.apiUrl}/statuses/${statusId}`)
      .pipe(catchError(response => this.handleError(response)));
  }

  indexCategoriesWithStatuses(): Observable<LaravelResourceResponse<Array<StatusCategory>>> {
    return this.http.get<LaravelResourceResponse<Array<StatusCategory>>>(
      this.apiUrl + '/statuses/by-parent', {params: {all: 1, select_all: 1} as any},
    ).pipe(catchError(response => this.handleError(response)));
  }

  store(status: Status): Observable<LaravelResourceResponse<Status>> {
    return this.http.post<LaravelResourceResponse<Status>>(`${this.apiUrl}/statuses`, status)
      .pipe(catchError(response => this.handleError(response)));
  }

  update(statusId: number, status: Status): Observable<LaravelResourceResponse<Status>> {
    return this.http.put<LaravelResourceResponse<Status>>(`${this.apiUrl}/statuses/${statusId}`, status)
      .pipe(catchError(response => this.handleError(response)));
  }

  delete(statusId: number): Observable<LaravelResourceResponse> {
    return this.http.delete<LaravelResourceResponse>(`${this.apiUrl}/statuses/${statusId}`)
      .pipe(catchError(response => this.handleError(response)));
  }

  indexInvoiceStatuses(data = {}): Observable<LaravelResourceResponse> {
    return this.http.get<LaravelResourceResponse<Array<CaseInvoiceStatus>>>(`${this.apiUrl}/case-invoice-statuses`, {params: data})
      .pipe(catchError(response => this.handleError(response)));
  }
}
