import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BizumPaymentComponent } from './bizum-payment/bizum-payment.component';
import { ClientPaymentRedirectComponent } from './client-payment-redirect/client-payment-redirect.component';
import { ClientPaymentComponent } from './client-payment/client-payment.component';
import { PaymentOutcomeComponent } from './client-payment/payment-outcome/payment-outcome.component';
import {
  MymoidPaymentConfirmationComponent
} from './mymoid-payment-confirmation/mymoid-payment-confirmation.component';
import { SepaPaymentComponent } from './sepa-payment/sepa-payment.component';

const routes: Routes = [
  {
    path: 'client', children: [
      {
        path: 'payments',
        children: [
          {path: 'confirm', component: MymoidPaymentConfirmationComponent},
          {path: 'bizzum', component: BizumPaymentComponent},
        ],
      },
      {
        path: 'payment', children: [
          {path: '', component: ClientPaymentRedirectComponent},
          {path: 'outcome', component: PaymentOutcomeComponent},
        ],
      },
      {path: 'payment-legacy', component: ClientPaymentComponent},
      {path: 'sepa', component: SepaPaymentComponent},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class PaymentRoutingModule {
}
