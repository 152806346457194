import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseStatusLog } from '../../../../../../../../../_base-shared/models/Case/CaseStatusLog';
import { CaseService } from '../../../../case.service';
import { User } from '../../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-case-status-log',
  templateUrl: './case-status-log.component.html',
  styles:      [],
})
export class CaseStatusLogComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  public authUser: User;
  public isLoading                           = 0;
  public caseStatusLogs: Array<CaseStatusLog>;
  private subscriptions: Array<Subscription> = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private caseService: CaseService,
              private globalEventService: MainGlobalEventService) {
  }

  ngOnInit(): void {
    this.globalEventService.authUser$.subscribe(user => this.authUser = user);
    this.fetchLogs(this.case.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchLogs(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
        this.caseService.getStatusLogs(caseId, {all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
            result => this.caseStatusLogs = result.data,
        ),
    );
  }

  public deleteStatusLog(log: CaseStatusLog) {
    this.isLoading++;
    this.subscriptions.push(
        this.caseService.destroyStatusLog(this.case.id, log.id).pipe(finalize(() => this.isLoading--)).subscribe(
            () => this.fetchLogs(this.case.id),
        ),
    );
  }

  public canDelete(): boolean {
    return [36, 751600, 752733].includes(this.authUser.id);
  }

}
