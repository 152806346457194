<div class="container">
  <div class="row pb-3">
    <div class="col-12">
      <h2>{{"CASE_CREDITOR.case_creditor_status.heading" | translate}}</h2>
    </div>

    <div class="col-md-8">
      <button mat-raised-button color="primary" (click)="editCaseCreditorStatus(null, $event)" type="button">
        + {{"CASE_CREDITOR.case_creditor_status.create" | translate}}
      </button>
    </div>
    <div class="col-4 pt-md-0 pt-5">
      <app-input [formControl]="search" type="text" appearance="standard" fullWidth="true"
                [label]="translateService.instant('SHARED.search')" [prefixType]="'icon'" prefix="search">
      </app-input>
    </div>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table matSort matSortActive="name_en" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource" style="width: 100%">
      <!-- Headers -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <!-- ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
      </ng-container>
      <!-- Name EN -->
      <ng-container matColumnDef="name_en">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASE_CREDITOR.case_creditor_status.status_name_en' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.name_en}}</td>
      </ng-container>
      <!-- Name -->
      <ng-container matColumnDef="name_es">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASE_CREDITOR.case_creditor_status.status_name_es' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.name_es}}</td>
      </ng-container>
      <!-- Is for Bulk DM -->
      <ng-container matColumnDef="is_for_bulk_dm">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASE_CREDITOR.case_creditor_status.is_for_bulk_dm' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <!-- show check icon if is for bulk dm -->
          <mat-icon *ngIf="element.is_for_bulk_dm" style="color: rgb(0, 80, 0)">check</mat-icon>
          <!-- else cancel -->
          <mat-icon *ngIf="!element.is_for_bulk_dm" style="color: rgb(98, 0, 0)">close</mat-icon>
        </td>
      </ng-container>
      <!-- Is System Status -->
      <ng-container matColumnDef="is_system_status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'CASE_CREDITOR.case_creditor_status.is_system_status' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <!-- show check icon if is for bulk dm -->
          <mat-icon *ngIf="element.is_system_status" style="color: rgb(0, 80, 0)">check</mat-icon>
          <!-- else cancel -->
          <mat-icon *ngIf="!element.is_system_status" style="color: rgb(98, 0, 0)">close</mat-icon>
        </td>
      </ng-container>
      <!-- Created at -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'SHARED.model.created_at' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.created_at | toDateObject | date }}  </td>
      </ng-container>

      <!-- Actions -->
      <ng-container matColumnDef="actions" class="mr-auto">
        <th mat-header-cell *matHeaderCellDef>{{"SHARED.actions" | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex">
            <p style="margin: 0; padding-right: 10px">
              <!--      Only Mark can edit/delete case creditor status      -->
              <a (click)="editCaseCreditorStatus(element, $event)">{{"SHARED.edit" | translate}}</a>
              <a *ngIf="!element.is_system_status" (click)="openDeleteDialog(element.id, $event)" style="color: rgb(98, 0, 0)">
                {{"SHARED.delete" | translate}}
              </a>
            </p>
          </div>
        </td>
      </ng-container>
    </table>

    <div *ngIf="isLoading" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">{{"SHARED.loading" | translate}}...</span>
      </div>
    </div>

    <mat-paginator
      [length]="paginatorConfig.length"
      [pageSize]="paginatorConfig.pageSize"
      [pageSizeOptions]="[5, 10, 20]"
      (page)="paginatorChange($event)"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>