import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as cardValidation from 'creditcards';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../_base-shared/models/Case/Case';
import { PaymentCard } from '../../../../../../_base-shared/models/Payment/PaymentCard';
import { User } from '../../../../../../_base-shared/models/User/User';
import { ClientService } from '../../client.service';

@Component({
  selector: 'app-bizum-payment',
  templateUrl: './bizum-payment.component.html',
  styles: [],
})
export class BizumPaymentComponent implements OnInit {
  public isLoading = 0;
  public serverResponse: LaravelResourceResponse;
  public case: Case;
  public paymentCards: Array<PaymentCard>;

  public bizzumInitForm: FormGroup;
  public bizzumRequestForm: FormGroup;
  public isSubmittingBizzum: boolean;
  public bizzumFormReady: boolean;
  public bizzumError: string;

  public amountControl: FormControl = new FormControl<number>(null);
  public newCardForm: FormGroup;
  public isSubmittingCardPayment: boolean;
  public cardFormReady: boolean;
  public isLoadingCards: boolean;
  public showExistingCards: boolean;
  public redsysError: boolean;
  public isSubmittingRedsys: boolean;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private clientService: ClientService) {
  }

  ngOnInit(): void {
    const caseUuid = '3a3fd0ff-57f5-4a90-b9b7-05260ff59189';
    this.fetchCase(caseUuid);
  }

  private fetchCase(caseUuid: string): void {
    this.isLoading++;
    this.clientService.showCase(caseUuid).pipe(finalize(() => this.isLoading--)).subscribe(
      result => {
        this.case = result.data;
        this.fetchPaymentCards(this.case);
        this.buildBizzumClientInfoForm(this.case.client);
      },
      error => {
        console.log(error);
      }
    );
  }

  private fetchPaymentCards(clientCase: Case): void {
    this.isLoadingCards = true;
    this.clientService.getPaymentCards(clientCase.uuid, ['payment_processor']).pipe(finalize(() => this.isLoadingCards = false))
      .subscribe(result => {
        this.paymentCards      = result.data.map(card => {
          const dateOfExpiry       = moment(`${ card.card_exp_month }${ card.card_exp_year }`, 'MMYYYY')
            .startOf('month')
            .format();
          card.expires_at          = moment(dateOfExpiry).endOf('month').format();
          const expirationDateDiff = moment(card.expires_at).diff(moment(), 'months', true);

          if (expirationDateDiff <= 4) {
            card.expire = 'soon';
          }

          if (expirationDateDiff < 0) {
            card.expire = 'expired';
          }

          card.card_brand = card.card_brand ? card.card_brand : cardValidation.card.type(card.card_bin, true);

          return card;
        });
        this.showExistingCards = true;
      });
  }

  private buildBizzumClientInfoForm(client: User): void {
    this.bizzumInitForm = this.fb.group({
      amount: [null, [Validators.required]],
      first_name: [client.first_name, [Validators.required]],
      last_name: [client.last_name, [Validators.required]],
      phone: [
        client ? (client.phone_country_code ? '+' + client.phone_country_code : '+34') + client.phone : null,
        [Validators.required]
      ],
    });
  }

  public submitBizzumRequestForm(form: FormGroup): void {
    this.bizzumError = null;
    if (form.invalid) {
      form.markAllAsTouched();
      console.log('form has errors');
      return;
    }

    this.isSubmittingBizzum = true;
    this.clientService.requestBizumPayment(this.case.uuid, form.value).pipe(finalize(() => this.isSubmittingBizzum = false))
      .subscribe(
        result => {
          console.log(result);
          this.bizzumRequestForm = this.fb.group({
            merchant_parameters: [result.data.merchant_parameters, [Validators.required]],
            signature_version: [result.data.signature_version, [Validators.required]],
            signature: [result.data.signature, [Validators.required]],
          });
          this.bizzumFormReady   = true;
        },
        error => {
          this.bizzumError = error.error.message;
          console.log(this.bizzumError);
          console.log(error);
        }
      );
  }

  public initNewCardPayment(amount: number): void {
    this.redsysError             = null;
    this.isSubmittingCardPayment = true;
    this.clientService.makeRedSysCardPayment(this.case.uuid, {amount})
      .pipe(finalize(() => this.isSubmittingCardPayment = false))
      .subscribe(
        result => {
          console.log(result);
          this.newCardForm   = this.fb.group({
            merchant_parameters: [result.data.merchant_parameters, [Validators.required]],
            signature_version: [result.data.signature_version, [Validators.required]],
            signature: [result.data.signature, [Validators.required]],
          });
          this.cardFormReady = true;
        },
        error => {
          this.redsysError = error.error.message;
          console.log(error);
        }
      );
  }

  public submitRedSysForm(form: FormGroup, amount: number): void {
    this.redsysError = null;
    if (form.invalid) {
      form.markAllAsTouched();
      console.log('form has errors');
      return;
    }

    const requestData = {
      Ds_SignatureVersion: form.get('signature_version').value,
      Ds_MerchantParameters: form.get('merchant_parameters').value,
      Ds_Signature: form.get('signature').value,
    };
    const formData    = new FormData();
    formData.append('Ds_SignatureVersion', form.get('signature_version').value);
    formData.append('Ds_MerchantParameters', form.get('merchant_parameters').value);
    formData.append('Ds_Signature', form.get('signature').value);
    this.clientService.submitRedSysPayment(formData).pipe(finalize(() => (console.log('finished'))))
      .subscribe(
        result => {
          console.log(result);
        },
        error => {
          this.redsysError = error.error.message;
          console.log(error);
        }
      );
  }

  public chargePaymentCard(paymentCard: PaymentCard, amount: number): void {
    this.redsysError        = null;
    this.isSubmittingRedsys = true;
    this.clientService.chargeRedSysCard(this.case.uuid, {payment_card_uuid: paymentCard.id, amount})
      .pipe(finalize(() => this.isSubmittingRedsys = false))
      .subscribe(
        result => {
          this.toastr.success('Charged selected card for ' + this.amountControl.value);
          console.log(result);
          this.amountControl.patchValue(null);
        },
        error => {
          this.redsysError = error.error.message;
          console.log(error);
        }
      );
  }

  public resetBizzumForm(): void {
    this.bizzumFormReady = false;
    this.bizzumRequestForm.reset();
    console.log(this.bizzumRequestForm.value);
  }

  public resetRedsysForm(): void {
    this.cardFormReady = false;
    this.newCardForm.reset();
    console.log(this.newCardForm.value);
  }
}
