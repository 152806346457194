<form *ngIf="form" [formGroup]="form" #ngForm class="filters">
  <div class="row">
    <div class="col-8">
      <!-- Start Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.start-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="startDate" formControlName="start_date">
        <button *ngIf="form.get('start_date') && form.get('start_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('start_date').disabled" (click)="clearFormControl($event, 'start_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate color="primary"></mat-datepicker>
      </mat-form-field>
      <!-- End Date -->
      <mat-form-field color="primary" appearance="standard">
        <mat-label>{{ "PAYMENTS.end-date" | translate }}</mat-label>
        <input matInput [matDatepicker]="endDate" formControlName="end_date"
               (dateChange)="dateChanged($event, 'end_date', true)">
        <button *ngIf="form.get('end_date') && form.get('end_date').value" mat-button matSuffix mat-icon-button
                [disabled]="form.get('end_date').disabled" (click)="clearFormControl($event, 'end_date')"
                aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate color="primary"></mat-datepicker>
      </mat-form-field>
      <button mat-stroked-button type="button" color="primary" class="btn ml-2"
              (click)="clearDates()">{{"SHARED.reset-date" | translate}}</button>
      <button mat-stroked-button type="button" color="primary" class="btn mt-2 ml-2"
              (click)="clearFilters()">{{"SHARED.reset-filters" | translate}}</button>
      <!-- From/To Shortcut modifiers -->
      <br>
      <div class="d-inline-block">
        <mat-radio-group [formControl]="dateRadioControl" aria-label="Select an option"
                         (change)="dateModifierChange($event)">
          <mat-radio-button value="day"
                            style="margin-right: 10px">{{ "CASES.single.today" | translate }}</mat-radio-button>
          <mat-radio-button value="week"
                            style="margin-right: 10px">{{ "CASES.single.this-week" | translate }}</mat-radio-button>
          <mat-radio-button value="month"
                            style="margin-right: 10px">{{ "CASES.single.this-month" | translate }}</mat-radio-button>
        </mat-radio-group>
        <!-- TODO: implement -->
        <!--<mat-slide-toggle formControlName="only_related" color="primary" class="ml-5"-->
        <!--                  (ngModelChange)="onlyRelatedChange($event)">-->
        <!--  My Case Payments-->
        <!--<div class="d-inline-block pl-5">
          <mat-label style="padding-right: 10px; display: block">
            {{ 'CASES.list.filters.status_date_type.label' | translate }}
          </mat-label>
          <mat-radio-group formControlName="activity_status_filter_type" aria-label="Select an option">
            <mat-radio-button value="currently" style="margin-right: 10px">
              {{ "PAYMENTS.activity-status.currently" | translate }}
            </mat-radio-button>
            <mat-radio-button value="in_time_frame" style="margin-right: 10px">
              {{ "PAYMENTS.activity-status.in-time-frame" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>-->
        <!--<div class="d-inline-block pl-5">
          <mat-label style="padding-right: 10px; display: block"
                     [matTooltip]="'CASES.list.filters.activity_status.label-tooltip' | translate">
            {{ 'CASES.list.filters.activity_status.label' | translate }}
          </mat-label>
          <mat-radio-group formControlName="activity_status" aria-label="Select an option">
            <mat-radio-button value="active" style="margin-right: 10px">
              {{ "CASES.list.filters.activity_status.options.active" | translate }}
            </mat-radio-button>
            <mat-radio-button value="inactive" style="margin-right: 10px">
              {{ "CASES.list.filters.activity_status.options.inactive" | translate }}
            </mat-radio-button>
            <mat-radio-button value="all" style="margin-right: 10px">
              {{ "CASES.list.filters.activity_status.options.all" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>-->
      </div>
    </div>
    <!-- Search -->
    <div class="col-4">
      <mat-form-field appearance="standard" class="input-full-width">
        <mat-label>{{ 'SHARED.search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput formControlName="search" type="text">
        <div *ngIf="form.get('search').errors && (form.get('search').touched || ngForm.submitted)">
          <mat-error [hidden]="!form.get('search').hasError('required')">
            {{ "SHARED.field-required" | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>
  </div>

  

  <div class="advanced-filters pb-5 pt-3">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ "PAYMENTS.advanced-filters" | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          
          <!-- Wallets -->
          <div class="col-6 col-lg">
            <app-input type="select" formControlName="wallets" appearance="standard"
                       fullWidth="false" [multiple]="true" [showClear]="true" [selectAll]="true"
                       [label]="'Wallet:'" [searchable]="true"
                       [selectOptions]="wallets" [selectLabel]="'name'" [selectValue]="'name'">
            </app-input>
          </div>

          <div class="col-6 col-lg">
            <app-input type="select" formControlName="cases" appearance="standard" multiple fullWidth="false"
                      [label]="'Cases'" [searchable]="true"
                      [selectOptions]="cases" 
                      [selectLabel]="'ref_number'" 
                      [selectValue]="'id'">
            </app-input>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-lg d-flex align-items-center">
            <div class="form-check">
              <app-input type="checkbox" formControlName="displayPending" appearance="standard"
                       fullWidth="false" [multiple]="false" [showClear]="false" [selectAll]="false"
                       [label]="'Display Pending'" [searchable]="false">
            </app-input>
            </div>
          </div>

          <div class="col-6 col-lg d-flex align-items-center">
            <div class="form-check">
              <app-input type="checkbox" formControlName="displayOnHold" appearance="standard"
                       fullWidth="false" [multiple]="false" [showClear]="false" [selectAll]="false"
                       [label]="'Display OnAccount'" [searchable]="false">
            </app-input>
            </div>
          </div>

          <div class="col-6 col-lg d-flex align-items-center">
            <div class="form-check">
              <app-input type="checkbox" formControlName="displayPaid" appearance="standard"
                       fullWidth="false" [multiple]="false" [showClear]="false" [selectAll]="false"
                       [label]="'Display Paid'" [searchable]="false">
            </app-input>
            </div>
          </div>

          <div class="col-6 col-lg d-flex align-items-center">
            <div class="form-check">
              <app-input type="checkbox" formControlName="displayDistributed" appearance="standard"
                       fullWidth="false" [multiple]="false" [showClear]="false" [selectAll]="false"
                       [label]="'Display Distributed'" [searchable]="false">
            </app-input>
            </div>
          </div>

          <div class="col-6 col-lg d-flex align-items-center">
            <div class="form-check">
              <app-input type="checkbox" formControlName="displayDisbursed" appearance="standard"
                       fullWidth="false" [multiple]="false" [showClear]="false" [selectAll]="false"
                       [label]="'Display Disbursed'" [searchable]="false">
            </app-input>
            </div>
          </div>
          
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</form>
