<div class="container">
  <div class="row">
    <div [hidden]="!isLoading" class="col-12">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
    <div *ngIf="serverResponse" class="col-12">
      <app-server-response [response]="serverResponse"></app-server-response>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <h1>Bizum Payment</h1>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="bizzumError" class="alert alert-danger" role="alert">
            {{ bizzumError }}
          </div>
          <div *ngIf="!bizzumFormReady">
            <form *ngIf="bizzumInitForm" [formGroup]="bizzumInitForm"
                  (ngSubmit)="submitBizzumRequestForm(bizzumInitForm)">
              <div class="row">
                <div class="col">
                  <app-input type="number" formControlName="amount" label="Amount" appearance="standard"></app-input>
                  <app-input formControlName="first_name" label="First name" appearance="standard"></app-input>
                  <app-input formControlName="last_name" label="Last name" appearance="standard"></app-input>
                  <app-input formControlName="phone" label="Mobile" appearance="standard"></app-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <app-spinner-btn type="submit" class="mt-3" [loading]="isSubmittingBizzum"
                                   [name]="'Go to payment'" [disabled]="!(bizzumInitForm.get('amount')?.value > 0)">
                  </app-spinner-btn>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="bizzumFormReady" class="row">
            <pre *ngIf="bizzumRequestForm?.value">{{ bizzumRequestForm?.value | json }}</pre>
            <form ngNoForm action="https://sis-t.redsys.es:25443/sis/realizarPago" method="POST" target="_blank">
              <input type="hidden" name="Ds_SignatureVersion"
                     [value]="bizzumRequestForm?.get('signature_version').value">
              <input type="hidden" name="Ds_MerchantParameters"
                     [value]="bizzumRequestForm?.get('merchant_parameters').value">
              <input type="hidden" name="Ds_Signature" [value]="bizzumRequestForm?.get('signature').value">
              <button mat-raised-button type="button" (click)="resetBizzumForm()">
                Reset
              </button>
              <button mat-raised-button color="primary" type="submit" class="ml-2"
                      [disabled]="!(bizzumInitForm.get('amount')?.value > 0)">
                Go to payment
              </button>
            </form>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-6">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <h1>Redsys Card Payment</h1>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="redsysError" class="alert alert-danger" role="alert">
            {{ redsysError }}
          </div>
          <div *ngIf="!cardFormReady" class="row">
            <div class="col-12">
              <app-input type="number" [formControl]="amountControl" appearance="standard" label="Amount"></app-input>
            </div>
            <div *ngIf="showExistingCards" class="col-12">
              <div *ngFor="let card of paymentCards" class="mt-2">
                **** **** **** {{ card.card_last_four }} {{ card.card_exp_month }}/{{ card.card_exp_year }}
                {{ card.card_brand }} {{ card.payment_processor.name }}
                <button type="button" mat-raised-button (click)="chargePaymentCard(card, amountControl.value)"
                        [disabled]="!(amountControl?.value > 0)">
                  Charge
                </button>
              </div>
            </div>
            <div class="col-12">
              <app-spinner-btn type="button" class="mt-3" [name]="'New Card'" [disabled]="!(amountControl?.value > 0)"
                               (click)="initNewCardPayment(amountControl.value)">
              </app-spinner-btn>
            </div>
          </div>
          <div *ngIf="cardFormReady" class="row">
            <pre *ngIf="newCardForm?.value">{{ newCardForm?.value | json }}</pre>
            <form ngNoForm action="https://sis-t.redsys.es:25443/sis/realizarPago" method="POST" target="_blank">
              <input type="hidden" name="Ds_SignatureVersion" [value]="newCardForm?.get('signature_version').value">
              <input type="hidden" name="Ds_MerchantParameters" [value]="newCardForm?.get('merchant_parameters').value">
              <input type="hidden" name="Ds_Signature" [value]="newCardForm?.get('signature').value">
              <button mat-raised-button type="button" (click)="resetRedsysForm()">
                Reset
              </button>
              <button mat-raised-button color="primary" type="submit" class="ml-2"
                      [disabled]="!(amountControl?.value > 0)">
                Go to payment
              </button>
            </form>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
