<h2 style="margin: 0px; padding: 0px;" mat-dialog-title>{{ 'CASES.single.monthly_target_payment_heading.heading' | translate }}</h2>
<mat-dialog-content>
  <div *ngIf="form" [formGroup]="form">
    <div formGroupName="case">
        <!-- <app-input type="input" formControlName="target_monthly_payment" [label]="'CASES.single.target_monthly_payment' | translate"
                        [extraLabel]="false" [fullWidth]="false" [hidden]="true">
        </app-input> -->
        <p>{{ 'CASES.single.monthly_target_payment_heading.first' | translate }} <span style="color: brown;">{{ getMonthlyTargetPayment() | currency: 'EUR' }}</span> {{ 'CASES.single.monthly_target_payment_heading.second' | translate }}</p>
        <p *ngIf="(financialOverview.income - financialOverview.expenses) > minimumAmount">{{ 'CASES.single.monthly_target_payment_heading.third' | translate }} <span style="color: brown;">{{ (financialOverview.income - financialOverview.expenses) | currency: 'EUR' }}</span></p>
        <p *ngIf="(financialOverview.income - financialOverview.expenses) < minimumAmount">{{ 'CASES.single.monthly_target_payment_heading.di_too_low' | translate }}</p>
        <div>
          <app-input type="input" formControlName="target_monthly_payment" [label]="'CASES.single.target_monthly_payment' | translate"
                          [extraLabel]="false" [fullWidth]="false">
          </app-input>

        </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="error" (click)="submitForm(form, customAmount)">{{ 'CASES.single.monthly_target_payment_heading.button_label' | translate }} {{ customAmount | currency: 'EUR' }}</button>
  <button mat-button color="primary" (click)="submitForm(form, getMonthlyTargetPayment())">{{ 'CASES.single.monthly_target_payment_heading.button_label' | translate }} {{ getMonthlyTargetPayment() | currency: 'EUR' }}</button>
  <button mat-button color="warning" [disabled]="(financialOverview.income - financialOverview.expenses) < minimumAmount" (click)="submitForm(form, (financialOverview.income - financialOverview.expenses))">{{ 'CASES.single.monthly_target_payment_heading.button_label' | translate }} {{ (financialOverview.income - financialOverview.expenses) | currency: 'EUR' }}</button>
</mat-dialog-actions>