import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { NegotiatorsService } from './negotiators.service';

@Component({
  selector:    'app-negotiator-config',
  templateUrl: './negotiators.component.html',
  styleUrls:   ['./negotiators.component.scss'],
})
export class NegotiatorsComponent implements OnInit {
    public form: UntypedFormGroup;
    public formReady          = true;
    public negotiators: any[] = [];
    public search             = new UntypedFormControl('');
    public searchFocus        = false;

    constructor(private negotiatorsService: NegotiatorsService) {

    }

    ngOnInit(): void {
        this.getNegotiators();
    }

    // get all negotiators through the server
    private getNegotiators(): void {
        this.negotiatorsService.index().subscribe(next => {
            console.log(next.data);
            this.negotiators = next.data;
        });
    }

    // return either a checkmark or an x mark based on the value of the parameter
    public checkmarkOrX(value: boolean): string {
        return value ? 'check' : 'close';
    }

    public submit(): void {
    }

    onFocus() {
      this.searchFocus = true;
    }
  
    onBlur() {
      this.searchFocus = false;
    }
}