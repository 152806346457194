import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSelectOption } from '../../../../../../../../_base-shared/contracts/common.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-case-relation-list',
  templateUrl: './case-relation-list.component.html',
  styles:      [
    `
      .card-height {
        min-height: 550px;
      }
    `],
})
export class CaseRelationListComponent implements OnInit {
  @Input() case: Case;
  @Output() caseCreditorsUpdated: EventEmitter<Case> = new EventEmitter<Case>();
  public selectedTab                                 = new UntypedFormControl(null);
  public tabs: Array<AppSelectOption>;
  public showInvoiceStatusLog                        = false;
  public authUser: User;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private globalEventsService: MainGlobalEventService,
  ) {
  }

  ngOnInit(): void {
    this.case.packager.master ? this.getRelationTabs() : this.getRelationTabsPackagers();
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
      if (((this.authUser.packager.master && !this.case.original_packager.master) || this.case.id === 195392) &&
        (this.authUser?.id === 1 || this.authUser?.id === 22 || this.authUser?.id === 27 || this.authUser?.id === 34 ||
          this.authUser?.id === 36 || this.authUser?.id === 2497)
      ) {
        this.showInvoiceStatusLog = true;
      }
      if (this.showInvoiceStatusLog) {
        this.tabs.push({
          value: 'invoice_status_log',
          label: this.translate.instant('CASES.single.general.relation_list.invoice_status.heading'),
        });
      }
    });
    this.route.queryParams.subscribe(result => {
      const queryParams   = new URLSearchParams(result);
      const activeTabName = queryParams.get('activeTab');
      const activeTab     = this.tabs.find(fTab => fTab.value === activeTabName);
      if (activeTab) {
        this.selectedTab.patchValue(activeTab.value);
      }
    });

    this.translate.onLangChange.subscribe(next => {
      this.case.packager.master ? this.getRelationTabs() : this.getRelationTabsPackagers();
    });
  }

  public selectTab(tabName: string): void {
    if (this.selectedTab.value === tabName) {
      return;
    }

    const queryParams: Params = {activeTab: tabName};

    this.router.navigate(
      [],
      {
        relativeTo:          this.route,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  private getRelationTabs(): void {
    this.tabs = [
      {value: 'notes', label: this.translate.instant('CASES.single.general.relation_list.notes.heading')},
      {
        value: 'payment_notes',
        label: this.translate.instant('CASES.single.general.relation_list.notes.heading_payment'),
      },
      {value: 'draft_notes', label: this.translate.instant('CASES.single.general.relation_list.notes.heading_draft')},
      // {
      //   value: 'creditor_notes',
      //   label: this.translate.instant('CASES.single.general.relation_list.creditor_notes.heading'),
      // },
      {
        value: 'email_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_email'),
      },
      {
        value: 'sms_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_sms'),
      },
      {
        value: 'call_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_call'),
      },
      {value: 'status_logs', label: this.translate.instant('CASES.single.general.relation_list.status_logs.heading')},
      {
        value: 'packager_status_logs',
        label: this.translate.instant('CASES.single.general.relation_list.packager_status_logs.heading'),
      },
      {value:  'call_status_logs',
        label: this.translate.instant('CASES.single.general.relation_list.call_status_logs.heading'),
      },
      {
        value: 'payment_status_logs',
        label: this.translate.instant('CASES.single.general.relation_list.payment_status_logs.heading'),
      },
      {value: 'contracts', label: this.translate.instant('CASES.single.general.relation_list.contracts.heading')},
      {value: 'proposals', label: this.translate.instant('CASES.single.general.relation_list.proposals.heading')},
      {value: 'case_creditor_status_log', label: this.translate.instant('CASES.single.general.relation_list.case_creditor_status_logs.heading')},
      {value: 'tasks', label: this.translate.instant('CASES.single.general.relation_list.tasks.heading')},
      {
        value: 'distribution',
        label: this.translate.instant('CASES.single.general.relation_list.notes.heading_distribution'),
      },
    ];
  }

  private getRelationTabsPackagers(): void {
    this.tabs = [
      {value: 'notes', label: this.translate.instant('CASES.single.general.relation_list.notes.heading')},
      {
        value: 'payment_notes',
        label: this.translate.instant('CASES.single.general.relation_list.notes.heading_payment'),
      },
      {
        value: 'email_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_email'),
      },
      {
        value: 'sms_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_sms'),
      },
      {
        value: 'call_history',
        label: this.translate.instant('CASES.single.general.relation_list.notification_list.heading_call'),
      },
      {value: 'status_logs', label: this.translate.instant('CASES.single.general.relation_list.status_logs.heading')},
      {value:  'call_status_logs',
        label: this.translate.instant('CASES.single.general.relation_list.call_status_logs.heading'),
      },
      {
        value: 'payment_status_logs',
        label: this.translate.instant('CASES.single.general.relation_list.payment_status_logs.heading'),
      },
      {value: 'contracts', label: this.translate.instant('CASES.single.general.relation_list.contracts.heading')},
      {value: 'tasks', label: this.translate.instant('CASES.single.general.relation_list.tasks.heading')}
    ];
  }

  public updateCaseCreditors(updatedCase: Case): void {
    this.case.secured_creditors   = updatedCase.secured_creditors;
    this.case.unsecured_creditors = updatedCase.unsecured_creditors;
    this.case.public_debts        = updatedCase.public_debts;
    this.caseCreditorsUpdated.emit(this.case);
  }
}
