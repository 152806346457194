import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { SignaturePadModule } from 'ngx-signaturepad';
import { CaseModule } from '../admin/case/case.module';
import { AuthModule } from '../auth/auth.module';
import { SharedClientModule } from './_shared/shared-client.module';
import { ClientRoutingModule } from './client-routing.module';
import { IntegrationModule } from './integration/integration.module';
import { NotaryDownloadComponent } from './notary-download/notary-download.component';
import { PaymentModule } from './payment/payment.module';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { RedirectToClientAppComponent } from './redirect-to-client-app/redirect-to-client-app.component';
import { RedirectToClientAppDynamicComponent } from './redirect-to-client-app-dynamic/redirect-to-client-app-dynamic.component';

@NgModule({
  imports:      [
    SharedClientModule,
    SignaturePadModule,
    CaseModule,
    PaymentModule,
    IntegrationModule,
    MatStepperModule,
    MatSidenavModule,
    AuthModule,
    ClientRoutingModule,
  ],
  exports:      [
  ],
  declarations: [
    UnsubscribeComponent,
    NotaryDownloadComponent,
    RedirectToClientAppComponent,
    RedirectToClientAppDynamicComponent,
  ],
  providers:    [{provide: Window, useValue: window}],
})
export class ClientModule {
}
