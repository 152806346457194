<div class="d-flex flex-row justify-content-between">
  <div>
    <button mat-stroked-button type="button" color="primary" class="btn mt-2 ml-2"
            (click)="clearFilters()">{{ "SHARED.reset-filters" | translate }}</button>
    <button [matMenuTriggerFor]="proposalMenu" mat-raised-button color="primary" class="ml-2 mt-2"
            *ngIf="case?.product?.group_slug === 'unified' || case?.product?.group_slug === 'dmp'">
      {{ 'CASES.single.draft.proposal.label' | translate }}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #proposalMenu="matMenu" >
      <button mat-menu-item color="primary" class="mb-1 mr-md-4" (click)="generateProposal()">
        {{ "CASES.details.generate-proposal" | translate }}
      </button>
      <button mat-menu-item (click)="sendProposalToCreditors(case.id)" [disabled]="!allCreditorsHaveProposalEmails">
        {{ 'CASES.single.draft.proposal.send_to_creditors' | translate }}
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="previewProposal('client')">
        {{ 'CASES.single.draft.proposal.preview_client' | translate }}
      </button>
      <button mat-menu-item (click)="previewProposal('partner')" *ngIf="case?.joint_application">
        {{ 'CASES.single.draft.proposal.preview_partner' | translate }}
      </button>
      <button mat-menu-item (click)="requestProposalSignature()">
        {{ 'CASES.single.draft.proposal.request_signature.label' | translate }}
      </button>
    </mat-menu>
    <!-- get latest dm propuesta -->
    <a mat-raised-button color="primary" class="ml-2" [disabled]="!latestPropuestaPdfLocation" target="_blank"
       href="{{storageUrl + latestPropuestaPdfLocation}}"
       [title]="!latestPropuestaPdfLocation ?
        ('CASES.single.get-propuesta-button-disabled' | translate) :
         ('CASES.single.get-propuesta' | translate)">
      {{ "CASES.single.get-propuesta" | translate }}
    </a>
    <!-- get latest client propuesta -->
    <a mat-raised-button color="primary" class="ml-2" [disabled]="!latestPropuestaClientPdfLocation" target="_blank"
       href="{{storageUrl + latestPropuestaClientPdfLocation}}"
       [title]="!latestPropuestaClientPdfLocation ?
        ('CASES.single.get-propuesta-button-disabled' | translate) :
         ('CASES.single.get-client-proposal' | translate)">
      {{ "CASES.single.get-client-proposal" | translate }}
    </a>
    <!-- <button [matMenuTriggerFor]="loanRequestMenu" mat-raised-button color="primary" class="ml-2 mt-2"
            *ngIf="case?.product?.group_slug === 'unified' || case?.product?.group_slug === 'dmp'">
            Loan Request
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #loanRequestMenu="matMenu" >
      <button mat-menu-item color="primary" class="mb-1 mr-md-4" (click)="generateExternalLoan()">
        Generate Loan Request PDF
      </button>
      <button mat-menu-item color="primary" class="mb-1 mr-md-4" (click)="openLinkInNewWindow(storageUrl + case?.loan_request_pdf_location)" [disabled]="!case?.loan_request_pdf_location">
        Download Loan Request PDF
      </button>
    </mat-menu> -->
    <div style="display: inline-block;margin-left:0.5em;" *ngIf="case && authUser?.packager?.master">
      <button mat-raised-button [mat-menu-trigger-for]="referCaseMenu" color="primary">
        {{'CASE_CREDITOR.refer_case.heading' | translate}}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #referCaseMenu class="refer-case-menu">
        <button mat-menu-item
                color="primary" [disabled]="!!case.epi_ref_number" (click)="sendCase(case, 'epi')">
          <span *ngIf="!case.epi_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_epi.refer_case_to_epi' | translate}}</span>
          <span *ngIf="case.epi_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_epi.referred_to' | translate}} {{ case.epi_ref_number }}</span>
        </button>
  
        <button mat-menu-item
                color="primary" [disabled]="!!case.deu_ref_number" (click)="sendCase(case, 'deu')">
          <span *ngIf="!case.deu_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_deu.refer_case_to_deu' | translate}}</span>
          <span *ngIf="case.deu_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_deu.referred_to' | translate}} {{ case.deu_ref_number }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
<!-- <div class="filters pb-5 pt-3" *ngIf="form" [formGroup]="form">
  <div class="advanced-filters">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "PAYMENTS.advanced-filters" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-4">
            <mat-form-field color="primary" appearance="standard">
              <mat-label>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</mat-label>
              <mat-select formControlName="ownership" multiple>
                <mat-option
                  value="applicant">{{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}</mat-option>
                <mat-option
                  value="partner">{{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}</mat-option>
                <mat-option value="joint">{{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}</mat-option>
              </mat-select>
              <button *ngIf="form.value.ownership.length" mat-button matSuffix mat-icon-button aria-label="Clear"
                      (click)="clearMultiSelect($event, 'ownership')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-md-6 col-lg col-xl">
            <mat-form-field color="primary" appearance="standard">
              <mat-label>{{ 'CASE_CREDITOR.model.verified.label' | translate }}</mat-label>
              <mat-select formControlName="verified" multiple>
                <mat-option [value]="1">{{"CASE_CREDITOR.model.verified.options.true" | translate}}</mat-option>
                <mat-option [value]="0">{{"CASE_CREDITOR.model.verified.options.false" | translate}}</mat-option>
              </mat-select>
              <button *ngIf="form.value.verified.length" mat-button matSuffix mat-icon-button aria-label="Clear"
                      (click)="clearMultiSelect($event, 'verified')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-md-6 col-lg col-xl">
            <mat-form-field color="primary" appearance="standard">
              <mat-label>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</mat-label>
              <mat-select formControlName="response_received" multiple>
                <mat-option value="mandate_sent">
                  {{"CASE_CREDITOR.model.response_received.options.mandate_sent" | translate}}
                </mat-option>
                <mat-option value="chase_required">
                  {{"CASE_CREDITOR.model.response_received.options.chase_required" | translate}}
                </mat-option>
                <mat-option value="mandate_rejected">
                  {{"CASE_CREDITOR.model.response_received.options.mandate_rejected" | translate}}
                </mat-option>
                <mat-option value="balance_received">
                  {{"CASE_CREDITOR.model.response_received.options.balance_received" | translate}}
                </mat-option>
                <mat-option value="balance_outstanding">
                  {{"CASE_CREDITOR.model.response_received.options.balance_outstanding" | translate}}
                </mat-option>
                <mat-option value="proposal_sent">
                  {{"CASE_CREDITOR.model.response_received.options.proposal_sent" | translate}}
                </mat-option>
                <mat-option value="proposal_rejected">
                  {{"CASE_CREDITOR.model.response_received.options.proposal_rejected" | translate}}
                </mat-option>
                <mat-option value="modification_required">
                  {{"CASE_CREDITOR.model.response_received.options.modification_required" | translate}}
                </mat-option>
                <mat-option value="proposal_accepted">
                  {{"CASE_CREDITOR.model.response_received.options.proposal_accepted" | translate}}
                </mat-option>
                <mat-option value="extrajudicial_claim_sent">
                  {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_sent' | translate }}
                </mat-option>
                <mat-option value="extrajudicial_claim_accepted">
                  {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_accepted' | translate }}
                </mat-option>
                <mat-option value="extrajudicial_claim_rejected">
                  {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_rejected' | translate }}
                </mat-option>
                <mat-option value="extrajudicial_claim_unanswered">
                  {{ 'CASE_CREDITOR.model.response_received.options.extrajudicial_claim_unanswered' | translate }}
                </mat-option>
                <mat-option value="claim_filed">
                  {{ 'CASE_CREDITOR.model.response_received.options.claim_filed' | translate }}
                </mat-option>
                <mat-option value="claim_approved">
                  {{ 'CASE_CREDITOR.model.response_received.options.claim_approved' | translate }}
                </mat-option>
                <mat-option value="claim_rejected">
                  {{ 'CASE_CREDITOR.model.response_received.options.claim_rejected' | translate }}
                </mat-option>
              </mat-select>
              <button *ngIf="form.value.response_received.length" mat-button matSuffix mat-icon-button
                      aria-label="Clear"
                      (click)="clearMultiSelect($event, 'response_received')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div> -->

<div class="creditors-section" *ngIf="!useCreditorLoanFields">
  <!-- Secured Creditors -->
  <h3>{{ "CASE_CREDITOR.model.type.options.secured" | translate }}</h3>
  <button mat-raised-button color="primary"
          [matMenuTriggerFor]="menuSecured">{{ "SHARED.bulk-actions" | translate }}</button>
  <mat-menu #menuSecured="matMenu">
    <div [title]="securedVerifyDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="securedVerifyDisabled"
              (click)="verifyCreditor($event, selectionSecured, 'secured_creditors')">
        {{ "CASES.details.verify-send-mandates" | translate }}
      </button>
    </div>
    <div [title]="securedResendDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="securedResendDisabled"
              (click)="verifyCreditor($event, selectionSecured, 'secured_creditors')">
        {{ "CASES.details.resend-mandates" | translate }}
      </button>
    </div>
    <div [title]="securedRecoveryDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="securedRecoveryDisabled"
              (click)="sendMandatesRecoveryAgent($event, selectionSecured)">
        {{ "CASES.details.send-mandates-recovery" | translate }}
      </button>
    </div>
    <!--<div
      [title]="securedSendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
      <button mat-menu-item [disabled]="securedSendAepDisabled"
              (click)="sendAep($event, selectionSecured, 'secured_creditors')">
        {{ "CASES.details.send-aep" | translate }}
      </button>
    </div>-->
    <!--<div
      [title]="securedResendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
      <button mat-menu-item [disabled]="securedResendAepDisabled"
              (click)="sendAep($event, selectionSecured, 'secured_creditors')">
        {{ "CASES.details.resend-aep" | translate }}
      </button>
    </div>-->
    <div
      [title]="securedProposalAccordDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="securedProposalAccordDisabled"
              (click)="sendProposalAndAccordSelection(selectionSecured)">
        {{ "CASES.details.send-proposal-accord" | translate }}
      </button>
    </div>
    <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="changeStatus($event, selectionSecured, 'secured_creditors')">
        {{ "CASES.details.change-status" | translate }}
      </button>
    </div>
    <!-- <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendLastChance($event, selectionSecured)">
        {{ "CASES.details.send-last-chance" | translate }}
      </button>
    </div> -->
    <!-- Right of Access -->
    <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendRightOfAccess(selectionSecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access' | translate }}
      </button>
    </div>
    <!--Right of Access  PPI-->
    <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendRightOfAccessPpi(selectionSecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access_ppi' | translate }}
      </button>
    </div>
    <!-- Send PPI Claim -->
    <div
      [title]="!selectionSecured.selected.length ? 'One or more selected creditors does not have this action available' : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendPpiClaim(selectionSecured)">
        {{ 'SHARED.send' | translate }} PPI Claim
      </button>
    </div>
    <!-- Azcarate Loan -->
    <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendAzcarateLoanLetter(selectionSecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_loan_letter' | translate }}
      </button>
    </div>
    <!-- Azcarate Revolving-->
    <div
      [title]="!selectionSecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionSecured.selected.length"
              (click)="sendAzcarateRevolvingLetter(selectionSecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_revolving_letter' | translate }}
      </button>
    </div>

  </mat-menu>
  <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
    <table style="width: 100%" mat-table matSort [dataSource]="securedCreditors">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle(selectionSecured, securedCreditors) : null" color="primary"
                        [checked]="selectionSecured.hasValue() && isAllSelected(selectionSecured, securedCreditors)"
                        [indeterminate]="selectionSecured.hasValue() && !isAllSelected(selectionSecured, securedCreditors)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? selectionSecured.toggle(row) : null"
                        [checked]="selectionSecured.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="ownership">
        <th mat-header-cell *matHeaderCellDef>{{ "ADDRESS-BOOK.product_type" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.pivot.ownership === 'applicant'">
            {{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}
          </ng-container>
          <ng-container *ngIf="element.pivot.ownership === 'partner'">
            {{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}
          </ng-container>
          <ng-container *ngIf="element.pivot.ownership === 'joint'">
            {{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="debt_type">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.debt_type.label" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ "CASE_CREDITOR.model.debt_type.options." + element.pivot.debt_type | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="initial_balance">
        <th mat-header-cell *matHeaderCellDef>
          <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.pivot.initial_balance | number: '1.2-2' }}€
        </td>
      </ng-container>

      <ng-container matColumnDef="current_balance">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.pivot.current_balance | number: '1.2-2'}}€</td>
      </ng-container>

      <ng-container matColumnDef="monthly_payments">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.pivot.monthly_payments | number: '1.2-2'}}€</td>
      </ng-container>

      <ng-container matColumnDef="reference_number">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.pivot.reference_number}} </td>
      </ng-container>

      <ng-container matColumnDef="response_received">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container>
            {{ ("CASE_CREDITOR.model.response_received.options." + element.pivot.response_received) | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="additional_partner">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.additional_partner" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.pivot.additional_partner" class="mat-icon-size" color="primary">check_circle
          </mat-icon>
          <mat-icon *ngIf="!element.pivot.additional_partner" class="mat-icon-size" color="warn">cancel</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="making_payments">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.pivot.making_payments" class="mat-icon-size" color="primary">check_circle</mat-icon>
          <mat-icon *ngIf="!element.pivot.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="azcarate">
        <th mat-header-cell *matHeaderCellDef>Azcarate</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.pivot.total_repaid > element.pivot.initial_balance" class="mat-icon-size"
                    color="primary">check_circle
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
          <mat-menu #menu="matMenu">
            <button *ngIf="!element.pivot.verified" mat-menu-item
                    (click)="verifyCreditor($event, [element.pivot.id], 'secured_creditors')">
              {{ "CASES.details.verify-send-mandates" | translate }}
            </button>
            <button *ngIf="element.pivot.verified" mat-menu-item
                    (click)="verifyCreditor($event, [element.pivot.id], 'secured_creditors')">
              {{ "CASES.details.resend-mandates" | translate }}
            </button>
            <button *ngIf="element.pivot.recovery_creditor_id" mat-menu-item
                    (click)="sendMandatesRecoveryAgent($event, [element.pivot.id])">
              {{ "CASES.details.send-mandates-recovery" | translate }}
            </button>
            <!--<div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <button *ngIf="!element.pivot.aep_sent" mat-menu-item
                      [disabled]="missingAepFiles"
                      (click)="sendAep($event, [element.pivot.id], 'secured_creditors')">
                {{ "CASES.details.send-aep" | translate }}
              </button>
            </div>-->
            <!--<div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <button *ngIf="element.pivot.aep_sent" mat-menu-item
                      [disabled]="missingAepFiles"
                      (click)="sendAep($event, [element.pivot.id], 'secured_creditors')">
                {{ "CASES.details.resend-aep" | translate }}
              </button>
            </div>-->

            <!-- [disabled]="!isSigned || !element.pivot.proposal_location" -->
            <button mat-menu-item
                    (click)="sendProposalAndAccord([element.pivot.id])">
              {{ "CASES.details.send-proposal-accord" | translate }}
            </button>
            <button mat-menu-item
                    [disabled]="!(element.pivot.total_repaid > element.pivot.initial_balance)"
                    (click)="sendEmail($event, element.pivot.id)">
              {{ "CASES.details.send-azcarate" | translate }}
            </button>
            <button mat-menu-item
                    (click)="changeStatus($event, [element.pivot.id], 'secured_creditors')">
              {{ "CASES.details.change-status" | translate }}
            </button>
            <!-- <button mat-menu-item
                    (click)="sendLastChance($event, [element.pivot.id])">
              {{ "CASES.details.send-last-chance" | translate }}
            </button> -->
            <button mat-menu-item
                    (click)="sendDeleteNumber($event, [element.pivot.id])">
              {{ "CASES.details.send-delete-number" | translate }}
            </button>
            <!-- <button mat-menu-item
                    (click)="sendAntiHarassment($event, [element.pivot.id])">
              {{ "CASES.details.send-azkarate-acoso" | translate }}
            </button> -->
            <!-- <button mat-menu-item
                    (click)="sendLoanCancellationEmail($event, [element.pivot.id])">
              {{ "CASES.details.send-azkarate-cancelar" | translate }}
            </button> -->
            <!-- <button mat-menu-item
                    *ngIf="element.pivot.ownership === 'applicant' || element.pivot.ownership === 'joint' "
                    (click)="getRightOfAccess('client', element.pivot.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 1
            </button> -->
            <!-- <button mat-menu-item
                    *ngIf="element.pivot.ownership === 'partner' || element.pivot.ownership === 'joint' "
                    (click)="getRightOfAccess('partner', element.pivot.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 2
            </button> -->
            <button mat-menu-item
              (click)="openLinkInNewWindow('/creditor-proposal-preview', {uuid: case.uuid, case_creditor_id:element.pivot.id, ownership: element.pivot.ownership})">
              {{'CASES.details.preview-creditor-proposal-and-accord' | translate}}
            </button>
            <a mat-menu-item class="clickable"
               href="{{storageUrl + element.pivot.proposal_location}}">
              {{'CASES.details.download_creditor_proposal' | translate}}
            </a>
            <a mat-menu-item class="clickable"
               href="{{storageUrl + element.pivot.proposal_location_accord}}">
              {{'CASES.details.download_proposal_accord' | translate}}
            </a>
          </mat-menu>
          <button mat-stroked-button class="ml-1" *ngIf="authUser.packager?.master"
                  (click)="toggleType(element, 'secured')">
            {{'CASE_CREDITOR.claim.make_as_claim' | translate}}
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="loader">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px">
          <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.pivot.id) !== -1"
                       diameter="20" color="accent"></mat-spinner>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="isFetching" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!isFetching && securedCreditors && !securedCreditors.data.length" style="padding: 50px">
      <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>
    </div>
  </div>
  <!-- Unsecured Creditors -->
  <h3>{{ "CASE_CREDITOR.model.type.options.unsecured" | translate }}</h3>
  <button mat-raised-button color="primary" [matMenuTriggerFor]="menuUnsecured">
    {{ "SHARED.bulk-actions" | translate }}
  </button>
  <mat-menu #menuUnsecured="matMenu">
    <div [title]="unsecuredVerifyDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="unsecuredVerifyDisabled"
              (click)="verifyCreditor($event, selectionUnsecured, 'unsecured_creditors')">
        {{ "CASES.details.verify-send-mandates" | translate }}
      </button>
    </div>
    <div [title]="unsecuredResendDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="unsecuredResendDisabled"
              (click)="verifyCreditor($event, selectionUnsecured, 'unsecured_creditors')">
        {{ "CASES.details.resend-mandates" | translate }}
      </button>
    </div>
    <div [title]="unsecuredRecoveryDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <a mat-menu-item [disabled]="unsecuredRecoveryDisabled"
         (click)="sendMandatesRecoveryAgent($event, selectionUnsecured)">
        {{ "CASES.details.send-mandates-recovery" | translate }}
      </a>
    </div>
    <!--<div
      [title]="unsecuredSendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
      <button mat-menu-item [disabled]="unsecuredSendAepDisabled"
              (click)="sendAep($event, selectionUnsecured, 'unsecured_creditors')">
        {{ "CASES.details.send-aep" | translate }}
      </button>
    </div>-->
    <!--<div
      [title]="unsecuredResendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
      <button mat-menu-item [disabled]="unsecuredResendAepDisabled"
              (click)="sendAep($event, selectionUnsecured, 'unsecured_creditors')">
        {{ "CASES.details.resend-aep" | translate }}
      </button>
    </div>-->
    <div
      [title]="unsecuredProposalAccordDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="unsecuredProposalAccordDisabled"
              (click)="sendProposalAndAccordSelection(selectionUnsecured)">
        {{ "CASES.details.send-proposal-accord" | translate }}
      </button>
    </div>
    <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              (click)="changeStatus($event, selectionUnsecured, 'unsecured_creditors')">
        {{ "CASES.details.change-status" | translate }}
      </button>
    </div>
    <!-- <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              (click)="sendLastChance($event, selectionUnsecured)">
        {{ "CASES.details.send-last-chance" | translate }}
      </button>
    </div> -->
    <!-- Right of Access -->
    <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              (click)="sendRightOfAccess(selectionUnsecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access' | translate }}
      </button>
    </div>
    <!--Right of Access  PPI-->
    <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              (click)="sendRightOfAccessPpi(selectionUnsecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access_ppi' | translate }}
      </button>
    </div>
    <!-- Send PPI Claim -->
    <div
      [title]="!selectionUnsecured.selected.length ? 'One or more selected creditors does not have this action available' : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              (click)="sendPpiClaim(selectionUnsecured)">
        {{ 'SHARED.send' | translate }} PPI Claim
      </button>
    </div>
    <!-- Azcarate Loan -->
    <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              (click)="sendAzcarateLoanLetter(selectionUnsecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_loan_letter' | translate }}
      </button>
    </div>
    <!-- Azcarate Revolving-->
    <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              (click)="sendAzcarateRevolvingLetter(selectionUnsecured)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_revolving_letter' | translate }}
      </button>
    </div>
    <!-- Send Email-->
    <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!isEmailEnabled && !selectionUnsecured.selected.length"
              (click)="sendEmail($event, '', 'unsecured_creditors')">
        {{ "CASES.details.send-azcarate" | translate }}
      </button>
    </div>
    <!--Send Delete number-->
    <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              (click)="sendDeleteNumber($event, '', 'unsecured_creditors')">
        {{ "CASES.details.send-delete-number" | translate }}
      </button>
    </div>
    <!-- <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              (click)="sendAntiHarassment($event, [], 'unsecured_creditors')">
        {{ "CASES.details.send-azkarate-acoso" | translate }}
      </button>
    </div> -->
    <!-- <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              (click)="sendLoanCancellationEmail($event, [], 'unsecured_creditors')">
        {{ "CASES.details.send-azkarate-cancelar" | translate }}
      </button>
    </div> -->
    <!-- <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              *ngIf="isGetRightOfAccessClientEnabled"
              (click)="getRightOfAccess('client', 0, $event, 'unsecured_creditors')">
        {{'CASES.details.get-right-of-access' | translate}} 1
      </button>
    </div> -->
    <!-- <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionUnsecured.selected.length"
              *ngIf="isGetRightOfAccessClientEnabled"
              (click)="getRightOfAccess('partner', 0, $event, 'unsecured_creditors')">
        {{'CASES.details.get-right-of-access' | translate}} 2
      </button>
    </div> -->
    <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <a mat-menu-item class="clickable"
         [disabled]="!selectionUnsecured.selected.length || selectionUnsecured.selected.length > 1 "
         (click)="previewCreditorProposal()">
        {{'CASES.details.preview-creditor-proposal-and-accord' | translate}}
      </a>
    </div>
    <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <a mat-menu-item class="clickable"
         [disabled]="!selectionUnsecured.selected.length || selectionUnsecured.selected.length > 1 "
         (click)="downloadFile('proposal')">
        {{'CASES.details.download_creditor_proposal' | translate}}
      </a>
    </div>
    <div
      [title]="!selectionUnsecured.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <a mat-menu-item class="clickable"
         [disabled]="!selectionUnsecured.selected.length || selectionUnsecured.selected.length > 1 "
         (click)="downloadFile('proposal_accord')">
        {{'CASES.details.download_proposal_accord' | translate}}
      </a>
    </div>
  </mat-menu>
  <div *ngIf="case" class="mat-elevation-z8"
       style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
    <table style="width: 100%; display: block;overflow-x: auto;" mat-table matSort [dataSource]="unsecuredCreditors"
           [ngClass]="[!!(case.product?.group_slug === 'dm'
    || case.product?.group_slug === 'dgs' || case.product?.group_slug === 'cajaplus') ? '' : 'table-responsive']">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="p-r-10">
          <mat-checkbox (change)="$event ? masterToggle(selectionUnsecured, unsecuredCreditors) : null" color="primary"
                        [checked]="selectionUnsecured.hasValue() && isAllSelected(selectionUnsecured, unsecuredCreditors)"
                        [indeterminate]="selectionUnsecured.hasValue() && !isAllSelected(selectionUnsecured, unsecuredCreditors)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? selectionUnsecured.toggle(row) : null"
                        [checked]="selectionUnsecured.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="ownership">
        <th mat-header-cell *matHeaderCellDef
            class="p-r-10">{{ "ADDRESS-BOOK.product_type" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container
            *ngIf="element.pivot.ownership === 'applicant'">{{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}</ng-container>
          <ng-container
            *ngIf="element.pivot.ownership === 'partner'">{{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}</ng-container>
          <ng-container
            *ngIf="element.pivot.ownership === 'joint'">{{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}</ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="debt_type">
        <th mat-header-cell *matHeaderCellDef>{{ 'CASE_CREDITOR.model.debt_type.label' | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container>
            {{ 'CASE_CREDITOR.model.debt_type.options.' + element.pivot.debt_type | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="initial_balance">
        <th mat-header-cell *matHeaderCellDef>
          <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>
        </th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          {{ element.pivot.initial_balance | number: '1.2-2' }}€
        </td>
      </ng-container>

      <ng-container matColumnDef="current_balance">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.current_balance | number: '1.2-2'}}
          €
        </td>
      </ng-container>

      <ng-container matColumnDef="amount_repaid">
        <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.total_repaid' | translate}}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.amount_repaid | number: '1.2-2'}}
          €
        </td>
      </ng-container>

      <ng-container matColumnDef="monthly_payments">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.monthly_payments | number: '1.2-2'}}
          €
        </td>
      </ng-container>

      <ng-container matColumnDef="reference_number">
        <th mat-header-cell *matHeaderCellDef
            class="p-r-10">{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.reference_number}} </td>
      </ng-container>

      <ng-container matColumnDef="response_received">
        <th mat-header-cell *matHeaderCellDef
            class="p-r-10">{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container>
            {{ ("CASE_CREDITOR.model.response_received.options." + element.pivot.response_received) | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="additional_partner">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.additional_partner" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <mat-icon *ngIf="element.pivot.additional_partner" class="mat-icon-size" color="primary">check_circle
          </mat-icon>
          <mat-icon *ngIf="!element.pivot.additional_partner" class="mat-icon-size" color="warn">cancel</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="making_payments">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <mat-icon *ngIf="element.pivot.making_payments" class="mat-icon-size" color="primary">check_circle</mat-icon>
          <mat-icon *ngIf="!element.pivot.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>
        </td>
      </ng-container>

      <form *ngIf="excludedForm" [formGroup]="excludedForm" class="p-2">
        <ng-container matColumnDef="debt_agreed">
          <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.debt_agreed' | translate}}</th>
          <td mat-cell *matCellDef="let element; let i =  index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <ng-container>
              <app-input *ngIf="isEditing && currentRow === i" type="number" formControlName="debt_agreed"
                         [showLabel]="false" [extraLabel]="false" appearance="standard" [fullWidth]="false"
                         flexClass="align-items-center">
              </app-input>
              <p *ngIf="!isEditing || currentRow != i">{{element.pivot.debt_agreed}}</p>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="debt_reduction">
          <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.debt_reduction' | translate}}</th>
          <td mat-cell *matCellDef="let element; let i =  index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <ng-container>
              {{element.pivot.current_balance - element.pivot.debt_agreed}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="monthly_repayment">
          <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.monthly_repayments' | translate}}</th>
          <td mat-cell *matCellDef="let element; let i =  index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <ng-container>
              <app-input *ngIf="isEditing && currentRow === i" type="number" formControlName="monthly_repayment"
                         [showLabel]="false" [extraLabel]="false" appearance="standard" [fullWidth]="false"
                         flexClass="align-items-center">
              </app-input>
              <p *ngIf="!isEditing || currentRow != i">{{element.pivot.monthly_repayment}}</p>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="term">
          <th mat-header-cell *matHeaderCellDef class="p-r-10">{{'CASE_CREDITOR.model.term' | translate}}</th>
          <td mat-cell *matCellDef="let element; let i =  index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <ng-container>
              {{+(+(element.pivot.debt_agreed) / +(element.pivot.monthly_repayment)) }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="p-r-10">{{'CASE_CREDITOR.model.status' | translate}}</th>
          <td mat-cell *matCellDef="let element; let i =  index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <ng-container>
              <app-input *ngIf="isEditing && currentRow === i" type="select" formControlName="status"
                         appearance="standard" fullWidth="false"
                         [label]="'CASE_CREDITOR.model.status' | translate"
                         [selectOptions]="statuses" [selectLabel]="'name'" [selectValue]="'id'">
              </app-input>
              <p *ngIf="!isEditing || currentRow != i">{{element.status}}</p>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef class="p-r-10">Reference</th>
          <td mat-cell *matCellDef="let element; let i = index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <ng-container>
              <app-input *ngIf="isEditing && currentRow === i" type="text" formControlName="reference"
                         appearance="standard" fullWidth="false"
                         [label]="'Reference'">
              </app-input>
              <p *ngIf="!isEditing || currentRow != i">{{element.reference}}</p>
            </ng-container>
          </td>
        </ng-container>
      </form>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
          <mat-menu #menu="matMenu">
            <button *ngIf="!element.pivot.verified" mat-menu-item
                    (click)="verifyCreditor($event, [element.pivot.id], 'unsecured_creditors')">
              {{ "CASES.details.verify-send-mandates" | translate }}
            </button>
            <button *ngIf="element.pivot.verified" mat-menu-item
                    (click)="verifyCreditor($event, [element.pivot.id], 'unsecured_creditors')">
              {{ "CASES.details.resend-mandates" | translate }}
            </button>
            <button *ngIf="element.pivot.recovery_creditor_id" mat-menu-item
                    (click)="sendMandatesRecoveryAgent($event, [element.pivot.id])">
              {{ "CASES.details.send-mandates-recovery" | translate }}
            </button>

            <!--<div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <button *ngIf="!element.pivot.aep_sent" mat-menu-item [disabled]="missingAepFiles"
                      (click)="sendAep($event, [element.pivot.id], 'unsecured_creditors')">
                {{ "CASES.details.send-aep" | translate }}
              </button>
            </div>-->
            <!--<div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <button *ngIf="element.pivot.aep_sent" mat-menu-item [disabled]="missingAepFiles"
                      (click)="sendAep($event, [element.pivot.id], 'unsecured_creditors')">
                {{ "CASES.details.resend-aep" | translate }}
              </button>
            </div>-->

            <!-- [disabled]="!isSigned || !element.pivot.proposal_location" -->
            <button mat-menu-item
                    (click)="sendProposalAndAccord([element.pivot.id])">
              {{ "CASES.details.send-proposal-accord" | translate }}
            </button>
            <!-- <button mat-menu-item
                     [disabled]="!(element.pivot.total_repaid > element.pivot.initial_balance)"
                     (click)="sendEmail($event, element.pivot.id)">
               {{ "CASES.details.send-azcarate" | translate }}
             </button>-->
            <button mat-menu-item
                    (click)="changeStatus($event, [element.pivot.id], 'unsecured_creditors')">
              {{ "CASES.details.change-status" | translate }}
            </button>
            <!-- <button mat-menu-item
                    (click)="sendLastChance($event, [element.pivot.id])">
              {{ "CASES.details.send-last-chance" | translate }}
            </button> -->
            <!--  <button mat-menu-item
                      (click)="sendDeleteNumber($event, [element.pivot.id])">
                {{ "CASES.details.send-delete-number" | translate }}
              </button>-->
            <!-- <button mat-menu-item
                    (click)="sendAntiHarassment($event, [element.pivot.id])">
              {{ "CASES.details.send-azkarate-acoso" | translate }}
            </button> -->
            <!-- <button mat-menu-item
                    (click)="sendLoanCancellationEmail($event, [element.pivot.id])">
              {{ "CASES.details.send-azkarate-cancelar" | translate }}
            </button> -->
            <!-- <button mat-menu-item
                    *ngIf="element.pivot.ownership === 'applicant' || element.pivot.ownership === 'joint' "
                    (click)="getRightOfAccess('client', element.pivot.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 1
            </button> -->
            <!-- <button mat-menu-item
                    *ngIf="element.pivot.ownership === 'partner' || element.pivot.ownership === 'joint' "
                    (click)="getRightOfAccess('partner', element.pivot.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 2
            </button> -->
            <button 
              (click)="openLinkInNewWindow('/creditor-proposal-preview', {uuid: case.uuid, case_creditor_id:element.pivot.id, ownership: element.pivot.ownership})">
              {{'CASES.details.preview-creditor-proposal-and-accord' | translate}}
            </button>
            <a mat-menu-item class="clickable"
               href="{{storageUrl + element.pivot.proposal_location}}">
              {{'CASES.details.download_creditor_proposal' | translate}}
            </a>
            <a mat-menu-item class="clickable"
               href="{{storageUrl + element.pivot.proposal_location_accord}}">
              {{'CASES.details.download_proposal_accord' | translate}}
            </a>
          </mat-menu>
          <button mat-stroked-button class="ml-1" *ngIf="authUser.packager?.master"
                  (click)="toggleType(element, 'unsecured')">
            {{'CASE_CREDITOR.claim.make_as_claim' | translate}}
          </button>
          <button mat-stroked-button class="ml-2"
                  (click)="onExclude(element)">{{'CASES.single.creditors.actions.exclude' | translate}}</button>
          <button mat-stroked-button class="ml-2" (click)="toggleEditMode(i, element)">
            {{ !isEditing || currentRow != i ? 'Edit' : 'Save' }}
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="loader">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.pivot.id) !== -1"
                       diameter="20" color="accent"></mat-spinner>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsUnsecured"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsUnsecured;"></tr>
    </table>
    <div *ngIf="isFetching" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!isFetching && unsecuredCreditors && !unsecuredCreditors.data.length" style="padding: 50px">
      <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>
    </div>
  </div>
  <!-- Claim Creditors -->
  <h3>{{ "CASE_CREDITOR.model.type.options.claim" | translate }}</h3>
  <button mat-raised-button color="primary" [matMenuTriggerFor]="menuClaim">
    {{ "SHARED.bulk-actions" | translate }}
  </button>
  <mat-menu #menuClaim="matMenu">
    <div [title]="claimVerifyDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="claimVerifyDisabled"
              (click)="verifyCreditor($event, selectionClaim, 'claim_creditors')">
        {{ "CASES.details.verify-send-mandates" | translate }}
      </button>
    </div>
    <div [title]="claimResendDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="claimResendDisabled"
              (click)="verifyCreditor($event, selectionClaim, 'claim_creditors')">
        {{ "CASES.details.resend-mandates" | translate }}
      </button>
    </div>
    <div [title]="claimRecoveryDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="claimRecoveryDisabled"
              (click)="sendMandatesRecoveryAgent($event, selectionClaim)">
        {{ "CASES.details.send-mandates-recovery" | translate }}
      </button>
    </div>
    <!--<div
      [title]="claimSendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
      <button mat-menu-item [disabled]="claimSendAepDisabled"
              (click)="sendAep($event, selectionClaim, 'claim_creditors')">
        {{ "CASES.details.send-aep" | translate }}
      </button>
    </div>-->
    <!--<div
      [title]="claimResendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
      <button mat-menu-item [disabled]="claimResendAepDisabled"
              (click)="sendAep($event, selectionClaim, 'claim_creditors')">
        {{ "CASES.details.resend-aep" | translate }}
      </button>
    </div>-->
    <div
      [title]="claimProposalAccordDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="claimProposalAccordDisabled"
              (click)="sendProposalAndAccordSelection(selectionClaim)">
        {{ "CASES.details.send-proposal-accord" | translate }}
      </button>
    </div>
    <div
      [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionClaim.selected.length"
              (click)="changeStatus($event, selectionClaim, 'claim_creditors')">
        {{ "CASES.details.change-status" | translate }}
      </button>
    </div>
    <!-- <div
      [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionClaim.selected.length"
              (click)="sendLastChance($event, selectionClaim)">
        {{ "CASES.details.send-last-chance" | translate }}
      </button>
    </div> -->
    <!-- Right of Access -->
    <div
      [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionClaim.selected.length"
              (click)="sendRightOfAccess(selectionClaim)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access' | translate }}
      </button>
    </div>
    <!--Right of Access  PPI-->
    <div
      [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionClaim.selected.length"
              (click)="sendRightOfAccessPpi(selectionClaim)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access_ppi' | translate }}
      </button>
    </div>
    <!-- Send PPI Claim -->
    <div
      [title]="!selectionClaim.selected.length ? 'One or more selected creditors does not have this action available' : ''">
      <button mat-menu-item [disabled]="!selectionClaim.selected.length"
              (click)="sendPpiClaim(selectionClaim)">
        {{ 'SHARED.send' | translate }} {{'CASES.single.creditors.actions.ppi_claim' | translate}}
      </button>
    </div>
    <!-- Azcarate Loan -->
    <div
      [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionClaim.selected.length"
              (click)="sendAzcarateLoanLetter(selectionClaim)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_loan_letter' | translate }}
      </button>
    </div>
    <!-- Azcarate Revolving-->
    <div
      [title]="!selectionClaim.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
      <button mat-menu-item [disabled]="!selectionClaim.selected.length"
              (click)="sendAzcarateRevolvingLetter(selectionClaim)">
        {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_revolving_letter' | translate }}
      </button>
    </div>
  </mat-menu>
  <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
    <table style="width: 100%" mat-table matSort [dataSource]="claimCreditors">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle(selectionClaim, claimCreditors) : null" color="primary"
                        [checked]="selectionClaim.hasValue() && isAllSelected(selectionClaim, claimCreditors)"
                        [indeterminate]="selectionClaim.hasValue() && !isAllSelected(selectionClaim, claimCreditors)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? selectionClaim.toggle(row) : null"
                        [checked]="selectionClaim.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="ownership">
        <th mat-header-cell *matHeaderCellDef>{{ "ADDRESS-BOOK.product_type" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container
            *ngIf="element.pivot.ownership === 'applicant'">{{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}</ng-container>
          <ng-container
            *ngIf="element.pivot.ownership === 'partner'">{{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}</ng-container>
          <ng-container
            *ngIf="element.pivot.ownership === 'joint'">{{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}</ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="debt_type">
        <th mat-header-cell *matHeaderCellDef>{{ 'CASE_CREDITOR.model.debt_type.label' | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container>
            {{ 'CASE_CREDITOR.model.debt_type.options.' + element.pivot.debt_type | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="initial_balance">
        <th mat-header-cell *matHeaderCellDef>
          <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>
        </th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          {{ element.pivot.initial_balance | number: '1.2-2' }}€
        </td>
      </ng-container>

      <ng-container matColumnDef="current_balance">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.current_balance | number: '1.2-2'}}
          €
        </td>
      </ng-container>

      <ng-container matColumnDef="monthly_payments">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.monthly_payments | number: '1.2-2'}}
          €
        </td>
      </ng-container>

      <ng-container matColumnDef="reference_number">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.reference_number}} </td>
      </ng-container>

      <ng-container matColumnDef="response_received">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <ng-container>
            {{ ("CASE_CREDITOR.model.response_received.options." + element.pivot.response_received) | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="additional_partner">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.additional_partner" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <mat-icon *ngIf="element.pivot.additional_partner" class="mat-icon-size" color="primary">check_circle
          </mat-icon>
          <mat-icon *ngIf="!element.pivot.additional_partner" class="mat-icon-size" color="warn">cancel</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="making_payments">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <mat-icon *ngIf="element.pivot.making_payments" class="mat-icon-size" color="primary">check_circle</mat-icon>
          <mat-icon *ngIf="!element.pivot.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="azcarate">
        <th mat-header-cell *matHeaderCellDef>Azcarate</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <mat-icon *ngIf="element.pivot.total_repaid > element.pivot.initial_balance" class="mat-icon-size"
                    color="primary">check_circle
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
          <mat-menu #menu="matMenu" yPosition="above">
            <button *ngIf="!element.pivot.verified" mat-menu-item
                    (click)="verifyCreditor($event, [element.pivot.id], 'claim_creditors')">
              {{ "CASES.details.verify-send-mandates" | translate }}
            </button>
            <button *ngIf="element.pivot.verified" mat-menu-item
                    (click)="verifyCreditor($event, [element.pivot.id], 'claim_creditors')">
              {{ "CASES.details.resend-mandates" | translate }}
            </button>
            <button *ngIf="element.pivot.recovery_creditor_id" mat-menu-item
                    (click)="sendMandatesRecoveryAgent($event, [element.pivot.id])">
              {{ "CASES.details.send-mandates-recovery" | translate }}
            </button>

            <!--<div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <button *ngIf="!element.pivot.aep_sent" mat-menu-item [disabled]="missingAepFiles"
                      (click)="sendAep($event, [element.pivot.id], 'claim_creditors')">
                {{ "CASES.details.send-aep" | translate }}
              </button>
            </div>-->
            <!--<div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
              <button *ngIf="element.pivot.aep_sent" mat-menu-item [disabled]="missingAepFiles"
                      (click)="sendAep($event, [element.pivot.id], 'claim_creditors')">
                {{ "CASES.details.resend-aep" | translate }}
              </button>
            </div>-->

            <!-- [disabled]="!isSigned || !element.pivot.proposal_location" -->
            <button mat-menu-item
                    (click)="sendProposalAndAccord([element.pivot.id])">
              {{ "CASES.details.send-proposal-accord" | translate }}
            </button>
            <a mat-menu-item [disabled]="!(element.pivot.total_repaid > element.pivot.initial_balance)"
               (click)="sendEmail($event, element.pivot.id)">
              {{ "CASES.details.send-azcarate" | translate }}
            </a>
            <button mat-menu-item
                    (click)="changeStatus($event, [element.pivot.id], 'claim_creditors')">
              {{ "CASES.details.change-status" | translate }}
            </button>
            <!-- <button mat-menu-item
                    (click)="sendLastChance($event, [element.pivot.id])">
              {{ "CASES.details.send-last-chance" | translate }}
            </button> -->
            <button mat-menu-item
                    (click)="sendDeleteNumber($event, [element.pivot.id])">
              {{ "CASES.details.send-delete-number" | translate }}
            </button>
            <!-- <button mat-menu-item
                    (click)="sendAntiHarassment($event, [element.pivot.id])">
              {{ "CASES.details.send-azkarate-acoso" | translate }}
            </button> -->
            <!-- <button mat-menu-item
                    (click)="sendLoanCancellationEmail($event, [element.pivot.id])">
              {{ "CASES.details.send-azkarate-cancelar" | translate }}
            </button> -->
            <!-- <button mat-menu-item
                    *ngIf="element.pivot.ownership === 'applicant' || element.pivot.ownership === 'joint' "
                    (click)="getRightOfAccess('client', element.pivot.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 1
            </button> -->
            <!-- <button mat-menu-item
                    *ngIf="element.pivot.ownership === 'partner' || element.pivot.ownership === 'joint' "
                    (click)="getRightOfAccess('partner', element.pivot.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 2
            </button> -->
            <a mat-menu-item 
            (click)="openLinkInNewWindow('/creditor-proposal-preview', {uuid: case.uuid, case_creditor_id:element.pivot.id, ownership: element.pivot.ownership})">
              {{'CASES.details.preview-creditor-proposal-and-accord' | translate}}
            </a>
            <a mat-menu-item class="clickable"
               href="{{storageUrl + element.pivot.proposal_location}}">
              {{'CASES.details.download_creditor_proposal' | translate}}
            </a>
            <a mat-menu-item class="clickable"
               href="{{storageUrl + element.pivot.proposal_location_accord}}">
              {{'CASES.details.download_proposal_accord' | translate}}
            </a>
          </mat-menu>
          <button mat-stroked-button *ngIf="authUser.packager?.master"
                  [matMenuTriggerFor]="returnToUnsecuredSecuredMenu">
            {{'CASES.details.make_as' | translate}}
          </button>
          <mat-menu #returnToUnsecuredSecuredMenu="matMenu">
            <button mat-menu-item (click)="toggleType(element,  'claim', 'unsecured')">
              {{ "CASE_CREDITOR.model.type.options.unsecured" | translate }}
            </button>
            <button mat-menu-item (click)="toggleType(element, 'claim', 'secured')">
              {{ "CASE_CREDITOR.model.type.options.secured" | translate }}
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <ng-container matColumnDef="loader">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.pivot.id) !== -1"
                       diameter="20" color="accent"></mat-spinner>
        </td>
        git
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="isFetching" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!isFetching && claimCreditors && !claimCreditors.data.length" style="padding: 50px">
      <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>
    </div>
  </div>

  <!-- Excluded Creditors -->
  <ng-container
    *ngIf="case?.product?.group_slug === 'dm' || case?.product?.group_slug === 'cajaplus' || case?.product?.group_slug === 'dgs'">
    <h3>{{'CASE_CREDITOR.model.type.options.excluded' | translate}}</h3>
    <button mat-raised-button color="primary" [matMenuTriggerFor]="menuExcluded">
      {{ "SHARED.bulk-actions" | translate }}
    </button>
    <mat-menu #menuExcluded="matMenu">
      <div [title]="excludedVerifyDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
        <button mat-menu-item [disabled]="excludedVerifyDisabled"
                (click)="verifyCreditor($event, selectionExcluded, 'excluded_creditors')">
          {{ "CASES.details.verify-send-mandates" | translate }}
        </button>
      </div>
      <div [title]="excludedResendDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
        <button mat-menu-item [disabled]="excludedResendDisabled"
                (click)="verifyCreditor($event, selectionExcluded, 'excluded_creditors')">
          {{ "CASES.details.resend-mandates" | translate }}
        </button>
      </div>
      <div [title]="excludedRecoveryDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
        <a mat-menu-item [disabled]="excludedRecoveryDisabled"
           (click)="sendMandatesRecoveryAgent($event, selectionExcluded)">
          {{ "CASES.details.send-mandates-recovery" | translate }}
        </a>
      </div>
      <!--<div
        [title]="excludedSendAepDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
        <button mat-menu-item [disabled]="excludedSendAepDisabled"
                (click)="sendAep($event, selectionExcluded, 'excluded_creditors')">
          {{ "CASES.details.send-aep" | translate }}
        </button>
      </div>-->
      <!--<div
        [title]="excludedProposalAccordDisabled ? ('CASES.single.creditors.actions.action_not_available_aep' | translate) : ''">
        <button mat-menu-item [disabled]="excludedProposalAccordDisabled"
                (click)="sendAep($event, selectionExcluded, 'excluded_creditors')">
          {{ "CASES.details.resend-aep" | translate }}
        </button>
      </div>-->
      <div
        [title]="excludedProposalAccordDisabled ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
        <button mat-menu-item [disabled]="excludedProposalAccordDisabled"
                (click)="sendProposalAndAccordSelection(selectionExcluded)">
          {{ "CASES.details.send-proposal-accord" | translate }}
        </button>
      </div>
      <div
        [title]="!selectionExcluded.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
        <button mat-menu-item [disabled]="!selectionExcluded.selected.length"
                (click)="changeStatus($event, selectionExcluded, 'excluded_creditors')">
          {{ "CASES.details.change-status" | translate }}
        </button>
      </div>
      <!-- <div
        [title]="!selectionExcluded.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
        <button mat-menu-item [disabled]="!selectionExcluded.selected.length"
                (click)="sendLastChance($event, selectionExcluded)">
          {{ "CASES.details.send-last-chance" | translate }}
        </button>
      </div> -->
      <!-- Right of Access -->
      <div
        [title]="!selectionExcluded.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
        <button mat-menu-item [disabled]="!selectionExcluded.selected.length"
                (click)="sendRightOfAccess(selectionExcluded)">
          {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access' | translate }}
        </button>
      </div>
      <!--Right of Access  PPI-->
      <div
        [title]="!selectionExcluded.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
        <button mat-menu-item [disabled]="!selectionExcluded.selected.length"
                (click)="sendRightOfAccessPpi(selectionExcluded)">
          {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.right_of_access_ppi' | translate }}
        </button>
      </div>
      <!-- Send PPI Claim -->
      <div
        [title]="!selectionExcluded.selected.length ? 'One or more selected creditors does not have this action available' : ''">
        <button mat-menu-item [disabled]="!selectionExcluded.selected.length"
                (click)="sendPpiClaim(selectionExcluded)">
          {{ 'SHARED.send' | translate }} PPI Claim
        </button>
      </div>
      <!-- Azcarate Loan -->
      <div
        [title]="!selectionExcluded.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
        <button mat-menu-item [disabled]="!selectionExcluded.selected.length"
                (click)="sendAzcarateLoanLetter(selectionExcluded)">
          {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_loan_letter' | translate }}
        </button>
      </div>
      <!-- Azcarate Revolving-->
      <div
        [title]="!selectionExcluded.selected.length ? ('CASES.single.creditors.actions.action_not_available' | translate) : ''">
        <button mat-menu-item [disabled]="!selectionExcluded.selected.length"
                (click)="sendAzcarateRevolvingLetter(selectionExcluded)">
          {{ 'SHARED.send' | translate }} {{ 'CASES.single.creditors.actions.azcarate_revolving_letter' | translate }}
        </button>
      </div>
    </mat-menu>
    <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
      <table style="width: 100%" mat-table matSort [dataSource]="excludedCreditors">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle(selectionExcluded, excludedCreditors) : null" color="primary"
                          [checked]="selectionExcluded.hasValue() && isAllSelected(selectionExcluded, excludedCreditors)"
                          [indeterminate]="selectionExcluded.hasValue() && !isAllSelected(selectionExcluded, excludedCreditors)">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                          (change)="$event ? selectionExcluded.toggle(row) : null"
                          [checked]="selectionExcluded.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="ownership">
          <th mat-header-cell *matHeaderCellDef>{{ "ADDRESS-BOOK.product_type" | translate }}</th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <ng-container
              *ngIf="element.pivot.ownership === 'applicant'">{{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}</ng-container>
            <ng-container
              *ngIf="element.pivot.ownership === 'partner'">{{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}</ng-container>
            <ng-container
              *ngIf="element.pivot.ownership === 'joint'">{{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}</ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="debt_type">
          <th mat-header-cell *matHeaderCellDef>{{ 'CASE_CREDITOR.model.debt_type.label' | translate }}</th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <ng-container>
              {{ 'CASE_CREDITOR.model.debt_type.options.' + element.pivot.debt_type | translate }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="initial_balance">
          <th mat-header-cell *matHeaderCellDef>
            <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>
          </th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            {{ element.pivot.initial_balance | number: '1.2-2' }}€
          </td>
        </ng-container>

        <ng-container matColumnDef="current_balance">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.current_balance | number: '1.2-2'}}
            €
          </td>
        </ng-container>

        <ng-container matColumnDef="amount_repaid">
          <th mat-header-cell *matHeaderCellDef>{{'CASE_CREDITOR.model.total_repaid' | translate}}</th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.amount_repaid | number: '1.2-2'}}
            €
          </td>
        </ng-container>

        <ng-container matColumnDef="monthly_payments">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.monthly_payments | number: '1.2-2'}}
            €
          </td>
        </ng-container>

        <ng-container matColumnDef="reference_number">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}"> {{element.pivot.reference_number}} </td>
        </ng-container>

        <ng-container matColumnDef="response_received">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <ng-container>
              {{ ("CASE_CREDITOR.model.response_received.options." + element.pivot.response_received) | translate }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="additional_partner">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.additional_partner" | translate }}</th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <mat-icon *ngIf="element.pivot.additional_partner" class="mat-icon-size" color="primary">check_circle
            </mat-icon>
            <mat-icon *ngIf="!element.pivot.additional_partner" class="mat-icon-size" color="warn">cancel</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="making_payments">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <mat-icon *ngIf="element.pivot.making_payments" class="mat-icon-size" color="primary">check_circle
            </mat-icon>
            <mat-icon *ngIf="!element.pivot.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
          <td mat-cell *matCellDef="let element"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
            <mat-menu #menu="matMenu">
              <button *ngIf="!element.pivot.verified" mat-menu-item
                      (click)="verifyCreditor($event, [element.pivot.id], 'unsecured_creditors')">
                {{ "CASES.details.verify-send-mandates" | translate }}
              </button>
              <button *ngIf="element.pivot.verified" mat-menu-item
                      (click)="verifyCreditor($event, [element.pivot.id], 'unsecured_creditors')">
                {{ "CASES.details.resend-mandates" | translate }}
              </button>
              <button *ngIf="element.pivot.recovery_creditor_id" mat-menu-item
                      (click)="sendMandatesRecoveryAgent($event, [element.pivot.id])">
                {{ "CASES.details.send-mandates-recovery" | translate }}
              </button>

              <!--<div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
                <button *ngIf="!element.pivot.aep_sent" mat-menu-item [disabled]="missingAepFiles"
                        (click)="sendAep($event, [element.pivot.id], 'unsecured_creditors')">
                  {{ "CASES.details.send-aep" | translate }}
                </button>
              </div>-->
              <!--<div [title]="missingAepFiles ? 'AEP files are not uploaded' : ''">
                <button *ngIf="element.pivot.aep_sent" mat-menu-item [disabled]="missingAepFiles"
                        (click)="sendAep($event, [element.pivot.id], 'unsecured_creditors')">
                  {{ "CASES.details.resend-aep" | translate }}
                </button>
              </div>-->
              
              <!-- [disabled]="!isSigned || !element.pivot.proposal_location" -->
              <button mat-menu-item
                      (click)="sendProposalAndAccord([element.pivot.id])">
                {{ "CASES.details.send-proposal-accord" | translate }}
              </button>
              <button mat-menu-item
                      [disabled]="!(element.pivot.total_repaid > element.pivot.initial_balance)"
                      (click)="sendEmail($event, element.pivot.id)">
                {{ "CASES.details.send-azcarate" | translate }}
              </button>
              <button mat-menu-item
                      (click)="changeStatus($event, [element.pivot.id], 'unsecured_creditors')">
                {{ "CASES.details.change-status" | translate }}
              </button>
              <!-- <button mat-menu-item
                      (click)="sendLastChance($event, [element.pivot.id])">
                {{ "CASES.details.send-last-chance" | translate }}
              </button> -->
              <button mat-menu-item
                      (click)="sendDeleteNumber($event, [element.pivot.id])">
                {{ "CASES.details.send-delete-number" | translate }}
              </button>
              <!-- <button mat-menu-item
                      (click)="sendAntiHarassment($event, [element.pivot.id])">
                {{ "CASES.details.send-azkarate-acoso" | translate }}
              </button> -->
              <!-- <button mat-menu-item
                      (click)="sendLoanCancellationEmail($event, [element.pivot.id])">
                {{ "CASES.details.send-azkarate-cancelar" | translate }}
              </button> -->
              <!-- <button mat-menu-item
                      *ngIf="element.pivot.ownership === 'applicant' || element.pivot.ownership === 'joint' "
                      (click)="getRightOfAccess('client', element.pivot.id, $event)">
                {{'CASES.details.get-right-of-access' | translate}} 1
              </button> -->
              <!-- <button mat-menu-item
                      *ngIf="element.pivot.ownership === 'partner' || element.pivot.ownership === 'joint' "
                      (click)="getRightOfAccess('partner', element.pivot.id, $event)">
                {{'CASES.details.get-right-of-access' | translate}} 2
              </button> -->
              <button mat-menu-item
              (click)="openLinkInNewWindow('/creditor-proposal-preview', {uuid: case.uuid, case_creditor_id:element.pivot.id, ownership: element.pivot.ownership})">
                {{'CASES.details.preview-creditor-proposal-and-accord' | translate}}
              </button>
              <a mat-menu-item class="clickable"
                 href="{{storageUrl + element.pivot.proposal_location}}">
                {{'CASES.details.download_creditor_proposal' | translate}}
              </a>
              <a mat-menu-item class="clickable"
                 href="{{storageUrl + element.pivot. porposal_location_accord}}">
                {{'CASES.details.download_proposal_accord' | translate}}
              </a>
            </mat-menu>
            <button mat-stroked-button class="ml-2"
                    (click)="onInclude(element)">{{'CASES.single.creditors.actions.include' | translate}}</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="loader">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.pivot.id) !== -1"
                         diameter="20" color="accent"></mat-spinner>
          </td>
          git
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumnsExcluded"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsExcluded;"></tr>
      </table>
      <div *ngIf="isFetching" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div *ngIf="!isFetching && excludedCreditors && !excludedCreditors.data.length" style="padding: 50px">
        <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>
      </div>
    </div>
  </ng-container>
  <!-- Public Debts -->
  <h3>{{ "CASE_CREDITOR.model.type.options.public" | translate }}</h3>
  <button mat-raised-button color="primary" [matMenuTriggerFor]="menuPublic">
    {{ "SHARED.bulk-actions" | translate }}
  </button>
  <mat-menu #menuPublic="matMenu">
    <button mat-menu-item
            [disabled]="publicDebtsVerifyDisabled"
            (click)="verifyCreditor($event, selectionPublicDebts, 'public_debts')">
      {{ "CASES.details.verify-send-mandates" | translate }}
    </button>
    <button mat-menu-item [disabled]="publicDebtsResendDisabled"
            (click)="verifyCreditor($event, selectionPublicDebts, 'public_debts')">
      {{ "CASES.details.resend-mandates" | translate }}
    </button>
    <button mat-menu-item (click)="changeStatus($event, selectionPublicDebts, 'public_debts')">
      {{ "CASES.details.change-status" | translate }}
    </button>
  </mat-menu>
  <div class="mat-elevation-z8"
       style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; bottom: 50px; margin-bottom: 2rem">
    <table style="width: 100%" mat-table matSort [dataSource]="publicDebts">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle(selectionPublicDebts, publicDebts) : null" color="primary"
                        [checked]="selectionPublicDebts.hasValue() && isAllSelected(selectionPublicDebts, publicDebts)"
                        [indeterminate]="selectionPublicDebts.hasValue() && !isAllSelected(selectionPublicDebts, publicDebts)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? selectionPublicDebts.toggle(row) : null"
                        [checked]="selectionPublicDebts.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell
            *matHeaderCellDef>{{ "CASE_CREDITOR.model.public_organization.label" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.public_organisation === 'social-security'">
            {{ "CASE_CREDITOR.model.public_organization.options.social-security" | translate }}
          </ng-container>
          <ng-container *ngIf="element.public_organisation === 'estate'">
            {{ "CASE_CREDITOR.model.public_organization.options.estate" | translate }}
          </ng-container>
          <ng-container *ngIf="element.public_organisation === 'town-hall'">
            {{ "CASE_CREDITOR.model.public_organization.options.town-hall" | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="ownership">
        <th mat-header-cell *matHeaderCellDef>{{ "ADDRESS-BOOK.product_type" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.ownership === 'applicant'">
            {{ "CASE_CREDITOR.model.ownership.options.applicant_joint" | translate }}
          </ng-container>
          <ng-container *ngIf="element.ownership === 'partner'">
            {{ "CASE_CREDITOR.model.ownership.options.partner" | translate }}
          </ng-container>
          <ng-container *ngIf="element.ownership === 'joint'">
            {{ "CASE_CREDITOR.model.ownership.options.joint" | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="debt_type">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.debt_type.label" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.debt_type === 'tax'">
            {{ "CASE_CREDITOR.model.debt_type.options.tax" | translate }}
          </ng-container>
          <ng-container *ngIf="element.debt_type === 'capital-gain'">
            {{ "CASE_CREDITOR.model.debt_type.options.capital-gain" | translate }}
          </ng-container>
          <ng-container *ngIf="element.debt_type === 'penalty-fee'">
            {{ "CASE_CREDITOR.model.debt_type.options.penalty-fee" | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="initial_balance">
        <th mat-header-cell *matHeaderCellDef>
          <span>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</span>
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.initial_balance | number: '1.2-2'}}€
        </td>
      </ng-container>

      <ng-container matColumnDef="current_balance">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.current_balance | number: '1.2-2'}}€</td>
      </ng-container>

      <ng-container matColumnDef="monthly_payments">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.monthly_payments | number: '1.2-2'}}€</td>
      </ng-container>

      <ng-container matColumnDef="reference_number">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.reference_number}} </td>
      </ng-container>

      <ng-container matColumnDef="response_received">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container>
            {{ ("CASE_CREDITOR.model.response_received.options." + element.response_received) | translate }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="additional_partner">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.interested_third_party" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.interested_third_party" class="mat-icon-size" color="primary">check_circle</mat-icon>
          <mat-icon *ngIf="!element.interested_third_party" class="mat-icon-size" color="warn">cancel</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="making_payments">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.making_payments" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.making_payments" class="mat-icon-size" color="primary">check_circle</mat-icon>
          <mat-icon *ngIf="!element.making_payments" class="mat-icon-size" color="warn">cancel</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button>
          <mat-menu #menu="matMenu" yPosition="above">
            <button *ngIf="!element.verified" mat-menu-item
                    (click)="verifyCreditor($event, [element.id], 'public_debts')">
              {{ "CASES.details.verify-send-mandates" | translate }}
            </button>
            <button *ngIf="element.verified" mat-menu-item
                    (click)="verifyCreditor($event, [element.id], 'public_debts')">
              {{ "CASES.details.resend-mandates" | translate }}
            </button>
            <button mat-menu-item
                    (click)="changeStatus($event, [element.id], 'public_debts')">
              {{ "CASES.details.change-status" | translate }}
            </button>
            <!-- <button mat-menu-item
                    *ngIf="element.ownership === 'applicant' || element.ownership === 'joint' "
                    (click)="getRightOfAccess('client', element.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 1
            </button> -->
            <!-- <button mat-menu-item
                    *ngIf="element.ownership === 'partner' || element.ownership === 'joint' "
                    (click)="getRightOfAccess('partner', element.id, $event)">
              {{'CASES.details.get-right-of-access' | translate}} 2
            </button> -->
          </mat-menu>
        </td>
      </ng-container>

      <ng-container matColumnDef="loader">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px">
          <mat-spinner
            *ngIf="isPublicLoading && isPublicLoadingId.length && isPublicLoadingId.indexOf(element.id) !== -1"
            diameter="20" color="accent"></mat-spinner>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsPD"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsPD;"></tr>
    </table>
    <div *ngIf="isFetchingPublicDebt" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!isFetchingPublicDebt && publicDebts && !publicDebts.data.length" style="padding: 50px">
      <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>
    </div>
  </div>
</div>

<div class="loan-creditors-section" *ngIf="useCreditorLoanFields">
  <h3>{{ "CASES.editor.creditors.heading" | translate }}</h3>
  <button mat-raised-button color="primary" [matMenuTriggerFor]="menuUnsecured">
    {{ "SHARED.bulk-actions" | translate }}
  </button>
  <mat-menu #menuUnsecured="matMenu">
    <button mat-menu-item [disabled]="selectionLoanCreditors.selected.length === 0"
            (click)="changeStatus($event, selectionUnsecured, 'unsecured_creditors')">
            {{'CASES.list.bulk_actions.change_creditor_status_product' | translate}}
    </button>
  </mat-menu>
  <div *ngIf="case" class="mat-elevation-z8"
       style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none; margin-bottom: 50px">
    <table style="width: 100%; display: table;overflow-x: auto;" mat-table matSort [dataSource]="loanCreditors"
           [ngClass]="'table-responsive'">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="p-r-10">
          <mat-checkbox (change)="$event ? masterToggle(selectionLoanCreditors, loanCreditors) : null" color="primary"
                        [checked]="selectionLoanCreditors.hasValue() && isAllSelected(selectionLoanCreditors, loanCreditors)"
                        [indeterminate]="selectionLoanCreditors.hasValue() && !isAllSelected(selectionLoanCreditors, loanCreditors)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                        (change)="$event ? selectionLoanCreditors.toggle(row) : null"
                        [checked]="selectionLoanCreditors.isSelected(row)">
          </mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
      </ng-container>
      <form *ngIf="loanCreditorsForm" [formGroup]="loanCreditorsForm">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.creditor-name" | translate }}</th>
            <td mat-cell *matCellDef="let element; let i =  index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
              <app-input type="select" formControlName="creditor_id" appearance="standard" *ngIf="isEditing && currentRow === i"
                        [label]="'CASES.single.general.status_editor.product' | translate"
                        [selectOptions]="allCreditors" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                        [showClear]="false" [fullWidth]="false" [showLabel]="false" [searchable]="true">
              </app-input>
              <span *ngIf="!isEditing || currentRow != i">
                {{getUnsecuredCreditorName(element.pivot.creditor_id)}}
                <span *ngIf="element.pivot.current_creditor_id">
                  <!-- mat info icon -->
                  <mat-icon style="height: 18px; width: 18px; font-size: 16px; color: #04abe4 !important;" (click)="showInfo(element)">info</mat-icon>
                </span>
              </span>
            </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="current_name">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.relations.current_creditor" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i =  index"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <app-input type="select" formControlName="current_creditor_id" appearance="standard" *ngIf="isEditing && currentRow === i"
                      [label]="'CASES.single.general.status_editor.product' | translate"
                      [selectOptions]="allCreditors" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                      [showClear]="true" [fullWidth]="false" [showLabel]="false" [searchable]="true">
            </app-input>
            <p *ngIf="!isEditing || currentRow != i">{{getUnsecuredCreditorName(element.pivot.current_creditor_id)}}</p>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="last_paid">
          <th mat-header-cell *matHeaderCellDef>Last Paid</th>
          <td mat-cell *matCellDef="let element; let i =  index"
          [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            {{ element.pivot.last_paid | toDateObject | date:'dd/MM/yy' }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef
              class="p-r-10">{{ "ADDRESS-BOOK.product_type" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i =  index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
              <app-input type="select" formControlName="product_id" appearance="standard" *ngIf="isEditing && currentRow === i"
                        [label]="'CASES.single.general.status_editor.product' | translate"
                        [selectOptions]="products" [selectLabel]="['name']" [selectValue]="'id'" class="full-width"
                        [showClear]="false" [fullWidth]="false" [showLabel]="false" [searchable]="true">
              </app-input>
              <span *ngIf="!isEditing || currentRow != i">{{getProductName(element.pivot.product_id)}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="debt_amount">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.debt_amount" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i =  index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <app-input *ngIf="isEditing && currentRow === i" type="text" formControlName="debt_amount"
                       appearance="standard" fullWidth="false"
                       [label]="'Debt Amount'">
            </app-input>
            <span *ngIf="!isEditing || currentRow != i">{{element.pivot.debt_amount | number: '1.2-2'}}€</span>
          </td>
          <td mat-footer-cell *matFooterCellDef class="ms-1"> {{getTotal('debt_amount')  | number: '1.2-2'}} €</td>
        </ng-container>

        <ng-container matColumnDef="negotiated_amount">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.negotiated_amount" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <app-input *ngIf="isEditing && currentRow === i" type="text" formControlName="negotiated_amount"
                       appearance="standard" fullWidth="false"
                       [label]="'Negotiated Amount'">
            </app-input>
            <span *ngIf="!isEditing || currentRow != i">{{element.pivot.negotiated_amount | number: '1.2-2'}} <span *ngIf="element.pivot.negotiated_amount">€</span></span>
          </td>
          <td mat-footer-cell *matFooterCellDef> {{getTotal('negotiated_amount')  | number: '1.2-2'}} €</td>
        </ng-container>

        <ng-container matColumnDef="total_reduction">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.total_reduction" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <span *ngIf="(element.pivot.debt_amount > 0) && (element.pivot.negotiated_amount > 0)">{{(element.pivot.debt_amount - element.pivot.negotiated_amount) | number: '1.2-2'}}€</span>
          </td>
          <td mat-footer-cell *matFooterCellDef> {{getTotal('total_reduction')  | number: '1.2-2'}} €</td>
        </ng-container>

        <ng-container matColumnDef="percent_reduction">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.percent_reduction" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
            <span *ngIf="(element.pivot.debt_amount > 0) && (element.pivot.negotiated_amount > 0) && (element.pivot.product_id === 14)">{{+(+(+(element.pivot.debt_amount - element.pivot.negotiated_amount) / element.pivot.debt_amount) *
              100) | number: '1.2-2'}} <span *ngIf="(element.pivot.debt_amount > 0) && (element.pivot.negotiated_amount > 0)">%</span></span>
          </td>
          <td mat-footer-cell *matFooterCellDef> <span>{{getTotal('percent_reduction')  | number: '1.2-2'}} %</span></td>
        </ng-container>

        <!-- percentage accepted -->
        <ng-container matColumnDef="percent_accepted">
          <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.percent_accepted" | translate }}</th>
          <td mat-cell *matCellDef="let element; let i = index"
              [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
              <span *ngIf="(element.pivot.response_received === 'dm_accepted')">{{ getDebtPercentage(element) | number: '1.2-2'}} <span *ngIf="getDebtPercentage(element)">%</span></span>
          </td>
          <td mat-footer-cell *matFooterCellDef="let element; let i = index">{{ getResponseReceivedStatus(unsecuredCreditors.data) }}%</td>
        </ng-container>
      </form>

      <ng-container matColumnDef="response_received">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <span>{{ getTranslatedCaseCreditorStatus(element) }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <!--<ng-container matColumnDef="response_received">
        <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.response_received.label" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container>
            {{ ("CASE_CREDITOR.model.response_received.options." + element.pivot.response_received) | translate }}
          </ng-container>
        </td>
      </ng-container>-->

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">

          <button mat-icon-button class="ml-1" [matMenuTriggerFor]="menu" style="color: 0a2540;">
            <mat-icon class="pr-1" style=" vertical-align: baseline !important; color: 0a2540;">
              settings
            </mat-icon>
          </button>
            <!-- <button mat-stroked-button [matMenuTriggerFor]="menu">{{ "SHARED.actions" | translate }}</button> -->
          <mat-menu #menu="matMenu">
            <button mat-menu-item
                    (click)="viewQuickWin(element, case)">
              {{ "CREDITORS.quick-win.title" | translate }}
            </button>
            <!-- <button mat-menu-item [disabled]="element.pivot.response_received === 'refinanced'"
                    (click)="markAsRefinanced(element)">
              {{ 'CASE_CREDITOR.refinanced.button_label' | translate }}
            </button> -->
            <button mat-menu-item
                    (click)="openPaymentPlanGenerationModal(element)">
              {{ "CASES.single.payments.actions.create_additional_payment_plan" | translate }}
            </button>
            <button *ngIf="!element.pivot.verified" mat-menu-item [disabled]="case.contracts.length === 0"
                    (click)="verifyCreditor($event, [element.pivot.id], 'unsecured_creditors', case)">
              {{ "CASES.details.verify-send-mandates" | translate }}
            </button>
            <button *ngIf="element.pivot.verified" mat-menu-item
                    (click)="verifyCreditor($event, [element.pivot.id], 'unsecured_creditors', case)">
              {{ "CASES.details.resend-mandates" | translate }}
            </button>
            <button *ngIf="element.pivot.recovery_creditor_id" mat-menu-item
                    (click)="sendMandatesRecoveryAgent($event, [element.pivot.id])">
              {{ "CASES.details.send-mandates-recovery" | translate }}
            </button>
            <button mat-menu-item
                    (click)="sendProposalAndAccord([element.pivot.id])">
              {{ "CASES.details.send-proposal-accord" | translate }}
            </button>
            <!-- <button mat-menu-item
            (click)="openLinkInNewWindow('/creditor-proposal-preview', {uuid: case.uuid, case_creditor_id:element.pivot.id, ownership: element.pivot.ownership})">
              {{'CASES.details.preview-creditor-proposal-and-accord' | translate}}
            </button> -->
            <!-- <a mat-menu-item class="clickable" target="_blank"
               href="{{storageUrl + element.pivot.proposal_location}}">
              {{'CASES.details.download_creditor_proposal' | translate}}
            </a>
            <a mat-menu-item class="clickable" target="_blank"
               href="{{storageUrl + element.pivot.proposal_location_accord}}">
              {{'CASES.details.download_proposal_accord' | translate}}
            </a> -->
          </mat-menu>
          <button mat-icon-button class="ml-21 edit-button" [id]="i" (click)="toggleLoanEditMode(i, element)" style="color: 0a2540;">
            <mat-icon class="pr-1" style=" vertical-align: baseline !important; color: 0a2540;">
              {{ !isEditing || currentRow != i ? 'edit' : 'save' }}
            </mat-icon>
          </button>
          <mat-icon class="pr-1" style="color: #0a2540;" (click)="creditorDetails(element, $event)">info</mat-icon>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="loader">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="min-width: 20px; padding: 0px"
            [ngStyle]="{'background-color': returnLenderType(element.lender_type)}">
          <mat-spinner *ngIf="isLoading && isLoadingIds.length && isLoadingIds.indexOf(element.pivot.id) !== -1"
                       diameter="20" color="accent"></mat-spinner>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsLoanCreditors"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsLoanCreditors;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumnsLoanCreditors"></tr>
    </table>
    <div class="row mt-3" *ngIf="!allCreditorsHaveProposalEmails">
      <div class="alert alert-danger ml-4" role="alert">
        <h4 class="alert-heading">{{ "CASES.single.draft.creditor-prop-email-not-set" | translate }}</h4>
        <p>{{ "CASES.single.draft.creditor-prop-email-not-set-action" | translate }}</p>
        <hr>
        <div>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{ "CASES.single.creditor-name" | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let creditor of creditorWithoutProposalEmails">
              <td><a href="creditor/{{creditor.id}}/edit" target="_blank">{{ creditor.name }}</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="isFetching" class="text-center" style="padding: 50px">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!isFetching && unsecuredCreditors && !unsecuredCreditors.data.length" style="padding: 50px">
      <h3 class="text-center">{{ "PAYMENTS.no-record" | translate }}</h3>
    </div>
  </div>
</div>
