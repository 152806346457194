<div class="container">
  <div class="card mb-3">
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <div class="card-title">
          <h2>{{ 'CASES.editor.assets.heading' | translate }}</h2>
        </div>
      </div>
    </div>
    <div class="card-body" *ngIf="isLoading || serverResponse">
      <div class="row">
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <div *ngIf="serverResponse" class="col-12">
          <app-server-response [response]="serverResponse"></app-server-response>
        </div>
      </div>
    </div>
  </div>
  <form *ngIf="form2" [formGroup]="form2">
    <div class="row">
      <div class="col-12">
        <div class="card shadow">
          <div class="card-header">
            <div class="card-title">
              <h3>{{ totalNetBankAccounts | number: '1.2-2' }}€</h3>
              <span class="small">
                {{ 'CASES.financial_overview.table.assets' | translate }}
                
              </span>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <app-input type="select" formControlName="prop_type" appearance="outline"
                      [label]="'CASES.editor.assets.asset_form.asset_type' | translate" class="full-width"
                      [selectOptions]="propTypes" [selectLabel]="['label']" [selectValue]="'value'"
                      [showClear]="false" [fullWidth]="true" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-12">
                <app-input type="number" formControlName="prop_value" appearance="outline"
                      [label]="'CASES.editor.assets.asset_form.asset_value' | translate" class="full-width" [showClear]="false" [fullWidth]="true" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-12">
                <app-input type="number" formControlName="prop_outstanding_finance" appearance="outline"
                      [label]="'CASES.editor.assets.asset_form.asset_finance' | translate" class="full-width" [showClear]="false" [fullWidth]="true" [extraLabel]="true">
                </app-input>
              </div>
              <div class="col-12">
                <app-input type="number" readonly formControlName="prop_net_equity" appearance="outline"
                      [label]="'CASES.editor.assets.asset_form.asset_equity' | translate" class="full-width" [fullWidth]="true" [extraLabel]="true">
                </app-input>
              </div>
              <!-- <div class="col-12">
                <app-input type="checkbox" formControlName="prop_liquidable" appearance="outline" [classList]="['pull-left']"
                      label="Property Liquidable" class="full-width" [showClear]="false" [fullWidth]="true" [extraLabel]="true">
                </app-input>
              </div> -->
              <div class="col-12">
                <button type="button" mat-raised-button color="primary" (click)="submitForm2(form2)">
                  {{ 'SHARED.save' | translate }}
                </button>
              </div>
            </div>
            <div class="row mt-5">
              <!-- iterate over the case assets -->
              <div class="col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{'CASES.editor.assets.asset_form.asset_type' | translate}}</th>
                      <th>{{'CASES.editor.assets.asset_form.asset_value' | translate}}</th>
                      <th>{{'CASES.editor.assets.asset_form.asset_finance' | translate}}</th>
                      <th>{{'CASES.editor.assets.asset_form.asset_equity' | translate}}</th>
                      <!-- <th>Property Liquidable</th> -->
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let formGroup of assets">
                      <td>{{ formGroup.type }}</td>
                      <td>{{ formGroup.balance ?? 0 | number: '1.2-2' }}€</td>
                      <td>{{ formGroup.outstanding_finance ?? 0 | number: '1.2-2' }}€</td>
                      <td>{{ formGroup.net_equity ?? 0 | number: '1.2-2' }}€</td>
                      <!-- <td>{{ formGroup.liquidable }}</td> -->
                      <td><button type="button" mat-icon-button color="warn" (click)="destroyAsset(formGroup.id)">
                        <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                      </button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
