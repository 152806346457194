<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <h1>
          <span *ngIf="editorType === 'create'">{{ 'DISTRIBUTION.batch-editor.heading_create' | translate }}</span>
          <span *ngIf="editorType === 'edit'">{{ 'DISTRIBUTION.batch-editor.heading_create' | translate }}</span>
        </h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
      </div>
      <!-- Batch Editor -->
      <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm(form)">
        <div class="row">
          <!-- Left Column -->
          <div class="col-md-6">
            <!-- Name -->
            <app-input type="select" formControlName="name" appearance="standard" fullWidth="true"
                       [label]="'DISTRIBUTION.batch.editor.form.name' | translate"
                       [searchable]="true" [selectOptions]="creditors" [selectLabel]="'name'" [selectValue]="'name'">
            </app-input>
      
            <!-- Status -->
            <app-input type="select" formControlName="status" appearance="standard" fullWidth="true"
                       [label]="'DISTRIBUTION.batch.editor.form.status' | translate"
                       [selectOptions]="batchStatusOptions" [selectLabel]="'label'" [selectValue]="'value'">
            </app-input>
      
            <!-- Total funded amount -->
            <!--<app-input *ngIf="form.get('status').value === 'funded'" type="number" formControlName="total_funded_amount"
                       appearance="standard" fullWidth="true"
                       [label]="'DISTRIBUTION.batch.editor.form.total_funded_amount' | translate">
            </app-input>-->
      
            <!-- Products -->
            <!--<app-input type="select" formControlName="product_ids" appearance="standard" multiple
                       [fullWidth]="true" [label]="'PRODUCT.model_name.plural' | translate"
                       [selectOptions]="products" [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>-->
      
            <!-- Payment Processor -->
            <!--<app-input type="select" formControlName="payment_processor_id" appearance="standard"
                       [fullWidth]="true" [label]="'DISTRIBUTION.provider.model.payment_processor_id' | translate"
                       [selectOptions]="paymentProcessors" [selectLabel]="'name'" [selectValue]="'id'">
            </app-input>-->
      
            <!-- Max Installments -->
            <app-input type="number" formControlName="max_installments" appearance="standard"
                       fullWidth="true"
                       [label]="'DISTRIBUTION.provider.model.max_installments' | translate">
            </app-input>
      
            <!-- Client Account Iban -->
            <app-input type="text" formControlName="client_account_iban" appearance="standard"
                       fullWidth="true"
                       [label]="'DISTRIBUTION.provider.model.client_account_iban' | translate">
            </app-input>
      
            <!-- Payment Iban -->
            <app-input type="text" formControlName="payment_iban" appearance="standard" fullWidth="true"
                       [label]="'DISTRIBUTION.provider.model.payment_iban' | translate">
            </app-input>

            
          </div>
      
          <!-- Right Column -->
          <div class="col-md-6">
            <!-- Provider -->
            <!--<app-input type="select" formControlName="distribution_provider_id" appearance="standard" fullWidth="true"
                       [label]="'DISTRIBUTION.batch.editor.form.distribution_provider_id' | translate"
                       [selectOptions]="distributionProviders" [selectLabel]="'company_name'" [selectValue]="'id'">
            </app-input>-->
      
            <!-- Users -->
            <!--<app-input type="select" formControlName="user_ids" appearance="standard" multiple
                       [fullWidth]="true" [label]="'USERS.model_name.plural' | translate"
                       [selectOptions]="distributionUsers" [selectLabel]="['first_name', 'last_name']"
                       [selectValue]="'id'">
            </app-input>-->
      
            <!-- Min Monthly Payment -->
            <!--<app-input type="number" formControlName="min_monthly_payment" appearance="standard"
                       fullWidth="true"
                       [label]="'DISTRIBUTION.provider.model.min_monthly_payment' | translate">
            </app-input>-->
      
            <!-- Min Contract Value -->
            <!--<app-input type="number" formControlName="min_contract_value" appearance="standard"
                       fullWidth="true"
                       [label]="'DISTRIBUTION.provider.model.min_contract_value' | translate">
            </app-input>-->
      
            <!-- Funding Amount Percentage -->
            <!--<app-input type="number" formControlName="funding_amount_percentage" appearance="standard"
                       fullWidth="true"
                       [label]="'Acceptance Percentage'">
            </app-input>-->
      
            <!-- Contact Person -->
            <app-input type="text" formControlName="contact_person" appearance="standard" fullWidth="true"
                       [label]="'DISTRIBUTION.provider.model.contact_person' | translate">
            </app-input>
      
            <!-- Address -->
            <app-input type="text" formControlName="address" appearance="standard" fullWidth="true"
                       [label]="'DISTRIBUTION.provider.model.address' | translate">
            </app-input>
      
            <!-- Email -->
            <app-input type="email" formControlName="email" appearance="standard" fullWidth="true"
                       label="Remittance Email">
            </app-input>
      
            <!-- Phone -->
            <app-input type="number" formControlName="phone" appearance="standard" fullWidth="true"
                       [label]="'DISTRIBUTION.provider.model.phone' | translate">
            </app-input>

            <!-- Contact Email -->
            <app-input type="email" formControlName="contact_email" appearance="standard" fullWidth="true"
                       [label]="'DISTRIBUTION.batch.editor.form.contact_email' | translate">
            </app-input>
          </div>
        </div>
      
        <!-- Submit -->
        <div class="row mt-5 mb-2">
          <div class="col-12 text-right">
            <app-spinner-btn type="submit" [loading]="isSubmitting"
                             [name]="(editorType === 'create' ? 'SHARED.save' :  'SHARED.update') | translate">
            </app-spinner-btn>
          </div>
        </div>
      </form>      
    </mat-card-content>
  </mat-card>
</div>
