<div class="d-flex justify-content-between align-items-center pb-2 flex-wrap">
  <div class="row mt-3">
    <div class="col" style="min-width: 300px">
      <div>
        <span>{{ 'CASES.single.payments.balance_overview.filtered_balance.progress' | translate }}:</span>
        <span class="font-weight-bold">{{+(filteredInstallmentsBalance.totalPaid) | number: '1.2-2'}}
          € / {{+(filteredInstallmentsBalance.totalToPay) | number: '1.2-2'}} € </span>
      </div>
      <div>
        <span>{{ 'CASES.single.payments.balance_overview.filtered_balance.balance' | translate}}:</span>
        <span class="font-weight-bold">{{+(filteredInstallmentsBalance.totalToPay -
        filteredInstallmentsBalance.totalPaid) | number: '1.2-2'}} €</span>
      </div>
      <!--<div class="progress total-progress" style="max-width: 300px">-->
      <!--  <div class="progress-bar progress-bar-striped" role="progressbar"-->
      <!--       [style]="{width: getPercent(filteredInstallmentsBalance.totalPaid, filteredInstallmentsBalance.totalToPay) + '%'}"-->
      <!--       [attr.aria-valuenow]="getPercent(filteredInstallmentsBalance.totalPaid, filteredInstallmentsBalance.totalToPay)"-->
      <!--       [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">-->
      <!--    {{ getPercent(filteredInstallmentsBalance.totalPaid, filteredInstallmentsBalance.totalToPay) }}%-->
      <!--  </div>-->
      <!--</div>-->
    </div>
    <div *ngIf="componentType === 'admin'" class="col" style="min-width: 500px">
      <div class="row">

        <div class="col" *ngIf="isPhaseTwo(); else lsoviewbalance">
          <span>Plan balance:</span>
          <span class="font-weight-bold">
            {{ (debtPlanInstallmentsBalance.totalToPay - debtPlanInstallmentsBalance.totalPaid) | number: '1.2-2' }} €
          </span>
        </div>

        <ng-template #lsoviewbalance>
          <div class="col" >
            <span>{{ 'CASES.single.payments.balance_overview.debt_plan_balance' |translate }}:</span>
            <span class="font-weight-bold">
              {{ (debtPlanInstallmentsBalance.totalToPay - debtPlanInstallmentsBalance.totalPaid) | number: '1.2-2' }} €
            </span>
          </div>
        </ng-template>
        
        <div class="col" *ngIf="isPhaseTwo(); else lsoviewpbalance">
          <div class="progress total-progress" style="max-width: 300px">
            <div class="progress-bar progress-bar-striped" role="progressbar"
                 [style]="{width: getPercent((debtPlanInstallmentsBalance.totalPaid),templateData[0].DMTotalDebtAmount) + '%'}"
                 [attr.aria-valuenow]="getPercent((debtPlanInstallmentsBalance.totalPaid),templateData[0].DMTotalDebtAmount)"
                 [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
                  {{ getPercent(
                    (debtPlanInstallmentsBalance.totalPaid),
                    templateData[0].DMTotalDebtAmount
                  ) }}%
            </div>
          </div>
        </div>
        
        <ng-template #lsoviewpbalance>
          <div class="col">
            <div class="progress total-progress" style="max-width: 300px">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                   [style]="{width: getPercent(debtPlanInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay) + '%'}"
                   [attr.aria-valuenow]="getPercent(debtPlanInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay)"
                   [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
                    {{ getPercent(debtPlanInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay) }}%
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="row mt-2">
        
        <div class="col" *ngIf="isPhaseTwo(); else lsoadditionaplansbalance">
          <span>unifye fees:</span>
          <span class="font-weight-bold">
            {{ case.expense.distribution_fee * templateData[0].DMMonthsFeeFullyPaid  + ' €' ?? 'N/A' }}
          </span>
        </div>

        <ng-template #lsoadditionaplansbalance>
          <div class="col">
            <span>{{ 'CASES.single.payments.balance_overview.additional_plans_balance' | translate }}:</span>
            <span class="font-weight-bold">
              {{ (additionalPlansInstallmentsBalance.totalToPay -
               additionalPlansInstallmentsBalance.totalPaid) | number: '1.2-2' }} €
            </span>
          </div>
        </ng-template>

        <div class="col" *ngIf="isPhaseTwo(); else lsoadditionaplansbalancepbar">
          <div class="progress total-progress" style="max-width: 300px">
            <div class="progress-bar progress-bar-striped" role="progressbar"
                 [style]="{width: getPercent(case.expense.distribution_fee*templateData[0].DMMonthsFeeFullyPaid,case.expense.distribution_fee*case.terms.length) + '%'}"
                 [attr.aria-valuenow]="getPercent(case.expense.distribution_fee*templateData[0].DMMonthsFeeFullyPaid,case.expense.distribution_fee*case.terms.length)"
                 [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
                  {{ getPercent(
                      case.expense.distribution_fee*templateData[0].DMMonthsFeeFullyPaid,
                      case.expense.distribution_fee*case.terms.length
                  ) }}%
            </div>
          </div>
        </div>

        <ng-template #lsoadditionaplansbalancepbar>
          <div class="col">
            <div class="progress total-progress" style="max-width: 300px">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                  [style]="{width: getPercent(additionalPlansInstallmentsBalance.totalPaid, additionalPlansInstallmentsBalance.totalToPay) + '%'}"
                  [attr.aria-valuenow]="getPercent(additionalPlansInstallmentsBalance.totalPaid, additionalPlansInstallmentsBalance.totalToPay)"
                  [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
                {{ getPercent(
                  additionalPlansInstallmentsBalance.totalPaid,
                  additionalPlansInstallmentsBalance.totalToPay
                  ) }}%
              </div>
            </div>
          </div>
       </ng-template>
      </div>
      <div class="row mt-2">
        
        <div class="col" *ngIf="isPhaseTwo(); else lsooverviewtotalbalance">
          <span>months remaining:</span>
          <span class="font-weight-bold">
          {{ templateData[0].DmTotalMonthsPending }} / {{ case.terms.length }}
        </span>
        </div>

        <ng-template #lsooverviewtotalbalance>
          <div class="col">
            <span>{{ 'CASES.single.payments.balance_overview.total_balance' | translate }}:</span>
            <span class="font-weight-bold">
            {{ ((debtPlanInstallmentsBalance.totalToPay + additionalPlansInstallmentsBalance.totalToPay) -
              (debtPlanInstallmentsBalance.totalPaid +
                additionalPlansInstallmentsBalance.totalPaid)) | number: '1.2-2' }} €
          </span>
          </div>
        </ng-template>
        

        <div class="col" *ngIf="isPhaseTwo(); else lsooverviewtotalbalancepbar">
          <div class="progress total-progress" style="max-width: 300px">
            <div class="progress-bar progress-bar-striped" role="progressbar"
                 [style]="{width: getPercent(templateData[0].DMMonthsFeeFullyPaid,case.terms.length) + '%'}"
                 [attr.aria-valuenow]="getPercent(templateData[0].DMMonthsFeeFullyPaid,case.terms.length)"
                 [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
                  {{ getPercent(
                    templateData[0].DMMonthsFeeFullyPaid,
                    case.terms.length) 
                  }} %
            </div>
          </div>
        </div>

        <ng-template #lsooverviewtotalbalancepbar>
          <div class="col">
            <div class="progress total-progress" style="max-width: 300px">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                   [style]="{width: getPercent(debtPlanInstallmentsBalance.totalPaid +
              additionalPlansInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay +
              additionalPlansInstallmentsBalance.totalToPay) + '%'}"
                   [attr.aria-valuenow]="getPercent(debtPlanInstallmentsBalance.totalPaid +
              additionalPlansInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay +
              additionalPlansInstallmentsBalance.totalToPay)"
                   [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">
                {{ getPercent(debtPlanInstallmentsBalance.totalPaid +
                additionalPlansInstallmentsBalance.totalPaid, debtPlanInstallmentsBalance.totalToPay +
                additionalPlansInstallmentsBalance.totalToPay) }}%
              </div>
            </div>
          </div>
        </ng-template>
        
      </div>
    </div>
  </div>
  <div class="text-right payment-action-buttons">
    <div *ngIf="componentType === 'admin'" class="admin-payment-actions">
      <button (click)="openPaymentPlanGenerationModal()" mat-raised-button color="primary" class="mr-2">
        {{ 'CASES.single.payments.actions.create_additional_payment_plan' | translate }}
      </button>
      <div class="form-group d-inline-block mr-2">
        <select name="payment_method" id="payment-method" class="form-control" (change)="changePaymentMethod($event)">
          <option *ngFor="let method of this.paymentMethods" value="{{method.slug}}"
                  style="font-family: 'Roboto', 'Helvetica', 'Neue', 'sans-serif'"
                  [selected]="paymentMethodIsSelected(method.id)">{{ method.name }}</option>
        </select>
      </div>
      <ng-container *ngIf="upcomingInstallments">
        <button [matMenuTriggerFor]="requestPaymentMenu" mat-raised-button color="primary" class="mr-2"
                [disabled]="upcomingInstallments?.length === 0 ||
         (installmentsPlanTypeControl.value.length > 1 && installmentsPlanTypeControl.value.includes('debt_plan')) || case.locked_no_products == true">
          {{ "CASES.single.payments.actions.request_payment.label" | translate }}
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #requestPaymentMenu="matMenu">
          <button mat-menu-item (click)="requestPaymentDialog('card', 'oppwa')">
            <span>{{ "CASES.single.payments.actions.request_payment.options.oppwa" | translate }}</span>
          </button>
          <!--      <button mat-menu-item *ngIf="appEnv === 'local' || appEnv === 'staging' ||-->
          <!--       (this.authUser.role_id === 5 && this.allowedMymoidIds.includes(this.authUser.id))"-->
          <!--              (click)="requestPaymentDialog('card', 'mymoid')">-->
          <!--        <span>{{ "CASES.single.payments.actions.request_payment.options.mymoid" | translate }}</span>-->
          <!--      </button>-->
        </mat-menu>
      </ng-container>
      <button (click)="rebuildInstallmentPlanModal()" mat-raised-button color="primary"
              class="mr-2" [disabled]="!onlyDebtPlanSelected">
        {{ "CASES.single.rebuild-plan" | translate }}
      </button>
      <button *ngIf="upcomingInstallments" (click)="openDialog(false)" mat-raised-button color="primary"
              class="mr-2" [disabled]="upcomingInstallments?.length === 0 ||
         (installmentsPlanTypeControl.value.length > 1 && installmentsPlanTypeControl.value.includes('debt_plan'))">
        {{ "CASES.single.record-payment" | translate }}
      </button>
      <button *ngIf="upcomingInstallments" (click)="openDialog(true)" mat-raised-button color="primary"
              class="mr-2" [disabled]="upcomingInstallments?.length === 0 ||
         (installmentsPlanTypeControl.value.length > 1 && installmentsPlanTypeControl.value.includes('debt_plan'))">
        {{ "CASES.single.relocate-payment" | translate }}
      </button>
      <div class="d-inline-block" matTooltip="No upcoming payment available"
           [matTooltipDisabled]="case.terms.length > 0">
        <button *ngIf="upcomingInstallments" [disabled]="upcomingInstallments?.length === 0 ||
         (installmentsPlanTypeControl.value.length > 1 && installmentsPlanTypeControl.value.includes('debt_plan')) || case.locked_no_products == true"
                (click)="openCardModal()" mat-raised-button color="primary">
          {{ "CASES.single.take-payment" | translate }}
        </button>
      </div>
      <button [matMenuTriggerFor]="remittanceRequestMenu" mat-raised-button color="primary" class="ml-2">
        {{ "CASES.single.remittance-request-text" | translate }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #remittanceRequestMenu>
        <button mat-menu-item (click)="generateRemittanceRequest()"><span>{{"SHARED.generate" | translate}}</span>
        </button>
      </mat-menu>
      <button [matMenuTriggerFor]="clientInstallmentMenu" mat-raised-button color="primary" class="ml-2">
        {{'CASES.single.client-installment-list-btn' | translate}}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #clientInstallmentMenu>
        <button mat-menu-item (click)="downloadClientInstallment()"><span>{{'CASES.single.download' | translate}}</span>
        </button>
        <a mat-menu-item [routerLink]="['/cases', case?.id, 'payments', case.uuid, 'preview-client-installment-list']"
           target="_blank">
          <span>{{'CASES.single.client-installment-list-preview' | translate}}</span></a>
        <button mat-menu-item (click)="sendPaymentPlan()">
          <span>{{'CASES.single.client-installment-list-send' | translate}}</span></button>
      </mat-menu>
    </div>
  </div>
</div>

<div class="mb-4" style="box-shadow: none">
  <div class="card-header" style="border: 1px solid #e4e4e4; position: relative">
    <app-input type="select" [formControl]="installmentsPlanTypeControl" appearance="standard" multiple
               fullWidth="false" [label]="'PAYMENTS.list.filters.payment_plan_type.label' | translate"
               [selectOptions]="paymentPlanTypes" [selectLabel]="'name'" [selectValue]="'slug'" [showClear]="false"
               style="position: absolute; left: 10px; top: 0" [hidden]="componentType === 'distribution'">
    </app-input>
    <h2 class="text-center m-0">{{ "CASES.single.upcoming-payments" | translate }}</h2>
    <div style="position: absolute; right: 10px; top: 20px">
      <mat-slide-toggle color="primary" (change)="this.showHideUnpaid($event)">
        {{"PAYMENTS.show-only-unpaid" | translate}}
      </mat-slide-toggle>
    </div>
  </div>
  <!--Bulk actions -->
  <button mat-raised-button color="primary" class="mb-2" [disabled]="selectionPaymentsDisabled"
          [matMenuTriggerFor]="menuPayment" [ngClass]="{'mt-4': selectionPaymentsDisabled}">
    {{ "SHARED.bulk-actions" | translate }}
  </button>
  <mat-menu #menuPayment="matMenu">
    <!--Delete -->
    <div [title]="selectionPaymentsDisabled ? 'One or more selected payments does not have this action available' : ''">
      <a mat-menu-item class="dropdown-item" href="#" [disabled]="selectionPaymentsDisabled"
         (click)="deletePayment($event, selectionPayments)">
        Delete
      </a>
    </div>
  </mat-menu>

  <div class="card-body p-0">

    <div class="mat-elevation-z8" style="border: 1px solid rgba(0, 0, 0, 0.12); border-top: none">
      <table style="width: 100%" mat-table matSort (matSortChange)="sortData($event)"
             [dataSource]="showOnlyUnpaid ? dataSourceUnpaid : dataSource" multiTemplateDataRows>
             
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle(selectionPayments, showOnlyUnpaid ? dataSourceUnpaid : dataSource) : null"
              color="primary"
              [checked]="selectionPayments.hasValue() && isAllSelected(selectionPayments, showOnlyUnpaid ? dataSourceUnpaid : dataSource)"
              [indeterminate]="selectionPayments.hasValue() && !isAllSelected(selectionPayments, showOnlyUnpaid ? dataSourceUnpaid : dataSource)">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary"
                          (change)="$event ? selectionPayments.toggle(row) : null"
                          [checked]="selectionPayments.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="client-name">
          <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.name" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.case.client.first_name + ' ' +
          element.case.client.last_name}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.email" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.case.client.email}} </td>
        </ng-container> -->

        <ng-container matColumnDef="next-payment">
          <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.payment-schedule" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.active">
              <span>{{element.term_date | toDateObject | date }}</span>
              <p class="m-0" style="color: red"
                 *ngIf="element.amount_paid < element.amount && (element.term_date | toDateObject) < now">
                <small>{{"PAYMENTS.missed-payment" | translate }}</small>
              </p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="date-paid">
          <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.date-paid" | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.date_paid | toDateObject | date }}
            <ng-container *ngIf="element.date_paid">
              <p class="m-0" style="color: green" *ngIf="element.amount_paid === element.amount">
                <small>{{"PAYMENTS.paid" | translate }}</small>
              </p>
              <p class="m-0" style="color: orange"
                 *ngIf="element.amount_paid < element.amount && element.amount_paid > 0">
                <small>{{"PAYMENTS.partial-paid" | translate }}</small>
              </p>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.amount" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <small class="font-weight-lighter">{{element.amount_paid | number: '1.2-2'}}€
              / {{element.amount | number: '1.2-2'}}€ </small>
            <span class="font-weight-bold">- {{element.amount - element.amount_paid | number: '1.2-2'}}€</span>
            <div class="progress" style="max-width: 120px">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                   [style]="{width: getPercent(element.amount_paid, element.amount)  + '%'}"
                   [attr.aria-valuenow]="getPercent(element.amount_paid, element.amount)"
                   [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercent(element.amount_paid,
                element.amount) }}%
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ "SHARED.actions" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="element.active" mat-stroked-button [matMenuTriggerFor]="menu">
              {{ "SHARED.actions" | translate }}
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item class="dropdown-item" (click)="saveTermId(element)">
                {{ 'PAYMENTS.change-date' | translate }}
              </button>
              <button *ngIf="element.case_payment_plan.type.default" mat-menu-item class="dropdown-item"
                      (click)="openChangeAmountModal(element)">
                {{ 'PAYMENTS.change-value' | translate }}
              </button>
              <button *ngIf="!element.case_payment_plan.type.default" mat-menu-item class="dropdown-item"
                      (click)="openAdditionalAmountEditorModal(element)">
                {{'PAYMENTS.change-additional-value' | translate}}
              </button>
              <a *ngIf="authUser.role_id === 5 &&
               (element.case_payment_plan.type.default && element.amount_paid === 0)"
                 mat-menu-item class="dropdown-item clickable" (click)="deleteInstallment(element.id)">
                {{ 'CASES.single.payments.installment_list.table.actions.delete' | translate }}
              </a>
              <a *ngIf="authUser.role_id === 5 &&
               (element.case_payment_plan.type.default && element.amount > 0 && (element.amount !== element.amount_paid))"
                 mat-menu-item class="dropdown-item clickable" (click)="markAsPromo(element)">
                {{ 'CASES.single.payments.installment_list.table.actions.mark_as_promo' | translate }}
              </a>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "PAYMENTS.label" | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name.includes('Phase 1') ? ('DISTRIBUTION.installments.phase1' | translate) : ('DISTRIBUTION.installments.phase2' | translate) }}<br>
            <!--{{ element.case_payment_plan.type.name }} {{ isPhaseTwo() ? ' ' : 'setup' }}<br>-->
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="newColSeparator">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" [ngClass]="'custom-column'"></td>
        </ng-container>

      <!-- *ngIf="element.name.includes('Phase 2');" -->
      <ng-container matColumnDef="dist-schedule"> 
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "DISTRIBUTION.installments.dist_schedule" | translate }}</th>
        <ng-container *matCellDef="let element">
          <ng-container *ngIf="!element.name.includes('Phase 1'); else emptyCell">
            <td mat-cell [ngClass]="'custom-column'"
            (click)="expandedElement = expandedElement === element ? null : element"> 
              {{element.distribution_schedule | toDateObject | date }}
            </td>
          </ng-container>
          <ng-template #emptyCell>
            <td mat-cell [ngClass]="'custom-column'"></td>
            <!-- (click)="expandedElement = expandedElement === element ? null : element" -->
          </ng-template>
        </ng-container>
      </ng-container>

      <!-- *ngIf="element.name.includes('Phase 2');" -->
       <ng-container matColumnDef="dist-paid">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ "DISTRIBUTION.installments.dist_date_paid" | translate }}</th>
        <ng-container *matCellDef="let element">
          <ng-container *ngIf="!element.name.includes('Phase 1'); else emptyCell">
            <td mat-cell [ngClass]="'custom-column'"
            (click)="expandedElement = expandedElement === element ? null : element"> 
            <span *ngIf="element.distributed_at; else pending">
              {{ element.distributed_at | toDateObject | date }}
            </span>
            <ng-template #pending>not-distributed</ng-template>
            </td>
          </ng-container>
          <ng-template #emptyCell>
            <td mat-cell [ngClass]="'custom-column'"></td>
            <!-- (click)="expandedElement = expandedElement === element ? null : element" -->
          </ng-template>
        </ng-container>
      </ng-container>


      <!-- Header row first group -->
      <ng-container matColumnDef="header-row-first-group">
        <th mat-header-cell *matHeaderCellDef
            colspan="8" style="text-align: center;">
            {{ "DISTRIBUTION.installments.dist_pay_plan" | translate }}
        </th>
      </ng-container>


      <!-- Header row second group -->
      <ng-container matColumnDef="header-row-second-group">
        <th mat-header-cell *matHeaderCellDef 
            colspan="5" style="text-align: center;"> 
          {{ "DISTRIBUTION.installments.dist_plan" | translate }} 
        </th>
      </ng-container>


      <!--<ng-container matColumnDef="amountDistributed">
        
        <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.amount" | translate }}</th>
          <td mat-cell *matCellDef="let element" [ngClass]="'custom-column'"
          (click)="expandedElement = expandedElement === element ? null : element">
            <small class="font-weight-lighter">{{element.amount_paid | number: '1.2-2'}}€
              / {{element.amount | number: '1.2-2'}}€ </small>
            <span class="font-weight-bold">- {{element.amount - element.amount_paid | number: '1.2-2'}}€</span>
            <div class="progress" style="max-width: 120px">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                   [style]="{width: getPercent(element.amount_paid, element.amount)  + '%'}"
                   [attr.aria-valuenow]="getPercent(element.amount_paid, element.amount)"
                   [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercent(element.amount_paid,
                element.amount) }}%
              </div>
            </div>
          </td>
        -->

        <!--         <th mat-header-cell *matHeaderCellDef>{{ "PAYMENTS.amount" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <small class="font-weight-lighter">0€
            / 125,00€ </small>
          <span class="font-weight-bold">- 125,00€</span>
          <div class="progress" style="max-width: 120px">
            <div class="progress-bar progress-bar-striped" role="progressbar"
                 [style]="{width: 0  + '%'}"
                 [attr.aria-valuenow]="0"
                 [attr.aria-valuemin]="0" [attr.aria-valuemax]="100">{{ getPercent(element.amount_paid,
              element.amount) }}%
            </div>
          </div>
        </td>
      </ng-container>
      -->
      <!-- *ngIf="isPhaseTwo()" -->
      <!--<ng-container matColumnDef="amountDistributed2" >
        <th mat-header-cell *matHeaderCellDef>Amt Distributed</th>
          <td mat-cell *matCellDef="let element" [ngClass]="'custom-column'" *ngIf="!element.name.includes('Phase 1')"
          (click)="expandedElement = expandedElement === element ? null : element">
            <small class="font-weight-lighter">0€
              / {{ (case.creditors[0].pivot.offer_to_creditor + case.expense.distribution_fee) }} € </small>
            <span class="font-weight-bold">- 0€</span>
            <div class="progress" style="max-width: 120px">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                   [style]="{width: 0  + '%'}"
                   [attr.aria-valuenow]="0"
                   [attr.aria-valuemin]="0" [attr.aria-valuemax]="100"> 0%
              </div>
            </div>
          </td>
      </ng-container>-->

      <ng-container matColumnDef="amountDistributed">
        <th mat-header-cell *matHeaderCellDef>{{ "DISTRIBUTION.installments.dist_amt_distributed" | translate }}</th>
        <ng-container *matCellDef="let element">
          <ng-container *ngIf="!element.name.includes('Phase 1'); else emptyCell">
            <td mat-cell [ngClass]="'custom-column'"
                (click)="expandedElement = expandedElement === element ? null : element">
              <small class="font-weight-lighter">{{ element.total_distributed_amount | number: '1.2-2' }}€ / {{ (element.offer_to_creditors_total + element.distribution_fee) | number: '1.2-2' }} €</small>
              <span class="font-weight-bold">- {{ element.offer_to_creditors_total + element.distribution_fee - element.total_distributed_amount | number: '1.2-2' }}€</span>
              <div class="progress" style="max-width: 120px">
                <div class="progress-bar progress-bar-striped" role="progressbar"
                     [style]="{width: getPercent(element.total_distributed_amount,element.offer_to_creditors_total+element.distribution_fee) + '%'}"
                     [attr.aria-valuenow]="getPercent(element.total_distributed_amount,element.offer_to_creditors_total+element.distribution_fee)"
                     [attr.aria-valuemin]="0" [attr.aria-valuemax]="100"> 
                     {{ 
                        getPercent(
                          element.total_distributed_amount,
                          element.offer_to_creditors_total+element.distribution_fee
                          ) 
                      }}%
                </div>
              </div>
            </td>
          </ng-container>
          <ng-template #emptyCell>
            <td mat-cell [ngClass]="'custom-column'"></td>
            <!-- (click)="expandedElement = expandedElement === element ? null : element" -->
          </ng-template>
        </ng-container>
      </ng-container>
      <!-- <ng-container matColumnDef="showDistribution">
        <th mat-header-cell *matHeaderCellDef>distribution plan</th>
        <td mat-cell *matCellDef="let element" [ngClass]="'custom-column'">
          <button *ngIf="element.active" mat-stroked-button (click)="expandedElement = expandedElement === element ? null : element">
            show
          </button>
        </td>
      </ng-container> -->

      <ng-container matColumnDef="showDistribution">
        <th mat-header-cell *matHeaderCellDef></th>
        <ng-container *matCellDef="let element">
          <ng-container *ngIf="!element.name.includes('Phase 1'); else emptyCell">
            <td mat-cell [ngClass]="'custom-column'">
              <!--<button *ngIf="element.total_distributed_amount > 0" mat-icon-button (click)="expandedElement = expandedElement === element ? null : element">-->
              <button mat-icon-button (click)="expandedElement = expandedElement === element ? null : element">
                <mat-icon>more_vert</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-template #emptyCell>
            <td mat-cell [ngClass]="'custom-column'"></td>
            <!-- (click)="expandedElement = expandedElement === element ? null : element" -->
          </ng-template>
        </ng-container>
      </ng-container>

      <!--<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>-->

      <ng-container *ngIf="isPhaseOne()||isPhaseTwo()">
        <tr mat-header-row *matHeaderRowDef="['header-row-first-group', 'header-row-second-group']"></tr>
      </ng-container>


      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <!-- <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <table style="border: 1px solid black; border-collapse: collapse;">
              <tr>
                <td style="border: 1px solid black; border-collapse: collapse;">creditor:</td>
                <td style="border: 1px solid black; border-collapse: collapse;">{{ case.unsecured_creditors[0].name }}</td>
              </tr>
              <tr>
                <td style="border: 1px solid black; border-collapse: collapse;">current balance:</td>
                <td style="border: 1px solid black; border-collapse: collapse;">{{ case.unsecured_creditors[0].pivot.current_balance }}</td>
              </tr>
              <tr>
                <td style="border: 1px solid black; border-collapse: collapse;">debt amount:</td>
                <td style="border: 1px solid black; border-collapse: collapse;">{{ case.unsecured_creditors[0].pivot.debt_amount }}</td>
              </tr>
              <tr>
                <td style="border: 1px solid black; border-collapse: collapse;">debt type:</td>
                <td style="border: 1px solid black; border-collapse: collapse;">{{ case.unsecured_creditors[0].pivot.debt_type }}</td>
              </tr>
              <tr>
                <td style="border: 1px solid black; border-collapse: collapse;">final payment:</td>
                <td style="border: 1px solid black; border-collapse: collapse;">{{ case.unsecured_creditors[0].pivot.final_payment }}</td>
              </tr>
              <tr>
                <td style="border: 1px solid black; border-collapse: collapse;">monthly payments:</td>
                <td style="border: 1px solid black; border-collapse: collapse;">{{ case.unsecured_creditors[0].pivot.monthly_payments }}</td>
              </tr>
              <tr>
                <td style="border: 1px solid black; border-collapse: collapse;">negotiated amount:</td>
                <td style="border: 1px solid black; border-collapse: collapse;">{{ case.unsecured_creditors[0].pivot.negotiated_amount }}</td>
              </tr>
              <tr>
                <td style="border: 1px solid black; border-collapse: collapse;">offer to creditor:</td>
                <td style="border: 1px solid black; border-collapse: collapse;">{{ case.unsecured_creditors[0].pivot.offer_to_creditor }}</td>
              </tr>
              <tr>
                <td style="border: 1px solid black; border-collapse: collapse;">response received:</td>
                <td style="border: 1px solid black; border-collapse: collapse;">{{ case.unsecured_creditors[0].pivot.response_received }}</td>
              </tr>
            </table>
             
          </div>
        </td>
      </ng-container> -->

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" > <!-- *ngIf="element.total_distributed_amount > 0" -->
            <ng-container>
              <!--<div style="margin-top: 16px;">Distribution fee : {{ case.expense.distribution_fee  + ' €' ?? 'N/A' }}</div>-->



                <!--<table style="border: 1px solid black; border-collapse: collapse; width: auto; margin-bottom: 16px; margin-top: 16px; margin-left: auto;">
                  <thead>
                    <tr>
                      <th style="border: 1px solid black; padding: 8px;">Attribute</th>
                      <th style="border: 1px solid black; padding: 8px;">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="padding: 8px;">Disposable Income</td>
                      <td style="padding: 8px;">{{ (templateData[0].totalCreditorsOffer ?? 0) + (case.expense.distribution_fee ?? 0)  + ' €' }}</td>
                    </tr>
                    <tr>
                      <td style="padding: 8px;">Distribution Fee</td>
                      <td style="padding: 8px;">
                        {{ case.expense.distribution_fee  + ' €' ?? 'N/A' }}
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 8px;">Offer to Creditors</td>
                      <td style="padding: 8px;">
                        {{ (templateData[0].totalCreditorsOffer ?? 0) + ' €' }}
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 8px;">Payment to creditors</td>
                      <td style="padding: 8px;">
                        {{ (templateData[0].final_payment ?? 0) + ' €' }}
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 8px;">Months to pay</td>
                      <td style="padding: 8px;">
                        {{ (templateData[0].DmTotalMonthsPending ?? 0) + ' €' }}
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 8px;">Fees paid</td>
                      <td style="padding: 8px;">
                        {{ (templateData[0].DMfeePaid ?? 0) + ' €' }}
                      </td>
                    </tr>
                  </tbody>
                </table>-->
              



              <!--<table style="border: 1px solid black; border-collapse: collapse; width: 100%; margin-bottom: 16px; margin-top: 16px;">
                <thead>
                  <tr>
                    <th style="border: 1px solid black; padding: 8px;">creditor</th>
                    <th style="border: 1px solid black; padding: 8px;">disposal</th>
                    <th style="border: 1px solid black; padding: 8px;">offer to creditor</th>
                    <th style="border: 1px solid black; padding: 8px;">final payment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let creditor of case.unsecured_creditors">
                    <td style="border: 1px solid black; padding: 8px;">{{ creditor.name }}</td>
                    <td style="border: 1px solid black; padding: 8px;">
                      {{ (creditor?.pivot?.offer_to_creditor != null && case?.expense?.distribution_fee != null) 
                          ? (creditor.pivot.offer_to_creditor + case.expense.distribution_fee) 
                          : 'N/A' }}
                    </td>
                    <td style="border: 1px solid black; padding: 8px;">
                      {{ creditor?.pivot?.offer_to_creditor != null 
                          ? creditor.pivot.offer_to_creditor + ' €' 
                          : 'N/A' }}
                    </td>
                    <td style="border: 1px solid black; padding: 8px;">
                      {{ creditor?.pivot?.final_payment != null 
                          ? creditor.pivot.final_payment + ' €' 
                          : 'N/A' }}
                    </td>
                  </tr>
                </tbody>
              </table>-->

              <!--<table style="border: 1px solid black; border-collapse: collapse; width: auto; margin-bottom: 16px; margin-top: 16px; margin-left: 55%;">
                <thead>
                  <tr>
                    <th style="border: 1px solid black; padding: 8px;">Creditor</th>
                    <th style="border: 1px solid black; padding: 8px;">Distributed</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let creditor of case.unsecured_creditors">
                    <td style="border: 1px solid black; padding: 8px;">{{ creditor.name }}</td>
                    <td style="border: 1px solid black; padding: 8px;">
                      {{ creditor?.pivot?.offer_to_creditor != null 
                        ? creditor.pivot.offer_to_creditor + ' €' 
                        : 'N/A' }}
                    </td>
                  </tr>
                  <tr>
                    <td style="border: 1px solid black; padding: 8px;">UNIFYE</td>
                    <td style="border: 1px solid black; padding: 8px;">
                      {{ case.expense.distribution_fee  + ' €' ?? 'N/A' }}
                    </td>
                  </tr>
                </tbody>
              </table>-->


              
              <div class="table-container" style="width: auto; margin-bottom: 16px; margin-top: 16px; margin-left: 55%;">
                <table class="styled-table">
                  <thead>
                    <tr>
                      <th colspan="3" class="table-title">{{ element.name.includes('Phase 1') ? ('DISTRIBUTION.installments.phase1' | translate) : ('DISTRIBUTION.installments.phase2' | translate) }}</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>{{ "DISTRIBUTION.installments.dist_creditor" | translate }}</th>
                      <th>{{ "DISTRIBUTION.installments.dist_distributed" | translate }}</th>
                      <th>On Account</th>
                    </tr>
                  </thead>
                  <tbody>


                    <ng-container *ngFor="let creditor of case.unsecured_creditors">
                      <tr>
                        <!-- Check if there are distribution case terms -->
                        <ng-container *ngIf="element.distribution_case_terms.length > 0; else noDTerms">
                          <!-- Check if creditor status is not 11 or 14 -->
                          <ng-container *ngIf="creditor.pivot.case_creditor_status_id !== 11 && creditor.pivot.case_creditor_status_id !== 14">
                            <ng-container *ngFor="let term of element.distribution_case_terms">
                              <!-- Match term batches with creditor name -->
                              <ng-container *ngIf="term.batches.name === creditor.name">
                                <td>{{ creditor.name }}</td>
                                <td>
                                  <span>
                                    {{ 
                                      term.amount_distributed != null && 
                                      element.name.includes('Phase 2') &&
                                      (term.amount_distributed <= creditor?.pivot?.offer_to_creditor) ? 
                                        (term.amount_distributed | number:'1.2-2') + ' € ' : '' 
                                    }}
                                    {{ 
                                      term.amount_distributed != null && 
                                      element.name.includes('Phase 2') &&
                                      (term?.amount_distributed > creditor?.pivot?.offer_to_creditor) ? 
                                        (creditor.pivot.offer_to_creditor | number:'1.2-2') + ' € ' : '' 
                                    }}
                                    / 
                                    {{ creditor?.pivot?.offer_to_creditor != null ? (creditor.pivot.offer_to_creditor | number:'1.2-2') + ' €' : 'N/A' }} 
                                  </span>
                                </td>
                                <td>
                                  <!-- First condition: term, hold_distribution, and amount match -->
                                  <ng-container *ngIf="term && term.hold_distribution && (term.amount == term.amount_paid)">
                                    {{ creditor.pivot.offer_to_creditor | number:'1.2-2' }} € 
                                  </ng-container>
                    
                                  <!-- Second condition: term and hold_distribution but amounts do not match -->
                                  <ng-container *ngIf="term && term.hold_distribution && !(term.amount == term.amount_paid)">
                                    / {{ creditor.pivot.offer_to_creditor | number:'1.2-2' }} €
                                  </ng-container>
                    
                                  <!-- Third condition: not term or hold_distribution, and additional checks -->
                                  <ng-container *ngIf="!(term && term.hold_distribution)">
                                    <ng-container *ngIf="creditor?.pivot?.product_id === 15 && 
                                                        (creditor?.pivot?.case_creditor_status_id === 4 || 
                                                         creditor?.pivot?.case_creditor_status_id === 35)">
                                      yes
                                    </ng-container>
                                    <ng-container *ngIf="!(creditor?.pivot?.product_id === 15 && 
                                                             (creditor?.pivot?.case_creditor_status_id === 4 || 
                                                              creditor?.pivot?.case_creditor_status_id === 35))">
                                      no
                                    </ng-container>
                                  </ng-container>
                                </td>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <!-- Template for no distribution case terms -->
                        <ng-template #noDTerms>
                          <!-- Check if creditor status is not 11 or 14 -->
                          <ng-container *ngIf="creditor.pivot.case_creditor_status_id !== 11 && creditor.pivot.case_creditor_status_id !== 14">
                            <td>{{ creditor.name }}</td>
                            <td>
                              0 {{ creditor?.pivot?.offer_to_creditor != null ? ' / ' + (creditor.pivot.offer_to_creditor | number:'1.2-2') + ' €' : 'N/A' }}
                            </td>
                            <td>
                              {{ 
                                creditor?.pivot?.product_id === 15 && 
                                  (creditor?.pivot?.case_creditor_status_id === 4 || 
                                  creditor?.pivot?.case_creditor_status_id === 35) ? 
                                '0 / ' + (creditor.pivot.offer_to_creditor | number:'1.2-2') + ' €' : 'no' 
                              }}
                            </td>
                          </ng-container>
                        </ng-template>
                      </tr>
                    </ng-container>
                    
                    <!--new version, no terms when just moved to distribution-->
                    <!-- <ng-container *ngFor="let creditor of case.unsecured_creditors">  -->
                      <!-- *ngFor="let creditor of creditorsMerged" -->
                      <!-- <tr> -->
                        <!-- <ng-container *ngIf="element.distribution_case_terms.length > 0; else noDTerms"> -->
                          <!-- <ng-container *ngFor="let term of element.distribution_case_terms"> -->
                            <!-- <ng-container *ngIf="term.batches.name === creditor.name"> -->
                              <!-- <td>{{ creditor.name }} -->
                                <!-- sapn in creditors counting if there are repeated creditors grouped-->
                                <!--<span *ngIf="creditor.offer_to_creditor_values.length > 1">
                                  ({{ creditor.offer_to_creditor_values.length }})
                                </span>-->
                              <!-- </td> -->
                              <!-- <td> -->
                                <!-- if not merged creditors, no sense-->
                                <!--<ng-container *ngIf="creditor.offer_to_creditor_values.length > 1">
                                  <span>
                                    {{ term.amount_distributed != null && element.name.includes('Phase 2') ? (term.amount_distributed | number:'1.2-2') + ' € ' : '' }}-->
                                    <!--{{ creditor?.total_offer_to_creditor != null ? (creditor.total_offer_to_creditor | number:'1.2-2') + ' €' : 'N/A' }}-->
                                  <!--</span>
                                  <div class="offer-values">
                                    [ {{ creditor.offer_to_creditor_values.join(' €, ') }} € ]
                                  </div>
                                </ng-container>-->
                                
                                <!-- <ng-container >  -->
                                  <!-- *ngIf="creditor.offer_to_creditor_values.length <= 1" -->
                                  <!-- <span> -->
                                    <!-- {{  
                                        term.amount_distributed != null && 
                                        element.name.includes('Phase 2') &&
                                        (term.amount_distributed <= creditor?.pivot?.offer_to_creditor) ? (term.amount_distributed | number:'1.2-2') + ' € ' : '' 
                                    }}
                                    {{ 
                                      term.amount_distributed != null && 
                                      element.name.includes('Phase 2') &&
                                      (term?.amount_distributed > creditor?.pivot?.offer_to_creditor) ? (creditor.pivot.offer_to_creditor | number:'1.2-2') + ' € ' : '' 
                                    }}
                                    / 
                                    {{ creditor?.pivot?.offer_to_creditor != null ? (creditor.pivot.offer_to_creditor | number:'1.2-2') + ' €' : 'N/A' }} 
                                    -->
                                    <!-- 
                                    {{ term.amount_distributed != null && element.name.includes('Phase 2') ? (term.amount_distributed | number:'1.2-2') + ' € ' : '' }} /   
                                    {{ creditor?.total_offer_to_creditor != null ? (creditor.total_offer_to_creditor | number:'1.2-2') + ' €' : 'N/A' }} 
                                    -->
                                  <!--</span>
                                </ng-container>
                              </td>
                              <td>-->
                                <!--<ng-container *ngIf="term && term.hold_distribution && (term.amount == term.amount_paid)">
                                  {{ creditor.pivot.offer_to_creditor | number:'1.2-2' }} € /
                                </ng-container>

                                <ng-container *ngIf="term && term.hold_distribution">
                                  {{ creditor.pivot.offer_to_creditor | number:'1.2-2' }} €
                                </ng-container>

                                <ng-container *ngIf="!(term && term.hold_distribution)">
                                  <ng-container *ngIf="creditor?.pivot?.product_id === 15 && 
                                                      (creditor?.pivot?.case_creditor_status_id === 4 || 
                                                        creditor?.pivot?.case_creditor_status_id === 35)">
                                    yes
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!(term && term.hold_distribution ) &&
                                                      !( !(term && term.hold_distribution) &&
                                                        (creditor?.pivot?.product_id === 15 && 
                                                        (creditor?.pivot?.case_creditor_status_id === 4 || 
                                                          creditor?.pivot?.case_creditor_status_id === 35)) )">
                                  no
                                </ng-container>-->
                                <!--
                                {{ (term && term.hold_distribution && (term.amount == term.amount_paid)) ? (creditor.pivot.offer_to_creditor | number:'1.2-2') + ' € / ' : '' }}
                                {{ (term && term.hold_distribution) ? (creditor.pivot.offer_to_creditor | number:'1.2-2') + ' €' : 'no' }}
                                -->
                              <!--</td>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <ng-template #noDTerms>
                          <td>{{ creditor.name }}</td>
                          <td>
                            0
                            {{ creditor?.pivot?.offer_to_creditor != null ? ' / ' + (creditor.pivot.offer_to_creditor | number:'1.2-2') + ' €' : 'N/A' }}
                          </td>
                          <td>
                            {{ 
                              creditor?.pivot?.product_id === 15 && 
                                (creditor?.pivot?.case_creditor_status_id === 4 || 
                                creditor?.pivot?.case_creditor_status_id === 35) ? '0 / ' + (creditor.pivot.offer_to_creditor | number:'1.2-2') + ' €' : 'no' 
                            }}
                          </td>
                        </ng-template>
                      </tr>
                    </ng-container>-->


                    <!--<ng-container *ngFor="let creditor of creditorsMerged">
                      <tr>
                        <ng-container *ngFor="let term of element.distribution_case_terms">
                          <ng-container *ngIf="term.batches.name === creditor.name">
                            <td>{{ creditor.name }}
                              <span *ngIf="creditor.offer_to_creditor_values.length > 1">
                                ({{ creditor.offer_to_creditor_values.length }})
                              </span>
                            </td>
                            <td>
                              <ng-container *ngIf="creditor.offer_to_creditor_values.length > 1">
                                <span>
                                  {{ term.amount_distributed != null && element.name.includes('Phase 2') ? (term.amount_distributed | number:'1.2-2') + ' € /' : '' }}
                                  {{ creditor?.total_offer_to_creditor != null ? (creditor.total_offer_to_creditor | number:'1.2-2') + ' €' : 'N/A' }}
                                </span>
                                <div class="offer-values">
                                  [ {{ creditor.offer_to_creditor_values.join(' €, ') }} € ]
                                </div>
                              </ng-container>
                              
                              <ng-container *ngIf="creditor.offer_to_creditor_values.length <= 1">
                                <span>
                                  {{ term.amount_distributed != null && element.name.includes('Phase 2') ? (term.amount_distributed | number:'1.2-2') + ' € /' : '' }}
                                  {{ creditor?.total_offer_to_creditor != null ? (creditor.total_offer_to_creditor | number:'1.2-2') + ' €' : 'N/A' }}
                                </span>
                              </ng-container>
                            </td>
                          </ng-container>
                        </ng-container>
                      </tr>
                    </ng-container>-->
                    <!--UNIFY is not separated anymore-->
                    <!--<tr>
                      <td>UNIFYE</td>
                      <td>{{ case.expense.distribution_fee != null ? (case.expense.distribution_fee | number:'1.2-2') + ' €' : 'N/A' }}</td>  
                    </tr>-->
                  </tbody>
                </table>
              </div>

              <!--<div class="table-container" style="width: auto; margin-bottom: 16px; margin-top: 16px; margin-left: 55%;">
                <table class="styled-table">
                  <thead>
                    <tr>
                      <th colspan="3" class="table-title">{{ element.name.includes('Phase 1') ? 'Setup plan ' : 'Debt Plan' }}</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Creditor</th>
                      <th>Distributed</th>
                      <th>Distributed2</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let creditor of processedCreditors">
                      <td>{{ creditor.name }}</td>
                      <td>{{ creditor?.pivot?.offer_to_creditor != null 
                        ? (creditor.pivot.offer_to_creditor | number:'1.2-2') + ' €' 
                        : 'N/A' }}
                      </td>
                      <td *ngIf="creditor.showDistributed2" [attr.rowspan]="creditor.rowspan">
                        {{ creditor.distributed2 | number:'1.2-2' }} €
                      </td>
                    </tr>
                    <tr>
                      <td>UNIFYE</td>
                      <td>{{ case.expense.distribution_fee + ' €' ?? 'N/A' }}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>-->
              
            </ng-container>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
       <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row" [ngClass]="'custom-row'"
           [class.example-expanded-row]="expandedElement === element">
       </tr>
       <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row" ></tr>
        
      </table>

      <div *ngIf="isLoading" class="text-center" style="padding: 50px">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div *ngIf="!isLoading && dataSource && dataSource.data.length < 1" style="padding: 50px">
        <h3 class="text-center">{{ "PAYMENTS.no-payments" | translate }}</h3>
      </div>

      <mat-paginator
        [length]="paginatorConfig.length"
        [pageSize]="paginatorConfig.pageSize"
        [pageSizeOptions]="[5, 10, 20, 25]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
