<h2 style="margin: 0px; padding: 0px;" mat-dialog-title>{{"CREDITORS.quick-win.title" | translate}}: {{data.creditor.name}}</h2>
<div class="alert alert-danger" role="alert" *ngIf="!data.creditor.approved">{{"CASE_CREDITOR.info.unapproved_creditor" | translate}}</div>
<mat-dialog-content>
  <!-- print the values of data in a table. -->
  <table class="mt-3">
    <tbody>
      <tr>
        <td><b>{{"CREDITORS.quick-win.creditor-fee" | translate}}</b></td>
        <td style="padding-left:2rem;">{{ creditorFee | currency  }}</td>
      </tr>
      <tr>
        <td><b>{{"CREDITORS.quick-win.negotiation-fee" | translate}}</b></td>
        <td style="padding-left:2rem;">{{ negotiationFee | currency }}</td>
      </tr>
      <tr>
        <td><b>{{"CREDITORS.quick-win.total-quick-win" | translate}}</b></td>
        <td style="padding-left:2rem;">{{ quickWinFee | currency }}</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td><b>{{"CREDITORS.quick-win.call-client" | translate}}</b></td>
        <td style="padding-left:2rem;"><a style="cursor: pointer; color: #04abe4;" (click)="clickToCall(data.client.client.phone)">{{ data.client.client.phone  }}</a></td>
      </tr>
    </tbody>
  </table>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>