import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CasePaymentStatusLog } from '../../../../../../../../../_base-shared/models/Case/CasePaymentStatusLog';
import { CaseService } from '../../../../case.service';
import { CaseInvoiceStatusChange } from "../../../../../../../../../_base-shared/models/Case/CaseInvoiceStatusChange";

@Component({
  selector: 'app-invoice-status-log',
  templateUrl: './invoice-status-log.component.html',
  styles: []
})
export class InvoiceStatusLogComponent implements OnInit, OnDestroy{
  @Input() case: Case;
  public isLoading                           = 0;
  public caseInvoiceStatusLogs: Array<CaseInvoiceStatusChange>;
  private subscriptions: Array<Subscription> = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.fetchLogs(this.case.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchLogs(caseId: number): void {
    this.isLoading++;
    this.subscriptions.push(
      this.caseService.getInvoiceStatusLogs(caseId, {all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.caseInvoiceStatusLogs = result.data,
      ),
    );

  }
}
