import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Creditor } from '../../../../../../../_base-shared/models/Entity/Creditor';
import { CreditorService } from '../../../creditor/creditor.service';
import { ReportService } from '../../report.service';

@Component({
  selector: 'app-automated-creditor-report',
  templateUrl: './automated-creditor-report.component.html',
  styles: []
})
export class AutomatedCreditorReportComponent implements OnInit{
  public form: UntypedFormGroup;
  public isLoading                  = 0;
  public creditors: Array<Creditor> = [];
  public case_creditor_responses: Array<{ id: string, name: string }> = [
    { id: '0', name: 'negotiating' },
    { id: '1', name: 'approved' }
  ];

  constructor(
    private toast: ToastrService,
    private reportService: ReportService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AutomatedCreditorReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private creditorService: CreditorService
  ) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetchCreditorss();
  }

  buildForm(): void {
    this.form = this.fb.group({
      //start_date:   [null, Validators.required],
      //end_date:     [null, Validators.required],
      response_received: [null, Validators.required],
      creditor_id: [null, Validators.required],
    });
  }

  sendReportData(): void {
    if (!this.form.valid) {
      return;
    }

    const requestData = {
      //start_date: this.form.value.start_date.toISOString(),
      //end_date:   this.form.value.end_date.toISOString(),
      creditor_response_received: this.form.value.response_received,
      creditor_id: this.form.value.creditor_id
    };
    this.isLoading++;
    this.reportService.generateAutomatedCreditorReport(requestData).pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
          this.dialogRef.close(true);
          this.toast.success(res.message);

        },
        err => console.error(err),
      );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private fetchCreditorss(): void {
    this.isLoading++;
    this.creditorService.index({all: 1, active: 1})
      .pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
          this.creditors    = res.data;
        },
        err => console.error(err)
      );
  }
}
