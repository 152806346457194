import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DateTime } from 'luxon';
import { BaseChartDirective, Label, MultiDataSet } from 'ng2-charts';
import { interval, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DashboardService } from '../dashboard.service';

type productSaleData = { number_of_sales: number, monthly_quota: number };
type totalData = { today: number, this_week: number, this_month: number, total_fees: number, quota: number };

@Component({
  selector:    'app-sales-leaderboard',
  templateUrl: './sales-leaderboard.component.html',
  styleUrls:   ['./sales-leaderboard.component.scss'],
})
export class SalesLeaderboardComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) set matSortFirstTable(sort: MatSort) {
    this.dataSourceAllProducts.sort = sort;
  }
  @ViewChild('salesSort', { read: MatSort }) set sort(sort: MatSort) {
    this.dataSourceSales.sort = sort;
  }
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  public isLoading                                                      = 0;
  public availableProducts: Array<{ group_slug: string, name: string }> = [];
  public selectedProduct: 'fds'             = 'fds';

  public saleData: { fds: productSaleData};
  public tableProductAgentData = {fds: []};
  public totalAgentSales       = {
    fds:      {} as totalData
  };

  public dataSourceSales: MatTableDataSource<any>;
  public dataSourceAllProducts: MatTableDataSource<any>;

  public displayedColumns: string[] = [
    'name',
    'today',
    'this_week',
    'this_month',
    'total_fees',
    'quota',
  ];

  public displayedColumnsSales: string[] = ['agent_name', 'number_of_sales'];
  public refreshDatasSub: Subscription;

  public doughnutCharts: {
    fds?: {
      labels: Label[],
      data: MultiDataSet,
      options: ChartOptions,
      type: ChartType,
      plugins: any[],
      colors: Array<{ backgroundColor: Array<string> }>
    }
  } = {};
  public chartsReady: boolean;

  public doughnutChartLabelsDefault: Label[]  = ['Target', 'No. of sales', 'Sales goal'];
  public doughnutChartLabelsExtended: Label[] = ['Target', 'Over Target', 'No. of sales', 'Sales goal'];

  public doughnutChartOptionsDefault: ChartOptions  = {
    // responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color:     'white',
        formatter: (value, ctx) => {
          //  Remove '0' from labels
          return value === 0 ? null : value;
        },
      },
    },
  };
  public doughnutChartOptionsExtended: ChartOptions = {
    tooltips: {
      custom: (label) => {
        if (label.dataPoints && label.dataPoints[0]?.datasetIndex === 1 && label.dataPoints[0]?.index === 2) {
          return label.opacity = 0;
        } else {
          return label;
        }
      },
    },
    plugins:  {
      datalabels: {
        color:     'white',
        formatter: (value, ctx) => {
          if (ctx.dataset.label === 'No. of sales' && ctx.dataIndex === 2) {
            return null;
          } else {
            return value === 0 ? null : value;
          }
        },
      },
    },
  };

  public doughnutChartColorsDefault  = [
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
  ];
  public doughnutChartColorsExtended = [
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
    {backgroundColor: ['rgba(4, 55, 61, 1)', 'rgba(121, 163, 3, 1)', 'rgba(121, 163, 3, 0.7)', 'rgba(0, 0, 0, 0.3)']},
  ];

  constructor(private dashboardService: DashboardService) {
  }

  ngOnInit(): void {
    this.availableProducts = [
      {group_slug: 'fds', name: 'FDS'}
    ];
    this.saleData          = {
      fds:      {number_of_sales: 0, monthly_quota: 0}
    };

    this.availableProducts.forEach(product => {
      this.doughnutCharts[product.group_slug] = {
        data:    [[0, 0, 0], [0, 0, 0]],
        options: this.doughnutChartOptionsDefault,
        colors:  this.doughnutChartColorsDefault,
        labels:  this.doughnutChartLabelsDefault,
        type:    'doughnut',
        plugins: [pluginDataLabels],
      };
    });

    this.getSalesData();
    const source         = interval(900000);  //  Get new data every 15 minutes
    this.refreshDatasSub = source.subscribe(val => this.getSalesData());
  }

  ngOnDestroy() {
    if (this.refreshDatasSub) {
      this.refreshDatasSub.unsubscribe();
    }
  }

  private getSalesData() {
    this.isLoading++;
    this.dashboardService.getSalesData().pipe(finalize(() => this.isLoading--)).subscribe(next => {
      this.availableProducts.forEach(product => {
        this.saleData[product.group_slug].number_of_sales = next.data[product.group_slug].agents_this_month;
        this.saleData[product.group_slug].monthly_quota   = next.data[product.group_slug].full_target;
      });

      //  500 sales data
      this.dataSourceSales = new MatTableDataSource(next.data.number_of_sales);

      this.formatDataForTable(next.data);
      this.updateChartData();
    });
  }

  private formatDataForTable(data) {
    this.availableProducts.forEach(product => {
      this.totalAgentSales[product.group_slug] = {today: 0, this_week: 0, this_month: 0, total_fees: 0, quota: 0};
    });
    data.agents_today.forEach(agentToday => {
      this.populateDataArray(agentToday, 'today');
    });

    data.agents_this_week.forEach(agentWeek => {
      this.populateDataArray(agentWeek, 'this_week');
    });

    data.agents_this_month.forEach(agentMonth => {
      this.populateDataArray(agentMonth, 'this_month');
    });

    data.amounts.forEach(agentMonth => {
      this.populateDataArray(agentMonth, 'total_fees');
    });
    this.dataSourceAllProducts = new MatTableDataSource(this.tableProductAgentData[this.selectedProduct]);

  }

  private populateDataArray(agent, timeSpan) {
    for (const product of this.availableProducts) {

      const value: number = timeSpan === 'total_fees' ?
        +agent['amount_paid_' + product.group_slug] :
        +agent['case_count_' + product.group_slug];

      if (value === 0){
        continue;
      }

      this.totalAgentSales[product.group_slug][timeSpan] += value;

      const agentInProductExists = this.tableProductAgentData[product.group_slug].find(
        a => a.agent_id === agent.agent_id);

      if (agentInProductExists) {
        const agentInProductIndex = this.tableProductAgentData[product.group_slug]
          .findIndex(a => a.agent_id === agentInProductExists.agent_id);

        this.tableProductAgentData[product.group_slug][agentInProductIndex][timeSpan] = value;
      } else {
        const agentData     = {
          agent_id:   agent.agent_id,
          name:       agent.agent_name,
          quota:      agent['target_' + product.group_slug] > 0 ? agent['target_' + product.group_slug] : 0,
          today:      0,
          this_week:  0,
          this_month: 0,
          total_fees: 0,
        };
        agentData[timeSpan] = value;
        this.totalAgentSales[product.group_slug].quota += agentData.quota;
        this.tableProductAgentData[product.group_slug].push(agentData);
      }
    }
  }

  private updateChartData(): void {
    const currentDayInMonth = DateTime.local().day;
    const chartDaysInMonth  = DateTime.local().daysInMonth;
    const percentOfTotal    = Math.round(currentDayInMonth / chartDaysInMonth * 100);

    this.availableProducts.forEach(product => {
      const monthlyQuota = this.saleData[product.group_slug].monthly_quota;
      const dayTarget    = (monthlyQuota / 100) * percentOfTotal;
      const actualSales  = this.saleData[product.group_slug].number_of_sales;

      this.doughnutCharts[product.group_slug].data[0][0] = +dayTarget.toFixed();
      this.doughnutCharts[product.group_slug].data[0][2] = +((monthlyQuota - dayTarget).toFixed());
      this.doughnutCharts[product.group_slug].data[1][1] = +actualSales;

      if (monthlyQuota - actualSales < 0) {
        this.doughnutCharts[product.group_slug].labels     = this.doughnutChartLabelsExtended;
        this.doughnutCharts[product.group_slug].colors     = this.doughnutChartColorsExtended;
        this.doughnutCharts[product.group_slug].options    = this.doughnutChartOptionsExtended;
        this.doughnutCharts[product.group_slug].data[1][3] = 0;
        this.doughnutCharts[product.group_slug].data[1][2] = +Math.abs(monthlyQuota - (actualSales - monthlyQuota))
          .toFixed();
        this.doughnutCharts[product.group_slug].data[1][1] = +Math.abs(monthlyQuota - actualSales).toFixed();
      } else {
        this.doughnutCharts[product.group_slug].data[1][2] = +((monthlyQuota - actualSales).toFixed());
      }
    });

    this.charts.forEach(chart => chart.update());
    this.chartsReady = true;
  }

  public getTotalNumberOfSales(field): number {
    let total = 0;
    if (this.dataSourceSales) {
      switch (field) {
        case '500':
          this.dataSourceSales.data.forEach(data => {
            total += data.number_of_sales || 0;
          });
          return total;
        default:
          break;
      }
    }

    return total;
  }

  public getTotalSales(field): number {
    let total = 0;

    if (!this.dataSourceAllProducts) {
      return total;
    }

    this.dataSourceAllProducts.data.forEach(agent => {
      total += +(agent[field]);
    });

    return total;
  }

  public getPercent(element): number {
    if (isFinite(element.this_month / +element.quota)) {
      return +(((element.this_month / +element.quota) * 100).toFixed(2));
    } else {
      return 0;
    }
  }

  public onRadioChange($event) {
    this.selectedProduct       = $event.value;
    this.dataSourceAllProducts = new MatTableDataSource<any>(this.tableProductAgentData[this.selectedProduct]);

  }
}
