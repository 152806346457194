import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../_base-shared/contracts/laravel-response.interface';
import { CaseAsset } from '../../../../../_base-shared/models/Case/CaseAsset';
import { MainBaseApiService } from '../../_shared/services/main-base-api.service';

@Injectable({
  providedIn: 'root',
})
export class CaseAssetService extends MainBaseApiService {
  public index(caseId: number) {
    return this.http.get<LaravelResourceResponse<Array<CaseAsset>>>(this.apiUrl + '/cases/' + caseId + '/assets')
        .pipe(catchError(response => this.handleError(response)));
  }

  public upsert(caseId: number, caseAssets) {
    return this.http.put<LaravelResourceResponse<Array<CaseAsset>>>(
        this.apiUrl + '/cases/' + caseId + '/assets', caseAssets,
    ).pipe(catchError(response => this.handleError(response)));
  }

  public destroy(caseId: number, assetId: number) {
    return this.http.delete<LaravelResourceResponse<Array<CaseAsset>>>(
        this.apiUrl + '/cases/' + caseId + '/assets/' + assetId,
    ).pipe(catchError(response => this.handleError(response)));
  }
}
