import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-client-payment-redirect',
  templateUrl: './client-payment-redirect.component.html',
  styles: [],
})
export class ClientPaymentRedirectComponent implements OnInit {
  private clientAppUrl = environment.CLIENT_APP_URL;
  public isLoading     = 0;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    let urlLocation = this.clientAppUrl + '/public/payments?';

    const caseUuid             = this.route.snapshot.queryParamMap?.get('uuid');
    const requestChargeAmounts = this.route.snapshot.queryParamMap?.get('amount');
    const paymentRequestUuid   = this.route.snapshot.queryParamMap?.get('payment_request');
    const requestSignature     = this.route.snapshot.queryParamMap?.get('signature');
    const planType             = this.route.snapshot.queryParamMap?.get('plan_type');

    if (caseUuid) {
      urlLocation += 'uuid=' + caseUuid;
    }

    if (requestChargeAmounts) {
      urlLocation += '&amount=' + requestChargeAmounts;
    }

    if (paymentRequestUuid) {
      urlLocation += '&payment_request=' + paymentRequestUuid;
    }

    if (planType) {
      urlLocation += '&plan_type=' + planType;
    }


    if (requestSignature) {
      urlLocation += '&signature=' + requestSignature;
    }

    window.location.href = urlLocation;
  }
}
