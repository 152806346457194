<div class="container">
  <div class="row">
    <div class="col-12">
      <h2 *ngIf="editorType === 'create'">{{"CREDITORS.add-creditor" | translate}}</h2>
      <h2 *ngIf="editorType !== 'create'">{{"CREDITORS.edit-creditor" | translate}}</h2>
    </div>
  </div>
  <div *ngIf="isLoading" class="row">
    <div class="col-12 pt-5 text-center">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
  </div>
  <ng-container *ngIf="this.formActive">
    <form [formGroup]="form" (submit)="submitForm(form)">
      <div class="row">
        <div class="col-md-6">
          <div class="card shadow mb-4">
            <div class="card-body">
              <!-- Name -->
              <app-input type="text" id="name" formControlName="name" [label]="'SHARED.model.name' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        flexClass="align-items-center" [placeholder]="'SHARED.model.name' | translate">
              </app-input>

              <!-- Active Status -->
              <app-input type="select" id="active" appearance="outline" formControlName="active"
                        [label]="'CREDITORS.status' | translate" [showLabel]="true"
                        [showClear]="false" [selectOptions]="creditorStatuses" [selectLabel]="'label'"
                        [selectValue]="'value'"
                        [fullWidth]="true">
              </app-input>
              <!-- Approved -->
              <app-input type="select" id="approved" appearance="outline" formControlName="approved"
                        [label]="'CREDITORS.approved' | translate" [showLabel]="true"
                        [showClear]="false" [selectOptions]="approvedStatuses" [selectLabel]="'label'"
                        [selectValue]="'value'"
                        [fullWidth]="true">
              </app-input>
              <!-- Company Name -->
              <app-input type="email" id="company_name" formControlName="company_name"
                        [label]="'CREDITORS.company' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        [placeholder]="'CREDITORS.company' | translate"
                        flexClass="align-items-center">
              </app-input>
              <!-- Address -->
              <app-input type="text" id="address" formControlName="address" [label]="'CREDITORS.address' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        flexClass="align-items-center" [placeholder]="'CREDITORS.address' | translate">
              </app-input>
              <!-- General Email -->
              <app-input type="text" id="email" formControlName="email"
                        [label]="'CREDITORS.editor.email_general.label' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        flexClass="align-items-center"
                        [placeholder]="'CREDITORS.editor.email_general.label' | translate">
              </app-input>
              <!-- Data Protection Email -->
              <!-- <app-input type="text" id="email_protection" formControlName="email_data_protection"
                        [label]="'CREDITORS.editor.email_data_protection.label' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        flexClass="align-items-center"
                        [placeholder]="'CREDITORS.editor.email_data_protection.label' | translate">
              </app-input> -->
              <!-- Complaints Email -->
              <!-- <app-input type="text" id="email_complaints" formControlName="email_complaints"
                        [label]="'CREDITORS.editor.email_complaints.label' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        flexClass="align-items-center"
                        [placeholder]="'CREDITORS.editor.email_complaints.label' | translate">
              </app-input> -->
              <!-- <div class="form-group">
                <app-input type="email" formControlName="email"
                          [label]="'CREDITORS.editor.email_general.label' | translate"
                          [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                          flexClass="align-items-center">
                </app-input>
              </div> -->
              <!-- Payments Email -->
              <app-input type="email" id="email_payments" formControlName="email_payments"
                        [label]="'CREDITORS.editor.email_payments.label' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        [placeholder]="'CREDITORS.editor.email_payments.label' | translate"
                        flexClass="align-items-center">
              </app-input>
              <!-- Mandates Email -->
              <app-input type="email" id="email_mandates" formControlName="email_mandates"
                        [label]="'CREDITORS.editor.email_mandates.label' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        [placeholder]="'CREDITORS.editor.email_mandates.label' | translate"
                        flexClass="align-items-center">
              </app-input>
              <!-- Proposals Email -->
              <app-input type="email" id="email_proposals" formControlName="email_proposals"
                        [label]="'CREDITORS.editor.email_proposals.label' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        [placeholder]="'CREDITORS.editor.email_proposals.label' | translate"
                        flexClass="align-items-center">
              </app-input>
              <div class="row">
                <div class="col-6">
                  <!-- Bulk FDS -->
                  <app-input type="checkbox" formControlName="bulk_fds_accepted"
                            [label]="'CREDITORS.bulk-fds-accepted' | translate">
                  </app-input>
                </div>
                <div class="col-6">
                  <!-- Bulk DM -->
                  <app-input type="checkbox" formControlName="bulk_dm_accepted"
                            [label]="'CREDITORS.bulk-dm-accepted' | translate">
                  </app-input>
                </div>
              </div>
              <div class="row">
                <!-- <div class="col-6"> -->
                  <!-- Can Be Recovery -->
                  <!-- <app-input type="checkbox" formControlName="can_be_recovery"
                            [label]="'CREDITORS.can-be-recovery' | translate">
                  </app-input> -->
                <!-- </div> -->
                <div class="col-6">
                  <!-- Automate mandate send -->
                  <app-input type="checkbox" formControlName="auto_send_mandate"
                            [label]="'CREDITORS.auto-send-mandate' | translate">
                  </app-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card shadow mb-4">
            <div class="card-body">
              <!-- Phone 1 -->
              <app-input type="email" id="phone_1" formControlName="phone_1" [label]="'CREDITORS.phone-1' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        [placeholder]="'CREDITORS.phone-1' | translate"
                        flexClass="align-items-center">
              </app-input>
              <!-- Phone 2 -->
              <app-input type="email" id="phone_2" formControlName="phone_2" [label]="'CREDITORS.phone-2' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        [placeholder]="'CREDITORS.phone-2' | translate"
                        flexClass="align-items-center">
              </app-input>
              <!-- Contact Person 1 -->
              <!-- <app-input type="email" id="contact_person_1" formControlName="contact_person_1"
                        [label]="'CREDITORS.contact-1'  | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        [placeholder]="'CREDITORS.contact-1'  | translate"
                        flexClass="align-items-center">
              </app-input> -->
              <!-- Contact Person 2 -->
              <!-- <app-input type="email" id="contact_person_2" formControlName="contact_person_2"
                        [label]="'CREDITORS.contact-2' | translate"
                        [showLabel]="true" [showClear]="false" appearance="outline" [fullWidth]="true"
                        [placeholder]="'CREDITORS.contact-2' | translate"
                        flexClass="align-items-center">
              </app-input> -->
              <!--Creditor type-->
              <app-input type="select" id="type" appearance="outline" formControlName="lender_type"
                        [label]="'CREDITORS.creditor-type' | translate" [showLabel]="true"
                        [showClear]="false" [selectOptions]="creditorTypes" [selectLabel]="'label'"
                        [selectValue]="'value'"
                        [fullWidth]="true">
              </app-input>
              <hr>
              <!-- <app-input type="select" appearance="standard" formControlName="voting_outcomes_lso"
                        [label]="('CREDITORS.voting-outcomes' | translate) + ' LSO'" [showLabel]="true"
                        [showClear]="false" [selectOptions]="lsoVotingOutcomes" [selectLabel]="'label'"
                        [placeholder]="'LSO'"
                        [selectValue]="'value'"
                        [fullWidth]="true">
              </app-input> -->
              <!-- <app-input type="select" appearance="standard" formControlName="voting_outcomes_dm"
                        [label]="('CREDITORS.voting-outcomes' | translate) + ' DM'" [showLabel]="true"
                        [showClear]="false" [selectOptions]="dmVotingOutcomes" [selectLabel]="'label'"
                        [placeholder]="'DM'"
                        [selectValue]="'value'"
                        [fullWidth]="true">
              </app-input> -->
              <!--Minimum dividend required LSO-->
              <!-- <app-input type="number" formControlName="minimum_dividend_required_lso"
                        [label]="'CREDITORS.min-dividend-req-lso' | translate"
                        [showLabel]="true" [showClear]="false" appearance="standard" [fullWidth]="true"
                        [placeholder]="'CREDITORS.min-dividend-req-lso' | translate"
                        flexClass="align-items-center">
              </app-input> -->
              <!--Minimum dividend required DM-->
              <!-- <app-input type="number" formControlName="minimum_dividend_required_dm"
                        [label]="'CREDITORS.min-dividend-req-dm' | translate"
                        [showLabel]="true" [showClear]="false" appearance="standard" [fullWidth]="true"
                        [placeholder]="'CREDITORS.min-dividend-req-dm' | translate"
                        flexClass="align-items-center">
              </app-input> -->
              <!--Payment account-->
              <app-input type="text" formControlName="payment_account"
                        [label]="'CREDITORS.payment_account' | translate" [placeholder]="'CREDITORS.payment_account' | translate"
                        [showLabel]="true" [showClear]="false" appearance="standard" [fullWidth]="true"
                        flexClass="align-items-center">
              </app-input>
              <!--Payment reference-->
              <app-input type="number" formControlName="payment_reference"
                        [label]="'CREDITORS.payment_reference' | translate" [placeholder]="'CREDITORS.payment_reference' | translate"
                        [showLabel]="true" [showClear]="false" appearance="standard" [fullWidth]="true"
                        flexClass="align-items-center">
              </app-input>
              <!-- Payment date-->
              <app-input type="date" formControlName="payment_date" [label]="'CREDITORS.payment_date' | translate"
                        [extraLabel]="false" [fullWidth]="true" appearance="standard"
                        [placeholder]="'CREDITORS.payment_date' | translate">
              </app-input>
              <!-- Creditor negotiatior -->
              <app-input type="select" appearance="standard" formControlName="assigned_to_id"
                        [label]="'CREDITORS.assigned_to_id' | translate" [showLabel]="true"
                        [showClear]="false" [selectOptions]="creditorNegotiators" [selectLabel]="'name'"
                        [selectValue]="'id'" [placeholder]="'CREDITORS.assigned_to_id' | translate"
                        [fullWidth]="true">
              </app-input>
              <!--            <mat-form-field class="input-full-width">-->
              <!--              <mat-select formControlName="voting_outcomes_lso" class="input-full-width">-->
              <!--                <mat-label>{{ "CASES.list.notifications.label" | translate }}</mat-label>-->
              <!--          -->
              <!--                <ng-container *ngIf="caseMultiFilterCtrl.value.length <= 5">-->
              <!--                  <div class="spinner-container p-2 d-flex justify-content-center">-->
              <!--                    <p class="m-0">Enter 6 or more characters</p>-->
              <!--                  </div>-->
              <!--                </ng-container>-->
              <!--                <ng-container *ngIf="isLoadingCases">-->
              <!--                  <div class="spinner-container p-2 d-flex justify-content-center">-->
              <!--                    <mat-spinner diameter="30"></mat-spinner>-->
              <!--                  </div>-->
              <!--                </ng-container>-->
              <!--                <ng-container *ngIf="!isLoadingCases">-->
              <!--                  <mat-option *ngFor="let caseObj of filteredCases | async" [value]="caseObj.id">-->
              <!--                    {{caseObj.ref_number}} - {{caseObj.client.first_name}} {{caseObj.client.first_name}}-->
              <!--                  </mat-option>-->

              <!--                </ng-container>-->
              <!--              </mat-select>-->
              <!--            </mat-form-field>-->

              <!-- Show Config -->
              <div class="mt-3">
                <mat-slide-toggle color="primary" formControlName="show_config">
                  {{"CREDITORS.enable-schedule-config" | translate}}
                </mat-slide-toggle>
                <div *ngIf="form.get('show_config').value">
                  <div class="form-group" formGroupName="schedule_config">
                    <div class="pt-4">
                      <mat-label>{{"CREDITORS.type" | translate}}</mat-label>
                      <mat-radio-group formControlName="type" class="d-flex">
                        <mat-radio-button value="daily" class="example-radio-button">
                          {{"CREDITORS.daily" | translate}}
                        </mat-radio-button>
                        <mat-radio-button value="weekly" class="example-radio-button">
                          {{"CREDITORS.weekly" | translate}}
                        </mat-radio-button>
                        <mat-radio-button value="bi-weekly" class="example-radio-button">
                          {{"CREDITORS.bi-weekly" | translate}}
                        </mat-radio-button>
                        <mat-radio-button value="monthly" class="example-radio-button">
                          {{"CREDITORS.monthly" | translate}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div>
                      <label class="pt-3" style="display: block">{{"CREDITORS.select-time" | translate}}</label>
                      <ng-container *ngIf="form.get('schedule_config.type').value === 'weekly' ||
                      form.get('schedule_config.type').value === 'bi-weekly' ">
                        <mat-form-field appearance="standard">
                          <mat-label>{{"CREDITORS.day" | translate}}</mat-label>
                          <mat-select formControlName="day">
                            <mat-option *ngFor="let day of dayOptions" [value]="day">
                              {{ day }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngIf="form.get('schedule_config.type').value === 'monthly'">
                        <mat-form-field appearance="standard">
                          <mat-label>{{"CREDITORS.day" | translate}}</mat-label>
                          <mat-select formControlName="day">
                            <mat-option *ngFor="let day of dayOptionsMonthly" [value]="day">
                              {{ day }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </ng-container>
                      <mat-form-field appearance="standard">
                        <mat-label>{{"CREDITORS.hour" | translate}}</mat-label>
                        <mat-select formControlName="hour">
                          <mat-option *ngFor="let hour of hourOptions" [value]="hour">
                            {{hour}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Show Config -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button type="submit" mat-raised-button color="primary" style="min-width: 120px" *ngIf="authUser.role_id === 5 || isCreditorNegotiator === true">
            {{ "SHARED.save" | translate }}
          </button>
          <button *ngIf="authUser.id === 751600 || authUser.id === 752733 || authUser.id === 747150 || authUser.id === 747148" type="button" mat-raised-button color="primary" class="ml-2"
                  style="min-width: 120px" (click)="deleteAndReassign()" [hidden]="isManager">
            {{ "CREDITORS.delete-and-reassign" | translate }}
          </button>
        </div>
      </div>
    </form>
  </ng-container>
</div>
