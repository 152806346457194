import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Creditor } from "projects/_base-shared/models/Entity/Creditor";


@Component({
    selector: 'app-case-creditor-info',
    templateUrl: './case-creditor-info.component.html'
})

export class CaseCreditorInfoComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<CaseCreditorInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        console.log(this.data);
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}
