import { NgModule } from '@angular/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { SharedModule } from '../../_shared/shared.module';
import { StatusEditorComponent } from './status-editor/status-editor.component';
import { StatusListComponent } from './status-list/status-list.component';
import { StatusRoutingModule } from './status-routing.module';
import { CaseCreditorStatusListComponent } from './case-creditor/status-list/status-list.component';
import { CaseCreditorStatusEditorComponent } from './case-creditor/status-editor/editor-dialog.component';

@NgModule({
  declarations: [
    StatusListComponent,
    StatusEditorComponent,
    CaseCreditorStatusListComponent,
    CaseCreditorStatusEditorComponent,
  ],
  imports:      [
    SharedModule,
    MatAutocompleteModule,
    StatusRoutingModule,
  ],
})
export class StatusModule {
}
