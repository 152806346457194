<h2 style="margin: 0px; padding: 0px;" mat-dialog-title>{{"CASE_CREDITOR.info.heading" | translate}}: {{data.name}}</h2>
<div class="alert alert-danger" role="alert" *ngIf="!data.approved">{{"CASE_CREDITOR.info.unapproved_creditor" | translate}}</div>
<mat-dialog-content>
  <!-- print the values of data in a table. -->
  <table>
    <tbody>
      <tr>
        <td><b>{{"CASE_CREDITOR.info.address" | translate}}</b></td>
        <td style="padding-left:2rem;">{{ data.address ?? 'N/A'  }}</td>
      </tr>
      <tr>
        <td><b>{{"CASE_CREDITOR.info.general_email" | translate}}</b></td>
        <td style="padding-left:2rem;">{{ data.email ?? 'N/A'  }}</td>
      </tr>
      <tr>
        <td><b>{{"CASE_CREDITOR.info.complaints_email" | translate}}</b></td>
        <td style="padding-left:2rem;">{{ data.email_complaints ?? 'N/A'  }}</td>
      </tr>
      <tr>
        <td><b>{{"CASE_CREDITOR.info.mandates_email" | translate}}</b></td>
        <td style="padding-left:2rem;">{{ data.email_mandates ?? 'N/A'  }}</td>
      </tr>
      <tr>
        <td><b>{{"CASE_CREDITOR.info.payments_email" | translate}}</b></td>
        <td style="padding-left:2rem;">{{ data.email_payments ?? 'N/A'  }}</td>
      </tr>
      <tr>
        <td><b>{{"CASE_CREDITOR.info.proposal_email" | translate}}</b></td>
        <td style="padding-left:2rem;">{{ data.email_proposals ?? 'N/A'  }}</td>
      </tr>
      <tr>
        <td><b>{{"CASE_CREDITOR.info.primary_phone" | translate}}</b></td>
        <td style="padding-left:2rem;">{{ data.phone_1 ?? 'N/A'  }}</td>
      </tr>
      <tr>
        <td><b>{{"CASE_CREDITOR.info.secondary_phone" | translate}}</b></td>
        <td style="padding-left:2rem;">{{ data.phone_2 ?? 'N/A' }}</td>
      </tr>
    </tbody>
  </table>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>