import { Case } from '../Case/Case';
import { PaymentPlanType } from '../Product/PaymentPlanType';
import { PaymentMethod } from './PaymentMethod';
import { PaymentTerm } from './PaymentTerm';

export class CasePaymentPlan {
  id?: number;
  case_id?: number;
  type_id?: number;
  payment_method_id?: number;

  initial_fee: number;
  phase_one_value: number;
  phase_one_duration: number;
  phase_one_monthly_fee: number;
  phase_one_interval: number;
  phase_one_interval_unit: string;
  phase_two_value: number;
  phase_two_duration: number;
  phase_two_monthly_fee: number;
  phase_two_interval: number;
  phase_two_interval_unit: string;
  phase_two_defer: boolean | 0 | 1;
  payment_day: number;
  contract_date?: Date | string;
  iban: string;

  // Claim
  charge_amount: number;
  percentage_agreed: number;

  created_at?: Date | string;
  updated_at?: Date | string;

  // Relations
  case: Case;
  type: PaymentPlanType;
  payment_method: PaymentMethod;
  installments?: Array<PaymentTerm>;
}
