import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, take } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseExpense, CaseExpenseType } from '../../../../../../../_base-shared/models/Case/CaseExpense';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { CaseExpenseService } from '../../case-expense.service';
import { CaseService } from '../../case.service';
import { CaseCreditor } from '../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CaseCreditorService } from '../../case-creditor.service';
import { TargetMonthlyPaymentComponent } from './target-monthly-modal/target-monthly.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MiscConfigService } from '../../../config/misc-config/misc-config.service';
import { CaseStatusLog } from 'projects/_base-shared/models/Case/CaseStatusLog';
import { CaseAsset } from '../../../../../../../_base-shared/models/Case/CaseAsset';
import { CaseAssetService } from '../../case-asset.service';

@Component({
  selector:    'app-case-expense-editor',
  templateUrl: './case-expense-editor.component.html',
  styleUrls:   ['./case-expense-editor.component.scss']
})
export class CaseExpenseEditorComponent implements OnInit {
  public case: Case;
  public caseExpenses: CaseExpense;
  public isLoading = 0;
  public isSubmitting: boolean;
  public form: UntypedFormGroup;
  public serverResponse: LaravelResourceResponse;

  public totalEssentialExpenses    = 0;
  public totalLivingExpenses       = 0;
  public totalPhoneExpenses        = 0;
  public totalTransportExpenses    = 0;
  public totalHousekeepingExpenses = 0;
  public totalOtherExpenses        = 0;
  public totalSpecialExpenses      = 0;

  public creditors: Array<CaseCreditor> = [];
  public terms: number             = 60;

  public isWelcomed: boolean = false;
  public caseAssets = new Array<CaseAsset>();
  public hasCar = false;

  public expense_default_ibi: number;
  public expense_default_water: number;
  public expense_default_power: number;
  public expense_default_fuel: number;
  public expense_default_insurance: number;
  public expense_default_bus: number;
  public expense_default_phone: number;
  public expense_default_groceries: number;

  constructor(
      private route: ActivatedRoute,
      private fb: UntypedFormBuilder,
      private toastr: ToastrService,
      private translate: TranslateService,
      private caseService: CaseService,
      private caseExpenseService: CaseExpenseService,
      private financialOverviewService: FinancialOverviewService,
      private caseCreditorService: CaseCreditorService,
      private dialog: MatDialog,
      private configService: MiscConfigService,
      private caseAssetService: CaseAssetService,
  ) {
  }

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe(params => {
      const caseId = +params.get('id');
      this.getDefaultExpensesValues();
      this.isLoading++;
      this.caseAssetService.index(caseId).pipe(finalize(() => this.isLoading--)).subscribe(res => {
        this.caseAssets = res.data ? res.data : [];
        let carAsset = res.data.find(asset => asset.type == 'vehicles');
        if(carAsset) {
          this.hasCar = true;
        }
      });
      this.isLoading++;
      this.caseService.get(caseId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.case = result.data;
        this.isLoading++;
        this.caseExpenseService.index(caseId).pipe(finalize(() => this.isLoading--)).subscribe(res => {
          this.caseExpenses = res.data ? res.data : new CaseExpense();
          this.buildForm(this.caseExpenses);
        });
      });
    });
  }

  public submitForm(form: UntypedFormGroup) {
    if (form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.caseExpenseService.upsert(this.case.id, form.getRawValue()).pipe(finalize(() => this.isSubmitting = false))
        .subscribe(
            () => {
              this.form.markAsPristine();
              this.toastr.success(this.translate.instant('CASES.editor.expenses.result.success'));
            },
            error => {
              this.serverResponse = error.error;
              this.toastr.error(this.translate.instant('CASES.editor.expenses.result.error'), error.error.message);
            },
        );
  }

  public isComponentDirty(): boolean {
    return this.form.dirty;
  }

  public isComponentValid(): boolean {
    return this.form.valid;
  }

  public submitComponent() {
    return this.submitForm(this.form);
  }

  public getFormArray(arrayName: string) {
    return this.form.get(arrayName) as UntypedFormArray;
  }

  public updateTotalExpenses(expenseType: CaseExpenseType, updateFinancialOverview = true) {
    let totalNet = 0;

    if (expenseType === 'essentials') {
      totalNet                    = (+this.form.get('essential_rent').value || 0) +
          (+this.form.get('essential_mortgage').value || 0) +
          (+this.form.get('life_insurance').value || 0) + (+this.form.get('pension_plan').value || 0) +
          (+this.form.get('ibi').value || 0) + (+this.form.get('home_insurance').value || 0) +
          (+this.form.get('school_expenses').value || 0) + (+this.form.get('essential_other').value || 0) +
          (+this.form.get('essential_local_tax').value || 0);
      this.totalEssentialExpenses = parseFloat(totalNet.toFixed(2));
    }

    if (expenseType === 'living_expenses') {
      totalNet                 = (+this.form.get('essential_gas').value || 0) +
          (+this.form.get('essential_water').value || 0) +
          (+this.form.get('essential_electricity').value || 0);
      this.totalLivingExpenses = parseFloat(totalNet.toFixed(2));
    }

    if (expenseType === 'phone') {
      totalNet                = (+this.form.get('phone_home').value || 0) + (+this.form.get('phone_mobile').value || 0);
      this.totalPhoneExpenses = parseFloat(totalNet.toFixed(2));
    }

    if (expenseType === 'transport') {
      totalNet                    = (+this.form.get('travel_public_transport').value || 0) +
          (+this.form.get('travel_car_insurance').value || 0) +
          (+this.form.get('travel_vehicle_tax').value || 0) + (+this.form.get('travel_fuel').value || 0) +
          (+this.form.get('essential_hire_purchase').value || 0) + (+this.form.get('itv').value || 0);
      this.totalTransportExpenses = parseFloat(totalNet.toFixed(2));
    }

    if (expenseType === 'housekeeping') {
      totalNet                       = (+this.form.get('housekeeping_food_and_groceries').value || 0) +
          (+this.form.get('housekeeping_cleaning').value || 0) +
          (+this.form.get('housekeeping_newspapers').value || 0) + (+this.form.get('housekeeping_alcohol').value || 0) +
          (+this.form.get('housekeeping_laundry').value || 0) +
          (+this.form.get('housekeeping_clothing_footwear').value || 0);
      this.totalHousekeepingExpenses = parseFloat(totalNet.toFixed(2));
    }

    if (expenseType === 'other') {
      totalNet                = (+this.form.get('other_hobbies_leisure_sport').value || 0) +
          (+this.form.get('other_other').value || 0) + (+this.form.get('pet_expenses').value || 0) +
          (+this.form.get('medical_expenses').value || 0) + (+this.form.get('medications').value || 0) +
          (+this.form.get('cosmetics_and_beauty').value || 0) + (+this.form.get('legal_fees').value || 0);
      this.totalOtherExpenses = parseFloat(totalNet.toFixed(2));
    }

    if (expenseType === 'special_expenses') {
      const specialExpensesArray = this.getFormArray('special_expenses');
      if (specialExpensesArray.controls.length) {
        specialExpensesArray.controls.forEach(formGroup => {
          totalNet += (+formGroup.get('value').value || 0);
        });
      }
      this.totalSpecialExpenses = parseFloat(totalNet.toFixed(2));
    }

    if (updateFinancialOverview) {
      this.updateTotalNet();
    }
  }

  public addSpecialExpenses() {
    const formArray = this.getFormArray('special_expenses');
    formArray.push(this.fb.group({label: null, value: 0}));
  }

  public removeSpecialExpenses(index: number) {
    this.getFormArray('special_expenses').removeAt(index);
  }

  private buildForm(caseExpenses: CaseExpense) {
    let ibi = (this.case?.client?.home_owner == 'owner') ? this.expense_default_ibi : null;
    let power = (this.case.client.home_owner == 'tenant' || this.case?.client?.home_owner == 'owner') ? this.expense_default_power : null;
    let water = (this.case.client.home_owner == 'tenant' || this.case?.client?.home_owner == 'owner') ? this.expense_default_water : null;
    let fuel = null;
    let bus = null;
    let insurance = null;

    let travelExpenses = (this.hasCar) ? this.expense_default_fuel : this.expense_default_bus;
    console.log(travelExpenses);
    // if(this.caseAssets.length) {
      // if the client has a car, add 100 to the fuel expenses
      // const carAsset = this.caseAssets.find(asset => asset.type == 'vehicles');
      // console.log(carAsset);
      console.log(this.hasCar);
      if(this.hasCar) {
        fuel = this.expense_default_fuel;
        insurance = this.expense_default_insurance;
        bus = null;
      } else {
        fuel = null;
        insurance = null;
        bus = this.expense_default_bus;
      }
    // }

    this.form = this.fb.group({
      // Essentials
      essential_rent:      [caseExpenses.essential_rent],
      essential_mortgage:  [caseExpenses.essential_mortgage],
      life_insurance:      [caseExpenses.life_insurance],
      pension_plan:        [caseExpenses.pension_plan],
      ibi:                 [caseExpenses.ibi ?? ibi, Validators.min(ibi)],
      home_insurance:      [caseExpenses.home_insurance],
      school_expenses:     [caseExpenses.school_expenses],
      essential_other:     [caseExpenses.essential_other],
      essential_local_tax: [caseExpenses.essential_local_tax],
      // Living Expenses
      essential_gas:         [caseExpenses.essential_gas],
      essential_electricity: [caseExpenses.essential_electricity ?? power, Validators.min(power)],
      essential_water:       [caseExpenses.essential_water ?? water, Validators.min(water)],
      // Phone
      phone_home:   [caseExpenses.phone_home ?? this.expense_default_insurance, Validators.min(this.expense_default_insurance)],
      phone_mobile: [caseExpenses.phone_mobile],
      // Transport
      travel_public_transport: [caseExpenses.travel_public_transport ?? bus, Validators.min(bus)],
      travel_car_insurance:    [caseExpenses.travel_car_insurance ?? insurance, Validators.min(insurance)],
      travel_vehicle_tax:      [caseExpenses.travel_vehicle_tax],
      travel_fuel:             [caseExpenses.travel_fuel ?? fuel, Validators.min(fuel)],
      essential_hire_purchase: [caseExpenses.essential_hire_purchase],
      itv:                     [caseExpenses.itv],
      // Housekeeping
      housekeeping_food_and_groceries: [caseExpenses.housekeeping_food_and_groceries ?? this.expense_default_groceries, Validators.min(this.expense_default_groceries)],
      housekeeping_cleaning:           [caseExpenses.housekeeping_cleaning],
      housekeeping_newspapers:         [caseExpenses.housekeeping_newspapers],
      housekeeping_alcohol:            [caseExpenses.housekeeping_alcohol],
      housekeeping_laundry:            [caseExpenses.housekeeping_laundry],
      housekeeping_clothing_footwear:  [caseExpenses.housekeeping_clothing_footwear],
      // Other Expenditure
      other_hobbies_leisure_sport: [caseExpenses.other_hobbies_leisure_sport],
      other_other:                 [caseExpenses.other_other],
      pet_expenses:                [caseExpenses.pet_expenses],
      medical_expenses:            [caseExpenses.medical_expenses],
      medications:                 [caseExpenses.medications],
      cosmetics_and_beauty:        [caseExpenses.cosmetics_and_beauty],
      legal_fees:                  [caseExpenses.legal_fees],
      // Special Expenses
      special_expenses: this.fb.array([]),
    });

    const specialExpenses = this.form.get('special_expenses') as UntypedFormArray;

    if (caseExpenses.special_expenses?.length) {
      caseExpenses.special_expenses.forEach(specialExpense => {
        specialExpenses.push(
            this.fb.group({label: specialExpense.label, value: specialExpense.value}),
        );
      });
    }

    this.updateTotalExpenses('essentials', false);
    this.updateTotalExpenses('living_expenses', false);
    this.updateTotalExpenses('phone', false);
    this.updateTotalExpenses('transport', false);
    this.updateTotalExpenses('housekeeping', false);
    this.updateTotalExpenses('other', false);
    this.updateTotalExpenses('special_expenses', false);
  }

  private updateTotalNet() {
    const totalNet = this.totalEssentialExpenses + this.totalLivingExpenses + this.totalPhoneExpenses +
        this.totalHousekeepingExpenses +
        this.totalTransportExpenses + this.totalOtherExpenses + this.totalSpecialExpenses;
    this.financialOverviewService.financialOverview$.pipe(take(1)).subscribe(overview => {
      if (overview) {
        overview.expenses = totalNet;
        this.financialOverviewService.updateFinancialOverviewLocally(overview);
      }
    });
  }

  public getMonthlyPayment(form) {
    const dialogRef = this.dialog.open(TargetMonthlyPaymentComponent, {
      width: 'fit-content',
      height: 'fit-content',
      data: {
        case: this.case
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.submitForm(form);
      if (result) {
        this.case.target_monthly_payment = result.case.target_monthly_payment;
        this.updateTargetMonthlyPayment();
      }
    });
  }

  private updateTargetMonthlyPayment() {
    this.financialOverviewService.financialOverview$.pipe(take(1)).subscribe(overview => {
      if (overview) {
        overview.target_monthly_payment = this.case.target_monthly_payment;
        this.financialOverviewService.updateFinancialOverviewLocally(overview);
      }
    });
  }

  public getDefaultExpensesValues() {
    this.configService.getConfigData().subscribe(next => {
      this.expense_default_ibi = next.data.find(obj => obj.key === 'expense_default_ibi').value;
      this.expense_default_water = next.data.find(obj => obj.key === 'expense_default_water').value;
      this.expense_default_power = next.data.find(obj => obj.key === 'expense_default_power').value;
      this.expense_default_fuel = next.data.find(obj => obj.key === 'expense_default_fuel').value;
      this.expense_default_insurance = next.data.find(obj => obj.key === 'expense_default_insurance').value;
      this.expense_default_bus = next.data.find(obj => obj.key === 'expense_default_bus').value;
      this.expense_default_phone = next.data.find(obj => obj.key === 'expense_default_phone').value;
      this.expense_default_groceries = next.data.find(obj => obj.key === 'expense_default_groceries').value;
    });
  }
}
