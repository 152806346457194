<div class="container">
  <div class="row">
    <div class="col-12">
      <h1 class="pb-5">{{ "REPORTS.financial-reports.title" | translate }}</h1>
      <table mat-table matSort [dataSource]="reportOptions" width="100%">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- Report Name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Report Name</th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
        <!-- Report Description -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description<th>
          <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>
        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element"><button type="button" mat-raised-button color="primary" (click)="element.action($event)" [disabled]="element.disabled">Generate Report</button></td>
        </ng-container>
      </table>
      <div class="card-body">
        <div class="text-right" style="float: right">
          <button type="button" mat-icon-button color="primary" (click)="fetchQueuedJobs()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div *ngIf="isLoadingJobs">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <div [hidden]="isLoadingJobs"></div>
        <div *ngIf="!appJobs.length" class="pt-5">
          <h3>{{ "REPORTS.financial-reports.jobs-list.no-items" | translate }}</h3>
        </div>
        <mat-list *ngIf="appJobs.length">
          <mat-list-item *ngFor="let appJob of appJobs">
            <div matLine class="text-left">
              <span *ngIf="!appJob.user_id">{{ "SHARED.system" | translate }}</span>
              <span *ngIf="appJob.user_id">
                {{ appJob.user.first_name + ' ' + appJob.user.last_name }}
              </span>
              <span *ngIf="appJob.processing_start_at">
                {{"REPORTS.financial-reports.jobs-list.status.progress" | translate }}&nbsp;
              </span>
              <span *ngIf="!appJob.processing_start_at">
                {{ "REPORTS.financial-reports.jobs-list.status.pending" | translate }}&nbsp;
              </span>
              <span>{{ appJob.label }}</span>
            </div>
            <div *ngIf="appJob.progress > 0" matLine>
              <mat-progress-bar mode="determinate" [value]="appJob.progress"></mat-progress-bar>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
</div>
