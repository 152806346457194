import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientService } from '../client.service';
import { finalize } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector   : 'app-notary-download',
  templateUrl: './notary-download.component.html',
  styleUrls  : ['./notary-download.component.scss']
})
export class NotaryDownloadComponent implements OnInit {
  public uuId;
  public form: UntypedFormGroup;
  public formReady = false;

  public isLoading = 0;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toast: ToastrService,
    private translateService: TranslateService,
    private clientService: ClientService
  ) {
  }

  ngOnInit(): void {
    this.uuId = this.route.snapshot.queryParamMap.get('uuid');

    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      uuid: [this.uuId, null],
      code: ['', Validators.required]
    });

    this.formReady = true;
  }

  public onSubmit() {
    if (this.form.valid) {
      this.isLoading++;
      this.clientService.notaryDownload(this.form.value).pipe(finalize(() => this.isLoading--))
        .subscribe(next => {
          saveAs(next.body, 'case-documents.zip');
        }, error => {
          this.toast.error(this.translateService.instant('CLIENT.notary-download.wrong-code'));
        });
    } else {
      this.form.markAllAsTouched();
    }
  }

}
