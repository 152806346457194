<h1 mat-dialog-title>{{ "CREDITORS.reassign-title" | translate }}</h1>
<div class="pt-3" >
  <div *ngIf="isLoading" class="row">
    <div class="col-12 pt-5 text-center">
      <mat-spinner color="primary" class="m-0 m-auto"></mat-spinner>
    </div>
  </div>
  <form  style="height: 100%" *ngIf="!isLoading" [formGroup]="form" (ngSubmit)="submitForm()">
    <p>You are about to delete the creditor. All cases debts of these creditor will be reassigned to the new creditor </p>
    <label>{{ "CREDITORS.reassign-title" | translate }}</label>
    <app-input type="select" formControlName="creditor" appearance="outline" [label]="'Select creditor'" (ngModelChange)="creditorChange($event)"
               [fullWidth]="true" [selectOptions]="creditors" [selectLabel]="'name'" [selectValue]="'id'" [searchable]="true">
    </app-input>
    <mat-error *ngIf="form.get('creditor').touched && !form.value.creditor">{{'SHARED.field-required' | translate}}</mat-error>
    <div class="pt-3">
      <h4>The creditor you are about to delete has {{ creditor_case_count }} cases assigned.</h4>
    </div>
    <div class="pt-3">
      <label>{{ "CASES.single.note" | translate }}</label>
      <textarea class="form-control" placeholder="{{ 'CASES.single.note' | translate }}"
      formControlName="notes" cols="60" rows="8"></textarea>
    </div>

    <ng-container *ngIf="form.value.creditor">
      <div class="alert alert-warning mt-3">
        <h3><strong>{{"SHARED.warning" | translate}}!</strong> {{"CREDITORS.revert-action" | translate}}</h3>
        {{"CREDITORS.delete-action-first" | translate}} <strong class="text-danger">{{ data.creditor.name }}</strong>
        {{"CREDITORS.delete-action-second" | translate}} <strong class="text-danger">{{ selectedCreditor.name }}</strong>
      </div>
    </ng-container>

    <div class='pt-2 text-right'>
      <button type="button" mat-button color="primary" class="mt-3 ml-2" (click)="dialogRef.close()">{{ "SHARED.close" | translate }}</button>
      <button type="submit" mat-raised-button color="primary" class="mt-3">{{ "CREDITORS.delete-and-reassign" | translate }}</button>
    </div>
  </form>
</div>
