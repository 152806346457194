<div class="container">
  <div class="card mb-3">
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <div class="card-title">
          <h2>{{ 'CASES.editor.creditors.heading' | translate }}</h2>
        </div>
        <!-- Page Actions -->
        <div *ngIf="form" class="d-flex">
          <!-- Case Referral Allowed-->
          <div *ngIf="case && authUser?.packager?.master" class="d-flex flex-row mr-2">
            <button mat-raised-button [mat-menu-trigger-for]="referCaseMenu" color="primary">
              {{'CASE_CREDITOR.refer_case.heading' | translate}}
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #referCaseMenu class="refer-case-menu">
              <button mat-menu-item
                      color="primary" [disabled]="!!case.deu_ref_number" (click)="sendCase(case, 'deu')">
                <span *ngIf="!case.deu_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_deu.refer_case_to_deu' | translate}}</span>
                <span *ngIf="case.deu_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_deu.referred_to' | translate}} {{ case.deu_ref_number }}</span>
              </button>

              <button mat-menu-item
                      color="primary" [disabled]="!!case.epi_ref_number" (click)="sendCase(case, 'epi')">
                <span *ngIf="!case.epi_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_epi.refer_case_to_epi' | translate}}</span>
                <span *ngIf="case.epi_ref_number">{{'CASE_CREDITOR.refer_case.referral_to_epi.referred_to' | translate}} {{ case.epi_ref_number }}</span>
              </button>
            </mat-menu>
          </div>
          <app-spinner-btn type="button" [loading]="isSubmitting" [name]="'SHARED.save' | translate"
                          (click)="submitForm(form)">
          </app-spinner-btn>
        </div>
      </div>
    </div>
    <div class="card-body" *ngIf="isLoading || serverResponse">
      <div class="row">
        <div [hidden]="!isLoading" class="col-12">
          <mat-spinner class="m-0 m-auto"></mat-spinner>
        </div>
        <div *ngIf="serverResponse" class="col-12">
          <app-server-response [response]="serverResponse"></app-server-response>
        </div>
      </div>
    </div>
  </div>
  <form *ngIf="form" [formGroup]="form">
    <div style="min-height: 1000px">
      <!-- Public Debts -->
      <!-- <div class="row">
        <div class="col-12">
          <div class="card shadow">
            <div class="card-header">
              <div class="card-title">
                <h3>{{ "CASE_CREDITOR.model.type.options.public" | translate }}</h3>
              </div>
            </div>
            <div class="card-body">
              <table *ngIf="getFormArray('public_debts').controls.length" formArrayName="public_debts" class="table">
                <thead>
                <tr>
                  <th scope="col">{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>
                  <th scope="col">{{ "CASE_CREDITOR.model.public_organization.label" | translate }}</th>
                  <th scope="col">{{ "CASE_CREDITOR.model.debt_type.label" | translate }}</th>
                  <th scope="col">{{ "CASE_CREDITOR.model.initial_balance" | translate }}</th>
                  <th scope="col">{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
                  <th scope="col">{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>
                  <th scope="col">{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>
                  <th scope="col">{{ "CASE_CREDITOR.model.judicial_claim" | translate }}</th>
                  <th scope="col">{{ "CASES.editor.creditors.table.debt_percentage" | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let formGroup of getFormArray('public_debts').controls let i = index">
                  <tr class="first-row border-top" [formGroupName]="i" style="width: 100%">
                    Ownership
                    <td>
                      <app-input *ngIf="!case.joint_application" type="select" formControlName="ownership"
                                selectLabel="label" selectValue="value"
                                [label]="'CASE_CREDITOR.model.ownership.label' | translate"
                                [selectOptions]="singleOwnershipOptions" [showClear]="false" width="160px">
                      </app-input>
                      <app-input *ngIf="case.joint_application" type="select" formControlName="ownership"
                                selectLabel="label" selectValue="value"
                                [label]="'CASE_CREDITOR.model.ownership.label' | translate"
                                [selectOptions]="jointOwnershipOptions" [showClear]="false">
                      </app-input>
                    </td>
                    Public Organisation
                    <td>
                      <app-input type="select" formControlName="public_organisation"
                                selectLabel="label" selectValue="value" [selectOptions]="publicOrganizations"
                                [label]="'CASE_CREDITOR.model.public_organization.label' | translate" [showClear]="false"
                                (ngModelChange)="publicOrganizationChanged($event, i)">
                      </app-input>
                    </td>
                    Debt Type
                    <td>
                      <app-input type="select" formControlName="debt_type"
                                selectLabel="label" selectValue="value" [selectOptions]="publicDebtTypes"
                                [label]="'CASE_CREDITOR.model.debt_type.label' | translate" [showClear]="true">
                      </app-input>
                    </td>
                    Initial Balance
                    <td>
                      <app-input type="number" formControlName="initial_balance" width="160px"
                                [label]="'CASE_CREDITOR.model.initial_balance' | translate">
                      </app-input>
                    </td>
                    Current Balance
                    <td>
                      <app-input type="number" formControlName="current_balance" width="160px"
                                [label]="'CASE_CREDITOR.model.current_balance' | translate">
                      </app-input>
                    </td>
                    Monthly Payment
                    <td>
                      <app-input type="number" formControlName="monthly_payments" width="160px"
                                [label]="'CASE_CREDITOR.model.monthly_payments' | translate">
                      </app-input>
                    </td>
                    Percentage and Remove
                    <td class="d-flex flex-row align-items-center flex-grow-1">
                      Percentage
                      <p>
                        {{ totalDebt ? ((+formGroup.get('current_balance').value / totalDebt) * 100).toFixed(1) : 0 }}
                        %
                      </p>
                      Remove Record
                      <button mat-icon-button color="warn" class="ml-2"
                              (click)="removeCreditor('public_debts',i)">
                        <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                      </button>
                    </td>
                  </tr>
                  <tr [formGroupName]="i" class="second-row">
                    Interested Third Party
                    <td>
                      <div class="d-flex align-items-center">
                        <app-input type="checkbox" formControlName="interested_third_party"
                                  [label]="'CASE_CREDITOR.model.interested_third_party' | translate">
                        </app-input>
                      </div>
                    </td>
                    Town Hall
                    <td *ngIf="formGroup.get('public_organisation').value === 'town-hall'">
                      <app-input *ngIf="formGroup.get('city').value" type="text" formControlName="city"
                                [label]="'ADDRESS.city' | translate" [fullWidth]="true" readonly>
                      </app-input>
                      <app-input type="select" formControlName="town_hall_id"
                                [label]="'CASE_CREDITOR.model.public_organization.options.town-hall' | translate"
                                [fullWidth]="true" selectLabel="name" selectValue="id" [searchable]="true"
                                [selectOptions]="townHalls" [showClear]="false"
                                (filterSearch)="fetchTownHalls($event)">
                      </app-input>
                    </td>
                    Making Payments
                    <td>
                      <app-input type="checkbox" formControlName="making_payments"
                                [label]="'CASE_CREDITOR.model.making_payments' | translate">
                      </app-input>
                    </td>
                    Verified Status
                    <td>
                      <span *ngIf="formGroup.get('verified')?.value" class="badge badge-success mr-1">
                        {{ 'CASE_CREDITOR.model.verified.options.true' | translate }}
                      </span>
                      <span *ngIf="!formGroup.get('verified')?.value" class="badge badge-danger mr-1">
                        {{ 'CASE_CREDITOR.model.verified.options.false' | translate }}
                      </span>
                    </td>
                    Notes
                    <td [colSpan]="formGroup.get('public_organisation').value === 'town-hall' ? 1 : 2">
                      <app-input type="text" formControlName="notes" [fullWidth]="true"
                                [label]="'CASE_CREDITOR.model.notes' | translate">
                      </app-input>
                    </td>
                    Reference Number
                    <td>
                      <app-input type="text" formControlName="reference_number" width="160px"
                                [label]="'CASE_CREDITOR.model.reference_number' | translate">
                      </app-input>
                    </td>
                    Judicial Claim
                    <td>
                      <app-input type="text" formControlName="judicial_claim" width="160px"
                                [label]="'CASE_CREDITOR.model.judicial_claim' | translate">
                      </app-input>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
              Add
              <button mat-raised-button color="primary" (click)="addPublicDebt()">
                + {{ 'CASES.editor.creditors.table.actions.add' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Unsecured Creditors -->
      <div class="row mt-3">
        <div class="col-12">
          <div class="card shadow">
            <div class="card-header">
              <div class="card-title">
                <h3>{{ "CASE_CREDITOR.model.type.options.unsecured" | translate }}</h3>
              </div>
            </div>
            <div class="card-body">
              <table *ngIf="getFormArray('unsecured_creditors').controls.length" formArrayName="unsecured_creditors"
                    class="table">
                <thead>
                <tr>
                  <th>{{ "CASE_CREDITOR.model.relations.creditor" | translate }}</th>
                  <!-- <th>Creditor Region</th> -->
                  <!-- <th>{{ "CASE_CREDITOR.model.relations.current_creditor" | translate }}</th> -->
                  <th>{{ "CASE_CREDITOR.model.debt_type.label" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>
                  <th>{{ "CASES.details.last-paid" | translate }}</th>
                  <!--<th>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>-->
                  <!--<th>{{ "CASE_CREDITOR.model.judicial_claim" | translate }}</th>-->
                  <th *ngIf="hasDmFields && dgsLegalAdvisor">{{ "CASE_CREDITOR.model.outcome" | translate }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let formGroup of getFormArray('unsecured_creditors').controls let i = index">
                  <tr [formGroupName]="i" style="border-collapse: separate" class="first-row border-top"
                      [matTooltip]="'CASES.editor.creditors.table.unapproved_tooltip' | translate"
                      [matTooltipDisabled]="formGroup.get('approved')?.value" matTooltipClass="unapproved-tooltip"
                      [class.unapproved]="!formGroup.get('approved')?.value">
                    <!-- Creditor -->
                    <td>
                      <app-input type="select" formControlName="creditor_id" width="160px"
                                selectLabel="name" selectValue="id"
                                [label]="'CASE_CREDITOR.model.relations.creditor' | translate"
                                [selectOptions]="creditors" [showClear]="false" [searchable]="true"
                                (ngModelChange)="checkCreditors($event, 'unsecured_creditors', i)">
                      </app-input>
                    </td>
                    <!-- Current Creditor -->
                    <!-- <td>
                      <app-input type="select" formControlName="current_creditor_id" width="160px"
                                selectLabel="name" selectValue="id"
                                [label]="'CASE_CREDITOR.model.relations.current_creditor' | translate"
                                [selectOptions]="creditors" [showClear]="true" [searchable]="true">
                      </app-input>
                    </td> -->
                    <!-- Debt Type -->
                    <td>
                      <app-input type="select" formControlName="debt_type" width="160px"
                                selectLabel="label" selectValue="value" [selectOptions]="unsecuredDebtTypes"
                                [label]="'CASE_CREDITOR.model.debt_type.label' | translate" [showClear]="true">
                      </app-input>
                    </td>
                    <!-- Current Balance -->
                    <td>
                      <app-input type="number" formControlName="current_balance" width="160px"
                                [label]="'CASE_CREDITOR.model.current_balance' | translate">
                      </app-input>
                    </td>
                    <!-- Monthly Payment -->
                    <td>
                      <app-input type="number" formControlName="monthly_payments" width="160px"
                                [label]="'CASE_CREDITOR.model.monthly_payments' | translate">
                      </app-input>
                    </td>
                    <!-- Total Repaid -->
                    <!-- <td>
                      <app-input type="number" formControlName="total_repaid" width="160px"
                                [label]="'CASE_CREDITOR.model.total_repaid' | translate">
                      </app-input>
                    </td> -->
                    <!-- Initial Balance -->
                    <!-- <td>
                      <app-input type="number" formControlName="initial_balance" width="160px"
                                [label]="'CASE_CREDITOR.model.initial_balance' | translate">
                      </app-input>
                    </td> -->
                    <!--Outcome-->
                    <td *ngIf="hasDmFields && dgsLegalAdvisor">
                      <app-input type="text" width="160px"
                                [label]="getOutcome(formGroup)" [readonly]="true">
                      </app-input>
                    </td>
                    <!-- Make as claim -->
                    <!-- <td>
                      <button mat-raised-button color="primary"
                              (click)="toggleType('unsecured_creditors', i, 'claim')">
                        {{'CASE_CREDITOR.claim.make_as_claim' | translate}}
                      </button>
                    </td> -->
                    <!-- Date Last Paid -->
                    <td>
                      <mat-form-field color="primary" appearance="standard">
                        <mat-label>{{ "CASES.details.last-paid" | translate }}</mat-label>
                        <input matInput [matDatepicker]="lastPaidDate" formControlName="last_paid">
                        <mat-datepicker-toggle matSuffix [for]="lastPaidDate"></mat-datepicker-toggle>
                        <mat-datepicker #lastPaidDate color="primary"></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <!-- Remove -->
                    <td class="d-flex flex-row align-items-center">
                      <!-- Remove -->
                      <button mat-icon-button color="warn" class="ml-2"
                              (click)="removeCreditor('unsecured_creditors',i)">
                        <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                      </button>
                    </td>
                  </tr>
                  <tr class="second-row" [formGroupName]="i" style="border-collapse: separate"
                      [matTooltip]="'CASES.editor.creditors.table.unapproved_tooltip' | translate"
                      [matTooltipDisabled]="formGroup.get('approved')?.value" matTooltipClass="unapproved-tooltip"
                      [class.unapproved]="!formGroup.get('approved')?.value">
                    <td>
                      <span *ngIf="formGroup.get('total_repaid')?.value > formGroup.get('initial_balance')?.value"
                            class="badge badge-success mr-1 mt-1">
                        {{ 'CASES.editor.creditors.table.potential_azcarate' | translate }}
                      </span>
                    </td>
                  </tr>
                  <tr [formGroupName]="i">
                    <td *ngIf="hasDmFields && dgsLegalAdvisor">
                      <app-input type="number" formControlName="tae" width="160px"
                                label="TAE">
                      </app-input>
                    </td>
                    <td *ngIf="hasDmFields && dgsLegalAdvisor">
                      <app-input type="number" formControlName="restructured" width="160px"
                                [label]="'CASE_CREDITOR.model.restructured' | translate">
                      </app-input>
                    </td>
                    <td *ngIf="hasDmFields && dgsLegalAdvisor" class="d-flex flex-column">
                      <p>{{'CASES.single.payments.case_payment_plan_generator.form.amount' | translate}}</p>
                      <p>{{formGroup.get('initial_balance').value - formGroup.get('total_repaid').value }}</p>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
              <!-- Add -->
              <button 
                mat-raised-button color="primary" 
                (click)="addCreditor('unsecured_creditors')"
                *ngIf="case.status_id !== 414 && case.status_id !== 415">
                + {{ 'CASES.editor.creditors.table.actions.add' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Secured Creditors -->
      <div class="row mt-3">
        <div class="col-12">
          <div class="card shadow">
            <div class="card-header">
              <div class="card-title">
                <h3>{{ "CASE_CREDITOR.model.type.options.secured" | translate }}</h3>
              </div>
            </div>
            <div class="card-body">
              <table *ngIf="getFormArray('secured_creditors').controls.length" formArrayName="secured_creditors"
                    class="table">
                <thead>
                <tr>
                  <!-- <th>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th> -->
                  <th>{{ "CASE_CREDITOR.model.relations.creditor" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.debt_type.label" | translate }}</th>
                  <!-- <th>{{ "CASE_CREDITOR.model.type_of_guarantee.label" | translate }}</th> -->
                  <!-- <th>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</th> -->
                  <th>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>
                  <!--<th>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>-->
                  <!--<th>{{ "CASE_CREDITOR.model.judicial_claim" | translate }}</th>-->
                  <!-- <th>{{ "CASES.editor.creditors.table.debt_percentage" | translate }}</th> -->
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let formGroup of getFormArray('secured_creditors').controls let i = index">
                  <tr [formGroupName]="i" class="first-row border-top"
                      [matTooltip]="'CASES.editor.creditors.table.unapproved_tooltip' | translate"
                      [matTooltipDisabled]="formGroup.get('approved')?.value" matTooltipClass="unapproved-tooltip"
                      [class.unapproved]="!formGroup.get('approved')?.value">
                    <!-- Ownership -->
                    <!-- <td>
                      <app-input *ngIf="!case.joint_application" type="select" formControlName="ownership"
                                selectLabel="label" selectValue="value" width="160px"
                                [label]="'CASE_CREDITOR.model.ownership.label' | translate"
                                [selectOptions]="singleOwnershipOptions" [showClear]="false">
                      </app-input>
                      <app-input *ngIf="case.joint_application" type="select" formControlName="ownership"
                                selectLabel="label" selectValue="value" width="160px"
                                [label]="'CASE_CREDITOR.model.ownership.label' | translate"
                                [selectOptions]="jointOwnershipOptions" [showClear]="false">
                      </app-input>
                    </td> -->
                    <!-- Creditor -->
                    <td>
                      <app-input type="select" formControlName="creditor_id" width="160px"
                                selectLabel="name" selectValue="id"
                                [label]="'CASE_CREDITOR.model.relations.creditor' | translate"
                                [selectOptions]="creditors" [showClear]="false" [searchable]="true"
                                (ngModelChange)="checkCreditors($event, 'secured_creditors', i)">
                      </app-input>
                    </td>
                    <!-- Debt Type -->
                    <td>
                      <app-input type="select" formControlName="debt_type" width="160px"
                                selectLabel="label" selectValue="value" [selectOptions]="securedDebtTypes"
                                [label]="'CASE_CREDITOR.model.debt_type.label' | translate" [showClear]="true">
                      </app-input>
                    </td>
                    <!-- Type of Guarantee -->
                    <!-- <td>
                      <app-input type="select" formControlName="type_of_guarantee" width="160px"
                                [selectLabel]="'label'" [selectValue]="'value'" [selectOptions]="typesOfGuarantee"
                                [label]="'CASE_CREDITOR.model.type_of_guarantee.label' | translate">
                      </app-input>
                    </td> -->
                    <!-- Initial Balance -->
                    <!-- <td>
                      <app-input type="number" formControlName="initial_balance" width="160px"
                                [label]="'CASE_CREDITOR.model.initial_balance' | translate">
                      </app-input>
                    </td> -->
                    <!-- Current Balance -->
                    <td>
                      <app-input type="number" formControlName="current_balance" width="160px"
                                [label]="'CASE_CREDITOR.model.current_balance' | translate">
                      </app-input>
                    </td>
                    <!-- Monthly Payments -->
                    <td>
                      <app-input type="number" formControlName="monthly_payments" width="160px"
                                [label]="'CASE_CREDITOR.model.monthly_payments' | translate">
                      </app-input>
                    </td>
                    <!-- Percentace and Remove -->
                    <td class="d-flex flex-row align-items-center flex-grow-1">
                      <!-- Percentage -->
                      <!-- <p>{{totalDebt ? ((+formGroup.get('current_balance').value / totalDebt) * 100).toFixed(1) : 0}}
                        %</p> -->
                      <button mat-icon-button color="warn" class="ml-2"
                              (click)="removeCreditor('secured_creditors',i)">
                        <!-- Remove -->
                        <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                      </button>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
              <!-- Add -->
              <button 
                mat-raised-button color="primary" 
                (click)="addCreditor('secured_creditors')"
                *ngIf="case.status_id !== 414 && case.status_id !== 415" >
                + {{ "CASES.editor.creditors.table.actions.add" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Claims Creditors -->
      <!-- <div *ngIf="authUser?.packager.master" class="row mt-3">
        <div class="col-12">
          <div class="card shadow">
            <div class="card-header">
              <div class="card-title">
                <h3>{{ "CASE_CREDITOR.model.type.options.claim" | translate }}</h3>
              </div>
            </div>
            <div class="card-body">
              <table formArrayName="claim_creditors"
                    class="table">
                <thead>
                <tr>
                  <th>{{ "CASE_CREDITOR.model.ownership.label" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.relations.creditor" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.debt_type.label" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.initial_balance" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.current_balance" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.monthly_payments" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.reference_number" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.judicial_claim" | translate }}</th>
                  <th>{{ "CASE_CREDITOR.model.outcome" | translate }}</th>
                  <th>{{ "CASES.editor.creditors.table.debt_percentage" | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let formGroup of getFormArray('claim_creditors').controls let i = index">
                  <tr [formGroupName]="i" style="border-collapse: separate" class="first-row border-top"
                      [matTooltip]="'CASES.editor.creditors.table.unapproved_tooltip' | translate"
                      [matTooltipDisabled]="formGroup.get('approved')?.value" matTooltipClass="unapproved-tooltip"
                      [class.unapproved]="!formGroup.get('approved')?.value">
                    Ownership
                    <td>
                      <app-input *ngIf="!case.joint_application" type="select" formControlName="ownership"
                                selectLabel="label" selectValue="value" width="160px"
                                [label]="'CASE_CREDITOR.model.ownership.label' | translate"
                                [selectOptions]="singleOwnershipOptions" [showClear]="false">
                      </app-input>
                      <app-input *ngIf="case.joint_application" type="select" formControlName="ownership"
                                selectLabel="label" selectValue="value" width="160px"
                                [label]="'CASE_CREDITOR.model.ownership.label' | translate"
                                [selectOptions]="jointOwnershipOptions" [showClear]="false">
                      </app-input>
                    </td>
                    Creditor
                    <td>
                      <app-input type="select" formControlName="creditor_id" width="160px"
                                selectLabel="name" selectValue="id"
                                [label]="'CASE_CREDITOR.model.relations.creditor' | translate"
                                [selectOptions]="creditors" [showClear]="false" [searchable]="true"
                                (ngModelChange)="checkCreditors($event, 'claim_creditors', i)">
                      </app-input>
                    </td>
                    Debt Type
                    <td>
                      <app-input type="select" formControlName="debt_type" width="160px"
                                selectLabel="label" selectValue="value" [selectOptions]="unsecuredDebtTypes"
                                [label]="'CASE_CREDITOR.model.debt_type.label' | translate" [showClear]="true">
                      </app-input>
                    </td>
                    Initial Balance
                    <td>
                      <app-input type="number" formControlName="initial_balance" width="160px"
                                [label]="'CASE_CREDITOR.model.initial_balance' | translate">
                      </app-input>
                    </td>
                    Current Balance
                    <td>
                      <app-input type="number" formControlName="current_balance" width="160px"
                                [label]="'CASE_CREDITOR.model.current_balance' | translate">
                      </app-input>
                    </td>
                    Monthly Payment
                    <td>
                      <app-input type="number" formControlName="monthly_payments" width="160px"
                                [label]="'CASE_CREDITOR.model.monthly_payments' | translate">
                      </app-input>
                    </td>
                    Outcome
                    <td>
                      <app-input type="text" width="160px"
                                [label]="getOutcome(formGroup)" [readonly]="true">
                      </app-input>
                    </td>
                    Percentage and Remove
                    <td class="d-flex flex-row align-items-center">
                      Percentage
                      <p>
                        {{totalDebt ? ((+formGroup.get('current_balance').value / totalDebt) * 100).toFixed(1) : 0}}%
                      </p>
                      Remove
                      <button mat-icon-button color="warn" class="ml-2"
                              (click)="removeCreditor('claim_creditors',i)">
                        <mat-icon class="text-danger mat-icon">remove_circle</mat-icon>
                      </button>
                    </td>
                  </tr>
                  <tr class="second-row" [formGroupName]="i" style="border-collapse: separate"
                      [matTooltip]="'CASES.editor.creditors.table.unapproved_tooltip' | translate"
                      [matTooltipDisabled]="formGroup.get('approved')?.value" matTooltipClass="unapproved-tooltip"
                      [class.unapproved]="!formGroup.get('approved')?.value">
                    Additional Partner
                    <td>
                      <app-input type="checkbox" formControlName="additional_partner"
                                [label]="'CASE_CREDITOR.model.additional_partner' | translate">
                      </app-input>
                    </td>
                    High Interest
                    <td>
                      Total Repaid
                      <app-input type="number" formControlName="total_repaid" width="160px"
                                [label]="'CASE_CREDITOR.model.total_repaid' | translate">
                      </app-input>
                      <span *ngIf="formGroup.get('total_repaid')?.value > formGroup.get('initial_balance')?.value"
                            class="badge badge-success mr-1 mt-1">
                        {{ 'CASES.editor.creditors.table.potential_azcarate' | translate }}
                      </span>
                    </td>
                    Making Payments
                    <td>
                      <app-input type="checkbox" formControlName="making_payments"
                                [label]="'CASE_CREDITOR.model.making_payments' | translate">
                      </app-input>
                    </td>
                    Has Recovery
                    <td>
                      <app-input type="checkbox" formControlName="has_recovery"
                                [label]="'CASE_CREDITOR.model.has_recovery' | translate"
                                (ngModelChange)="hasRecoveryChanged('unsecured_creditors', $event, i)">
                      </app-input>
                    </td>
                    Recovery Creditor
                    <td *ngIf="formGroup.get('has_recovery')?.value">
                      <app-input type="select" formControlName="recovery_creditor_id" width="160px"
                                selectLabel="name" selectValue="id"
                                [label]="'CASE_CREDITOR.model.relations.creditor' | translate"
                                [selectOptions]="recoveryCreditors" [showClear]="false" [searchable]="true">
                      </app-input>
                    </td>
                    Notes
                    <td [colSpan]="(formGroup.get('lender_type').value === 'high_interest' ||
                    formGroup.get('has_recovery')?.value) ? 1 : 2">
                      <app-input type="textarea" formControlName="notes" [fullWidth]="true"
                                [label]="'CASE_CREDITOR.model.notes' | translate">
                      </app-input>
                    </td>
                    Reference Number
                    <td>
                      <app-input type="text" formControlName="reference_number" width="160px"
                                [label]="'CASE_CREDITOR.model.reference_number' | translate">
                      </app-input>
                    </td>
                    Judicial Claim
                    <td>
                      <app-input type="text" formControlName="judicial_claim" width="160px"
                                [label]="'CASE_CREDITOR.model.judicial_claim' | translate">
                      </app-input>
                    </td>
                  </tr>
                  <tr class="third-row" [formGroupName]="i">
                    Make as unsecured or secured
                    <td *ngIf="formGroup.get('id').value">
                      <button mat-raised-button color="primary" [matMenuTriggerFor]="entitiesList">
                        {{'CASE_CREDITOR.model.make_as' | translate}}
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </button>
                      <mat-menu #entitiesList="matMenu">
                        <button mat-menu-item class="mb-1 mr-md-4"
                                (click)="toggleType('claim_creditors', i, 'unsecured')">
                          {{'CASE_CREDITOR.model.type.options.unsecured' | translate}}
                        </button>
                        <button mat-menu-item class="mb-1 mr-md-4" (click)="toggleType('claim_creditors', i, 'secured')">
                          {{'CASE_CREDITOR.model.type.options.secured' | translate}}
                        </button>
                      </mat-menu>
                    </td>
                    <td>
                      <app-input type="number" formControlName="tae" width="160px"
                                label="TAE">
                      </app-input>
                    </td>
                    <td>
                      <app-input type="number" formControlName="restructured" width="160px"
                                [label]="'CASE_CREDITOR.model.restructured' | translate">
                      </app-input>
                    </td>
                    Amount
                    <td class="d-flex flex-column">
                      <p>{{'CASES.single.payments.case_payment_plan_generator.form.amount' | translate}}</p>
                      <p>{{formGroup.get('initial_balance').value - formGroup.get('total_repaid').value }}</p>
                    </td>
                    Condonar
                    <td>
                      <p>{{'CASES.single.payments.case_payment_plan_generator.form.condone' | translate}}</p>
                      <p>{{getAmountToCondone(
                        (formGroup.get('initial_balance').value - formGroup.get('total_repaid').value),
                        formGroup.get('current_balance').value)}}</p>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
              <div class="d-flex justify-content-between">
                Add
                <button mat-raised-button color="primary" (click)="addCreditor('claim_creditors')">
                  + {{ 'CASES.editor.creditors.table.actions.add' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </form>
</div>
