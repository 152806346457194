<div class="container">
  <mat-card class="mat-elevation-z3">
    <mat-card-header class="d-flex justify-content-center">
      <mat-card-title class="mb-3 d-flex flex-column align-items-center">
        <h2>
          {{'CASES.single.draft.lso_generator.lso_heading' | translate}}
        </h2>
        <span>{{'CASES.single.draft.lso_generator.lso_subheading' | translate}}</span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div [hidden]="!isLoading" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
      <app-server-response *ngIf="serverResponse" [response]="serverResponse"></app-server-response>
      <form *ngIf="form" [formGroup]="form" class="p-2" (ngSubmit)="submitForm(form)">
        <h4><b>{{'CASES.single.draft.lso_generator.lso_debtor_data' | translate}}</b></h4>
        <!-- Data from the Civil Registry -->
        <p><b>{{ 'CASES.single.draft.lso_generator.civil_registry' | translate }}:</b></p>
        <app-input type="text" formControlName="civil_registry"
                   [label]="'CASES.single.draft.lso_generator.civil_registry' | translate" [fullWidth]="true">
        </app-input>
        <!--Employment-->
        <p><b>{{ 'CASES.single.draft.lso_generator.employment_status' | translate }}:</b></p>
        <app-input type="text" formControlName="employment_status" [fullWidth]="true"
                   [label]="'CASES.single.draft.lso_generator.employment_status' | translate" [showClear]="false">
        </app-input>
        <!--Change of address in the last six months -->
        <p><b>{{'CASES.single.draft.lso_generator.address_change' | translate}}:</b></p>
        <mat-radio-group formControlName="address_change">
          <mat-radio-button value="yes" class="mr-2">
            {{'SHARED.yes' | translate}}
          </mat-radio-button>
          <mat-radio-button value="no">
            {{'SHARED.no' | translate}}
          </mat-radio-button>
        </mat-radio-group>
        <div formGroupName="marital_status">
          <!-- Marital status and matrimonial property regime -->
          <p class="mt-3"><b>{{'CASES.single.draft.lso_generator.marriage_data.marriage_status' | translate}}:</b></p>
          <mat-radio-group formControlName="marital_status_value" class="d-flex flex-column"
                           (ngModelChange)="changeSelection($event)">
            <mat-radio-button value="single" class="mr-2">
              {{'CASES.single.draft.lso_generator.marriage_data.singe' | translate}}
            </mat-radio-button>
            <mat-radio-button value="in_relationship">
              {{'CASES.single.draft.lso_generator.marriage_data.domestic_partner' | translate}}
            </mat-radio-button>
            <div
              [ngClass]="{'d-flex flex-row justify-content-center align-items-baseline': is_maritial_status === 'married'}">
              <mat-radio-button value="married">
                {{'CASES.single.draft.lso_generator.marriage_data.married' | translate}}:
              </mat-radio-button>
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.marriage_data.marriage_status' |translate"
                         *ngIf="is_maritial_status === 'married'"
                         formControlName="marital_status_married_value" class="full-width ml-2" [showClear]="false"
                         [selectOptions]="maritialList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div class="d-flex flex-row">
              <mat-radio-button value="divorced">
                {{'CASES.single.draft.lso_generator.marriage_data.divorced' | translate}}
              </mat-radio-button>
              <div class="ml-2" *ngIf="is_maritial_status === 'divorced'">
                <mat-radio-group formControlName="marital_status_divorced" (ngModelChange)="setDivorcedStatus($event)">
                  <mat-radio-button value="yes" class="mr-2">
                    {{'SHARED.yes' | translate}}
                  </mat-radio-button>
                  <mat-radio-button value="no">
                    {{'SHARED.no' | translate}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </mat-radio-group>
        </div>
        <!-- Details of partner -->
        <p class="mt-3"><b>{{'CASES.single.draft.lso_generator.partner.spouse_data' | translate}}:</b></p>
        <div [formGroupName]="'partner'">
          <!--Has the spouse applied for the contest-->
          <div class="d-flex flex-row">
            <p class="mr-2"><b>{{'CASES.single.draft.lso_generator.partner.partner_applied' | translate}}:</b></p>
            <mat-radio-group formControlName="partner_applied">
              <mat-radio-button value="yes" class="mr-2">
                {{'SHARED.yes' | translate}}
              </mat-radio-button>
              <mat-radio-button value="no">
                {{'SHARED.no' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="w-100% d-flex flex-row justify-content-around align-items-end">
            <div style="width: 16%">
              <!--Partner procedures-->
              <p style="margin-bottom: -8px;">{{'CASES.single.draft.lso_generator.partner.procedure' | translate}}</p>
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.partner.procedure' | translate" [fullWidth]="true"
                         formControlName="procedure" class="full-width ml-2" [showClear]="false"
                         [selectOptions]="procedureList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <!-- Partner number -->
              <p>{{'CASES.single.draft.lso_generator.partner.number' | translate}}</p>
              <app-input type="number" formControlName="number"
                         [label]="'CASES.single.draft.lso_generator.partner.number' | translate" [fullWidth]="true">
              </app-input>
            </div>
            <div style="width: 16%">
              <!-- Partner year -->
              <p>{{'CASES.single.draft.lso_generator.partner.year' | translate}}</p>
              <app-input type="number" formControlName="year"
                         [label]="'CASES.single.draft.lso_generator.partner.year' | translate" [fullWidth]="true">
              </app-input>
            </div>
            <div style="width: 16%">
              <p style="margin-bottom: -8px;">{{'CASES.single.draft.lso_generator.partner.court' | translate}}</p>
              <!-- Court -->
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.partner.court' | translate" [fullWidth]="true"
                         formControlName="court" class="full-width ml-2" [showClear]="false"
                         [selectOptions]="courtList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <!-- Partner number two -->
              <p>{{'CASES.single.draft.lso_generator.partner.number' | translate}}</p>
              <app-input type="number" formControlName="number_two"
                         [label]="'CASES.single.draft.lso_generator.partner.number' | translate" [fullWidth]="true">
              </app-input>
            </div>
            <div style="width: 16%">
              <!-- Partner location -->
              <p>{{'CASES.single.draft.lso_generator.partner.location' | translate}}</p>
              <app-input type="text" formControlName="location"
                         [label]="'CASES.single.draft.lso_generator.partner.location' | translate" [fullWidth]="true">
              </app-input>
            </div>
          </div>
          <!--Are they co-owners of the habitual residence?-->
          <p><b>{{'CASES.single.draft.lso_generator.partner.coowners' | translate}}?</b></p>
          <mat-radio-group formControlName="coowners">
            <mat-radio-button value="yes" class="mr-2">
              {{'SHARED.yes' | translate}}
            </mat-radio-button>
            <mat-radio-button value="no">
              {{'SHARED.no' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div [formGroupName]="'debtor'" class="mt-5">
          <h4><b>{{'CASES.single.draft.lso_generator.debtor' | translate}}</b></h4>
          <!--Attorney empowerment-->
          <div class="d-flex flex-row">
            <p class="mr-2">{{'CASES.single.draft.lso_generator.empowerment' | translate}}:</p>
            <mat-radio-group formControlName="empowerment" class="d-flex flex-column">
              <mat-radio-button value="previously_awarded" class="mr-2">
                {{'CASES.single.draft.lso_generator.previously_awarded' | translate}}
              </mat-radio-button>
              <mat-radio-button value="request_granting_apud_act">
                {{'CASES.single.draft.lso_generator.request_granting' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div [formGroupName]="'state_of_insolvency'" class="mt-5">
          <h4 class="mr-2"><b>{{'CASES.single.draft.lso_generator.state_of_insolvency.insolvency_data' | translate}}</b>
          </h4>
          <div class="d-flex flex-row">
            <p class="mr-2"><b>{{'CASES.single.draft.lso_generator.state_of_insolvency.insolvency_classes' | translate}}
              :</b></p>
            <mat-radio-group formControlName="insolvency_class">
              <mat-radio-button value="current" class="mr-2">
                {{'CASES.single.draft.lso_generator.state_of_insolvency.actual' | translate}}
              </mat-radio-button>
              <mat-radio-button value="imminent">
                {{'CASES.single.draft.lso_generator.state_of_insolvency.imminent' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <!--Situation summary-->
          <p><b>{{'CASES.single.draft.lso_generator.state_of_insolvency.insolvency_facts' | translate}}</b></p>
          <app-input type="textarea" formControlName="situation_facts" [showClear]="false"
                     [label]="'CASES.single.draft.lso_generator.state_of_insolvency.insolvency_facts' | translate"
                     [fullWidth]="true">
          </app-input>
          <!--Ceased activity-->
          <div class="d-flex flex-row">
            <p class="mr-2"><b>{{'CASES.single.draft.lso_generator.state_of_insolvency.ceased_activity' | translate}}
              ?:</b></p>
            <mat-radio-group formControlName="ceased_activity">
              <mat-radio-button value="yes" class="mr-2">
                {{'SHARED.yes' | translate}}
              </mat-radio-button>
              <mat-radio-button value="no">
                {{'SHARED.no' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <!--Number of workers-->
          <p><b>{{'CASES.single.draft.lso_generator.state_of_insolvency.number_of_workers' | translate}}:</b></p>
          <app-input type="number" formControlName="number_of_workers" [showClear]="false"
                     [label]="'CASES.single.draft.lso_generator.state_of_insolvency.number_of_workers' | translate"
                     [fullWidth]="true">
          </app-input>
          <div [formGroupName]="'fees_received'">
            <p><b>{{'CASES.single.draft.lso_generator.state_of_insolvency.fees_received' | translate}}:</b></p>
            <!--Lawyer amount-->
            <app-input type="number" formControlName="lawyer_amount" [showClear]="false"
                       [label]="'CASES.single.draft.lso_generator.state_of_insolvency.lawyer_amount' | translate"
                       [fullWidth]="true"
                       suffix="euros">
            </app-input>
            <!--Attorney amount-->
            <app-input type="number" formControlName="attorney_amount" [showClear]="false"
                       [label]="'CASES.single.draft.lso_generator.state_of_insolvency.attorney_amount' | translate"
                       [fullWidth]="true"
                       suffix="euros">
            </app-input>
          </div>
        </div>
        <div [formGroupName]="'competition_solution'" class="mt-5">
          <h4><b>{{'CASES.single.draft.lso_generator.solution.competition_solution' | translate}}</b></h4>
          <mat-radio-group formControlName="solution" class="d-flex flex-column">
            <mat-radio-button value="propuesta_anticipada_de_convenio" class="mr-2">
              {{'CASES.single.draft.lso_generator.solution.advance_agreement' | translate}}
            </mat-radio-button>
            <mat-radio-button value="convenio">
              {{'CASES.single.draft.lso_generator.solution.health_insurance' | translate}}
            </mat-radio-button>
            <mat-radio-button value="liquidacion">
              {{'CASES.single.draft.lso_generator.solution.settlement' | translate}}
            </mat-radio-button>
            <mat-radio-button value="plan_de_liquidacion_con_propuesta vinculante_de_compra_de_unidad_productiva">
              {{'CASES.single.draft.lso_generator.solution.liquidation_plan' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div [formGroupName]="'application_documentation'" class="mt-5 mb-2">
          <h4><b>{{'CASES.single.draft.lso_generator.documentation.application_documentation' | translate}}</b></h4>
          <app-input type="checkbox" formControlName="special_power" appearance="standard"
                     [label]="'CASES.single.draft.lso_generator.documentation.special_power' | translate"
                     style="height: 30px;display: flex">
          </app-input>
          <app-input type="checkbox" formControlName="economic_and_legal_memory" appearance="standard"
                     [label]="'CASES.single.draft.lso_generator.documentation.economic_and_legal_memory' | translate"
                     style="height: 30px;display: flex">
          </app-input>
          <app-input type="checkbox" formControlName="inventory_of_assets_and_rights" appearance="standard"
                     [label]="'CASES.single.draft.lso_generator.documentation.inventory_of_assets_and_rights' | translate"
                     style="height: 30px;display: flex">
          </app-input>
          <app-input type="checkbox" formControlName="list_of_creditors" appearance="standard"
                     [label]="'CASES.single.draft.lso_generator.documentation.list_of_creditors' | translate"
                     style="height: 30px;display: flex">
          </app-input>
          <app-input type="checkbox" formControlName="staff_of_workers" appearance="standard"
                     [label]="'CASES.single.draft.lso_generator.documentation.staff_of_workers' | translate"
                     style="height: 30px;display: flex">
          </app-input>
          <app-input type="checkbox" formControlName="early_agreement_proposal" appearance="standard"
                     [label]="'CASES.single.draft.lso_generator.documentation.early_agreement_proposal' | translate"
                     style="height: 30px;display: flex">
          </app-input>
          <app-input type="checkbox" formControlName="accessions" appearance="standard"
                     [label]="'CASES.single.draft.lso_generator.documentation.accessions' | translate"
                     style="height: 30px;display: flex">
          </app-input>
          <app-input type="checkbox" formControlName="settlement_plan" appearance="standard"
                     [label]="'CASES.single.draft.lso_generator.documentation.settlement_plan' | translate"
                     style="height: 30px;display: flex">
          </app-input>
          <app-input type="checkbox" formControlName="other_documents" appearance="standard"
                     [label]="'CASES.single.draft.lso_generator.documentation.other_documents' | translate"
                     style="height: 30px;display: flex">
          </app-input>
        </div>
        <!--Observations-->
        <div class="mt-5">
          <h4><b>{{'CASES.single.draft.lso_generator.observations' | translate}}</b></h4>
          <app-input type="textarea" formControlName="observations" [showClear]="false"
                     [label]="'CASES.single.draft.lso_generator.observations' | translate" [fullWidth]="true">
          </app-input>
        </div>
        <div [formGroupName]="'records'" class="mt-2">
          <h4>
            <b>{{'CASES.single.draft.lso_generator.records.record' | translate}}</b>({{'CASES.single.draft.lso_generator.records.record_purpose' | translate}}
            )</h4>
          <div [formGroupName]="'record_one'" class="w-100% d-flex flex-row justify-content-around align-items-end">
            <div style="width: 16%">
              <p>{{'CASES.single.draft.lso_generator.records.procedure' | translate}}</p>
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.procedure' | translate" [fullWidth]="true"
                         formControlName="procedure" class="full-width" [showClear]="false"
                         [selectOptions]="procedureRecordsList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <p>{{'CASES.single.draft.lso_generator.records.number' | translate}}</p>
              <app-input type="number" formControlName="number_one"
                         [label]="'CASES.single.draft.lso_generator.records.number' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <p>{{'CASES.single.draft.lso_generator.records.year' | translate}}</p>
              <app-input type="number" formControlName="year"
                         [label]="'CASES.single.draft.lso_generator.records.year' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <p style="margin-bottom: -8px;">{{'CASES.single.draft.lso_generator.records.court' | translate}}</p>
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.records.court' | translate" [fullWidth]="true"
                         formControlName="court" class="full-width ml-2" [showClear]="false"
                         [selectOptions]="courtList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <p>{{'CASES.single.draft.lso_generator.records.number' | translate}}</p>
              <app-input type="number" formControlName="number_two"
                         [label]="'CASES.single.draft.lso_generator.records.number' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <p>{{'CASES.single.draft.lso_generator.records.location' | translate}}</p>
              <app-input type="text" formControlName="location"
                         [label]="'CASES.single.draft.lso_generator.records.location' | translate"
                         [fullWidth]="true"></app-input>
            </div>
          </div>
          <div [formGroupName]="'record_two'" class="w-100% d-flex flex-row justify-content-around align-items-end">
            <div style="width: 16%">
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.records.procedure' | translate" [fullWidth]="true"
                         formControlName="procedure" class="full-width" [showClear]="false"
                         [selectOptions]="procedureRecordsList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="number_one"
                         [label]="'CASES.single.draft.lso_generator.records.number' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="year"
                         [label]="'CASES.single.draft.lso_generator.records.year' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.records.court' | translate" [fullWidth]="true"
                         formControlName="court" class="full-width ml-2" [showClear]="false"
                         [selectOptions]="courtList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="number_two"
                         [label]="'CASES.single.draft.lso_generator.records.number' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="text" formControlName="location"
                         [label]="'CASES.single.draft.lso_generator.records.location' | translate"
                         [fullWidth]="true"></app-input>
            </div>
          </div>
          <div [formGroupName]="'record_three'" class="w-100% d-flex flex-row justify-content-around align-items-end">
            <div style="width: 16%">
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.records.procedure' | translate" [fullWidth]="true"
                         formControlName="procedure" class="full-width" [showClear]="false"
                         [selectOptions]="procedureRecordsList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="number_one"
                         [label]="'CASES.single.draft.lso_generator.records.number' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="year"
                         [label]="'CASES.single.draft.lso_generator.records.year' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.records.court' | translate" [fullWidth]="true"
                         formControlName="court" class="full-width ml-2" [showClear]="false"
                         [selectOptions]="courtList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="number_two"
                         [label]="'CASES.single.draft.lso_generator.records.number' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="text" formControlName="location"
                         [label]="'CASES.single.draft.lso_generator.records.location' | translate"
                         [fullWidth]="true"></app-input>
            </div>
          </div>
          <div [formGroupName]="'record_four'" class="w-100% d-flex flex-row justify-content-around align-items-end">
            <div style="width: 16%">
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.records.procedure' | translate" [fullWidth]="true"
                         formControlName="procedure" class="full-width" [showClear]="false"
                         [selectOptions]="procedureRecordsList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="number_one"
                         [label]="'CASES.single.draft.lso_generator.records.number' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="year"
                         [label]="'CASES.single.draft.lso_generator.records.year' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.records.court' | translate" [fullWidth]="true"
                         formControlName="court" class="full-width ml-2" [showClear]="false"
                         [selectOptions]="courtList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="number_two"
                         [label]="'CASES.single.draft.lso_generator.records.number' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="text" formControlName="location"
                         [label]="'CASES.single.draft.lso_generator.records.location' | translate"
                         [fullWidth]="true"></app-input>
            </div>
          </div>
          <div [formGroupName]="'record_five'" class="w-100% d-flex flex-row justify-content-around align-items-end">
            <div style="width: 16%">
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.records.procedure' | translate" [fullWidth]="true"
                         formControlName="procedure" class="full-width" [showClear]="false"
                         [selectOptions]="procedureRecordsList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="number_one"
                         [label]="'CASES.single.draft.lso_generator.records.number' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="year"
                         [label]="'CASES.single.draft.lso_generator.records.year' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="select" appearance="outline"
                         [label]="'CASES.single.draft.lso_generator.records.court' | translate" [fullWidth]="true"
                         formControlName="court" class="full-width ml-2" [showClear]="false"
                         [selectOptions]="courtList" [selectLabel]="'label'" [selectValue]="'value'">
              </app-input>
            </div>
            <div style="width: 16%">
              <app-input type="number" formControlName="number_two"
                         [label]="'CASES.single.draft.lso_generator.records.number' | translate"
                         [fullWidth]="true"></app-input>
            </div>
            <div style="width: 16%">
              <app-input type="text" formControlName="location"
                         [label]="'CASES.single.draft.lso_generator.records.location' | translate"
                         [fullWidth]="true"></app-input>
            </div>
          </div>
        </div>
        <!--Location-->
        <p><b>{{'CASES.single.draft.lso_generator.signature_location' | translate}}:</b></p>
        <app-input type="text" formControlName="signature_location"
                   [label]="'CASES.single.draft.lso_generator.signature_location' | translate" [fullWidth]="true">
        </app-input>
        <!-- Signature Date -->
        <app-input type="mat-datepicker" formControlName="signature_date" appearance="standard"
                   [label]="'CASES.single.draft.lso_generator.date' | translate" [fullWidth]="true">
        </app-input>
        <!-- Lawyer  -->
        <p><b>{{'CASES.single.draft.lso_generator.lawyer' | translate}}</b></p>
        <app-input type="text" formControlName="lawyer"
                   [label]="'CASES.single.draft.demand_generator.lawyer' | translate" [fullWidth]="true">
        </app-input>
        <div class="row justify-content-between">
          <div class="col-4">
          </div>
          <div class="col-2 d-flex justify-content-center align-items-center">
            <!-- Submit -->
            <div class="d-flex justify-content-end">
              <button mat-raised-button color="primary" class="ml-2" [matMenuTriggerFor]="assetList"
                      aria-label="Send notification menu" type="button">
                {{'CASES.single.draft.lso_generator.generate' | translate}}
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #assetList="matMenu">
                <button mat-menu-item (click)="chooseDocsType('doc', form)"
                        value="word">
                  <span>{{"CASES.single.draft.demand_generator.generate_word" | translate}}</span></button>
                <button mat-menu-item (click)="chooseDocsType('pdf', form)"
                        value="pdf">
                  <span>{{"CASES.single.draft.demand_generator.generate_pdf" | translate}}</span></button>
              </mat-menu>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
