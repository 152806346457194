import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { MainGlobalEventService } from '../services/main-global-event.service';

@Injectable({
  providedIn: 'root'
})
export class PackagerCasesPermissionGuard implements CanActivate {

  constructor(public router: Router,
              private globalEventsService: MainGlobalEventService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.globalEventsService.authUser$.pipe(
      filter(result => (result !== null)),
      map(res => this.checkPermission(res), take(1)),
    );
  }

  private checkPermission(user): boolean {
    if (!user) {
      return false;
    }

    if (user.id === 27 || user.id === 36 || user.id === 2497 || user.id === 34 || user.id === 1) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
    return false;
  }
}
