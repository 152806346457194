import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { MiscConfigService } from '../../../../config/misc-config/misc-config.service';
import { CaseCreditor } from "projects/_base-shared/models/Case/CaseCreditor";
import { CaseService } from '../../../case.service';


@Component({
    selector: 'app-case-creditor-info',
    templateUrl: './quick-win-info.component.html'
})

export class QuickWinInfoComponent implements OnInit {
    defaultNegotiationPercent: number;
    defaultTaxRate: number;

    public creditorFee: number;
    public negotiationFee: number;
    public quickWinFee: number;

    constructor(
        public dialogRef: MatDialogRef<QuickWinInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private configService: MiscConfigService,
        private caseService: CaseService,
    ) {
    }

    ngOnInit() {
        this.getConfigData();
        console.log(this.data);
    }

    // calculate negotiation fee
    calculateNegotiationFee(creditor: CaseCreditor): number {
        let amount = (creditor.pivot.debt_amount - creditor.pivot.negotiated_amount) * this.defaultNegotiationPercent / 100;
        return amount + (amount * this.defaultTaxRate / 100);;
    }

    private getConfigData(): void {
        this.configService.getConfigData().subscribe(next => {
            this.defaultNegotiationPercent = next.data.find(obj => obj.key === 'negotiation_fee_percentage').value;
            this.defaultTaxRate            = next.data.find(obj => obj.key === 'default_iva_rate').value;

            this.creditorFee = this.data.creditor.pivot.negotiated_amount ?? 0;
            this.negotiationFee = this.calculateNegotiationFee(this.data.creditor) ?? 0;
            this.quickWinFee = +this.creditorFee + +this.negotiationFee ?? 0;
        });
    }

    public clickToCall(phone_number: string): void {
        this.caseService.call({phone_number}).subscribe((response) => {
        });
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}
