<div class="d-flex flex-column flex-md-row justify-content-between pb-2">
  <div class="d-flex flex-row align-items-center">
    <h1 class="m-0">{{ 'CASES.single.heading' | translate }}
      <span class="primary-color-text" style="font-size: 18px">{{ case?.ref_number | uppercase }}</span>
      <span *ngIf="case?.is_test"> - {{ 'CASES.single.case_flags.is_test' | translate }}
        <mat-icon>warning</mat-icon>
      </span>
    </h1>
    <mat-icon *ngIf="unapprovedCreditors" class="ml-2" color="warn" matTooltipClass="unapproved-tooltip"
              [matTooltip]="'CASES.single.case_flags.has_unapproved_creditor'| translate">
      report
    </mat-icon>
  </div>
  <div *ngIf="!isLoading && !showSnapshot" class="d-flex">
    <a mat-raised-button color="primary" class="mr-2 duplicate-btn"
       *ngIf="authUser.id === 1 || authUser.id === 22 || authUser.id === 27 || authUser.id === 2497"
       (click)="onDuplicateCase()">
      {{'CASES.single.actions.duplicate_case' | translate}}
    </a>
    <button *ngIf="!authUser?.packager?.master && authUser.show_transfer_button && !showSnapshot" mat-raised-button
            color="primary" class="mr-2 transfer-btn" (click)="requestTransfer()">
      {{ 'CASES.single.actions.transfer.request.label' | translate }}
    </button>
    <button *ngIf="authUser?.packager?.master && authUser?.role.slug === 'super-admin' &&
     (case?.transfer_request && !(!!case.transfer_request.resolved_at))"
            mat-raised-button color="primary" class="ml-2" [matMenuTriggerFor]="transferCaseMenu">
      {{ "CASES.single.actions.transfer.resolve_request.label" | translate }}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #transferCaseMenu="matMenu">
      <button mat-menu-item (click)="acceptTransfer()">
        {{ 'CASES.single.actions.transfer.accept.label' | translate }}
      </button>
      <button mat-menu-item (click)="rejectTransfer()">
        {{ 'CASES.single.actions.transfer.reject.label' | translate }}
      </button>
    </mat-menu>
    <button mat-raised-button color="primary" (click)="addNewTask(case)" class="ml-2">
      {{ "CASES.single.actions.create_task" | translate }}
    </button>
    <button mat-raised-button color="primary" class="ml-2" [matMenuTriggerFor]="sendNotificationMenu"
            aria-label="Send notification menu">
      {{ "CASES.single.actions.send_notification" | translate }}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #sendNotificationMenu="matMenu">
      <button mat-menu-item (click)="openNotificationModal($event, case.id, 'email')">
        Email
      </button>
      <button mat-menu-item (click)="openNotificationModal($event, case.id, 'sms')">
        SMS
      </button>
      <button mat-menu-item (click)="openNotificationModal($event, case.id, 'all')">
        Email & SMS
      </button>
    </mat-menu>
    <a mat-raised-button color="primary" class="ml-2" [routerLink]="['/cases', case?.id, 'edit', 'general']">
      {{ "CASES.single.actions.edit" | translate }}
    </a>
  </div>
</div>
<div *ngIf="isLoading" class="row">
  <div class="col-12 pt-5 text-center">
    <mat-spinner class="m-0 m-auto"></mat-spinner>
  </div>
</div>

<nav *ngIf="case" mat-tab-nav-bar dynamicHeight>
  <ng-container *ngIf="!showSnapshot">
    <a mat-tab-link [routerLink]="['/cases', case.id, 'general']" routerLinkActive
       #rla="routerLinkActive" [active]="rla.isActive">
      {{ 'CASES.single.general.heading'  | translate }}
    </a>
    <a mat-tab-link [routerLink]="['/cases', case.id, showOldDocModule ? 'documents' : 'documents-v2']" routerLinkActive
       #rla1="routerLinkActive" [active]="rla1.isActive">
      {{ 'CASES.single.documents.heading'  | translate }}
    </a>
    <a mat-tab-link [routerLink]="['/cases', case.id,  'creditors']" routerLinkActive
       #rla2="routerLinkActive" [active]="rla2.isActive" *ngIf="authUser?.packager?.master">
      {{ 'CASES.single.creditors.heading'  | translate }}
    </a>
<!--    <a mat-tab-link [routerLink]="['/cases', case.id,  'legal']" routerLinkActive-->
<!--       #rla3="routerLinkActive" [active]="rla3.isActive" *ngIf="authUser?.packager?.master && showLegalTab">-->
<!--      {{ 'CASES.single.draft.heading'  | translate }}-->
<!--    </a>-->
    <a mat-tab-link [routerLink]="['/cases', case.id, 'loan-calculator']" routerLinkActive
      #rla3="routerLinkActive" [active]="rla3.isActive">
        {{ 'CASES.single.loan-calculator.heading' | translate }}
    </a>

    <a mat-tab-link [routerLink]="['/cases', case.id, 'payments']" routerLinkActive
       #rla4="routerLinkActive" [active]="rla4.isActive" *ngIf="authUser?.packager?.master">
      {{ 'CASES.single.payments.heading'  | translate }}
    </a>
    <a *ngIf="showNewDocumentsTab" mat-tab-link [routerLink]="['/cases', case.id, 'documents-new']"
       routerLinkActive #rla5="routerLinkActive" [active]="rla5.isActive">
      {{ 'CASES.single.documents_new.heading'  | translate }}
    </a>
  </ng-container>
  <a *ngIf="showSnapshot" mat-tab-link [routerLink]="['/cases', case.id, 'snapshot']"
     routerLinkActive #rla5="routerLinkActive" [active]="rla5.isActive">
    {{ 'CASES.single.snapshot.heading' |translate }}
  </a>
</nav>
<router-outlet></router-outlet>
<app-floating-note [case]="case"></app-floating-note>
