<div class="card shadow mb-4 h-100">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center" style="height: 40px">
      <div>
        <h3>{{ 'CASES.single.general.department_editor.heading' | translate }}</h3>
      </div>
      <div *ngIf="!isEditing" class="col-5 text-right">
        <button mat-icon-button color="primary" (click)="toggleEditMode(true)" class="p-0">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="card-body d-flex flex-column">
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center">
      <mat-spinner diameter="30" color="primary"></mat-spinner>
    </div>
    <form class="disabled-form" *ngIf="formActive" [hidden]="isLoading" [formGroup]="form"
          (ngSubmit)="submitForm(form)">
      <div *ngFor="let departmentCategory of filteredDepartmentCategories">
        <!--<h3>{{ departmentCategory.name }}</h3>-->
        <div *ngFor="let department of departmentCategory.departments">
          <app-input type="select" appearance="standard" class="m-2" style="display: block" [searchable]="true"
                     [fullWidth]="true" *ngIf="department.active"
                     [formControlName]="department.type" [extraLabel]="true" [selectOptions]="department.users"
                     [selectValue]="'id'" [label]="department.name" [selectLabel]="['first_name', 'last_name']">
          </app-input>
        </div>
      </div>
      <div class="ml-2 row" *ngIf="!case?.original_packager?.master" style="margin-top: 17px;">
        <p class="col" style="padding: 0">{{'PACKAGER.model_name.original_packager' | translate}}:</p>
        <p class="col" style="padding: 0;margin-right: 18px;">{{case.original_packager.name_en}}</p>
      </div>
      <div class="ml-2 row" *ngIf="!case?.original_packager?.master" style="margin-top: 17px;">
        <p class="col" style="padding: 0">{{'PACKAGER.model_name.current_packager' | translate}}:</p>
        <p class="col" style="padding: 0;margin-right: 18px;">{{case.packager.name_en}}</p>
      </div>
      <div *ngIf="isEditing" class="row pt-3">
        <div class="col-12 text-right">
          <button mat-button color="primary" class="mr-3" (click)="toggleEditMode(false)">
            {{ 'SHARED.cancel' | translate }}
          </button>
          <app-spinner-btn type="submit" [loading]="isSubmitting" color="primary">
            {{ 'SHARED.save' | translate }}
          </app-spinner-btn>
        </div>
      </div>
    </form>
  </div>
</div>
