import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { CasePublicDebt } from '../../../../../../../../../_base-shared/models/Case/CasePublicDebt';
import { CaseService } from '../../../../case.service';

@Component({
  selector:    'app-case-creditor-note-list',
  templateUrl: './case-creditor-note-list.component.html',
  styles:      [],
})
export class CaseCreditorNoteListComponent implements OnInit {
  @Input() case: Case;
  @Output() caseCreditorsLoaded: EventEmitter<Case>         = new EventEmitter<Case>();
  public isLoading                                          = 0;
  public securedCreditors: Array<CaseCreditor>;
  public unsecuredCreditors: Array<CaseCreditor>;
  public publicDebts: Array<CasePublicDebt>;
  public allCreditors: Array<CaseCreditor | CasePublicDebt> = [];

  constructor(private caseService: CaseService) {
  }

  ngOnInit(): void {
    this.loadCaseCreditors(this.case);
  }

  private loadCaseCreditors(clientCase: Case) {
    this.isLoading++;
    this.caseService.get(clientCase.id, ['secured_creditors', 'unsecured_creditors', 'public_debts'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            result => {
              this.case.secured_creditors   = result.data.secured_creditors;
              this.case.unsecured_creditors = result.data.unsecured_creditors;
              this.case.public_debts        = result.data.public_debts;
              this.setComponentCreditors();
              this.caseCreditorsLoaded.emit(this.case);
            },
        );
  }

  private setComponentCreditors() {
    this.securedCreditors   = this.case.secured_creditors ? this.case.secured_creditors : [];
    this.unsecuredCreditors = this.case.unsecured_creditors ? this.case.unsecured_creditors : [];
    this.publicDebts        = this.case.public_debts ? this.case.public_debts : [];
    this.allCreditors       = this.securedCreditors.concat(this.unsecuredCreditors);
    this.allCreditors       = this.allCreditors.concat(this.publicDebts);
  }
}
