import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { DistributionProvider } from '../../../../../../../_base-shared/models/Distribution/DistributionProvider';
import { PaymentProcessor } from '../../../../../../../_base-shared/models/Payment/PaymentProcessor';
import { Product } from '../../../../../../../_base-shared/models/Product';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { ProductService } from '../../../case/product.service';
import { PaymentProcessorService } from '../../../payment/payment-processor.service';
import { UserService } from '../../../user/user.service';
import { DistributionProviderService } from '../../distribution-provider.service';

@Component({
  selector:    'app-distribution-provider-editor',
  templateUrl: './distribution-provider-editor.component.html',
  styles:      [],
})
export class DistributionProviderEditorComponent implements OnInit {
  public editorType: 'create' | 'edit';
  public isLoading                                  = 0;
  public isSubmitting: boolean;
  public distributionProvider: DistributionProvider;
  public serverResponse: LaravelResourceResponse;
  public form: UntypedFormGroup;
  public products: Array<Product>                   = [];
  public paymentProcessors: Array<PaymentProcessor> = [];
  public distributionUsers: Array<User>             = [];

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private fb: UntypedFormBuilder,
      private translate: TranslateService,
      private toastr: ToastrService,
      private distributionProviderService: DistributionProviderService,
      private productService: ProductService,
      private paymentProcessorService: PaymentProcessorService,
      private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.editorType = data.editorType;
      this.fetchProducts();
      this.fetchDistributionPaymentProcessors();
      this.fetchDistributionUsers();
      if (this.editorType === 'edit') {
        this.route.paramMap.subscribe(params => {
          const providerId = +params.get('id');
          this.fetchDistributionProvider(providerId);
        });
      } else {
        this.buildForm(new DistributionProvider());
      }
    });
  }

  public submitForm(form: UntypedFormGroup) {
    this.serverResponse = null;
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    let formObserver: Observable<LaravelResourceResponse<DistributionProvider>>;
    if (this.editorType === 'create') {
      formObserver = this.distributionProviderService.store(form.value);
    } else {
      formObserver = this.distributionProviderService.update(this.distributionProvider.id, form.value);
    }

    this.isSubmitting = true;
    formObserver.pipe(finalize(() => this.isSubmitting = false)).subscribe(
        result => {
          this.editorType === 'create' ?
              this.toastr.success(this.translate.instant('SHARED.submit_result.create.success',
                  {model: this.translate.instant('DISTRIBUTION.provider.model_name.singular')})) :
              this.toastr.success(this.translate.instant('SHARED.submit_result.update.success',
                  {model: this.translate.instant('DISTRIBUTION.provider.model_name.singular')}));
          this.router.navigate(['/distribution', 'providers']);
        },
        error => {
          this.serverResponse = error.error;
          this.editorType === 'create' ?
              this.toastr.error(this.translate.instant('SHARED.submit_result.create.error',
                  {model: this.translate.instant('DISTRIBUTION.provider.model_name.singular')})) :
              this.toastr.error(this.translate.instant('SHARED.submit_result.update.error',
                  {model: this.translate.instant('DISTRIBUTION.provider.model_name.singular')}));
        },
    );
  }

  private buildForm(distributionProvider: DistributionProvider) {
    this.form = this.fb.group({
      payment_processor_id:      [distributionProvider.payment_processor_id],
      product_ids:               [distributionProvider.products ? distributionProvider.products.map(p => p.id) : null],
      user_ids:                  [distributionProvider.users ? distributionProvider.users.map(u => u.id) : null],
      company_name:              [distributionProvider.company_name, [Validators.required]],
      min_contract_value:        [distributionProvider.min_contract_value, []],
      min_monthly_payment:       [distributionProvider.min_monthly_payment, []],
      max_installments:          [distributionProvider.max_installments, []],
      funding_amount_percentage: [distributionProvider.funding_amount_percentage, [Validators.required]],
      cash_hurdle_percentage:    [distributionProvider.cash_hurdle_percentage, [Validators.required]],
      retention_percentage:      [distributionProvider.retention_percentage, []],
      client_account_iban:       [
        distributionProvider.client_account_iban,
        [Validators.minLength(34), Validators.maxLength(34)],
      ],
      payment_iban:              [
        distributionProvider.payment_iban,
        [Validators.minLength(34), Validators.maxLength(34)],
      ],
      address:                   [distributionProvider.address, []],
      contact_person:            [distributionProvider.contact_person, []],
      email:                     [distributionProvider.email, [Validators.email]],
      phone:                     [
        distributionProvider.phone,
        [
          Validators.maxLength(9),
          Validators.minLength(9),
          Validators.pattern('(6|7|9)([0-9])\\w+'),
        ],
      ],
    });
  }

  private fetchDistributionProvider(providerId: number) {
    this.isLoading++;
    this.distributionProviderService.show(providerId, ['products', 'users'])
        .pipe(finalize(() => this.isLoading--))
        .subscribe(result => {
              this.distributionProvider = result.data;
              this.buildForm(this.distributionProvider);
            },
            () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
  }

  private fetchProducts() {
    this.isLoading++;
    this.productService.index({select_all: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.products = result.data,
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }

  private fetchDistributionPaymentProcessors() {
    this.isLoading++;
    this.paymentProcessorService.index({select_all: 1, distribution_selectable: 1})
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            result => this.paymentProcessors = result.data,
            () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
  }

  private fetchDistributionUsers() {
    this.isLoading++;
    this.userService.index({select_all: 1, role_slugs: ['distribution-provider']})
        .pipe(finalize(() => this.isLoading--))
        .subscribe(
            result => this.distributionUsers = result.data,
            () => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
        );
  }
}
