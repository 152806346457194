import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FinancialOverview } from '../../../../../../../_base-shared/models/Payment/FinancialOverview';
import { FinancialOverviewService } from '../../../payment/financial-overview.service';
import { TargetMonthlyPaymentComponent } from '../case-expense-editor/target-monthly-modal/target-monthly.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseService } from '../../case.service';
import { User } from 'projects/_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';

@Component({
  selector:    'app-case-finance-overview',
  templateUrl: './case-finance-overview.component.html',
  styleUrls:   ['./case-finance-overview.component.scss'],
})
export class CaseFinanceOverviewComponent implements OnInit, OnDestroy {
  @Input() caseId: number;
  public financialOverview: FinancialOverview;
  public isLoading = 0;
  public case: Case;
  public authUser: User;

  @Input() debt: number;
  @Input() unsecuredDebt: number;
  @Input() monthlyPayment: number;
  @Input() assets: number;
  @Input() income: number;
  @Input() expenses: number;

  private subscriptions: Array<Subscription> = [];

  constructor(
    private financialOverviewService: FinancialOverviewService,
    private dialog: MatDialog,
    private caseService: CaseService,
    private globalEventsService: MainGlobalEventService,
  ) {
  }

  ngOnInit(): void {
    this.getFinancialOverview(this.caseId);
    this.caseService.get(this.caseId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
      this.case = result.data;
    });

    this.subscriptions.push(this.globalEventsService.authUser$.subscribe(user => {
      this.authUser = user;
    }));
  }

  ngOnDestroy(): void {
    console.log('destroying case finance');
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.financialOverviewService.updateFinancialOverviewLocally(null);
  }

  public refreshFinancialOverview(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
        this.financialOverviewService.fetchLatest(caseId).pipe(finalize(() => this.isLoading--)).subscribe(result => {
          console.log('fetched latest financial overview');
        }),
    );
  }

  private getFinancialOverview(caseId: number) {
    this.isLoading++;
    this.subscriptions.push(
        this.financialOverviewService.financialOverview$.pipe(finalize(() => this.isLoading--)).subscribe(result => {
          console.log('received financial overview result');
          if (result) {
            this.financialOverview = result;
          } else {
            console.log('no financial overview, fetching latest');
            this.refreshFinancialOverview(caseId);
          }
        }),
    );
  }

  public getMonthlyPayment() {
    const dialogRef = this.dialog.open(TargetMonthlyPaymentComponent, {
      width: 'fit-content',
      height: 'fit-content',
      data: {
        case: this.case
      },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshFinancialOverview(this.caseId);
    });
  }
}
