<h1 mat-dialog-title>Creditor Negotiation Report</h1>
<div class="pt-3">
  <form [formGroup]="form" (ngSubmit)="sendReportData()">

    <mat-form-field appearance="fill" class="mr-1">
      <mat-label>
        {{ "REPORTS.financial-reports.list.negotiation-report.fields.start-date" | translate }}
      </mat-label>
      <input matInput [matDatepicker]="start_date" formControlName="start_date">
      <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
      <mat-datepicker #start_date></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>
        {{'CREDITORS.status' | translate}}
      </mat-label><input matInput style="display:none;">
      <app-input *ngIf="allStatuses.length" type="select"
        formControlName="status" [extraLabel]="true" [fullWidth]="true" [searchable]="true"
        [label]="'CREDITORS.status' | translate" [selectOptions]="filteredStatusCategories" [optGroupProperty]="'statuses'"
        [selectLabel]="'name'" [selectValue]="'id'">
      </app-input>
    </mat-form-field>

    <div class='pt-2 text-right'>
      <button type="button" mat-button color="primary" class="mt-3 ml-2" (click)="dialogRef.close()">
        {{ "REPORTS.financial-reports.list.negotiation-report.actions.close" | translate }}
      </button>
      <button type="submit" mat-raised-button color="primary" class="mt-3">
        {{ "REPORTS.financial-reports.list.negotiation-report.actions.submit" | translate }}
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-white" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
      </button>
    </div>
  </form>
</div>
