import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { ReportService } from '../../report.service';
import { Creditor } from 'projects/_base-shared/models/Entity/Creditor';
import { CreditorService } from '../../../creditor/creditor.service';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { Status } from 'projects/_base-shared/models/Status/Status';
import { StatusCategory } from 'projects/_base-shared/models/Status/StatusCategory';

@Component({
  selector:    'bulk-dm-report-modal',
  templateUrl: './bulkdm-report-modal.component.html',
  styleUrls:   ['./bulkdm-report-modal.component.scss'],
})
export class BulkDmModalComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = 0;
  public creditors: Array<Creditor> = [];
  public statusCategories: Array<StatusCategory>         = [];
  public filteredStatusCategories: Array<StatusCategory> = [];
  public allStatuses: Array<Status>                      = [];
  public amountPaid: Array<AppSelectOption> = [
    {label: '125', value: '125'},
    {label: '250', value: '250'},
    {label: '375', value: '375'},
    {label: '500', value: '500'},
    {label: '750', value: '750'},
  ]
  public reportOptions: Array<AppSelectOption> = [
    {label: this.translate.instant('bulk_dm_report.full_bulk_dm_report'), value: 'full_bulk_dm_report'},
    {label: this.translate.instant('bulk_dm_report.full_microloans_collections_report'), value: 'full_microloans_collections_report'},
    {label: this.translate.instant('bulk_dm_report.70_microloans_collections_report'), value: '70_microloans_collections_report'},
  ]
  constructor(
      private toast: ToastrService,
      private reportService: ReportService,
      private fb: UntypedFormBuilder,
      private translate: TranslateService,
      public dialogRef: MatDialogRef<BulkDmModalComponent>,
      private creditorService: CreditorService,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.fetchCreditors();
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      creditor_id: [null, Validators.required],
      amount_paid: [null, Validators.required],
      report_type: [null, Validators.required],
    });
  }

  sendReportData() {
    if (!this.form.valid) {
      return;
    }

    const requestData = {
      creditor_id: this.form.get('creditor_id').value,
      amount_paid: this.form.get('amount_paid').value,
      report_type: this.form.get('report_type').value,
    };
    this.isLoading++;
    this.reportService.generateBulkDmReport(requestData).pipe(finalize(() => this.isLoading--))
        .subscribe(
            res => {
              this.toast.success(res.message);
              this.dialogRef.close();
            },
            err => {
              console.error(err);
              this.toast.error('Failed to generate report');
            },
        );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private fetchCreditors() {
    this.isLoading++;
    this.creditorService.index({all: 1, active: 1}).pipe(finalize(() => this.isLoading--)).subscribe(
        result => this.creditors = result.data,
        err => console.error(err),
    );
  }
}
