<div class="d-flex flex-column h-100">
  <h2>{{"CASES.details.signature-verify" | translate}}</h2>
  <div *ngIf="data.verifyCreditor">
    <mat-divider></mat-divider>
    <div class="p-2 d-flex justify-content-center align-items-center">
      <mat-icon class="mat-icon-size mat-icon-warning">warning</mat-icon>
      <p class="m-0 pl-2" style="color: #856404"><b>{{"CASES.details.verify-signature-creditor" | translate}}</b></p>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div *ngIf="signatureVerified">
    <mat-divider></mat-divider>
    <div class="p-2 d-flex justify-content-center align-items-center">
      <mat-icon class="mat-icon-size" color="primary">check_circle</mat-icon>
      <p class="m-0 pl-2"><b>{{"CASES.details.signature-verified-note" | translate}}</b></p>
    </div>
    <mat-divider></mat-divider>
  </div>
  <h3 *ngIf="!jointApplication">{{"CASES.single.client" | translate}}</h3>
  <h3 *ngIf="jointApplication">{{"CASES.single.client-1" | translate}}</h3>
  <div class="row">
    <div class="col-6">
      <ng-container *ngIf="clientDni.length">
        <div *ngFor=" let file of clientDni" class="pb-3">
          <a [href]="storageUrl + file.location" target="_blank">
            <img class="img-fluid" [src]="storageUrl + file.location" alt="">
          </a>
        </div>
      </ng-container>
      <div *ngIf="!clientDni.length">
        <p>{{"CASES.details.no-dni-client" | translate}}</p>
      </div>
    </div>
    <div class="col-6 d-flex justify-content-center" [class.align-items-center]="clientSignature">
      <div *ngIf="clientSignature">
        <a [href]="storageUrl + clientSignature" target="_blank">
          <img class="img-fluid" [src]="storageUrl + clientSignature" alt="">
        </a>
      </div>
      <div *ngIf="!clientSignature">
        <p>{{"CASES.details.no-signature-client" | translate}}</p>
      </div>
    </div>
  </div>
  <mat-divider *ngIf="jointApplication"></mat-divider>
  <h3 *ngIf="jointApplication">{{"CASES.single.client-2" | translate}}</h3>
  <div class="row" *ngIf="jointApplication">
    <div class="col-6">
      <ng-container *ngIf="partnerDni.length">
        <div *ngFor=" let file of partnerDni" class="pb-3">
          <a [href]="storageUrl + file.location" target="_blank">
            <img class="img-fluid" [src]="storageUrl + file.location" alt="">
          </a>
        </div>
      </ng-container>
      <div *ngIf="!partnerDni.length">
        <p>{{"CASES.details.no-dni-partner" | translate}}</p>
      </div>
    </div>
    <div class="col-6 d-flex justify-content-center" [class.align-items-center]="partnerSignature">
      <div *ngIf="partnerSignature">
        <a [href]="storageUrl + partnerSignature" target="_blank">
          <img class="img-fluid" [src]="storageUrl + partnerSignature" alt="">
        </a>
      </div>
      <div *ngIf="!partnerSignature">
        <p>{{"CASES.details.no-signature-partner" | translate}}</p>
      </div>
    </div>
  </div>

  <div class="text-right mt-auto mb-5">
    <button type="button" mat-stroked-button color="primary" class="mr-3"
            (click)="closeDialog()" data-dismiss="modal">{{ "SHARED.close" | translate }}</button>
    <button *ngIf="!signatureVerified" mat-raised-button color="primary" (click)="verifySignature()"
            [disabled]="jointApplication ? !clientSignature || !partnerSignature : !clientSignature">
      {{"CASES.details.verify-signature" | translate}}
    </button>
  </div>
</div>
