import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../_base-shared/models/Case/Case';
import { CaseContract } from '../../../../../../../_base-shared/models/CaseDocument/CaseContract';
import { AppDocument } from '../../../../../../../_base-shared/models/Document/AppDocument';
import { DebtCancellationModel } from '../../../../../../../_base-shared/models/Document/DebtCancellationModel';
import { DocumentType } from '../../../../../../../_base-shared/models/DocumentType';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { SafePipe } from '../../../../../../../_base-shared/pipes/safe.pipe';
import { DocumentTypeService } from '../../../../../../../_base-shared/services/document-type.service';
import { environment } from '../../../../../environments/environment';
import { VerifySignatureComponent } from '../../../../_shared/components/verify-signature/verify-signature.component';
import { MainGlobalEventService } from '../../../../_shared/services/main-global-event.service';
import { CaseDocumentService } from '../../case-document.service';
import { CaseService } from '../../case.service';

@Component({
  selector:    'app-case-document-list',
  templateUrl: './case-document-list.component.html',
  styleUrls:   ['./case-document-list.component.scss'],
})
export class CaseDocumentListComponent implements OnInit {
  @Output() updateSignatureVerification = new EventEmitter<number>();
  public case: Case;
  public isLoading = 0;
  public serverResponse: LaravelResourceResponse;

  public form: UntypedFormGroup;
  public creditorForm: UntypedFormGroup;
  public creditorFormPartner: UntypedFormGroup;
  public legalForm: UntypedFormGroup;
  public legalFormCustom: UntypedFormGroup;
  public legalFormCustomPartner: UntypedFormGroup;
  public legalFormPartner: UntypedFormGroup;

  public courtForm: UntypedFormGroup;
  public courtFormPartner: UntypedFormGroup;

  public bankFormClient: UntypedFormGroup;
  public bankFormPartner: UntypedFormGroup;

  public authUser: User;
  public tabSelected = 'Document statistics';
  public tabs = ['Document statistics', 'Make new request', 'User files by types', 'All user file'];
  public requestAccordion = false;
  public labels: {} = {};
  public allCreditors = [];
  public allBanks = [];
  public files = [];
  public creditorDocsClient = [];
  public creditorDocsPartner = [];
  public legalDocsClient = [];
  public legalDocsClientCustom = [];
  public legalDocsPartner = [];
  public courtDocsClient = [];
  public courtDocsPartner = [];
  public legalDocsPartnerCustom = [];
  public legalDocumentType = [];
  public courtDocumentType = [];

  public bankDocsClient = [];
  public bankDocsPartner = [];

  public documentType: DocumentType[];
  public storageUrl = environment.STORAGE_URL + '/';
  public contractPdfLocation;
  public redactedContractPdfLocation;
  public mandatePdfLocation;
  public mandatePdfLocationPartner;
  public sepaFileLocation;

  public userDocuments = [];
  public userDocumentsPartner = [];
  public selectedTypes: string[] = [];
  public uploadSpinner = false;
  public uploadSpinnerClient = false;
  public uploadSpinnerPartner = false;
  public uploadSpinnerClientLegal = false;
  public uploadSpinnerClientLegalCustom = false;
  public uploadSpinnerPartnerLegal = false;
  public uploadSpinnerClientCourt = false;
  public uploadSpinnerPartnerCourt = false;
  public uploadSpinnerPartnerLegalCustom = false;
  public uploadSpinnerClientBank = false;
  public uploadSpinnerPartnerBank = false;
  public regenerateSpinner = false;
  public resignSpinner = false;
  public lang = 'es';

  public files_by_creditor = [];
  public files_by_public_debt = [];
  public partner_files_by_creditor = [];
  public partner_files_by_public_debt = [];

  public files_by_legal = [];
  public partner_files_by_legal = [];

  public files_by_bank = [];
  public partner_files_by_bank = [];

  public files_by_court = [];
  public partner_files_by_court = [];

  public updateFiles = new BehaviorSubject<any>([]);
  public fileUrl: string;
  public fileType: string;

  private subscriptions: Array<Subscription> = [];

  public debtCancellationArray: DebtCancellationModel;
  public areExperianAttachmentsReady: boolean;

  public isSticky = false;

  public loadingDocumentVisibility = 0;
  public creditorDocumentsVisible = false;
  public bankDocumentsVisible = false;
  public autoDeConcursoUploaded = false;
  public autoDeExoneracionUploaded = false;
  public advicePackVisible = false;

  public debtExonerationDocumentDownload = false;
  public debtExonerationDocument: AppDocument;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 250;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private documentTypeService: DocumentTypeService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
    private sanitized: DomSanitizer,
    private caseService: CaseService,
    private caseDocumentService: CaseDocumentService,
    private safePipe: SafePipe,
    public translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
    this.lang = this.cookieService.get('lang');
    this.translate.onLangChange.subscribe(next => {
      this.documentTypeService.get().subscribe(result => {
        this.documentType = result;
        //  Add custom documents to docTypes
        const customFiles = this.case.file_requests.filter(file => file.custom);
        this.documentType = this.documentType.concat(customFiles);
      });
    });

    this.route.parent.paramMap.subscribe(params => {

      const caseId = +params.get('id');
      this.isLoading++;
      this.caseService.get(caseId, ['product']).pipe(finalize(() => this.isLoading--)).subscribe(result => {
        this.advicePackVisible = ['lso-pp', 'lso-te', 'lso-lq'].includes(result.data.product.slug);
        this.isLoading++;
        this.caseDocumentService.indexCaseDocuments(caseId)
          .pipe(finalize(() => this.isLoading--))
          .subscribe(res => {
            this.case = res.data;
            this.isDebtExonerationDocumentReady();
            this.caseDocumentService.getIsExperianReady(this.case.id).subscribe(response => {
              this.debtCancellationArray = response.data;
              if (this.debtCancellationArray.auto_exoneracion_document &&
                this.debtCancellationArray.debt_cancellation_request_document &&
                this.debtCancellationArray.dni_document
                && this.debtCancellationArray.mandate_document) {
                this.areExperianAttachmentsReady = true;
              } else {
                this.areExperianAttachmentsReady = false;
              }
            });
            //  Get all creditors and public debts
            this.case.public_debts.map(debt => {
              //  Add name prop so we can use it in select
              if (debt.public_organisation) {
                debt.name = debt.public_organisation.replace('-', ' ').toUpperCase();
              }
            });
            this.setDocumentVisibility();
            this.allCreditors = this.case.secured_creditors.concat(this.case.unsecured_creditors);
            this.allCreditors = this.allCreditors.concat(this.case.public_debts);
            this.allBanks = this.case.assets.filter(asset => asset.type === 'bank_accounts');

            this.buildForms();

            this.selectedTypes = this.case.file_requests.map(type => type.name);
            this.userDocuments = this.case.client_files_by_type;
            this.userDocumentsPartner = this.case.partner_files_by_type;
            this.contractPdfLocation = this.case.contracts[0]?.pdf_location;
            this.redactedContractPdfLocation = this.case.contracts[0]?.redacted_pdf_location;
            this.mandatePdfLocation = this.case.contracts[0]?.mandate_location;
            this.mandatePdfLocationPartner = this.case.contracts[0]?.mandate_location_1;
            this.sepaFileLocation = this.case.contracts[0]?.sepa_location;

            this.files_by_creditor = this.case.files_by_creditor;
            this.files_by_public_debt = this.case.files_by_public_debt;
            this.partner_files_by_creditor = this.case.partner_files_by_creditor;
            this.partner_files_by_public_debt = this.case.partner_files_by_public_debt;

            this.files_by_legal = this.case.legal_documents_client;
            this.partner_files_by_legal = this.case.legal_documents_partner;
            this.autoDeConcursoUploaded = this.case.legal_documents.find(
              document => document.document_type === 'auto-de-concurso') !== undefined;
            this.autoDeExoneracionUploaded = this.case.legal_documents.find(
              document => document.document_type === 'auto-exoneracion') !== undefined;

            this.files_by_bank = this.case.client_files_by_bank;
            this.partner_files_by_bank = this.case.partner_files_by_bank;

            this.files_by_court = this.case.court_documents_client;
            this.partner_files_by_court = this.case.court_documents_partner;

            this.documentTypeService.get().subscribe(r => {
              this.documentType = r;
              //  Add custom documents to docTypes
              const customFiles = this.case.file_requests.filter(file => file.custom);
              this.documentType = this.documentType.concat(customFiles);
            });

            this.documentTypeService.getLegalDocumentTypes().subscribe(r => {
              this.legalDocumentType = r;
            });

            this.documentTypeService.getCourtDocumentTypes().subscribe(r => {
              this.courtDocumentType = r;
            });
          });
      });
    });
  }

  onFileChange(event) {
    const files = event.target.files;
    const filesArray = [...this.files];
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.map(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    this.files = filesArray;
  }

  creditorDocsChange(event, type) {
    const files = event.target.files;
    const filesArray = [...this.files];
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.map(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    if (type === 'client') {
      this.creditorDocsClient = filesArray;
    } else {
      this.creditorDocsPartner = filesArray;
    }
  }

  legalDocsChange(event, type) {
    const files = event.target.files;
    const filesArray = [...this.files];
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.map(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    if (type === 'client') {
      this.legalDocsClient = filesArray;
    } else {
      this.legalDocsPartner = filesArray;
    }
  }

  legalDocsChangeCustom(event, type) {
    const files = event.target.files;
    const filesArray = [...this.files];
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.map(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    if (type === 'client') {
      this.legalDocsClientCustom = filesArray;
    } else {
      this.legalDocsPartnerCustom = filesArray;
    }
  }

  courtDocsChange(event, type) {
    const files = event.target.files;
    const filesArray = [...this.files];
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.map(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    if (type === 'client') {
      this.courtDocsClient = filesArray;
    } else {
      this.courtDocsPartner = filesArray;
    }
  }

  bankDocsChange(event, type) {
    const files = event.target.files;
    const filesArray = [...this.files];
    // Push files to array
    if (files && files.length) {
      const arr = [...files];
      arr.map(file => {
        filesArray.push(file);
      });
    }
    filesArray.map((file, index) => file.index = index);
    if (type === 'client') {
      this.bankDocsClient = filesArray;
    } else {
      this.bankDocsPartner = filesArray;
    }
  }

  removeFile($event, index, type = 'file') {
    $event.preventDefault();
    if (type === 'client') {
      this.creditorDocsClient = this.creditorDocsClient.filter(file => file.index !== index);
    } else if (type === 'partner') {
      this.creditorDocsPartner = this.creditorDocsPartner.filter(file => file.index !== index);
    } else {
      this.files = this.files.filter(file => file.index !== index);
    }
  }

  removeLegalFile($event, index, type = 'file') {
    $event.preventDefault();
    if (type === 'client') {
      this.legalDocsClient = this.legalDocsClient.filter(file => file.index !== index);
    } else if (type === 'partner') {
      this.legalDocsPartner = this.legalDocsPartner.filter(file => file.index !== index);
    }
  }

  removeCourtFile($event, index, type = 'file') {
    $event.preventDefault();
    if (type === 'client') {
      this.courtDocsClient = this.courtDocsClient.filter(file => file.index !== index);
    } else if (type === 'partner') {
      this.courtDocsPartner = this.courtDocsPartner.filter(file => file.index !== index);
    }
  }

  removeLegalFileCustom($event, index, type = 'file') {
    $event.preventDefault();
    if (type === 'client') {
      this.legalDocsClientCustom = this.legalDocsClientCustom.filter(file => file.index !== index);
    } else if (type === 'partner') {
      this.legalDocsPartnerCustom = this.legalDocsPartnerCustom.filter(file => file.index !== index);
    }
  }

  resendDocumentRequest({ fileId, type }) {
    const data = {
      caseId:      this.case.id,
      fileId,
      client_type: type,
    };
    this.caseDocumentService.resendRequest(data)
      .subscribe(
        res => {
          // this.toastr.success('Request sent');
          this.toastr.success(this.translate.instant('CASES.single.request-sent-success'),
            this.translate.instant('SHARED.success'));
        },
        err => {
          console.log(err);
          this.toastr.error(this.translate.instant('CASES.single.request-sent-error'),
            this.translate.instant('SHARED.error'));
        },
      );
  }

  removeBankFile($event, index, type = 'file') {
    $event.preventDefault();
    if (type === 'client') {
      this.bankDocsClient = this.bankDocsClient.filter(file => file.index !== index);
    } else if (type === 'partner') {
      this.bankDocsPartner = this.bankDocsPartner.filter(file => file.index !== index);
    }
  }

  documentInfoChange($event) {
    const data = {
      caseId:              this.case.id,
      fileId:              $event.document.id,
      document_type_id:    $event.newTypeDoc?.id || null,
      creditor_id:         $event.newTypeCred?.id || null,
      case_public_debt_id: $event.newTypePublic?.id || null,
      name:                $event.name,
      uploaded_by:         $event.uploaded_by,
      version:             '1',
    };
    this.caseDocumentService.changeStatus(data)
      .subscribe(
        res => {
          this.userDocuments = res.data.client_files_by_type;
          this.userDocumentsPartner = res.data.partner_files_by_type;
          this.updateFiles.next(res.data);  //  Update creditor files
          this.toastr.success(this.translate.instant('DOCUMENTS.file-details-changed-success'));
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-details-changed-error'));
        },
      );
  }

  documentStatusChange(data) {
    this.caseDocumentService.changeStatus(data)
      .subscribe(
        res => {
          this.userDocuments = res.data.client_files_by_type;
          this.userDocumentsPartner = res.data.partner_files_by_type;
          this.toastr.success(this.translate.instant('DOCUMENTS.file-status-changed-success'));
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-status-changed-error'));
        },
      );
  }

  documentsMerged(data) {
    this.userDocuments = data.client_files_by_type;
    this.userDocumentsPartner = data.partner_files_by_type;
  }

  deleteFile(data) {
    this.caseDocumentService.removeUploadedFile(data.uuId, data.fileId)
      .subscribe(
        res => {
          this.userDocuments = res.data.client_files_by_type;
          this.userDocumentsPartner = res.data.partner_files_by_type;
          this.toastr.success(this.translate.instant('DOCUMENTS.file-deleted'));
        },
        err => {
          this.toastr.error(this.translate.instant('DOCUMENTS.file-deleted-error'));
        },
      );
  }

  closeRequestAccordion($event) {
    this.requestAccordion = $event;
  }

  switch(tabName: string, $event) {
    $event.preventDefault();
    this.tabSelected = tabName;
  }

  regenerateContract() {
    this.regenerateSpinner = true;

    let contractToRegen: CaseContract;
    this.case.contracts.every(contract => {
      if (contract.signature) {
        contractToRegen = contract;
        return false;
      }
      return true;
    });

    if ( ! contractToRegen) {
      contractToRegen = this.case.contracts[0];
    }

    this.caseDocumentService.regenerateContract(this.case.id, contractToRegen.id)
      .pipe(finalize(() => this.regenerateSpinner = false))
      .subscribe(
        next => {
          this.contractPdfLocation = next.data.pdf_location;
          this.mandatePdfLocation = next.data.mandate_location;
          this.mandatePdfLocationPartner = next.data.mandate_location_1;
          this.toastr.success(
            this.translate.instant('CASES.single.regenerate-contract-response'),
            this.translate.instant('SHARED.success'),
          );
        },
        error => {
          this.toastr.error(
            this.translate.instant('CASES.single.regenerate-contract-error'),
            this.translate.instant('SHARED.error'),
          );
        },
      );
  }

  changeUploadFor($event) {
    this.form.patchValue({ fileType: '' });

    this.documentTypeService.get().subscribe(res => {
      this.documentType = res;
      //  Set categories for upload files based on client type (partner | client)
      let customFiles = [];
      if ($event === 'client') {
        customFiles = this.case.file_requests.filter(file => file.custom);
      } else {
        customFiles = this.case.partner_file_requests.filter(file => file.custom);
      }

      this.documentType = this.documentType.concat(customFiles);
    });
  }

  submitFiles() {
    if (this.files.length > 0) {
      this.uploadSpinner = true;
      const formData = new FormData();
      const fileType = this.form.value.fileType;
      const uploadFor = this.form.value.uploadFor;
      //  Append files to form data
      this.files.map(file => {
        formData.append(`files[]`, file);
      });
      formData.append(`uploaded_by`, uploadFor);
      //  If file type is 'contract' or 'mandates'
      //  Call different API endpoint for upload
      if (fileType === 'contract' || fileType === 'mandate' || fileType === 'sepa') {
        formData.append(`type`, fileType);
        this.caseDocumentService.adminUploadContract(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinner = false;
            this.files = [];
          }))
          .subscribe(
            next => {
              this.contractPdfLocation = next.data.pdf_location;
              this.mandatePdfLocation = next.data.mandate_location;
              this.mandatePdfLocationPartner = next.data.mandate_location_1;
              this.sepaFileLocation = next.data.sepa_location;
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
            }, error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      } else {
        formData.append(`document_type_id`, fileType);
        this.caseDocumentService.adminUploadFiles(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinner = false;
            this.files = [];
          }))
          .subscribe(
            next => {
              this.userDocuments = next.data.client_files_by_type;
              this.userDocumentsPartner = next.data.partner_files_by_type;
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            });
      }
    }
  }

  submitCreditorFiles(type) {
    let files = [];
    if (type === 'client') {
      files = this.creditorDocsClient;
      if (this.creditorForm.value.fileType) {
        this.uploadSpinnerClient = true;
        const formData = new FormData();
        formData.append('uploaded_by', type);
        files.map(file => {
          formData.append(`${ this.creditorForm.value.fileType }[]`, file);
        });

        this.caseDocumentService.uploadFiles(this.case.uuid, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerClient = false;
            this.creditorDocsClient = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_creditor = next.data.files_by_creditor;
              this.files_by_public_debt = next.data.files_by_public_debt;
              this.partner_files_by_creditor = next.data.partner_files_by_creditor;
              this.partner_files_by_public_debt = next.data.partner_files_by_public_debt;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    } else {
      files = this.creditorDocsPartner;
      if (this.creditorFormPartner.value.fileType) {
        this.uploadSpinnerPartner = true;
        const formData = new FormData();
        formData.append('uploaded_by', type);
        files.map(file => {
          formData.append(`${ this.creditorFormPartner.value.fileType }[]`, file);
        });

        this.caseDocumentService.uploadFiles(this.case.uuid, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerPartner = false;
            this.creditorDocsPartner = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_creditor = next.data.files_by_creditor;
              this.files_by_public_debt = next.data.files_by_public_debt;
              this.partner_files_by_creditor = next.data.partner_files_by_creditor;
              this.partner_files_by_public_debt = next.data.partner_files_by_public_debt;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    }

  }

  submitLegalFiles(type) {
    let files = [];
    if (type === 'client') {
      files = this.legalDocsClient;
      if (this.legalForm.get('fileType')?.value) {
        this.uploadSpinnerClientLegal = true;
        const formData = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.legalForm.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesLegal(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerClientLegal = false;
            this.legalDocsClient = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_legal = next.data.legal_documents_client;
              this.partner_files_by_legal = next.data.legal_documents_partner;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    } else {
      files = this.legalDocsPartner;
      if (this.legalFormPartner.value.fileType) {
        this.uploadSpinnerPartnerLegal = true;
        const formData = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.legalFormPartner.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesLegal(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerPartnerLegal = false;
            this.legalDocsPartner = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_legal = next.data.legal_documents_client;
              this.partner_files_by_legal = next.data.legal_documents_partner;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    }
  }

  submitCourtFiles(type) {
    let files = [];
    if (type === 'client') {
      files = this.courtDocsClient;
      if (this.courtForm.value.fileType) {
        this.uploadSpinnerClientCourt = true;
        const formData = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.courtForm.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesCourt(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerClientCourt = false;
            this.courtDocsClient = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_court = next.data.court_documents_client;
              this.partner_files_by_court = next.data.court_documents_partner;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    } else {
      files = this.courtDocsPartner;
      if (this.courtFormPartner.value.fileType) {
        this.uploadSpinnerPartnerCourt = true;
        const formData = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.courtFormPartner.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesCourt(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerPartnerCourt = false;
            this.courtDocsPartner = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_court = next.data.court_documents_client;
              this.partner_files_by_court = next.data.court_documents_partner;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    }
  }

  submitBankFiles(type) {
    let files = [];
    if (type === 'client') {
      files = this.bankDocsClient;
      if (this.bankFormClient.value.fileType) {
        this.uploadSpinnerClientBank = true;
        const formData = new FormData();
        formData.append('uploaded_by', type);
        // formData.append('document_type', this.bankFormClient.value.fileType);
        files.map(file => {
          formData.append(`${ this.bankFormClient.value.fileType }[]`, file);
        });

        this.caseDocumentService.uploadFiles(this.case.uuid, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerClientBank = false;
            this.bankDocsClient = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_bank = next.data.client_files_by_bank;
              this.partner_files_by_bank = next.data.partner_files_by_bank;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    } else {
      files = this.bankDocsPartner;
      if (this.bankFormPartner.value.fileType) {
        this.uploadSpinnerPartnerBank = true;
        const formData = new FormData();
        formData.append('uploaded_by', type);
        files.map(file => {
          formData.append(`${ this.bankFormPartner.value.fileType }[]`, file);
        });

        this.caseDocumentService.uploadFiles(this.case.uuid, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerPartnerBank = false;
            this.bankDocsPartner = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
              this.files_by_bank = next.data.client_files_by_bank;
              this.partner_files_by_bank = next.data.partner_files_by_bank;
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    }
  }

  submitLegalFilesCustom(type) {
    let files = [];
    if (type === 'client') {
      files = this.legalDocsClientCustom;
      if (this.legalFormCustom.value.fileType) {
        this.uploadSpinnerClientLegalCustom = true;
        const formData = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.legalFormCustom.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesLegal(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerClientLegalCustom = false;
            this.legalDocsClientCustom = [];
          }))
          .subscribe(
            next => {
              this.files_by_legal = next.data.legal_documents_client;
              this.partner_files_by_legal = next.data.legal_documents_partner;
              this.updateFiles.next(next);
              this.legalFormCustom.patchValue({ fileType: '' });
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    } else {
      files = this.legalDocsPartnerCustom;
      if (this.legalFormCustomPartner.value.fileType) {
        this.uploadSpinnerPartnerLegalCustom = true;
        const formData = new FormData();
        formData.append('uploaded_by', type);
        formData.append('document_type', this.legalFormCustomPartner.value.fileType);
        files.map(file => {
          formData.append(`files[]`, file);
        });

        this.caseDocumentService.uploadFilesLegal(this.case.id, formData)
          .pipe(finalize(() => {
            this.uploadSpinnerPartnerLegalCustom = false;
            this.legalDocsPartnerCustom = [];
          }))
          .subscribe(
            next => {
              this.updateFiles.next(next);
              this.files_by_legal = next.data.legal_documents_client;
              this.partner_files_by_legal = next.data.legal_documents_partner;
              this.legalFormCustomPartner.patchValue({ fileType: '' });
              this.toastr.success(this.translate.instant('DOCUMENTS.documents-upload-success'));
            },
            error => {
              this.toastr.error(this.translate.instant('DOCUMENTS.documents-upload-error'));
            },
          );
      }
    }
  }

  public verifySignature($event: MouseEvent) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(VerifySignatureComponent, {
      width:  '50%',
      height: '50%',
      data:   {
        case: this.case,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.data.message) {
          this.updateSignatureVerification.emit(1);
        }
      }
    });
  }

  resignContract($event: MouseEvent, type: string, client_type: string) {
    $event.preventDefault();
    const data = {
      type,
      client_type,
    };
    this.resignSpinner = true;
    this.caseDocumentService.resignContract(this.case.id, data)
      .pipe(finalize(() => this.resignSpinner = false))
      .subscribe(
        next => {
          this.toastr.success(
            this.translate.instant('CASES.single.resign-contract-response'),
            this.translate.instant('SHARED.success'),
          );
        },
        error => {
          this.toastr.error(
            this.translate.instant('CASES.single.resign-contract-error'),
            this.translate.instant('SHARED.error'),
          );
        },
      );
  }

  setFileUrl(file: any) {
    this.fileUrl = this.safePipe.transform(file.url + '?output=embed', 'resourceUrl') as string;
    this.fileType = file?.type;
  }

  public updateDocumentVisibility(visibility: boolean, type: 'case_creditor' | 'bank_account') {
    this.loadingDocumentVisibility++;
    this.caseDocumentService.setDocumentVisibility(this.case.id, {
      visibility,
      type,
    }).pipe(finalize(() => this.loadingDocumentVisibility--))
      .subscribe(res => {
        if (type === 'case_creditor') {
          this.creditorDocumentsVisible = visibility;
        } else {
          this.bankDocumentsVisible = visibility;
        }
        this.toastr.success('Document visibility successfully changed');
      }, err => {
        this.toastr.error('Failed to change document visibility');
      });
  }

  private setDocumentVisibility() {
    if (this.case.creditors.length) {
      this.creditorDocumentsVisible = !! this.case.creditors[0].pivot.client_documents_visible;
    }

    const bankAccounts = this.case.assets.filter(asset => asset.type === 'bank_accounts');
    if (bankAccounts.length) {
      this.bankDocumentsVisible = !! bankAccounts[0].client_documents_visible;
    }
  }

  private buildForms() {
    this.form = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(1),
      uploadFor: this.fb.control('client'),
    });
    this.creditorForm = this.fb.group({
      files: this.fb.control([]),
      //  Set select value to first creditor in array
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('client'),
    });
    this.creditorFormPartner = this.fb.group({
      files: this.fb.control([]),
      //  Set select value to first creditor in array
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('partner'),
    });
    this.legalForm = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('client'),
    });
    this.legalFormPartner = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('partner'),
    });
    this.legalFormCustom = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('client'),
    });
    this.legalFormCustomPartner = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('partner'),
    });
    this.bankFormClient = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('client'),
    });
    this.bankFormPartner = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('partner'),
    });
    this.courtForm = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('client'),
    });
    this.courtFormPartner = this.fb.group({
      files:     this.fb.control([]),
      fileType:  this.fb.control(''),
      uploadFor: this.fb.control('partner'),
    });
  }

  public sendAutoDeConscursoDoc(clientRole: 'client' | 'partner') {
    this.caseDocumentService.sendAutoDeConcurso(this.case.uuid, clientRole).subscribe(res => {
      this.toastr.success(this.translateService.instant('CASES.single.successfully_sent'));
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public sendAutoDeExoneracionDoc(clientRole: 'client' | 'partner') {
    this.caseDocumentService.sendAutoDeExoneracion(this.case.uuid, clientRole).subscribe(res => {
      this.toastr.success(this.translateService.instant('CASES.single.successfully_sent'));
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public sendSignedContract() {
    this.caseDocumentService.sendSignedContract(this.case.id).subscribe(res => {
      this.toastr.success(this.translateService.instant('CASES.single.successfully_sent'));
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public resendAdvicePackEmail(regenerate: boolean) {
    this.caseDocumentService.resendAdvicePack(this.case.id, regenerate).subscribe(res => {
      this.toastr.success(this.translateService.instant('CASES.single.successfully_sent'));
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public requestDebtCancellationRequestSignature(clientRole: 'client' | 'partner'): void {
    this.caseDocumentService.requestDebtCancellationRequestSignature(this.case.id, clientRole).subscribe(res => {
      this.toastr.success(
        this.translateService.instant(
          'CASES.single.documents.debt_cancellation_request.request_signature.response.success'),
      );
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public sendEmailToExperian() {
    this.caseDocumentService.sendExperianEmail(this.case.id).subscribe(res => {
      this.toastr.success(this.translateService.instant('CASES.single.successfully_sent'));
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public sendDebtExonerationSignature(channel: 'sms' | 'email'): void {
    this.caseDocumentService.sendDebtExonerationSignature(this.case.id, {channel}).subscribe(res => {
      this.toastr.success(
        this.translateService.instant(
          'CASES.single.documents.send_debt_exoneration.request_signature.response.success'),
      );
    }, err => {
      this.toastr.error(this.translateService.instant('SHARED.went-wrong'));
    });
  }

  public isDebtExonerationDocumentReady(): void {
    const dataRelation = {
      'with[0]' : 'files',
      user_id: this.case.client.id,
      app_document_type_slug: 'debt-exoneration'
    };
    this.caseDocumentService.debtExonerationDownload(this.case.id, dataRelation)
      .subscribe(response => {
        this.debtExonerationDocument = response.data;
        this.debtExonerationDocumentDownload = response.data?.files[0] ? true : false;
      });
  }
}
