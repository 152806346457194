import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-redirect-to-client-app',
  templateUrl: './redirect-to-client-app.component.html',
  styles: []
})
export class RedirectToClientAppComponent implements OnInit{
  private clientUrl = environment.CLIENT_APP_URL;
  private caseUuid: string;
  private type: string;
  private sign: string;
  private resign: string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.caseUuid             = this.route.snapshot.queryParamMap.get('uuid');
    this.type               = this.route.snapshot.queryParamMap.get('type');
    this.sign               = this.route.snapshot.queryParamMap.get('sign');
    this.resign               = this.route.snapshot.queryParamMap.get('resign');
    this.route.data.subscribe(data => {
      const urlLocation = this.clientUrl + '/' + data.routeUrl;
      switch (true) {
        case urlLocation.includes('sign-contract'):
          window.location.href = urlLocation + '?uuid=' + this.caseUuid + '&sign=' + this.sign + '&resign=' + this.resign;
          break;

        case urlLocation.includes('sign-creditor-asset-list'):
          window.location.href = urlLocation + '?uuid=' + this.caseUuid + '&type=' + this.type;
          break;

        case urlLocation.includes('sign-debt-cancellation-request'):
          window.location.href = urlLocation + '?uuid=' + this.caseUuid + '&type=' + this.type + '&sign=' + this.sign;
          break;

        case urlLocation.includes('e-sign'):
          window.location.href = urlLocation + '?uuid=' + this.caseUuid + '&sign=' + this.sign;
          break;

        case urlLocation.includes('aep-signature'):
          window.location.href = urlLocation + '?uuid=' + this.caseUuid + '&sign=' + this.sign;
          break;

        case urlLocation.includes('sign-debt-exoneration'):
          window.location.href = urlLocation + '?uuid=' + this.caseUuid + '&sign=' + this.sign;
          break;

        default:
          window.location.href = urlLocation;
          break;
      }
    });
  }

}
