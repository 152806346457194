import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Packager } from '../../../../../../_base-shared/models/Packager/Packager';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { AdminPackagerService } from '../../../admin/admin-packager/admin-packager.service';
import { PackagerService } from '../../packager.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector:    'app-packager-general-editor',
  templateUrl: './packager-general-editor.component.html',
  styles:      []
})
export class PackagerGeneralEditorComponent implements OnInit {
  public authUser: User;
  public packager: Packager;
  public isLoading = 0;
  public form: UntypedFormGroup;
  public isSubmitting: boolean;
  public logoImgPath: string | any;
  public signatureImgPath: string | any;

  public formDataLogo      = new FormData();
  public formDataSignature = new FormData();

  public storageUrl = environment.STORAGE_URL + '/';

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventService: MainGlobalEventService,
    private adminPackagerService: AdminPackagerService,
    private packagerService: PackagerService,
    private detectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.globalEventService.authUser$.subscribe(user => {
      this.authUser = user;
    });

    this.route.parent.paramMap.subscribe(params => {
      const packagerId = +params.get('id');
      this.fetchPackager(packagerId);
    });
  }

  public submitForm(form: UntypedFormGroup): void {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }

    this.isSubmitting = true;
    this.adminPackagerService.update(this.packager.id, form.value)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        result => {
          this.packager = result.data;
          this.toastr.success(this.translate.instant('SHARED.submit_result.update.success',
            { model: this.translate.instant('PACKAGER.model_name.singular') }));
        },
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong'))
      );
  }

  public onLogoUpload(event): void {
    this.formDataLogo.delete('file');
    const reader = new FileReader();
    const file   = event.target.files.item(0);
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => this.logoImgPath = reader.result;
      this.detectorRef.markForCheck();
    }
    this.formDataLogo.append(`file`, file);
    this.formDataLogo.append('type', 'logo');
  }

  public onSignatureUpload(event): void {
    this.formDataSignature.delete('file');
    const reader = new FileReader();
    const file   = event.target.files.item(0);
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => this.signatureImgPath = reader.result;
      this.detectorRef.markForCheck();
    }
    this.formDataSignature.append(`file`, file);
    this.formDataSignature.append('type', 'signature');
  }

  public saveUploadedLogo(): void {
    this.isLoading++;
    this.packagerService.uploadImage(this.formDataLogo, this.packager.id).pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
          this.toastr.success(this.translate.instant('PACKAGER.details.logo_uploaded_success'));
        },
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong'))
      );
  }

  public saveUploadedSignature(): void {
    this.isLoading++;
    this.packagerService.uploadImage(this.formDataSignature, this.packager.id)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(res => {
          this.toastr.success(this.translate.instant('PACKAGER.details.signature_uploaded_success'));
        },
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong')));
  }

  private fetchPackager(packagerId: number): void {
    this.isLoading++;
    this.packagerService.show(packagerId, ['logo', 'address', 'signature', 'bank_account'])
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          this.packager         = result.data;
          this.logoImgPath      = this.storageUrl + this.packager.logo?.path;
          this.signatureImgPath = this.storageUrl + this.packager.signature?.path;
          this.buildForm(this.packager);
        },
        () => this.toastr.error(this.translate.instant('SHARED.went-wrong'))
      );
  }

  private buildForm(packager: Packager): void {
    this.form = this.fb.group({
      name_en:      [packager.name_en, [Validators.required]],
      name_es:      [packager.name_es, [Validators.required]],
      email:        [packager.email, [Validators.required, Validators.email]],
      bank_account: this.fb.group({
        bank_name:      [packager?.bank_account?.bank_name],
        bank_address:   [packager?.bank_account?.bank_address],
        account_number: [packager?.bank_account?.account_number],
        iban:           [packager?.bank_account?.iban]
      }),
      address:      this.fb.group({
        country:      [packager?.address?.country],
        county:       [packager?.address?.county],
        region:       [packager?.address?.region],
        city:         [packager?.address?.city],
        municipality: [packager?.address?.municipality],
        postcode:     [packager?.address?.postcode, [Validators.min(3)]],
        address_1:    [packager?.address?.address_1],
        address_2:    [packager?.address?.address_2]
      })
    });
  }
}
