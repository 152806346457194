<div class="container">
  <div class="row">
    <div *ngIf="isLoading" class="col-12 pt-5 text-center">
      <mat-spinner class="m-0 m-auto"></mat-spinner>
    </div>
    <div class="col-md-12" *ngIf="!isLoading">
      <h1>Creditor Import</h1>
      <div class="card">
        <div class="card-body">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-md-6">
                Hi, I'm the creditor import view, select your file on the right...
                <div class="mt-3">
                  Import File column headings: <br>
                  <code *ngIf="selectedOption === 'fds'">| DNI | Debt Amount | Offered Amount |</code>
                  <code *ngIf="selectedOption === 'dmp'">| DNI | Deuda Actualizada | Aceptada |</code>
                  <code *ngIf="selectedOption === 'updatestatus'">| DNI |</code>
                  <code *ngIf="selectedOption === 'updateamount'">| DNI | Update Amount |</code>
                  <br>
                  <small>The names are important, they can not differ from the above.</small>
                  <div class="col-12 mt-2">
                    <mat-radio-group formControlName="import_type">
                      <mat-radio-button value="fds" class="mr-3">Import FDS</mat-radio-button>
                      <mat-radio-button value="dmp" class="mr-3">Import DM</mat-radio-button>
                      <mat-radio-button value="updatestatus" class="mr-3">Status Update</mat-radio-button>
                      <mat-radio-button value="updateamount" class="mr-3">Update Amounts</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <div class="mt-3" *ngIf="multipleCreditorsForCase.length >= 1">
                  <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Multiple Creditors for case found.</h4>
                    <p>There are cases that have multiple records for the same creditor, please review these and update them manually.</p>
                    <p>The records below have been automatically updated.</p>
                    <p>Click on the case ref number to open the case in a new tab.</p>
                    <hr>
                    <div>
                      <table class="table table-striped" width="100%">
                        <thead>
                          <tr>
                            <th scope="col">Ref Number</th>
                            <th scope="col">DNI</th>
                            <th scope="col">No. of Entries</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of multipleCreditorsForCase">
                            <td>
                              <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', row.id, 'creditors']" target="_blank">
                                {{ row.ref_number }}</a>
                            </td>
                            <td>{{ row.dni }}</td>
                            <td>{{ row.creditor_count }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                  <div class="col-12">
                  <!-- Creditor -->
                    <app-input type="select" *ngIf="creditors?.length" formControlName="creditor_id" appearance="outline"
                      [searchable]="true" [label]="'CASE_CREDITOR.model.relations.creditor' | translate"
                      class="full-width" [selectOptions]="creditors" [selectLabel]="['name']"
                      [selectValue]="'id'" [showClear]="false" [fullWidth]="true" [showLabel]="true" [extraLabel]="true">
                    </app-input>
                  </div>
                  <div class="col-12">
                    <input type="file" name="file" accept=".csv" id="file" class="form-control" (change)="changeListener($event.target.files)" [disabled]="!creditorSelected">
                  </div>
                  <!-- <input type="submit" value="Upload" class="btn btn-primary" name="submit" id="submit" (click)="submitFile($event)"> -->
            </div>
          </div>
        </form>
          <div class="row">
            <div class="col-md-12">
              <table mat-table matSort [dataSource]="csvContent" width="100%" *ngIf="csvContent.length > 0 && selectedOption === 'fds'">
                <tr mat-header-row *matHeaderRowDef="fdsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: fdsDisplayedColumns;" (click)="openLink(row.id)"
                    [ngClass]="{
                      'creditor-import-percentage-bad': row.percentage_reduction <= badPercentage,
                      'creditor-import-percentage-decent': row.percentage_reduction <= decentPercentage,
                      'creditor-import-percentage-good': row.percentage_reduction >= goodPercentage}"></tr>
                
                <!-- Ref Number -->
                <ng-container matColumnDef="ref_number">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'creditors']" target="_blank">
                      {{ element.ref_number }}</a></td>
                </ng-container>
                <!-- First Name -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.full-name" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }} </td>
                </ng-container>
                <!-- Last Name -->
                <ng-container matColumnDef="dni">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.document-types.dni" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.dni }}</td>
                </ng-container>
                <!-- Product Name -->
                <ng-container matColumnDef="debt_amount">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.debt-amount" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.debt_amount | currency }}</td>
                </ng-container>
                <!-- Package Name -->
                <ng-container matColumnDef="offered_amount">
                  <th mat-header-cell *matHeaderCellDef>Offered Amount</th>
                  <td mat-cell *matCellDef="let element">{{ element.offered_amount | currency }}</td>
                </ng-container>
                <!-- Package Name -->
                <ng-container matColumnDef="percentage_reduction">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASE_CREDITOR.model.percent_reduction" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.percentage_reduction.toFixed(2) }}%</td>
                </ng-container>
              </table>
              <table mat-table matSort [dataSource]="csvContent" width="100%" *ngIf="csvContent.length > 0 && selectedOption === 'dmp'">
                <tr mat-header-row *matHeaderRowDef="dmpDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: dmpDisplayedColumns;" (click)="openLink(row.id)"></tr>
                <!-- Ref Number -->
                <ng-container matColumnDef="ref_number">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'creditors']" target="_blank">
                      {{ element.ref_number }}</a></td>
                </ng-container>
                <!-- DNI -->
                <ng-container matColumnDef="dni">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.document-types.dni" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.dni }}</td>
                </ng-container>
                <!-- First Name -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.full-name" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }} </td>
                </ng-container>
                <!-- Accepted -->
                <ng-container matColumnDef="aceptada">
                  <th mat-header-cell *matHeaderCellDef>Accepted<th>
                  <td mat-cell *matCellDef="let element">{{ element.aceptada }}</td>
                </ng-container>
              </table>
              <table mat-table matSort [dataSource]="csvContent" width="100%" *ngIf="csvContent.length > 0 && selectedOption === 'updatestatus'">
                <tr mat-header-row *matHeaderRowDef="updateStatusDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: updateStatusDisplayedColumns;" (click)="openLink(row.id)"></tr>
                <!-- Ref Number -->
                <ng-container matColumnDef="ref_number">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'creditors']" target="_blank">
                      {{ element.ref_number }}</a></td>
                </ng-container>
                <!-- DNI -->
                <ng-container matColumnDef="dni">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.document-types.dni" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.dni }}</td>
                </ng-container>
                <!-- First Name -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.full-name" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }} </td>
                </ng-container>
              </table>
              <table mat-table matSort [dataSource]="csvContent" width="100%" *ngIf="csvContent.length > 0 && selectedOption === 'updateamount'">
                <tr mat-header-row *matHeaderRowDef="updateAmountDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: updateAmountDisplayedColumns;" (click)="openLink(row.id)"></tr>
                <!-- Ref Number -->
                <ng-container matColumnDef="ref_number">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'creditors']" target="_blank">
                      {{ element.ref_number }}</a></td>
                </ng-container>
                <!-- DNI -->
                <ng-container matColumnDef="dni">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.document-types.dni" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.dni }}</td>
                </ng-container>
                <!-- First Name -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.full-name" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }} </td>
                </ng-container>
                <!-- Product Name -->
                <ng-container matColumnDef="debt_amount">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.debt-amount" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.debt_amount | currency }}</td>
                </ng-container>
                <!-- Product Name -->
                <ng-container matColumnDef="offered_amount">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.offered_amount" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.offered_amount | currency }}</td>
                </ng-container>
              </table>
            </div>
          </div>
          <div class="row mt-3" *ngIf="noCreditorsForCase.length >= 1">
            <div class="col-md-12">
              <h3>No Creditors for Case</h3>
              <p>These cases have no creditors associated with <b>{{selectedCreditorName}}</b>.</p>
              <table mat-table matSort [dataSource]="noCreditorsForCase" width="100%" *ngIf="noCreditorsForCase.length > 0">
                <tr mat-header-row *matHeaderRowDef="noCreditorsForCaseDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: noCreditorsForCaseDisplayedColumns;" (click)="openLink(row.id)"></tr>
                <!-- Ref Number -->
                <ng-container matColumnDef="ref_number">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.model.ref_number" | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <a class="pr-3" (click)="$event.stopPropagation()" [routerLink]="['/cases', element.id, 'creditors']" target="_blank">
                      {{ element.ref_number }}</a></td>
                </ng-container>
                <!-- DNI -->
                <ng-container matColumnDef="dni">
                  <th mat-header-cell *matHeaderCellDef>{{ "CASES.single.document-types.dni" | translate }}</th>
                  <td mat-cell *matCellDef="let element">{{ element.dni }}</td>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>