import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LaravelResourceResponse } from '../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../_base-shared/models/Case/Case';
import { CaseCreditor } from '../../../../../../../../_base-shared/models/Case/CaseCreditor';
import { PaymentMethod, PaymentMethodType } from '../../../../../../../../_base-shared/models/Payment/PaymentMethod';
import { PaymentProcessorType } from '../../../../../../../../_base-shared/models/Payment/PaymentProcessor';
import { PaymentTerm } from '../../../../../../../../_base-shared/models/Payment/PaymentTerm';
import { PaymentPlanType } from '../../../../../../../../_base-shared/models/Product/PaymentPlanType';
import { User } from '../../../../../../../../_base-shared/models/User/User';
import { environment } from '../../../../../../environments/environment';
import {
  ChangeAmountModalComponent,
} from '../../../../../_shared/components/change-amount-modal/change-amount-modal.component';
import {
  ChangeDateModalComponent,
} from '../../../../../_shared/components/change-date-modal/change-date-modal.component';
import {
  RequestPaymentModalComponent,
} from '../../../../../_shared/components/request-payment-modal/request-payment-modal.component';
import { MainGlobalEventService } from '../../../../../_shared/services/main-global-event.service';
import { DistributionService } from '../../../../distribution/distribution.service';
import {
  AdminPaymentHandlerComponent
} from '../../../../payment/admin-payment-handler/admin-payment-handler.component';
import { PaymentMethodService } from '../../../../payment/payment-method.service';
import { PaymentPlanTypeService } from '../../../../payment/payment-plan-type.service';
import { PaymentService } from '../../../../payment/payment.service';
import { CaseDocumentService } from '../../../case-document.service';
import {
  CaseInstallmentAmountEditorComponent,
} from '../case-installment-amount-editor/case-installment-amount-editor.component';
import {
  CasePaymentPlanGeneratorComponent,
} from '../case-payment-plan-generator/case-payment-plan-generator.component';
import { RebuildPaymentPlanComponent } from '../rebuild-payment-plan/rebuild-payment-plan.component';
import { RecordPaymentModalComponent } from './record-payment-modal/record-payment-modal.component';
import { saveAs } from 'file-saver';

@Component({
  selector:    'app-case-installment-list',
  templateUrl: './case-installment-list.component.html',
  styleUrls:   ['./case-installment-list.component.scss'],
  animations:  [
    trigger('detailExpand', [
      state('collapsed', style({display: 'none', minHeight: '0'})),
      state('expanded', style({display: 'block'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CaseInstallmentListComponent implements OnInit {
  @Input() componentType: 'distribution' | 'admin';
  @Input() case: Case;
  @Input() openRecordPaymentDialog: EventEmitter<any>;
  @Input() fetchInstalments: EventEmitter<any>;
  @Output() paymentRecorded: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('changeValueModal') valueModal: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public appEnv: string;
  public upcomingInstallments: Array<PaymentTerm> = [];
  public unpaidInstallments                       = [];
  public displayedColumns: Array<string>          = [];
  public installmentsPlanTypeControl              = new UntypedFormControl(['debt_plan'], [Validators.required]);
  public dataSource: MatTableDataSource<PaymentTerm>;
  public dataSourceUnpaid: MatTableDataSource<PaymentTerm>;
  public recordForm: UntypedFormGroup;
  public totalResults: number;
  public showOnlyUnpaid                           = false;
  public sortOrder                                = null;
  public sortBy                                   = null;
  public filteredInstallmentsBalance              = {
    totalPaid:  0,
    totalToPay: 0,
    totalPaidCounter: 0,
    totalDistributed: 0,
  };
  public debtPlanInstallmentsBalance              = {
    totalPaid:  0,
    totalToPay: 0,
  };
  public additionalPlansInstallmentsBalance       = {
    totalPaid:  0,
    totalToPay: 0,
  };
  public paginatorConfig                          = {
    pageIndex: 0,
    pageSize:  10,
    length:    1,
  };
  public now                                      = new Date(new Date().setHours(0, 0, 0, 0));
  public days                                     = [];
  public isLoading                                = 0;
  public authUser: User;
  public paymentMethods: Array<PaymentMethod>;
  public paymentPlanTypes: Array<PaymentPlanType>;
  private forceDefaultProcessor                   = false;
  public selectionPayments                        = new SelectionModel<any>(true, []);
  public selectionPaymentsDisabled                = true;
  public onlyDebtPlanSelected                     = true;
  public isSubmitting: boolean;

  public expandedRow: number;
  public expandedElement: CaseCreditor | null;
  public templateData: Array<any> = [];
  public creditorsMerged: Array<any> = [];

  public authorizedUsers                          = [27, 751562, 751600, 752733];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private globalEventsService: MainGlobalEventService,
    private paymentService: PaymentService,
    private distributionService: DistributionService,
    private paymentMethodService: PaymentMethodService,
    private paymentPlanTypeService: PaymentPlanTypeService,
    private caseDocumentService: CaseDocumentService
  ) {
    this.appEnv = environment.APP_ENV;
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(result => {
      const stateParams    = new URLSearchParams(result);
      const paymentOutcome = stateParams.get('payment_outcome');
      if (paymentOutcome === 'success' || paymentOutcome === 'error' || paymentOutcome === 'cancelled') {
        const paymentSuccess = paymentOutcome === 'success';
        let title: string;
        let message: string;
        if (paymentOutcome === 'success') {
          title   = this.translate.instant('SHARED-COMPONENTS.payment_outcome.success.heading');
          message = this.translate.instant('SHARED-COMPONENTS.payment_outcome.success.message');
        } else if (paymentOutcome === 'cancelled') {
          title   = this.translate.instant('SHARED-COMPONENTS.payment_outcome.cancelled.heading');
          message = this.translate.instant('SHARED-COMPONENTS.payment_outcome.cancelled.message');
        } else {
          title   = this.translate.instant('SHARED-COMPONENTS.payment_outcome.error.heading');
          message = this.translate.instant('SHARED-COMPONENTS.payment_outcome.error.message');
        }
        Swal.fire({
          title,
          text:               message,
          icon:               paymentSuccess ? 'success' : 'error',
          showCancelButton:   false,
          showConfirmButton:  true,
          cancelButtonText:   this.translate.instant('SHARED.cancel'),
          confirmButtonColor: '#886ab5',
        }).then(res => {
          if (res.isConfirmed) {
            this.router.navigate([], {queryParams: {payment_outcome: null}, queryParamsHandling: 'merge'});
          }
        });

      }
    });
    this.globalEventsService.authUser$.subscribe(user => {
      this.authUser         = user;
      this.displayedColumns = this.getTableColumns(this.componentType);
      this.fetchInstallments(this.componentType);
      this.fetchPaymentPlanTypes(this.componentType);
      //  Get total amount of paid terms and total amount to pay
      this.case.terms.forEach(term => {
        this.filteredInstallmentsBalance.totalToPay += term.amount;
        this.filteredInstallmentsBalance.totalPaid += term.amount_paid;
        this.filteredInstallmentsBalance.totalPaidCounter += this.filteredInstallmentsBalance.totalPaidCounter;
        if (term.case_plan_id === this.case.debt_payment_plan.id) {
          this.debtPlanInstallmentsBalance.totalToPay += term.amount;
          this.debtPlanInstallmentsBalance.totalPaid += term.amount_paid;
        }
        if (term.case_plan_id !== this.case.debt_payment_plan.id) {
          this.additionalPlansInstallmentsBalance.totalToPay += term.amount;
          this.additionalPlansInstallmentsBalance.totalPaid += term.amount_paid;
        }
      });
    });

    this.installmentsPlanTypeControl.valueChanges.subscribe(value => {
      this.onlyDebtPlanSelected      = value.includes('debt_plan') && value.length === 1;
      this.selectionPaymentsDisabled = !! (value.includes('debt_plan') || value.length === 0);
      this.forceDefaultProcessor     = value ? ! value.includes('debt_plan') : false;
      this.fetchInstallments(this.componentType);
    });

    //  Generate days array
    for (let i = 1; i <= 31; i++) {
      this.days.push(i);
    }

    this.selectionPayments.changed.subscribe(next => {
      this.checkIfDisabled(next);
    });

    //better to calculate if phase1 or 2 using variables than calling from the template
    //this.monthsRemaining = this.getMonthsRemaining();
    this.templateData.push(this.viewCalculations());

    let creditors = this.case.unsecured_creditors
    //const cred_unifye = this.case.creditors.find(creditor => creditor.id === 2817);
    const cred_unifye = this.case.creditors.find(creditor => creditor.name === 'UNIFYE');
    if (cred_unifye) {
      creditors.push(cred_unifye);
    }
    //not grouping for now, delete it ?  DAM
    //this.creditorsMerged = this.groupByNameAndCollectValues(creditors);
    this.creditorsMerged = creditors
    //console.log(this.creditorsMerged);
    
  }

  private groupByNameAndCollectValues(data: any[]) {
    // Initialize a map to store grouped data
    const map = new Map<string, any>();

    // Iterate through the data to group and collect values
    data.forEach(item => {
      const key = item.name;
      if (!map.has(key)) {
        map.set(key, { name: item.name, offer_to_creditor_values: [], total_offer_to_creditor: 0 });
        //map.set(key, { ...item, pivot: { ...item.pivot, t_offer_to_creditor: 0 } });
      }
      const group = map.get(key);
      group.offer_to_creditor_values.push(item.pivot.offer_to_creditor);
      group.total_offer_to_creditor += item.pivot.offer_to_creditor;
    });

    // Convert the map values to an array
    return Array.from(map.values());
  }

  private checkIfDisabled(data) {
    data.added.forEach(payment => {
      if (payment.case_payment_plan.type.slug === 'debt_plan') {
        this.selectionPaymentsDisabled = true;
        if (this.authorizedUsers.includes(this.authUser.id)) {
          this.selectionPaymentsDisabled = false;
        }
      } else {
        this.selectionPaymentsDisabled = false;
      }
    });
  }

  private fetchInstallments(componentType: 'admin' | 'distribution') {
    const data = {
      per_page:        this.paginatorConfig.pageSize,
      page:            this.paginatorConfig.pageIndex + 1,
      case_id:         this.case.id,
      select_all:      1,
      order:           this.sortOrder || 'asc',
      sort_by:         this.sortBy || 'term_date',
      plan_type_slugs: this.installmentsPlanTypeControl.value ? this.installmentsPlanTypeControl.value : [],
    };

    const withRelations = [
      'case.client',
      'case.debt_payment_plan',
      'distribution_case_terms',
      //'case.terms',
      'distribution_case_terms.batches',
      'case.payment_status',
      'case_payment_plan.type',
    ];
    const observable    = componentType === 'admin' ?
      this.paymentService.indexCaseInstallments(this.case.id, data, withRelations) :
      this.distributionService.indexInstallments(this.case.uuid, data, withRelations);

    this.isLoading++;
    this.dataSource           = new MatTableDataSource<PaymentTerm>([]);
    this.upcomingInstallments = [];
    observable.pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => this.handleResponse(res),
        error => console.log(error),
      );
  }

  public saveTermId(installment: PaymentTerm) {
    const dialogRef = this.dialog.open(ChangeDateModalComponent, {
      width: '50%',
      data:  {
        term: installment,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchInstallments(this.componentType);
      }
    });
  }

  public toggleRow(element: any) {
    element.expanded = !element.expanded;
  }

  public showHideUnpaid($event) {
    this.paginator.firstPage();
    if ($event.checked) {
      this.paginatorConfig.length = this.unpaidInstallments.length;
    } else {
      this.paginatorConfig.length = this.upcomingInstallments.length;
    }
    this.showOnlyUnpaid = $event.checked;
  }

  public sortData(sort) {
    this.sortOrder = sort.direction !== '' ? sort.direction : null;
    this.sortBy    = sort.direction !== '' ? sort.active : null;

    this.fetchInstallments(this.componentType);
  }

  public openChangeAmountModal(installment: PaymentTerm) {
    const dialogRef = this.dialog.open(ChangeAmountModalComponent, {
      width: '50%',
      data:  {
        term: installment,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchInstallments(this.componentType);
      }
    });
  }

  public openAdditionalAmountEditorModal(installment: PaymentTerm) {
    const dialogRef = this.dialog.open(CaseInstallmentAmountEditorComponent, {
      width: '50%',
      data:  {
        installment,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.fetchInstallments(this.componentType);
    });
  }

  public requestPaymentDialog(paymentMethodType: PaymentMethodType, paymentProcessorType: PaymentProcessorType): void {
    const nextTerm       = this.upcomingInstallments.find(t => t.amount > t.amount_paid);
    let maxPayableAmount = 0;
    this.upcomingInstallments.forEach(term => {
      maxPayableAmount += term.amount - term.amount_paid;
    });

    maxPayableAmount = +maxPayableAmount.toFixed(2);

    if (nextTerm) {
      const dialogRef = this.dialog.open(RequestPaymentModalComponent, {
        width:        '50%',
        minHeight:    200,
        disableClose: true,
        autoFocus:    false,
        data:         {
          caseId:                this.case.id,
          paymentMethodType,
          paymentProcessorType,
          amount:                (nextTerm.amount - nextTerm.amount_paid).toFixed(2),
          maxPayableAmount,
          forceDefaultProcessor: this.forceDefaultProcessor,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.success) {
            this.toastr.success(this.translate.instant(
              'CASES.single.request-payment-success'), this.translate.instant('SHARED.success'),
            );
          } else {
            this.toastr.error(this.translate.instant(
              'CASES.single.request-payment-error'), this.translate.instant('SHARED.error'),
            );
          }
        }
      });
    } else {
      this.toastr.error('No installment available');
    }
  }

  public openDialog(relocate = false): void {
    this.buildForm();
    const dialogRef = this.dialog.open(RecordPaymentModalComponent, {
      width: '80%',
      data:  {
        form:              this.recordForm,
        forAdditionalPlan: ! this.onlyDebtPlanSelected,
        relocate,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchInstallments(this.componentType);
        if (relocate) {
          this.toastr.success(this.translate.instant('CASES.single.payment-relocated-success'),
            this.translate.instant('SHARED.success'));
        } else {
          this.toastr.success(this.translate.instant('CASES.single.payment-record-success'),
            this.translate.instant('SHARED.success'));
        }
        this.fetchInstalments.emit();
      }
    });
  }

  public openCardModal(): void {
    const nextTerm       = this.upcomingInstallments.find(t => t.amount > t.amount_paid);
    const chargeAmount   = nextTerm ? (nextTerm.amount - nextTerm.amount_paid).toFixed(2) : 0;
    let maxPayableAmount = 0;
    this.upcomingInstallments.forEach(term => {
      maxPayableAmount += term.amount - term.amount_paid;
    });

    if (nextTerm) {
      const dialogRef = this.dialog.open(AdminPaymentHandlerComponent, {
        width:        '60%',
        minHeight:    200,
        disableClose: false,
        panelClass:   ['custom-mat-dialog', 'scrollable-mat-dialog'],
        autoFocus:    false,
        data:         {
          caseId:          this.case.id,
          clientRole:      'client',
          type:            'moto',
          amount:          chargeAmount,
          editableAmount:  true,
          maxPayableAmount,
          paymentPlanType: this.forceDefaultProcessor ? 'additional_plans' : 'debt_plan',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.reFetch) {
          this.fetchInstallments(this.componentType);
          this.fetchInstalments.emit();
          this.displayDistributionWarningModal();
        }
      });
    }
  }

  public buildForm() {
    const defaultPaymentMethod = this.paymentMethods.find(m => m.slug === 'sepa');
    const defaultIncomeAccount = defaultPaymentMethod.billing_bank_accounts.find(account => account.default === true);
    const defaultPlanId        = this.paymentPlanTypes.find(planType => planType.slug === 'debt_plan');

    this.recordForm = this.fb.group({
      case_id:           this.case.id,
      payment_plan_id:   defaultPlanId?.id ? defaultPlanId.id : null,
      note:              '',
      payment_date:      [new Date()],
      payment_method_id: [defaultPaymentMethod.id],
      payment_method:    [defaultPaymentMethod.slug],
      income_account_id: [defaultIncomeAccount.id, Validators.required],
      split_type:        ['custom', null],
      amount:            [0, Validators.required],
      amount_paid:       [null, Validators.required],
      phase_one_amount:  [0, null],
      phase_two_amount:  [0, null],
      phase_one_paid:    [
        this.upcomingInstallments.filter(
          term => (term.type === null || term.type === 'phase_one' || term.type === 'deferred_one') && term.amount >
            term.amount_paid).length === 0, null],
      phase_two_paid:    [
        this.upcomingInstallments.filter(
          term => (term.type === 'phase_two' || term.type === 'deferred_two') && term.amount >
            term.amount_paid).length === 0, null],
      terms:             this.fb.array([]),
      terms_phase_one:   this.fb.array(
        this.upcomingInstallments.filter(
          term => term.type === null || term.type === 'phase_one' || term.type === 'deferred_one'),
      ),
      terms_phase_two:   this.fb.array(this.upcomingInstallments.filter(term => term.type === 'phase_two')),
    });
    this.addInstalments();
    this.calculateAmountPaid();
  }

  addInstalments() {
    const instalments = this.recordForm.get('terms') as UntypedFormArray;
    this.upcomingInstallments.forEach(term => {
      instalments.push(
        this.fb.group({
          id:              term.id,
          term_date:       term.term_date,
          date_paid:       term.date_paid,
          type:            term.type,
          name:            term.name,
          amount:          term.amount,
          amount_paid:     0,
          old_amount_paid: term.amount_paid,
        }),
      );
    });
  }

  calculateAmountPaid() {
    let phaseOneAmount = 0;
    let phaseTwoAmount = 0;
    let amountPaid     = 0;
    let amountPayable  = 0;
    this.upcomingInstallments.forEach(term => {
      amountPayable += term.amount - term.amount_paid;
      amountPaid += term.amount_paid;
      if (term.type === null || term.type === 'phase_one' || term.type === 'deferred_one') {
        phaseOneAmount += term.amount - term.amount_paid;
      } else if (term.type === 'phase_two' || term.type === 'deferred_two') {
        phaseTwoAmount += term.amount - term.amount_paid;
      }
    });
    amountPayable = +amountPayable.toFixed(2);
    this.recordForm.get('phase_one_amount').setValue(phaseOneAmount);
    this.recordForm.get('phase_two_amount').setValue(phaseTwoAmount);
    this.recordForm.get('amount_paid').setValue(amountPaid);
    this.recordForm.get('amount').setValidators([Validators.required, Validators.max(Math.min(amountPayable, 4000))]);
  }

  /*public getNextOrSameMonthDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    
    //if (day >= 15) {
        // Move to the next month
    //    date.setMonth(date.getMonth() + 1);
    //}
    
    // Move to the next month
    date.setMonth(date.getMonth() + 1);
    // Set the date to the 15th of the current or next month
    date.setDate(15);

    return date;
  }*/

  public getNextOrSameMonthDate(inputDate) {
    const date = new Date(inputDate);

    // Loop until the date is the 15th of the next month
    while (date.getDate() !== 15 || date.getMonth() === new Date(inputDate).getMonth()) {
        // Increment the date by one day
        date.setDate(date.getDate() + 1);
    }

    return date;
  }

  public handleResponse(response: LaravelResourceResponse) {
    //  Get total amount of paid terms and total amount to pay
    this.filteredInstallmentsBalance.totalToPay = 0;
    this.filteredInstallmentsBalance.totalPaid  = 0;
    this.filteredInstallmentsBalance.totalPaidCounter = 0;
    this.filteredInstallmentsBalance.totalDistributed = 0;

    let totalTermDistribution: number = 0;
    let term_distributed_at: Date = null;

    
    response.data.forEach(term => {
      totalTermDistribution = 0;
      term_distributed_at = null;

      this.filteredInstallmentsBalance.totalToPay += term.amount;
      this.filteredInstallmentsBalance.totalPaid += term.amount_paid;
      this.filteredInstallmentsBalance.totalPaidCounter += this.filteredInstallmentsBalance.totalPaidCounter;

      term.distribution_case_terms.forEach(dist => {
        this.filteredInstallmentsBalance.totalDistributed += dist.amount_distributed;
        totalTermDistribution += dist.amount_distributed;
        if (term.id === dist.term_id && 
            dist.distributed_at != null && 
            dist.distributed_at !== undefined 
            && dist.distributed_at !== '') {
          term_distributed_at = dist.distributed_at;
        }
      });
      term.total_distributed_amount = totalTermDistribution;
      term.distribution_schedule = this.getNextOrSameMonthDate(term.term_date);
      term.distributed_at = term_distributed_at;
      term.offer_to_creditors_total = Number(this.case.expense.offer_to_creditors_total);
      term.distribution_fee = Number(this.case.expense.distribution_fee);

    });

    const onlyUnpaid = response.data.filter(obj => obj.amount !== obj.amount_paid);

    this.totalResults               = response.data.total;
    this.upcomingInstallments       = response.data;
    this.unpaidInstallments         = onlyUnpaid;
    this.dataSource                 = new MatTableDataSource<PaymentTerm>(response.data);
    this.dataSourceUnpaid           = new MatTableDataSource<PaymentTerm>(onlyUnpaid);
    this.dataSource.paginator       = this.paginator;
    this.dataSource.sort            = this.sort;
    this.dataSourceUnpaid.paginator = this.paginator;
    this.dataSourceUnpaid.sort      = this.sort;
    this.paginatorConfig.length     = response.data.length;
  }

  public getPercent(paid, amount) {
    if ((paid / amount) * 100) {
      return ((paid / amount) * 100).toFixed(2);
    } else {
      return 0;
    }
  }

  deleteAndRebuild() {
    this.isLoading++;
    this.dataSource = new MatTableDataSource<PaymentTerm>([]);

    this.paymentService.deleteAllPayments(this.case.id) //  Delete all installments
      .subscribe(
        value => {
          this.paymentService.rebuildInstallmentPlan(this.case.id)  //  Rebuild installments
            .pipe(finalize(() => this.isLoading--))
            .subscribe(
              res => {
                this.fetchInstallments(this.componentType);
                this.toastr.success(this.translate.instant('CASES.single.rebuild-plan-note-success'));
              },
              error => {
                this.toastr.error(error.message, this.translate.instant('SHARED.error'));
              },
            );
        },
        error => {
          this.toastr.error(error.message, this.translate.instant('SHARED.error'));
        },
      );
  }

  rebuild() {
    this.isLoading++;
    this.dataSource = new MatTableDataSource<PaymentTerm>([]);

    this.paymentService.rebuildInstallmentPlan(this.case.id)  //  Rebuild installments
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.fetchInstallments(this.componentType);
          this.toastr.success(this.translate.instant('CASES.single.rebuild-plan-note-success'));
        },
        error => {
          this.toastr.error(error.message, this.translate.instant('SHARED.error'));
        },
      );
  }

  rebuildManual(data) {
    this.isLoading++;
    this.dataSource = new MatTableDataSource<PaymentTerm>([]);

    this.paymentService.rebuildInstallmentPlanManual(data)  //  Rebuild installments
      .pipe(finalize(() => this.isLoading--))
      .subscribe(
        res => {
          this.fetchInstallments(this.componentType);
          this.toastr.success(this.translate.instant('CASES.single.rebuild-plan-note-success'));
        },
        error => {
          this.toastr.error(error.message, this.translate.instant('SHARED.error'));
        },
      );
  }

  public rebuildInstallmentPlanModal() {
    const dialogRef = this.dialog.open(RebuildPaymentPlanComponent, {
      width: '60%',
      data:  {
        case:             this.case,
        terms:            this.upcomingInstallments,
        deleteAndRebuild: () => this.deleteAndRebuild(),
        rebuild:          () => this.rebuild(),
        rebuildManual:    (data) => this.rebuildManual(data),
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.fetchPayments();
      // this.fetchInstalments.emit();
    });
  }

  public openPaymentPlanGenerationModal() {
    const dialogRef = this.dialog.open(CasePaymentPlanGeneratorComponent, {
      width: '60%',
      data:  {
        case: this.case,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.fetchInstallments(this.componentType);
    });
  }

  deleteAllPayments() {
    Swal.fire({
      title:              this.translate.instant('CASES.single.remittance-request-text'),
      text:               this.translate.instant('CASES.single.remittance-request-warning'),
      icon:               'warning',
      showCancelButton:   true,
      cancelButtonText:   this.translate.instant('SHARED.no'),
      confirmButtonText:  this.translate.instant('SHARED.yes'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.dataSource = new MatTableDataSource<PaymentTerm>([]);

        this.paymentService.deleteAllPayments(this.case.id).pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.fetchInstallments(this.componentType);
              this.toastr.success(this.translate.instant('CASES.single.remittance-request-success'));
            },
            error => {
              this.toastr.error(this.translate.instant('CASES.single.remittance-request-error'));
            },
          );
      }
    });
  }

  private fetchPaymentMethods(componentType: 'admin' | 'distribution'): void {
    if (componentType === 'distribution') {
      this.paymentMethods = [];
      return;
    }
    this.isLoading++;
    this.paymentMethodService.index({}, ['billing_bank_accounts']).pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        this.paymentMethods = result.data;
        this.buildForm();
      });
  }

  private fetchPaymentPlanTypes(componentType: 'distribution' | 'admin') {
    this.isLoading++;
    this.paymentPlanTypeService.index().pipe(finalize(() => this.isLoading--))
      .subscribe(result => {
        let data              = result.data.filter(planType => ! ['claim_fee', 'asnef_fee', 'annual_review_fee'].includes(planType.slug));
        this.paymentPlanTypes = componentType === 'admin' ?
          data :
          data.filter(planType => planType.default === true);
        this.fetchPaymentMethods(this.componentType);
      });
  }

  changePaymentMethod($event): void {
    this.isLoading++;
    const data = {payment_method: $event.target.value};
    this.paymentMethodService.updateCasePaymentMethod(this.case.id, data).pipe()
      .subscribe(res => {
          this.isLoading--;
          this.toastr.success('', this.translate.instant('SHARED.success'),
          );
        },
        error => {
          this.isLoading--;
          this.toastr.error('', this.translate.instant('SHARED.error'));
        },
      );
  }

  paymentMethodIsSelected($paymentMethodId: number): boolean {
    return $paymentMethodId === this.case?.debt_payment_plan?.payment_method_id;
  }

  displayDistributionWarningModal(): void {
    Swal.fire({
      text:               this.translate.instant('DISTRIBUTION.modals.confirm-payment'),
      icon:               'warning',
      showCancelButton:   false,
      confirmButtonText:  'OK',
      confirmButtonColor: '#4267b2',
    });
  }

  public updateComponentType(type: 'distribution' | 'admin') {
    this.componentType    = type;
    this.displayedColumns = this.getTableColumns(type);
    this.fetchInstallments(type);
  }

  private getTableColumns(type: 'distribution' | 'admin'): Array<string> {
    const columns = [
      'select',
      'client-name',
      //'email',
      'name',
      'next-payment',
      'date-paid',
      'amount',
    ];
    if (type === 'admin') {
      columns.push('actions');
    }

    if (this.isPhaseOne()||this.isPhaseTwo()){
      columns.push('newColSeparator');
      columns.push('dist-schedule');
      columns.push('dist-paid');
    }

    if (this.isPhaseTwo()){
      columns.push('amountDistributed');
      columns.push('showDistribution');
    }
    
    return columns;
  }

  public deleteInstallment(termId: number) {
    Swal.fire({
      title:              this.translate.instant('SHARED.warning'),
      text:               this.translate.instant('SHARED.action.delete_confirmation',
        {model: this.translate.instant('INSTALLMENTS.model_name.singular')}),
      icon:               'warning',
      showCancelButton:   true,
      confirmButtonText:  this.translate.instant('SHARED.delete'),
      cancelButtonText:   this.translate.instant('SHARED.cancel'),
      confirmButtonColor: '#886ab5',
    }).then(res => {
      if (res.isConfirmed) {
        this.isLoading++;
        this.dataSource = new MatTableDataSource<PaymentTerm>([]);

        this.paymentService.deleteCaseInstallment(this.case.id, termId).pipe(finalize(() => this.isLoading--))
          .subscribe(
            value => {
              this.fetchInstallments(this.componentType);
              this.toastr.success(this.translate.instant('SHARED.submit_result.delete.success', {
                model: this.translate.instant('INSTALLMENTS.model_name.singular'),
              }));
            },
            error => {
              this.toastr.error(this.translate.instant('SHARED.submit_result.delete.error', {
                model: this.translate.instant('INSTALLMENTS.model_name.singular'),
              }));
            },
          );
      }
    });
  }

  public markAsPromo(installment: PaymentTerm) {
    this.isLoading++;
    this.paymentService.caseInstallmentMarkAsPromo(this.case.id, installment.id).pipe(finalize(() => this.isLoading--))
      .subscribe(
        result => {
          this.toastr.success(this.translate.instant('SHARED.submit_result.update.success', {
            model: this.translate.instant('INSTALLMENTS.model_name.singular'),
          }));
        },
        error => {
          this.toastr.error(this.translate.instant('SHARED.submit_result.update.error', {
            model: this.translate.instant('INSTALLMENTS.model_name.singular'),
          }));
        }
      );
  }

  public isPhaseTwo(){
    const phaseTwoStatusIds = [335];
    return phaseTwoStatusIds.includes(this.case.status_id);
  }

  public isPhaseOne(){
    const phaseTwoStatusIds = [411, 383, 394, 113];
    return phaseTwoStatusIds.includes(this.case.status_id);
  }

  public getMonthsRemaining(){
    //console.log("months remaining");
    //console.log(this.case.terms);
    //console.log(this.case.terms.length);
    let totalMonths : number = this.case.terms.length;
    this.case.terms.forEach((item, index) => {
      console.log(`Item at index ${index}:`, item);
      if (item.amount_paid > 0){
        totalMonths--;
      }
    });
    return totalMonths;
  }

  public viewCalculations(){
    //months pending to pay
    let totalMonthsToPay : number = this.case.terms.length;
    let fee = this.case.expense.distribution_fee;
    let feesPaid = 0;
    let totalMonthsFullyPaid = 0;
    let totalDebtAmount = 0;
    let totalFinalPayments= 0;
    let totalCreditorsOffer = 0;
    this.case.terms.forEach((item, index) => {
      
      //console.log(`Item at index ${index}:`, item);
      if (item.amount_paid > 0){
        totalMonthsToPay--;
        feesPaid += item.amount_paid;
      }
      if (item.amount_paid != null && item.amount_paid > this.case.expense.distribution_fee){
        //console.log("Fully paid");
        //console.log(item.amount_paid);
        //console.log(fee);
        totalMonthsFullyPaid++;
      }
    });

    this.case.creditors.forEach((item, index) => {
      if (item.pivot.debt_amount > 0){
        totalDebtAmount += Number(item.pivot.debt_amount);
      }

      //Ehem
      if (item.pivot.offer_to_creditor > 0){
        totalCreditorsOffer += item.pivot.offer_to_creditor;
      }

      if (item.pivot.final_payment > 0){
        totalFinalPayments += item.pivot.final_payment;
      }
    });

    return { DmTotalMonthsPending: totalMonthsToPay,
        DMfeePaid: feesPaid,
        DMMonthsFeeFullyPaid: totalMonthsFullyPaid,
        DMTotalDebtAmount: totalDebtAmount,
        totalCreditorsOffer,
        totalFinalPayments //,
        //DMbalance: balance
      };
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(selection, dataSource) {
    const numSelected = selection.selected.length;
    const numRows     = dataSource.data.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(selection, dataSource) {
    if (this.isAllSelected(selection, dataSource)) {
      selection.clear();
      return;
    }

    selection.select(...dataSource.data);
  }

  deletePayment($event, selectionPayment) {
    $event.preventDefault();
    const installment_ids = [];
    selectionPayment._selected.forEach(selected => installment_ids.push(selected.id));
    this.paymentService.deletePayments(this.case.id, installment_ids).pipe(finalize(() => this.isLoading--))
      .subscribe(
        value => {
          this.toastr.success(this.translate.instant('SHARED.item-deleted'));
        },
        error => {
          this.toastr.error(this.translate.instant('SHARED.went-wrong'));
        },
      );
  }

  generateRemittanceRequest(): void {
    this.paymentService.generateRemittanceRequest(this.case.id)
      .subscribe(res => {
        this.toastr.success(this.translate.instant('SHARED.success'));
      }, err => {
        this.toastr.error(this.translate.instant('SHARED.went-wrong'));
      });
  }

  public downloadClientInstallment(): void {
    const data = {
      document_type_slug: 'client-installment-list'
    };
    this.isSubmitting = true;
    this.caseDocumentService.generateDocument(this.case.id, data)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(result => {
        if (result.type === 4) {
          const fileName = 'client-installment-list_' + DateTime.local().toFormat('yyyy-LL-dd_HH-mm') + '.' + 'pdf';
          saveAs(result.body, fileName);
          this.toastr.success('Downloaded document');
        }
      }, err => {
        this.toastr.error('Failed to generate document');
      });
  }

  public sendPaymentPlan(): void {
    this.caseDocumentService.sendClientInstallmentList(this.case.id).subscribe(res => {
      this.toastr.success(this.translate.instant('SHARED.success'));
    }, err => {
      this.toastr.error(this.translate.instant('SHARED.went-wrong'));
    });
  }
}
