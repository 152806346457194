import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppSelectOption } from '../../../../../../../_base-shared/contracts/common.interface';
import { CaseVariable } from '../../../../../../../_base-shared/models/Case/Case';
import { DepartmentCategory } from '../../../../../../../_base-shared/models/Department/DepartmentCategory';
import { AppFile, PickedFile } from '../../../../../../../_base-shared/models/File/AppFile';
import { EmailTemplate } from '../../../../../../../_base-shared/models/Notification/EmailTemplate';
import { User } from '../../../../../../../_base-shared/models/User/User';
import { MainBaseApiService } from '../../../../_shared/services/main-base-api.service';
import { UploadService } from '../../../app-file/upload.service';
import { CaseService } from '../../../case/case.service';
import { DepartmentService } from '../../../department/department.service';
import { UserService } from '../../../user/user.service';
import { EmailService } from '../email.service';

@Component({
  selector:    'app-email-editor',
  templateUrl: './email-editor.component.html',
  styleUrls:   ['./email-editor.component.scss'],
})
export class EmailEditorComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public isLoading                                       = 0;
  public editorType: string;
  public emailTemplateId: number;
  public email: EmailTemplate;
  public fromableTypes: Array<AppSelectOption>;
  public attachments: Array<AppFile>;
  public pickedFiles: Array<PickedFile>                  = [];
  public users: Array<User>                              = [];
  public departmentCategories: Array<DepartmentCategory> = [];
  public templateStatuses: Array<AppSelectOption>        = [];
  public templateVariables: Array<CaseVariable>          = [];
  public editor;
  public submitted                                       = false;
  public show                                            = false;

  public quillModules                        = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };
  private subscriptions: Array<Subscription> = [];

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private toastr: ToastrService,
              private emailService: EmailService,
              private uploadService: UploadService,
              private caseService: CaseService,
              private userService: UserService,
              private departmentService: DepartmentService) {
  }

  ngOnInit(): void {
    this.templateStatuses = [
      {value: 1, label: this.translate.instant('AFFILIATES.active')},
      {value: 1, label: this.translate.instant('AFFILIATES.inactive')},
    ];
    this.buildFromableTypes();
    this.fetchVariables();
    this.fetchFromables();
    this.editorType = this.route.snapshot.data.editor;
    if (this.editorType === 'edit') {
      this.emailTemplateId = +this.route.snapshot.paramMap.get('id');
      this.fetchEmail(this.emailTemplateId);
    } else {
      this.email = new EmailTemplate();
      this.buildForm();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private fetchEmail(emailId: number) {
    this.isLoading++;
    this.emailService.get(emailId, ['attachments'])
        .pipe(
            finalize(() => this.isLoading--),
        )
        .subscribe(
            res => {
              this.email = res.data;
              this.buildForm();
            },
            err => console.log(err),
        );
  }

  get attachmentsArray() {
    return this.form.get('attachments') as UntypedFormArray;
  }

  private buildFromableTypes() {
    this.fromableTypes = [
      {
        value: '',
        label: this.translate.instant(
            'CONFIG.drip-campaign.editor.drip-notification.fromable_type.options.none',
        ),
      },
      {
        value: 'user',
        label: this.translate.instant(
            'CONFIG.drip-campaign.editor.drip-notification.fromable_type.options.user',
        ),
      },
      {
        value: 'department',
        label: this.translate.instant(
            'CONFIG.drip-campaign.editor.drip-notification.fromable_type.options.department',
        ),
      },
    ];
  }

  private fetchVariables() {
    this.isLoading++;
    this.subscriptions.push(
        this.caseService.indexCaseVariables().pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.templateVariables = result.data),
    );
  }

  private buildForm() {
    this.form = this.fb.group({
      sendgrid_template_id: [this.email.sendgrid_template_id, [Validators.minLength(5)]],
      fromable_type:        [this.email.fromable_type],
      fromable_id:          [this.email.fromable_id],
      label:                [this.email.label, Validators.required],
      description:          [this.email.description],
      subject:              [this.email.subject],
      bcc:                  [this.email.bcc ? this.email.bcc.join(', ') : null],
      content:              [this.email.content, Validators.required],
      attachments:          this.fb.array([]),
      active:               [this.email.active, Validators.required],
    });

    const formAttachments = this.form.get('attachments') as UntypedFormArray;
    if (this.email.attachments && this.email.attachments.length) {
      this.email.attachments.forEach(attachment => {
        formAttachments.push(this.initAttachment(attachment));
      });
    }
  }

  public getEditorInstance(editor) {
    this.editor = editor;
  }

  public submit(form: UntypedFormGroup) {
    this.submitted = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toastr.error(this.translate.instant('CONFIG.sms-template.sms-validation'));
      return;
    }
    const data = this.getFormData(form.value, this.pickedFiles);
    if (this.editorType === 'edit') {
      this.emailService.update(this.emailTemplateId, data).subscribe(
          res => {
            this.toastr.success(
                this.translate.instant('CONFIG.email-template.edit-email-template-success'),
                this.translate.instant('SHARED.success'),
            );
            this.router.navigateByUrl('/email');
          },
          err => {
            this.toastr.error(
                this.translate.instant('CONFIG.email-template.edit-email-template-error'),
                this.translate.instant('SHARED.error'),
            );
          },
      );
    } else {
      this.emailService.store(data).subscribe(
          res => {
            this.toastr.success(
                this.translate.instant('CONFIG.email-template.add-email-template-success'),
                this.translate.instant('SHARED.success'),
            );
            this.router.navigateByUrl('/email');
          },
          err => {
            console.log(err);
            this.toastr.error(
                this.translate.instant('CONFIG.email-template.add-email-template-error'),
                this.translate.instant('SHARED.error'),
            );
          },
      );
    }
  }

  private uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }

  public addVariable($event) {
    const content = this.form.get('content').value || '';
    this.form.get('content').setValue(content + $event.target.innerText + ' ');
  }

  private fetchFromables() {
    this.isLoading++;
    this.subscriptions.push(
        this.userService.index({is_staff: 1, select_all: 1}).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.users = result.data),
    );
    this.isLoading++;
    this.subscriptions.push(
        this.departmentService.categoryIndex(['departments']).pipe(finalize(() => this.isLoading--))
            .subscribe(result => this.departmentCategories = result.data),
    );
  }

  public onAttachmentsChange($event) {
    const fileList: FileList = ($event.target as HTMLInputElement).files;
    for (let i = 0; i < fileList.length; i++) {
      const file      = fileList[i];
      const fileName  = file.name;
      const lastDot   = fileName.lastIndexOf('.');
      const name      = fileName.substring(0, lastDot);
      const extension = fileName.substring(lastDot + 1);
      this.pickedFiles.push({name, extension, type: file.type, size: file.size, file});
    }
  }

  private initAttachment(attachment: AppFile) {
    return this.fb.group({
      id:        [attachment.id ? attachment.id : null],
      name:      [attachment.name, [Validators.required]],
      extension: [{value: attachment.extension, disabled: true}],
      disk:      [{value: attachment.disk, disabled: true}],
      path:      [{value: attachment.path, disabled: true}],
      mime_type: [{value: attachment.mime_type, disabled: true}],
      size:      [{value: attachment.size, disabled: true}],
    });
  }

  public removeAttachment(index: number) {
    this.attachmentsArray.removeAt(index);
  }

  public removeLocalAttachment(index: any) {
    this.pickedFiles.splice(index, 1);
  }

  private getFormData(formValue, pickedFiles: Array<PickedFile>): FormData {
    const formData = MainBaseApiService.getFormData(formValue);

    for (const picked of pickedFiles) {
      formData.append('new_attachments[]', picked.file, picked.name + '.' + picked.extension);
    }

    return formData;
  }

  public updateFileName($event, index: number) {
    this.pickedFiles[index].name = $event.target.value;
  }

  public fromableTypeChanged() {
    this.form.get('fromable_id').setValue(null);
    this.form.get('fromable_id').updateValueAndValidity();
  }
}
