<div *ngIf="isLoading || serverResponse" class="card mb-3">
  <div class="card-body">
    <div class="row">
      <div [hidden]="!isLoading" class="col-12">
        <mat-spinner class="m-0 m-auto"></mat-spinner>
      </div>
      <div *ngIf="serverResponse" class="col-12">
        <app-server-response [response]="serverResponse"></app-server-response>
      </div>
    </div>
  </div>
</div>

<div *ngIf="financialOverview" class="row outcome-wrap">
  <div *ngFor="let product of products" class="col-3">
    <div class="card shadow">
      <div class="card-header text-center selectable"
           [class.selected]="selectedProductControl.value === product.id">
        <h2 class="m-0">{{ product.name }}</h2>
      </div>
      <div class="card-body d-flex flex-column" style="min-height: 900px">
        <!-- Dynamic Description -->
        <div class="dynamic-description">
          <!-- LSO General Info -->
          <div *ngIf="product.group_slug === 'lso'">
            <p *ngIf="product.slug === 'lso-te' || product.slug === 'lso-lq'">
              {{ 'CASES.editor.outcome.product_summary.expected_term_label' | translate }}:
              {{ 'SHARED.average' | translate }} 6 {{ 'SHARED.months' | translate }}
            </p>
            <p *ngIf="product.slug === 'lso' || product.slug === 'lso2'">
              {{ 'CASES.editor.outcome.product_summary.expected_term_label' | translate }}:
              {{ 'SHARED.average' | translate }} 60 {{'SHARED.months' | translate}}
            </p>
            <p *ngIf="product.slug === 'lso-te' || product.slug === 'lso-lq'">
              {{ 'CASES.financial_overview.table.disposable_income' | translate }}:
              {{(financialOverview?.income - financialOverview?.expenses) | number:'1.2-2'}}€
            </p>
            <div class="d-flex" *ngIf="product.slug === 'lso-te' || product.slug === 'lso-lq'">
              <p>
                {{ 'CASES.financial_overview.table.unsecured_debt' | translate }}:
                {{ financialOverview?.unsecured_debt | number:'1.2-2' }}€
              </p>
            </div>
            <div class="d-flex" *ngIf="product.slug === 'lso-te' || product.slug === 'lso-lq'">
              <p>
                {{ 'CASES.editor.outcome.product_summary.has_public_debt' | translate }}:
                {{ case?.public_debts?.length ? ('SHARED.yes' | translate) : ('SHARED.no' | translate) }}
              </p>
            </div>
            <div class="d-flex" *ngIf="product.slug === 'lso-te' || product.slug === 'lso-lq'">
              <p class="">
                {{ 'CASES.editor.outcome.product_summary.total_assets_less_than_unsecured_debut' | translate }}:
                {{ (financialOverview.assets < financialOverview.unsecured_debt) ?
                ('SHARED.yes' | translate) :
                ('SHARED.no' | translate) }}
              </p>
            </div>
            <div class="d-flex" *ngIf="potentialAzcarate.length">
              <p class="pr-2" style="text-decoration: underline">
                {{ 'CASES.editor.outcome.product_summary.potential_azcarate_list' | translate }}:
              </p>
              <p *ngFor="let azcarateCreditor of potentialAzcarate; let i = index" class="pr-2"
                 style="text-decoration: underline">
                {{ azcarateCreditor.name }}{{ i + 1 < potentialAzcarate.length ? ', ' : ''}}
              </p>
            </div>
          </div>
          <!-- DM General Info -->
          <div *ngIf="product.group_slug === 'dmp'">
            <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.expected_term_label' | translate }}:
                {{ expectedTerm | number:'1.2-2'}} {{ 'SHARED.months' | translate }}
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.financial_overview.table.disposable_income' | translate }}:
                {{ (financialOverview?.income - financialOverview?.expenses) | number:'1.2-2' }}€
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.financial_overview.table.unsecured_debt' | translate }}:
                {{ financialOverview?.unsecured_debt | number:'1.2-2' }}€
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.unsecured_creditor_count' | translate }}:
                {{ unsecuredCreditorsCount }}
              </p>
            </div>
            <div class="d-flex" *ngIf="potentialAzcarate.length">
              <p class="pr-2" style="text-decoration: underline">
                {{ 'CASES.editor.outcome.product_summary.potential_azcarate_list' | translate }}:
              </p>
              <p *ngFor="let azcarateCreditor of potentialAzcarate; let i = index" class="pr-2"
                 style="text-decoration: underline">
                {{ azcarateCreditor.name }}{{ i + 1 < potentialAzcarate.length ? ', ' : ''}}
              </p>
            </div>
            <p style="padding-bottom: 40px"></p>
          </div>
          <!-- TODO: LSO - Total Exoneration Specific Info -->
          <div *ngIf="product.slug === 'lso-te'"></div>
          <!-- LSO - Payment Plan Specific Info -->
          <div *ngIf="product.slug === 'lso-pp'">
            <div class="requirement_1">
              <h2 class="font-weight-bold">
                {{ 'CASES.editor.outcome.product_summary.lso_payment_plan.requirement_1.heading' | translate }}
              </h2>
              <p>
                {{ 'CASES.editor.outcome.product_summary.lso_payment_plan.requirement_1.income' | translate}}:
                {{ financialOverview?.income | number:'1.2-2' }}€
              </p>
              <p>
                {{ 'CASES.financial_overview.table.seizable_amount' | translate }}:
                {{ financialOverview.seizable_amount | number:'1.2-2' }}€
              </p>
              <p>
                {{ 'CASES.financial_overview.table.disposable_income' | translate }}:
                {{ (financialOverview?.income - financialOverview?.expenses) | number:'1.2-2' }}€
              </p>
              <p>
                {{ 'CASES.editor.outcome.product_summary.risk_result.label' | translate }}:
                <span *ngIf="isProductRequirementFulfilled(case, product, 1)" class="badge badge-success">
                  {{ 'CASES.editor.outcome.product_summary.risk_result.no_risk' | translate }}
                </span>
                <span *ngIf="!isProductRequirementFulfilled(case, product, 1)" class="badge badge-danger">
                  {{ 'CASES.editor.outcome.product_summary.risk_result.at_risk' | translate }}
                </span>
              </p>
            </div>
            <div class="requirement_2 mt-3">
              <h2 class="font-weight-bold">
                {{ 'CASES.editor.outcome.product_summary.lso_payment_plan.requirement_2.heading' | translate }}
              </h2>
              <p>
                {{ 'CASES.financial_overview.table.max_installments' | translate }}:
                {{ financialOverview?.max_installments_count }} {{ 'SHARED.months' | translate }}
              </p>
              <p>
                {{ 'CASES.financial_overview.table.amortisation_with_di' | translate }}:
                {{ financialOverview?.amortisation_with_disposable_income | number:'1.2-2' }}€
              </p>
              <p>
                {{ 'CASES.financial_overview.table.non_liquidable_assets' | translate }}:
                {{ (financialOverview?.assets - financialOverview?.liquidable_assets) | number:'1.2-2' }}€
              </p>
              <p>
                {{ 'CASES.financial_overview.table.liquidable_assets' | translate }}:
                {{ financialOverview?.liquidable_assets | number:'1.2-2' }}€
              </p>
              <p>
                {{ 'CASES.financial_overview.table.unsecured_debt' | translate }}:
                {{ financialOverview?.unsecured_debt | number:'1.2-2' }}€
              </p>
              <p>
                {{ 'CASES.editor.outcome.product_summary.lso_payment_plan.requirement_2.total_payment_plan_payable' | translate }}
                :
                {{ (financialOverview?.amortisation_with_disposable_income +
                financialOverview?.liquidable_assets) | number:'1.2-2' }}€
              </p>
              <!--<p *ngIf="!areAssetsPayable()">-->
              <!--  {{ 'CASES.editor.outcome.product_summary.lso_payment_plan.requirement_2.are_assets_payable' | translate }}:-->
              <!--  <span class="badge badge-danger">-->
              <!--    {{ 'CASES.editor.outcome.product_summary.risk_result.at_risk' | translate }}-->
              <!--  </span>-->
              <!--</p>-->
              <!--<p *ngIf="!isUnsecuredDebtPayable()">-->
              <!--  {{ 'CASES.editor.outcome.product_summary.lso_payment_plan.requirement_2.is_unsecured_debt_payable' | translate }}:-->
              <!--  <span class="badge badge-danger">-->
              <!--    {{ 'CASES.editor.outcome.product_summary.risk_result.at_risk' | translate }}-->
              <!--  </span>-->
              <!--</p>-->
              <p>
                {{ 'CASES.editor.outcome.product_summary.risk_result.label' | translate }}:
                <span *ngIf="isProductRequirementFulfilled(case, product, 2)" class="badge badge-success">
                  {{ 'CASES.editor.outcome.product_summary.risk_result.no_risk' | translate }}
                </span>
                <span *ngIf="!isProductRequirementFulfilled(case, product, 2)" class="badge badge-danger">
                  {{ 'CASES.editor.outcome.product_summary.risk_result.at_risk' | translate }}
                </span>
              </p>
            </div>
            <div class="requirement_3 mt-3">
              <h2 class="font-weight-bold">
                {{ 'CASES.editor.outcome.product_summary.lso_payment_plan.requirement_3.heading' | translate }}
              </h2>
            </div>
          </div>
          <!-- TODO: LSO - Liquidation Specific Info -->
          <div *ngIf="product.slug === 'lso-lq'"></div>
          <div *ngIf="product.slug === 'dgs-dm' || product.slug === 'dgs-mixed'">
            <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.expected_term_label' | translate }}:
                {{ expectedTerm | number:'1.2-2'}} {{ 'SHARED.months' | translate }}
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.financial_overview.table.disposable_income' | translate }}:
                {{ (financialOverview?.income - financialOverview?.expenses) | number:'1.2-2' }}€
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.financial_overview.table.unsecured_debt' | translate }}:
                {{ financialOverview?.unsecured_debt | number:'1.2-2' }}€
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.unsecured_creditor_count' | translate }}:
                {{ unsecuredCreditorsCount }}
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.claim_creditor_count' | translate }}:
                {{ claimCreditorsCount }}
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.creditor_amount_balance' | translate }}:
                {{ creditorAmountBalance }}
              </p>
            </div>
          </div>
          <!-- TODO: DGS Claim dynamic info -->
          <div *ngIf="product.slug === 'dgs-claim'">
            <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.claim_creditor_count' | translate }}:
                {{ claimCreditorsCount }}
              </p>
            </div>
            <div class="d-flex">
              <p>
                {{ 'CASES.editor.outcome.product_summary.creditor_amount_balance' | translate }}:
                {{ creditorAmountBalance }}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="product.slug === 'cajaplus'">
          <p>
            {{'CASES.editor.outcome.previous_monthly_payment' | translate}}:
            {{(financialOverview?.unsecured_monthly_payment) | number:'1.2-2'}}€
          </p>
          <p>
            {{'CASES.editor.outcome.new_monthly_payment' | translate}}:
            {{ legalFees | currency }}
          </p>
          <p>
            {{'CASES.editor.outcome.term_of_repayment_plan' | translate}}:
            {{ this.getRepaymentPlan() }}
          </p>
        </div>
        <div *ngIf="product.slug === 'unified'">
          <p>
            {{'CASES.editor.outcome.existing_debt' | translate}}:
            {{(financialOverview?.unsecured_debt) | number:'1.2-2'}}€
          </p>
          <p>
            {{'CASES.editor.outcome.renegotiated_debt' | translate}}:
            {{renegotiatedDebt | number:'1.2-2'}}€
          </p>
          <p>
            {{'CASES.editor.outcome.monthly_repayable' | translate}}:
            {{monthlyRepayable | number:'1.2-2'}}€
          </p>
        </div>
        <mat-divider class="mt-2 mb-5"></mat-divider>
        <div [innerHtml]="product.description" *ngIf="product.group_slug !== 'cajaplus'"></div>
        <div *ngIf="product.group_slug === 'cajaplus'">
          <p [ngStyle]="{'font-size': product.group_slug === 'cajaplus' ? '20px' : ''}">
            {{'CASES.editor.outcome.cajaplus_text_one' | translate}} <span
            class="disposableIncome"><b>{{ legalFees | currency }}</b></span> {{'CASES.editor.outcome.cajaplus_text_for' | translate}}
            <span class="numberOfMonths">
              <b>{{getRepaymentPlan()}}</b>
            </span> {{'CASES.editor.outcome.cajaplus_text_meses' | translate}}
            <br><br>
            {{'CASES.editor.outcome.cajaplus_text_two' | translate}}
            <br><br>
            {{'CASES.editor.outcome.cajaplus_text_three' | translate}}
            <br><br>
            {{'CASES.editor.outcome.cajaplus_text_four' | translate}}
            <br><br>
            {{'CASES.editor.outcome.cajaplus_text_five' | translate}}
          </p>
        </div>
        <!-- Select Product -->
        <div *ngIf="isProductSelectable(product)" class="d-flex justify-content-center">
          <button mat-button mat-raised-button color="primary" (click)="selectOutcome(product)" [disabled]="case.locked_no_products == true">
            {{ 'CASES.editor.outcome.select_outcome' | translate }}
          </button>
        </div>
        <!--Cajaplus not selectable error text-->
        <div *ngIf="!isCajaplusSelectable && product.group_slug === 'cajaplus'">
          <p
            class="cajaplus-error">{{'CASES.editor.outcome.product_cant_be_selected' | translate}} {{minDisposableIncome}}
            .</p>
        </div>
        <!-- Is Selected -->
        <div *ngIf="selectedProductControl.value === product.id && case.product_assigned_at" class="text-center"
             style="margin-top: auto">
          <mat-icon style="font-size: 150px; width: auto; height: auto" class="mat-icon-size" color="primary">
            check_circle
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
