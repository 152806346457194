import { NgModule } from '@angular/core';
import { SharedModule } from '../../../_shared/shared.module';
import { NegotiatorsRoutingModule } from './negotiators-routing.module';
import { NegotiatorsComponent } from './negotiators.component';

@NgModule({
  declarations: [NegotiatorsComponent],
  imports:      [
    SharedModule,
    NegotiatorsRoutingModule,
  ],
})
export class NegotiatorsModule {
}